import React from 'react'
import DpAccordincatlog from './DpAccordinCatlog'
import { Box } from '@chakra-ui/react'

const DpCatlog = () => {
  return (
    <Box pt="10px">
        <DpAccordincatlog/>
    </Box>
  )
}

export default DpCatlog