import { Box, Flex, Grid, Text, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderData } from "../../../redux/Admin/AppReducer/orders/action";

const AllOrderDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const order = useSelector((state) => state.OrderReducer.order);
  const toast=useToast()
  const navigate=useNavigate()

  useEffect(() => {
    dispatch(
      getOrderData(
        `${process.env.REACT_APP_API_URL}/order/${id}/suborders`
      ,toast,navigate)
    );
  }, []);

  return (
    <Grid
      templateColumns={{
        base: "repeat(1,1fr)",
        md: "repeat(2,1fr)",
        lg: "repeat(2,1fr)",
      }}
      gap={{ base: "10px", md: "2rem", lg: "4rem" }}
    >
      {order?.suborders?.map((item, i) => (
        <Flex
          key={i}
          direction="column"
          textAlign="left"
          gap="10px"
          borderRadius="7px"
          p="5"
          paddingLeft="2rem"
          boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
          backgroundColor="white"
          mb="20px"
        >
          <Flex gap="20px" alignItems="center">
            <Text fontSize="20px" fontWeight="bold">
              ID :
            </Text>
            <Text>{item.suborder_id}</Text>
          </Flex>

          <Flex gap="20px" alignItems="center">
            <Text fontSize="20px" fontWeight="bold">
              Shipping Country:
            </Text>
            <Text>{item.shipping_address.country}</Text>
          </Flex>

          <Flex gap="20px" alignItems="center">
            <Text fontSize="20px" fontWeight="bold">
              Shipping City:
            </Text>
            <Text>{item.shipping_address.city}</Text>
          </Flex>

          <Flex gap="20px" alignItems="center">
            <Text fontSize="20px" fontWeight="bold">
              Qty :
            </Text>
            <Text>{item.line_items.length}</Text>
          </Flex>

          <Text fontSize="20px" fontWeight="bold">
            Products
          </Text>

          <Box>
            {item.line_items.map((prod, i) => (
              <Flex
                flexDirection="column"
                key={i}
                mb="10px"
                p={4}
                border="1px solid black"
                borderRadius="7px"
              >
                <Flex gap="20px" alignItems="center">
                  <Text fontSize="16px" fontWeight="bold">
                    title :
                  </Text>
                  <Text>{prod.name}</Text>
                </Flex>

                <Flex gap="20px" alignItems="center">
                  <Text fontSize="16px" fontWeight="bold">
                    Price :
                  </Text>
                  <Text>{prod.name}</Text>
                </Flex>

                <Flex gap="20px" alignItems="center">
                  <Text fontSize="16px" fontWeight="bold">
                    Vendor :
                  </Text>
                  <Text>{prod.vendor}</Text>
                </Flex>

                <Flex gap="20px" alignItems="center">
                  <Text fontSize="16px" fontWeight="bold">
                    Sku :
                  </Text>
                  <Text>{prod.sku}</Text>
                </Flex>
              </Flex>
            ))}
          </Box>

          {/* <Flex gap="20px" alignItems="center">
                  <Text fontSize="20px" fontWeight="bold">Title :</Text>
                  <Text>{item.name}</Text>
                  </Flex>

                  <Flex gap="20px" alignItems="center">
                  <Text fontSize="20px" fontWeight="bold">vendor:</Text>
                  <Text>{item.vendor}</Text>
                  </Flex>

                  
                  
                  <Flex gap="20px" alignItems="center">
                  <Text fontSize="20px" fontWeight="bold">TimeSlot :</Text>
                  <Text>{item.timeslot}</Text>
                  </Flex>

                  <Flex gap="20px" alignItems="center">
                  <Text fontSize="20px" fontWeight="bold"> Variant : </Text>
                  <Text>{item.variant_title}</Text>
                  </Flex>
                   */}
        </Flex>
      ))}
    </Grid>
  );
};

export default AllOrderDetail;
