import React from 'react'
import DpOrdersStatus from '../Components/DpOrders/DpOrdersStatus'

const DpAttemptedOrders = () => {
  return (
    <div><DpOrdersStatus
    order_status="attempted"
    heading={"Attempted Orders"}
    selection={true}
    challanshow={false}

    button_action={["fulfilled"]}
    buttons_line={[ "Delivered"]}
    modal_fn={[
      {

        heading: "Delivered",
        action: "Are You Sure You want to Mark This Order As Delivered",
        ConfirmButton: "Delivered",
        color: "whatsapp",
        toast_notification: "Order Delivered",
      },
    ]}

    remark={false}

  /></div>
    
  )
}

export default DpAttemptedOrders