import React from 'react'
import DpOrdersStatus from '../Components/DpOrders/DpOrdersStatus'

const DpOutForDeliveryOrders = () => {
  return (
    <div>
        <DpOrdersStatus
        order_status="out_for_delivery"
        heading={"Out For Delivery Orders"}
        selection={true}
        challanshow={false}
        button_action={["attempted","fulfilled"]}
        buttons_line={["Attempted", "Delivered"]}
        modal_fn={[
          {
            heading: "Attempted",
            action: "Are You Sure You want to Mark This Order As Attempted",
            ConfirmButton: "Attempted",
            color: "whatsapp",
            toast_notification: "Order Marked Attempted",
          },
          {
            heading: "Delivered",
            action: "Are You Sure You want to Mark This Order As Delivered",
            ConfirmButton: "Delivered",
            color: "whatsapp",
            toast_notification: "Order Delivered",
          },
        ]}

        remark={true}

      />
    </div>
  )
}

export default DpOutForDeliveryOrders