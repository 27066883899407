import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import ConfirmationModal from "../../../Fucntions/Modals/ConfirmationModal";
import axios from "axios";
import { setToast } from "../../../Fucntions/Toastfunction";

const OrderCards = ({ data, refreshdata, setRefreshdata }) => {
  const toast = useToast();
  let role = JSON.parse(sessionStorage.getItem("role"));
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("bakertoken"))}`,
  };
  const [readyId, setReadyId] = useState(null);
  function ForFindingProductDescription(arr) {
    const prodDescription = arr.find(
      (property) => property.name.trim() === "_product_description"
    );
    if (prodDescription.value === "null") {
      return null;
    }

    if (prodDescription) {
      return prodDescription.value;
    }

    return null; // Return null if no image property was found
  }

  function ReadybuttonIsdisabledLogic(item) {
    let id = item.id;
    let value_to_return = data.line_item_product_status?.find(
      (k) => k.line_item_id == id
    )?.status;

    return value_to_return;
  }

  const [confirmation, setConfirmation] = useState(false);
  const onOpenConfirmation = (id) => {
    setConfirmation(true);
    setReadyId(id);
  };

  const onCloseConfirmation = () => {
    setConfirmation(false);
  };

  const [productReadyLoader, setProductReadyLoader] = useState(false);
  function ProductIsReadyHitApi() {
    if (productReadyLoader) {
      return;
    }
    setProductReadyLoader(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/baker/productready`,
      data: {
        suborder_id: data.suborder_id,
        line_item_prod_id: readyId,
      },
      headers,
    })
      .then((r) => {
        console.log(r.data);
        setToast(toast, "Updated successfully", "", "success");
        setProductReadyLoader(false);
        onCloseConfirmation();
        setRefreshdata(!refreshdata);
      })
      .catch((err) => {
        console.log(err);
        setToast(toast, `${err.message}`, "", "error");
        setProductReadyLoader(false);
        onCloseConfirmation();
        setRefreshdata(!refreshdata);
      });
  }

  function tocheckprodiscakeornot(item) {
    let showornot;
    let prod_type = item.find((k) => k.name === "_product_type")?.value;
    if (role.toLowerCase().trim() === "cakes") {
      if (
        prod_type.toLowerCase().trim() === "cake" ||
        prod_type.toLowerCase().trim() === "cakes" ||
        prod_type.toLowerCase().trim() === "flowerandcake" ||
        prod_type.toLowerCase().trim() === "flowersandcakes"
      ) {
        showornot = true;
      } else {
        showornot = false;
      }
    } else {
      if (
        prod_type.toLowerCase().trim() === "cake" ||
        prod_type.toLowerCase().trim() === "cakes"
      ) {
        showornot = false;
      } else {
        showornot = true;
      }
    }
    return showornot;
  }

 

  function tofindppandwp(variant, type, arr) {
    let value;
    if (type === "wp") {
      value = arr.find((k) => k.variant_id === variant)?.wp || null;
    } else {
      value = arr.find((k) => k.variant_id === variant)?.price || null;
    }
    return value;
  }
  return (
    <><ConfirmationModal
    heading="Prodcut Ready"
    action="Are you Sure? This is Ready"
    ConfirmButton="Ready"
    onClickFunction={ProductIsReadyHitApi}
    isOpen={confirmation}
    onClose={onCloseConfirmation}
    loader={true}
    loading={productReadyLoader}
    color={"green"}
  />
 
    <Box
      boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
      borderRadius="10px"
      mb="20px"
      bg="white"
      pb="10px"
    >
      
      {/* BOX FOR ORDER NO  */}
   
      <Box bg="#7E8035" p="10px">
        <Flex gap="10px">
          <Text color="white" fontWeight="semibold">
            Order No:{" "}
          </Text>
          <Text color="white">{data.suborder_name}</Text>
        </Flex>
      </Box> 
      <Grid templateColumns="repeat(1,1fr)">
        {data.line_items.map((item, i) => (
          <>
            {tocheckprodiscakeornot(item.properties) ? (
              <Box key={i}>
                {/* Box FOR QUANTITY AND NAME */}
                <Box bg="#EEEEEE" p="10px">
                  <Flex gap="50px">
                    <Text fontWeight="bold">{item.title}</Text>
                    <Flex gap="5px">
                      <Text> QTY :</Text>
                      <Box>
                        <Text>{item.fulfillable_quantity}</Text>
                      </Box>
                    </Flex>
                  </Flex>
                </Box>
                {/* BOX FOR DATE PP WP AND SLOT  */}
                <Box mt="5px" pl="10px" pr="30px" mb="5px">
                  <Flex justifyContent="space-between">
                    <Flex>
                      <Text fontWeight="semibold">Delivery Date :</Text>
                      <Text>{data.to_be_shipped_on}</Text>
                    </Flex>
                    {tofindppandwp(
                      item.variant_id,
                      "wp",
                      data?.variant_id_and_pp_wp
                        ? data.variant_id_and_pp_wp
                        : []
                    ) && (
                      <Flex gap="5px">
                        <Text fontWeight="semibold">WP : </Text>
                        <Text fontWeight="semibold">
                          {tofindppandwp(
                            item.variant_id,
                            "wp",
                            data?.variant_id_and_pp_wp
                              ? data.variant_id_and_pp_wp
                              : []
                          )}
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Flex>
                      <Text fontWeight="semibold">Slot :</Text>
                      <Text>{data.time_slot}</Text>
                    </Flex>
                    {tofindppandwp(
                      item.variant_id,
                      "pp",
                      data?.variant_id_and_pp_wp
                        ? data.variant_id_and_pp_wp
                        : []
                    ) && (
                      <Flex gap="5px">
                        <Text fontWeight="semibold">PP :</Text>
                        <Text fontWeight="semibold">
                          {tofindppandwp(
                            item.variant_id,
                            "pp",
                            data?.variant_id_and_pp_wp
                              ? data.variant_id_and_pp_wp
                              : []
                          )}
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Box>
                {/* BOX FOR PRODUCT IMAGE AND DESCRIPTION*/}
                <Box pl="10px" pr="10px">
                  <Flex gap="20px">
                    <Box>
                      <Image
                        src={
                          item.properties.filter(
                            (k) => k.name.trim() === "_product_image"
                          )[0]?.value
                        }
                        width="200px"
                        height="200px"
                      />
                    </Box>
                    <Box
                      textAlign="left"
                      color="#2b2b2b"
                      marginLeft="15px"
                      fontSize="14px"
                      flex={5}
                      dangerouslySetInnerHTML={{
                        __html: ForFindingProductDescription(item.properties),
                      }}
                    ></Box>
                  </Flex>
                  <Flex flexWrap="wrap">
                    {data.notes && (
                      <Text
                        fontWeight="bold"
                        marginY="10px"
                        paddingTop="10px"
                        textAlign="left"
                        color="red"
                        fontSize="18px"
                        width="100%"
                      >
                        Special Instructions : {data.notes}
                      </Text>
                    )}
                    
                    {item.properties.find((k) => k.name === "_product_type")
                      ?.value === "cakes" ||
                    item.properties.find((k) => k.name === "_product_type")
                      ?.value === "cake" ? (item.properties.find((k) => k.name === "_message")
                      ?.value).replace(/"/g, '') && (
                      <Text
                        fontWeight="bold"
                        
                        paddingTop="10px"
                        textAlign="left"
                        
                        fontSize="18px"
                        width="100%"
                      >
                      Message on Cake : {(item.properties.find((k) => k.name === "_message")
                      ?.value).replace(/"/g, '')}
                      </Text>
                    ) : null}
                  </Flex>
                </Box>
                {/* BOX FOR BUTTON  */}
                <Box margin="auto" pb="20px" pt="10px">
                  <Button
                    bg="#7E8035"
                    p="5px"
                    borderRadius="20px"
                    color="white"
                    width="80px"
                    isDisabled={ReadybuttonIsdisabledLogic(item) || false}
                    onClick={() => onOpenConfirmation(item.id)}
                  >
                    Ready
                  </Button>
                </Box>
              </Box>
            ) : null}
          </>
        ))}
      </Grid>
    </Box>
  
    </>
  );
};

export default OrderCards;
