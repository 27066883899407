import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { setToast } from "../../../Fucntions/Toastfunction";
const ChangePasswordModal = ({ isOpen, onClose, id }) => {
  const toast = useToast();
  const [passwordData, setPasswordData] = useState({
    password: "",
    confirm_password: "",
  });

  function handlepasswordchange(e) {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  }
  const [passloader, setPassLoader] = useState(false);
  function ChangePasswordHitApi() {
    if (passwordData.password === "" || passwordData.confirm_password === "") {
      setToast(toast, "Password Should Not Be Empty", "", "error");
      return;
    }
    if (passwordData.password !== passwordData.confirm_password) {
      setToast(toast, "Both Password Should Be Same", "", "error");
      return;
    }
    if (passloader) {
      return;
    }
    let data = {
      vendor_id: sessionStorage.getItem("id"),
      id: id,
      password: passwordData.password,
    };
    setPassLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("partnertoken")
      )}`,
    };
    axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/baker/changepassword`,
      data,
      headers,
    })
      .then((r) => {
        setToast(toast, `password Update Successfull`, "", "success");
        console.log(r.data);
        setPassLoader(false);
        onClose();
      })
      .catch((err) => {
        console.log(err);
        setToast(toast,"please try again",'','error')
        setPassLoader(false);
        onClose();
      });
    setPasswordData({
      password: "",
      confirm_password: "",
    });
  }

  return (
    <div>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily="NexaText-Trial-bold" color="gray">
            Chage Password
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody color="gray.500">
            <FormControl mb="10px" isRequired>
              <FormLabel>password</FormLabel>
              <Input
                placeholder="Enter Password"
                name="password"
                onChange={handlepasswordchange}
              />
            </FormControl>

            <FormControl mb="10px">
              <FormLabel>Confirm Password</FormLabel>
              <Input
                onChange={handlepasswordchange}
                placeholder="Confirm Password"
                name="confirm_password"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              color="#A1BE28"
              variant="outline"
              border="1px solid #A1BE28"
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              bg="#A1BE28"
              color="white"
              _hover={{
                bg: "white",
                color: "#A1BE28",
                border: "1px solid #A1BE28",
              }}
              onClick={ChangePasswordHitApi}
            >
              {passloader ? <Spinner /> : "Save"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ChangePasswordModal;
