import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Divider,
  Box,
  Heading,
  Card,
  Grid,
  Flex,
  useToast,
  Spinner,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  OrderAfterStartOrderEditing,
  SetSelectedTimeSLot,
  getOrderData,
} from "../../../../redux/Admin/AppReducer/orders/action";
import axios from "axios";
import { setToast } from "../../../../Fucntions/Toastfunction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ModalforDeliveryAddress from "./ModalforDeliveryAddress";

const ModalForDeliverySlot = ({ isOpen, onClose }) => {
  const [showdiscount, setShowDiscount] = useState(false);
  const [radioval, setRadioval] = useState("percentage");
  const [discountamount, setDiscountAmount] = useState(null);
  const { id } = useParams();
  const { pathname } = useLocation();
  // console.log(showdiscount, radioval, discountamount, "loc");
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prodadded = useSelector(
    (state) => state.OrderReducer.Order_Editing_Added_Product
  );
  const note_attr = useSelector((state) => state.OrderReducer.Order_noteAttr);
  // console.log(note_attr, "note_atte");
  const variantId = useSelector(
    (state) => state.OrderReducer.Order_Editing_Variantid_Of_Product
  );
  const DeliverySlot = useSelector(
    (state) => state.OrderReducer.Order_Editing_Delivery_Slot
  );
  //console.log(DeliverySlot,"slot")
  const cityDateDetail = useSelector(
    (state) => state.OrderReducer.Order_Editing_CityDate_Detail
  );
  let [changecolor, setChangecolor] = useState([]);

  const [DeliveryDetails, setDeliveryDetails] = useState({
    time_slot: "",
    shipping_date: "",
    city: "",
    shipping_id: "",
    shipping_method: "",
  });

  function handleDeliverySlot(id, slot, item) {
    let arr = [];
    arr.push(slot);
    setChangecolor(arr);
    setDeliveryDetails((prev) => ({
      ...prev,
      time_slot: slot,
      shipping_id: id,
      shipping_method: item.name,
    }));
    let obj = {
      time_slot: slot,
      shipping_method: item.name,
    };
    dispatch(SetSelectedTimeSLot(obj));
  }
  useEffect(() => {
    setDeliveryDetails((prev) => ({
      ...prev,
      city: cityDateDetail.city,
      shipping_date: cityDateDetail.date,
    }));
  }, [DeliverySlot]);

  const [address, setAddress] = useState({
    fname: "",
    lname: "",
    address1: "",
    address2: "",
  });

  const [processstart, setProcessstart] = useState(false);

  function handleHitApiWithData() {
    setProcessstart(true);
    setToast(toast, `Order Adding in process`, "", "info");
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    let data = {
      order_id: id,
      discount: showdiscount,
      discounttype: radioval,
      discountamount: discountamount,
      staffNote: reasonEdit,
      variantId,
      quantity: 1,
      product_image: prodadded.images[0],
      ...DeliveryDetails,
      address: `${address.fname + " " + address.lname},${address.address1},${
        address.address2
      }`,
    };
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/order/addNewVariants`,
      data,
      headers,
    })
      .then((r) => {
        //console.log(r)
        if (r.data.err) {
          setToast(toast, `${r.data.err}`, "", "error");
          setDeliveryDetails({
            time_slot: "",
            shipping_date: "",
            city: "",
            shipping_id: "",
          });
          setChangecolor([]);
          setProcessstart(false);
          return;
        }
        // console.log(r.data, "*******************rrrrrrrrrrrrrrrr");
        dispatch(OrderAfterStartOrderEditing(r.data));
        setDeliveryDetails({
          time_slot: "",
          shipping_date: "",
          city: "",
          shipping_id: "",
        });
        setChangecolor([]);
        setToast(toast, `Order Added Successfully`, "", "success");
        closeAddressModal();
        setProcessstart(false);
      })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        setToast(toast, `${err.response.data.err}`, "", "error");
        setProcessstart(false);
        return;
      });
    onClose();
  }
  const [reasonEdit, setReasonEdit] = useState(null); //Reason For Edit
  let [cityloader, setCityLoader] = useState(false);

  // ************************   FOR CITY EDIT ONLY    ***********************
  let [reason, setReason] = useState("");
  function handleOnChange(e) {
    if (e.target.value.charAt(0) === " ") {
      // Remove the first character (space)
      e.target.value = e.target.value.slice(1);
      return;
    }
    setReason(e.target.value);
  }
  // console.log(DeliveryDetails, "detail");
  function EditCityHitApi() {
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    if (cityloader) {
      return;
    }
    setCityLoader(true);
    let new_noteattr=note_attr.map((item)=>{
      if(item.name==="shipping_date"){
        return {...item,value: DeliveryDetails.shipping_date}
      }
      if(item.name==="shipping_time"){
        return {...item,value: DeliveryDetails.time_slot}
      }
      if(item.name==="shipping_method"){
        return {...item,value:DeliveryDetails.shipping_method,}
      }
      return item
      })
    let data = {
      id,
      note_attr:new_noteattr,
      order_id: id,
      line_item_id: prodadded.id,
      action: "shipping",
      staffNote: reason,
      properties: [
        {
          name: "city",
          value: DeliveryDetails.city,
        },
        {
          name: "shipping_id",
          value: DeliveryDetails.shipping_id,
        },
        {
          name: "shipping_date",
          value: DeliveryDetails.shipping_date,
        },
        {
          name: "shipping_method",
          value: DeliveryDetails.shipping_method,
        },
        {
          name: "time_slot",
          value: DeliveryDetails.time_slot,
        },
      ],
    };
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/order/editshipping`,
      data,
      headers,
    })
      .then((r) => {
        setToast(toast, "Data Successfully Changed", "", "success");
        dispatch(
          getOrderData(
            `${process.env.REACT_APP_API_URL}/order/read/${id}`,
            toast,
            navigate
          )
        );
        setCityLoader(false);
        setDeliveryDetails({
          time_slot: "",
          shipping_date: "",
          city: "",
          shipping_id: "",
        });
        setReason("");
        setChangecolor([]);
        onClose();
      })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        // console.log(err);
        setCityLoader(false);
        setDeliveryDetails({
          time_slot: "",
          shipping_date: "",
          city: "",
          shipping_id: "",
        });
        setReason("");
        setChangecolor([]);
        setToast(toast, `${err.response.data.err}`, "", "error");
      });

    //   let new_noteattr=note_attr.map((item)=>{
    //   if(item.name==="shipping_date"){
    //     return {...item,value: DeliveryDetails.shipping_date}
    //   }
    //   if(item.name==="shipping_time"){
    //     return {...item,value: DeliveryDetails.time_slot}
    //   }
    //   if(item.name==="shipping_method"){
    //     return {...item,value:DeliveryDetails.shipping_method,}
    //   }
    //   return item
    //   })
    //   console.log(new_noteattr,"new")
    //  let main_data={
    //   id,
    //   note_attr:new_noteattr
    //  }
    //     axios({
    //       method: "POST",
    //       url: `${process.env.REACT_APP_API_URL}/order/editnoteattr`,
    //       data:main_data,
    //       headers
    //     })
    //     .then((r) => {
    //       setToast(toast, "Data Successfully Changed", "", "success");
    //       dispatch(
    //         getOrderData(
    //           `${process.env.REACT_APP_API_URL}/order/read/${id}`,
    //           toast,
    //           navigate
    //         )
    //       );
    //       setCityLoader(false);
    //       setDeliveryDetails({
    //         time_slot: "",
    //         shipping_date: "",
    //         city: "",
    //         shipping_id: "",
    //       });
    //       setReason("");
    //       setChangecolor([]);
    //       onClose();
    //     })
    //     .catch((err) => {
    //       if (err.response.data.err === "Invalid token") {
    //         sessionStorage.removeItem("admintoken");
    //         navigate("/admin/login");
    //         return;
    //       }
    //       // console.log(err);
    //       setCityLoader(false);
    //       setDeliveryDetails({
    //         time_slot: "",
    //         shipping_date: "",
    //         city: "",
    //         shipping_id: "",
    //       });
    //       setReason("");
    //       setChangecolor([]);
    //       setToast(toast, `${err.response.data.err}`, "", "error");
    //     });
  }

  // ****************************************    FOR ADDRESS MODAL *****************************
  const [addressmodal, setAddressModal] = useState(false);
  const openAddressModal = () => {
    if (DeliveryDetails.time_slot) {
      onClose();
      setAddressModal(true);
    } else {
      setToast(toast, "Please Select any timeSlot", "", "info");
    }
  };
  const closeAddressModal = () => {
    setAddressModal(false);
  };

  return (
    <div>
      <ModalforDeliveryAddress
        onClose={closeAddressModal}
        isOpen={addressmodal}
        address={address}
        setAddress={setAddress}
        handleHitApiWithData={handleHitApiWithData}
        processstart={processstart}
        reasonEdit={reasonEdit}
        setReasonEdit={setReasonEdit}
        showdiscount={showdiscount}
        setShowDiscount={setShowDiscount}
        radioval={radioval}
        setRadioval={setRadioval}
        discountamount={discountamount}
        setDiscountAmount={setDiscountAmount}
      />

      <Modal
        closeOnOverlayClick={false}
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Delivery Time</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody pb={6} textAlign="left" mt="10px">
            <Box>
              {DeliverySlot?.map((item, i) => (
                <Card
                  boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px;"
                  key={i}
                  mb={i === DeliverySlot.length - 1 ? "0px" : "30px"}
                  p={2}
                >
                  <Heading as="h4" size="md" margin="auto" mb="10px">
                    {item?.name}
                  </Heading>
                  <Flex justifyContent={"space-between"}>
                    <Heading as="h6" size="xs" margin="auto" flex={8}>
                      {item?.message}
                    </Heading>
                    <Heading as="h6" size="xs" margin="auto" flex={2}>
                      price : {item?.price ? item.price : "Free"}
                    </Heading>
                  </Flex>
                  <Grid
                    templateColumns="repeat(3,1fr)"
                    margin="auto"
                    gap="20px"
                    mt="20px"
                    mb={i === 0 ? "30px" : "10px"}
                  >
                    {item?.time_slots?.map((slot, i) => (
                      <Flex
                        _hover={{ cursor: "pointer" }}
                        borderRadius="7px"
                        key={i}
                        bg={changecolor.includes(slot) ? "teal.100" : "white"}
                        p={2}
                        boxShadow="rgba(0, 0, 0, 0.15) 0px 2px 8px;"
                        justifyContent="center"
                        onClick={() => handleDeliverySlot(item.id, slot, item)}
                      >
                        {slot}
                      </Flex>
                    ))}
                  </Grid>
                </Card>
              ))}
              {pathname === `/admin/order/${id}` && (
                <FormControl>
                  <FormLabel>Reason For Edit</FormLabel>
                  <Input onChange={handleOnChange} />
                </FormControl>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            {pathname === `/admin/order/${id}` ? (
              <Button
                colorScheme="blue"
                mr={3}
                isDisabled={!reason || !DeliveryDetails.time_slot}
                onClick={() => {
                  EditCityHitApi();
                }}
              >
                {cityloader ? <Spinner /> : "Done"}
              </Button>
            ) : (
              <Button
                colorScheme="blue"
                mr={3}
                isDisabled={!DeliveryDetails.time_slot}
                onClick={() => {
                  openAddressModal();
                }}
              >
                Done
              </Button>
            )}
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ModalForDeliverySlot;
