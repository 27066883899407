import * as types from "./action.types";
const initialstate = {
  prod: [],
  singleProd: [],
  searched:[],
  isloading:"",
  isError:"",
};

export const ProductReducer = (state = initialstate, { type, payload }) => {
  
  switch (type) {
    case types.GET_PRODUCT_DATA_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.GET_PRODUCT_DATA_ERROR: {
      return {
        ...state,
        isError: true,
        isloading: false,
      };
    }
    case types.GET_PRODUCT_DATA_SUCCESS: {
      return {
        ...state,
        isloading: false,
        prod: payload,
      };
    }

    case types.GET_SINGLE_PRODUCT_DATA_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.GET_SINGLE_PRODUCT_DATA_ERROR: {
      return {
        ...state,
        isError: true,
        isloading: false,
      };
    }
    case types.GET_SINGLE_PRODUCT_DATA_SUCCESS: {
      return {
        ...state,
        isloading: false,
        singleProd: [payload],
      };
    }

    case types.SEARCHED_VENDOR_DATA_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.SEARCHED_VENDOR_DATA_ERROR: {
      return {
        ...state,
        isError: true,
        isloading: false,
      };
    }
    case types.SEARCHED_VENDOR_DATA_SUCCESS: {
      return {
        ...state,
        isloading: false,
        searched: payload,
      };
    }

    case types.UPDATE_VENDOR_DETAIL_IN_PRODUCT_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.UPDATE_VENDOR_DETAIL_IN_PRODUCT_ERROR: {
      return {
        ...state,
        isError: true,
        isloading: false,
      };
    }
    case types.UPDATE_VENDOR_DETAIL_IN_PRODUCT_SUCCESS: {
      return {
        ...state,
        isloading: false,
      };
    }

    case types.UPDATE_VARIANTS_DETAIL_IN_PRODUCTDETAILPAGE_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.UPDATE_VARIANTS_DETAIL_IN_PRODUCTDETAIL_PAGE_ERROR: {
      return {
        ...state,
        isError: true,
        isloading: false,
      };
    }
    case types.UPDATE_VARIANTS_DETAIL_IN_PRODUCTDETAIL_PAGE_SUCCESS: {
      return {
        ...state,
        isloading: false,
       singleProd:payload
      };
    }

    default: {
      return state;
    }
  }
};
