import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  FormLabel,
  Divider,
  Flex,
  Box,
  Text,
  Checkbox,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import {  OrderAfterStartOrderEditing } from "../../../../redux/Admin/AppReducer/orders/action";
import axios from "axios";
import { setToast } from "../../../../Fucntions/Toastfunction";
import { useNavigate, useParams } from "react-router-dom";

const ModalforAddCustomItem = ({ isOpen, onClose }) => {
  const [customdetail,setCustomDetail]=useState({
    name:"",
    price:"",
    quantity:"",
    taxable:false,
    shipping:false
  })
  const {id}=useParams()
  const toast=useToast()
  const dispatch=useDispatch()
  const navigate=useNavigate()
  function handlechagecustomitem(e){
    // console.log(e)
   const {name,value,checked}=e.target;
   if(name==="shipping" || name==="taxable"){
    setCustomDetail({
      ...customdetail,
      [name]:checked
     })
   }
   else{
    setCustomDetail({
      ...customdetail,
      [name]:value
     })
   }
  }
const [isLoading,setIsLoading]=useState(false)
  const EditLineItems = () => {
    if(isLoading){
      return
    }
    setIsLoading(true)
    setToast(toast,`Order Adding in process`,"","info")
    const headers = {
      "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
    };
    
    let data={...customdetail,order_id:id,title:customdetail.name
    }
    
    axios({
      method:"Put",
      url:`${process.env.REACT_APP_API_URL}/order/addCustomLineItem`,
      data,
      headers
    }) .then((r)=>{
      if(r.data.err){
        setToast(toast,`${r.data.err}`,"","error")
        return
      }
      console.log(r.data,"r.data")
   dispatch(OrderAfterStartOrderEditing(r.data))
     setToast(toast,`Order Added Successfully`,"","success")
     setCustomDetail({
      name:"",
      price:"",
      quantity:"",
      taxable:false,
      shipping:false
    })
    setIsLoading(false)
onClose()
    }).catch((err)=>{
      if(err.response.data.err==="Invalid token"){
        sessionStorage.removeItem('admintoken');
        navigate("/admin/login")
        return
      }
     setToast(toast,`Something Went Wrong`,"","error")
     setIsLoading(false)
    })
  };

const CloseModal=()=>{
    setCustomDetail({
      name:"",
      price:"",
      quantity:"",
      taxable:false,
      shipping:false
    })
    onClose()
  }

  return (
    <div>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        size="2xl"
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Custom Item</ModalHeader>
          <ModalCloseButton onClick={CloseModal}/>
          <ModalBody pb={6}>
            <Divider />
            <Flex gap="10px" mb="40px" mt="20px" textAlign="left">
              <Box flex={4}>
                <Text mb="10px">Item name</Text>
                <Input onChange={handlechagecustomitem} name="name"/>
              </Box>
              <Box flex={2}>
                <Text mb="10px">Price</Text>
                <Input type="number" onChange={handlechagecustomitem} name="price"/>
              </Box>
              <Box flex={2}>
                <Text mb="10px">Quantity</Text>
                <Input type="number" onChange={handlechagecustomitem} name="quantity"/>
              </Box>
            </Flex>
            <Box>
              <FormControl>
                <Flex gap="10px" alignItems="center">
                <Checkbox  size="lg" borderRadius="7px" onChange={handlechagecustomitem}
                name="taxable"/>
                <FormLabel pt="5px">Item is taxable</FormLabel>
                </Flex>
              </FormControl>
              <FormControl>
                <Flex gap="10px" alignItems="center">
                <Checkbox name="shipping" 
                size="lg" borderRadius="7px" onChange={handlechagecustomitem}/>
                <FormLabel pt="5px">Item requires shipping</FormLabel>
                </Flex>
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3}
            onClick={EditLineItems}
            isDisabled={customdetail.name==="" || customdetail.price==="" || customdetail.quantity===""}
          >
              {isLoading?<Spinner/>:"Save"}
            </Button>
            <Button onClick={CloseModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ModalforAddCustomItem;
