import React, { useState } from "react";
import OrderStatusComp from "./OrderStatusComp";

const PrintedOrders = () => {
  let [policy, setPolicy] = useState([]);
  let [buttons_name, setButtonname] = useState([]);
  let [button_modal, setButtonModal] = useState([]);
  return (
    <div>
      <OrderStatusComp
        status={"printed"}
        order_status={"printed_orders"}
        heading={"Printed Orders"}
        selection={true}
        challanshow={true}
        // button_action={[ "driver_allocated","rejected","accepted","allocated"]}
        // buttons_line={[ "Allocate Driver","Reject Orders","Accept Orders","Allocate Orders"]}
        // modal_fn={[
        //   {
        //     heading: "Allocate To Driver",
        //     action: "Are You Sure Your Want to Allocate this order to",
        //     ConfirmButton: "Confirm",
        //     color: "blue",
        //     toast_notification: "Order Allocated To Driver",
        //   },
        //   {
        //     heading: "Reject Order",
        //     action: "Are You Sure You want to Reject This order",
        //     ConfirmButton: "Reject Order",
        //     color: "red",
        //     toast_notification: "Order Rejected",
        //   },
        //   {
        //     heading: "Accept Order",
        //     action: "Are You Sure You want to Accept This order",
        //     ConfirmButton: "Accept Order",
        //     color: "whatsapp",
        //     toast_notification: "Order Accepted",
        //   },
        //   {
        //     heading: "Allocate Order",
        //     action: "Are You Sure You want to Allocate This order",
        //     ConfirmButton: "Allocated Order",
        //     color: "green",
        //     toast_notification: "Order Allocated",
        //   },
        // ]}
        button_action={policy}
        buttons_line={buttons_name}
        modal_fn={button_modal}
        setPolicy={setPolicy}
        setButtonModal={setButtonModal}
        setButtonname={setButtonname}
        policy={policy}
        buttons_name={buttons_name}
        button_modal={button_modal}
      />
    </div>
  );
};

export default PrintedOrders;
