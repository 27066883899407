import React, {  useState } from "react";
import { useNavigate, } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Flex,
} from "@chakra-ui/react";
import { AiFillHome, AiFillStop, AiFillTag } from "react-icons/ai";
import { BsFillCartFill, BsPeopleFill } from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";

import { FaCity } from "react-icons/fa";
import { MdLocalShipping } from "react-icons/md";

const Accordincatlog = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveItem(index);
  };

  return (
    <div>
      <Accordion width="210px" allowToggle>
        
        <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                navigate("/admin");
                handleAccordionClick(0);
              }}
              bg={activeItem === 0 ? "gray.200" : ""}
              color={activeItem === 0 ? "#7D8035" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <AiFillHome fontSize="20px" />
                  Home
                </Flex>
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                handleAccordionClick(1);
              }}
              bg={activeItem === 1 ? "gray.200" : ""}
              color={activeItem === 1 ? "#7D8035" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <BsFillCartFill fontSize="20px" />
                  Orders
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>

            <AccordionItem>
              <h2>
                <Flex
                  textAlign="left"
                  paddingLeft="18px"
                  height="40px"
                  alignItems="center"
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("admin/order/allorder");
                    handleAccordionClick(1.1);
                  }}
                  bg={activeItem === 1.1 ? "gray.200" : ""}
                  color={activeItem === 1.1 ? "#7D8035" : ""}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="bold"
                    fontSize="14px"
                  >
                    All Orders
                  </Box>
                </Flex>
              </h2>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <Flex
                  textAlign="left"
                  paddingLeft="18px"
                  height="40px"
                  alignItems="center"
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    handleAccordionClick(1.2);
                    navigate(`admin/order/suborder/${"all"}`)
                  }}
                  bg={activeItem === 1.2 ? "gray.200" : ""}
                  color={activeItem === 1.2 ? "#7D8035" : ""}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    
                    fontWeight="bold"
                    fontSize="14px"
                  >
                    All SubOrders
                  </Box>
                </Flex>
              </h2>
            </AccordionItem>

          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                navigate("/admin/products");
                handleAccordionClick(2);
              }}
              color={activeItem === 2 ? "#7D8035" : ""}
              bg={activeItem === 2 ? "gray.200" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <AiFillTag fontSize="20px" />
                  Products
                </Flex>
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                handleAccordionClick(3);
              }}
              bg={activeItem === 3 ? "gray.200" : ""}
              color={activeItem === 3 ? "#7D8035" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <IoIosPeople fontSize="20px" />
                  Administration
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <AccordionItem>
              <h2>
                <Flex
                  textAlign="left"
                  paddingLeft="18px"
                  height="40px"
                  alignItems="center"
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/admin/roles");
                    handleAccordionClick(3.1);
                  }}
                  bg={activeItem === 3.1 ? "gray.200" : ""}
                  color={activeItem === 3.1 ? "#7D8035" : ""}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="bold"
                    fontSize="14px"
                  >
                    Roles
                  </Box>
                </Flex>
              </h2>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <Flex
                  textAlign="left"
                  paddingLeft="18px"
                  height="40px"
                  alignItems="center"
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/admin/users");
                    handleAccordionClick(3.2);
                  }}
                  bg={activeItem === 3.2 ? "gray.200" : ""}
                  color={activeItem === 3.2 ? "#7D8035" : ""}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    onClick={() => navigate(`admin/order/suborder/${"all"}`)}
                    fontWeight="bold"
                    fontSize="14px"
                  >
                    Users
                  </Box>
                </Flex>
              </h2>
            </AccordionItem>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                navigate("/admin/vendor");
                handleAccordionClick(4);
              }}
              color={activeItem === 4 ? "#7D8035" : ""}
              bg={activeItem === 4 ? "gray.200" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <BsPeopleFill fontSize="20px" />
                  Vendors
                </Flex>
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                navigate("/admin/shippingmethods");
                handleAccordionClick(5);
              }}
              color={activeItem === 5 ? "#7D8035" : ""}
              bg={activeItem === 5 ? "gray.200" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <MdLocalShipping fontSize="22px" />
                  Shipping Methods
                </Flex>
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                navigate("/admin/cities");
                handleAccordionClick(6);
              }}
              color={activeItem === 6 ? "#7D8035" : ""}
              bg={activeItem === 6 ? "gray.200" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <FaCity fontSize="22px" />
                  City
                </Flex>
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                navigate("/admin/datetimeblocker");
                handleAccordionClick(7);
              }}
              color={activeItem === 7 ? "#7D8035" : ""}
              bg={activeItem === 7 ? "gray.200" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <AiFillStop fontSize="22px" />
                  Date Time Blocker
                </Flex>
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem>

      </Accordion>
    </div>
  );
};

export default Accordincatlog;
