export const manageNoteAttribute = (arr) => {
  console.log(arr,"ayur")
  let note_attributes = [
{name: 'cutoffTime', value: '5'},
{name: 'variantId', value: '44441557795065'},
    { name: "english_city", value: "" },
    { name: "keep Secret", value: "" },
    { name: "sender_name", value: "" },
    { name: "sender_phoneNum", value: "" },
    { name: "call recipient", value: "" },
    { name: "shipping_method", value: "" },
    { name: "shipping_date", value: "" },
    { name: "shipping_id", value: "" },
    { name: "shipping_time", value: "" },
    { name: "message_receiver", value: "" },
    { name: "gift_message", value: "" },
    { name: "message_sender", value: "" },
    {name: 'old_gift_message', value: ''}
  ];

  arr.map((item) => {
    note_attributes.find((k) => k.name === item.name).value = item.value;
  });

  return note_attributes;
};
