import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Box,
  Input,
  Image,
  Button,
  Text,
  InputGroup,
  InputRightElement,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AdminLogin } from "../../redux/AuthReducer/action";
import fnp_log_bg from "../../images/fnp_logo_bg.png"
import fnp_logo from "../../images/fnp_logo.jpg"

const Login = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [logincred, setLoginCred] = useState({
    username: "",
    password: "",
  });


  function handlechange(e) {
    const { name, value } = e.target;
    // Check if the first character is a space
    if (value.charAt(0) === ' ') {
     
      // Remove the first character (space)
      e.target.value = value.slice(1);
      return
    }
    setLoginCred({
      ...logincred,
      [name]: value,
    });
  }


  async function handleAdminLoginSave(e) {
    let nav = "/admin";
  

  await  dispatch(
      AdminLogin(
        `${process.env.REACT_APP_API_URL}/user/login`,
        logincred,
        toast,
        nav,
        navigate
      )
    );

  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAdminLoginSave()
    }
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box
        boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
        borderRadius="10px"
        width={{ base: "100%", md: "80%", lg: "400px" }}
        p="0px 20px 20px 20px"
        bg="white"
      >
        <Image
          src={fnp_logo}
          alt="fnp"
          height="70px"
          width="150px"
          margin="10px auto"
        />
        <FormControl>
          <FormLabel>User Name</FormLabel>
          <Input
            placeholder="Enter Email"
            name="username"
            onChange={(e) => handlechange(e)}
          />
        </FormControl>
        <br />
        <FormControl>
          <FormLabel>Password</FormLabel>
          <InputGroup size="md">
            <Input
              name="password"
              onChange={handlechange}
              pr="4.5rem"
              type={show ? "text" : "password"}
              placeholder="Enter password"
              onKeyDown={handleKeyDown}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <Button w="100%" mt="15px" onClick={handleAdminLoginSave}>
          {"Admin Login"}
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
