import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Checkbox,
  Divider,
  Image,
  Text,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setToast } from "../../../../Fucntions/Toastfunction";
import {
  AddProductAfterStartOrderEditing,
  OrderEditingAddProductAndVarinatId,
} from "../../../../redux/Admin/AppReducer/orders/action";
import ModalForCityAndDate from "../EditMainOrder/ModalForCityAndDate";
import { useNavigate } from "react-router-dom";

const ModalForProduct = ({ isOpen, onClose }) => {
  const [variantId, setVariantId] = useState([]);
  const [variant, setVariant] = useState([]);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [prodsearch, setProdSearch] = useState(1);
  const toast = useToast();
  const dispatch = useDispatch();
  console.log(products,"sa")

  // *************    MODAL FOR CITY AND DATE   *********************
  const [cityAndDateModalstatus, setCityAndDateModalStatus] = useState(false);

  const openCityAndDateModal = () => {
    if (variantId.length > 0) {
      dispatch(OrderEditingAddProductAndVarinatId([...variant, ...variantId]));
      onClose();
      setCityAndDateModalStatus(true);
      setVariantId([]);
      setProdSearch(1);
    } else {
      setToast(toast, "please Select any Variant First", "", "error");
    }
  };
  const closeCityAndDateModal = () => setCityAndDateModalStatus(false);

  function handleVariantAddRemove(e, variant, prod) {
    console.log(prod,"")
    let arr = [];
    let vararr = [];
    if (variantId.includes(variant.id)) {
      arr = [];
      vararr = [];
    } else {
      arr.push(variant.id);
      vararr.push(prod);
    }
    setVariantId(arr);
    setVariant(vararr);
  }

  const handleAddProductHitApi = () => {
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/order/addNewVariants`,
      data: {
        variantId: variantId[0],
        quantity: 1,
      },
      headers,
    })
      .then((r) => {
        //console.log(r)
        if (r.data.success.data.orderEditAddVariant.userErrors.length === 0) {
          // console.log(r.data.success.data.orderEditAddVariant.calculatedOrder,"***************************")
          dispatch(
            AddProductAfterStartOrderEditing(
              r.data.success.data.orderEditAddVariant.calculatedOrder
            )
          );
          setVariantId([]);
          setProdSearch(1);
          onClose();
        } else {
          setToast(
            toast,
            `${r.data.success.data.orderEditAddVariant.userErrors[0].message}`,
            "",
            "error"
          );
        }
      })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        // console.log(err);
        return;
      });
  };

  useEffect(() => {
    let timeoutId;
    const debounceSearch = () => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        const headers = {
          Authorization: `Bearer ${JSON.parse(
            sessionStorage.getItem("admintoken")
          )}`,
        };
        axios({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}/product/search?query=${prodsearch}`,
          headers,
        })
          .then((r) => {
            setProducts(r.data);
          })
          .catch((err) => {
            if (err.response.data.err === "Invalid token") {
              sessionStorage.removeItem("admintoken");
              navigate("/admin/login");
              return;
            }
            if (err.response.data.err) {
              setToast(toast, `${err.response.data.err}`, "", "error");
            } else {
              setToast(toast, "something Went Wrong", "", "error");
            }
          });
      }, 300);
    };
    debounceSearch();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [prodsearch]);

  function closeModal() {
    setVariantId([]);
    onClose();
    setProdSearch(1);
  }

  return (
    <>
      <ModalForCityAndDate
        isOpen={cityAndDateModalstatus}
        onClose={closeCityAndDateModal}
      />
      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        size={"2xl"}
        isOpen={isOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading
              as="h4"
              size="md"
              fontFamily="NexaText-Trial-Bold"
              mb="15px"
            >
              Add Products
            </Heading>
          </ModalHeader>
          <ModalCloseButton onClick={closeModal} />
          <ModalBody
            maxH="400px"
            overflowX="auto"
            overflowY="auto"
            whiteSpace="nowrap"
          >
            <Flex gap="20px">
              <InputGroup size="md">
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon color="gray.500" />}
                />
                <Input
                  pr="4.5rem"
                  placeholder="Search Products"
                  onChange={(e) => setProdSearch(e.target.value)}
                />
              </InputGroup>
              <Button variant="outline">Browse</Button>
            </Flex>
            <br />
            <Divider />
            {products.length > 0 ? (
              <TableContainer>
                <Table variant="simple">
                  <Tbody>
                    {products?.map((product, i) => (
                      <React.Fragment key={i}>
                        <FormLabel
                          htmlFor={`check_${i}`}
                          _hover={{ bgColor: "gray.100", cursor: "pointer" }}
                        >
                          <Tr>
                            <Td>
                              <Flex gap="20px">
                                <Image
                                  borderRadius="5px"
                                  src={product.images[0]}
                                  alt="image"
                                  height="40px"
                                  width="40px"
                                />
                              </Flex>
                            </Td>

                            <Td
                              width="330px"
                              whiteSpace="normal"
                              wordBreak="break-word"
                            >
                              {product.title}
                            </Td>
                            <Td> QAR {product.showPrice}</Td>
                            <Td></Td>
                          </Tr>
                        </FormLabel>
                        {product.variants.map((variant, j) => (
                          <FormLabel
                            _hover={{ bgColor: "gray.100", cursor: "pointer" }}
                            key={`${i}-${j}`}
                          >
                            <Tr>
                              <Td>
                                <Flex gap="20px" marginLeft="30px">
                                  <Checkbox
                                    isChecked={variantId.includes(variant.id)}
                                    size="md"
                                    onChange={(e) =>
                                      handleVariantAddRemove(
                                        e,
                                        variant,
                                        product
                                      )
                                    }
                                  />
                                  <Text>{variant.title}</Text>
                                </Flex>
                              </Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                            </Tr>
                          </FormLabel>
                        ))}
                      </React.Fragment>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Text
                color="red.400"
                fontSize="40px"
                fontWeight="semibold"
                mt="30px"
                pb="30px"
              >
                No Product Found
              </Text>
            )}
          </ModalBody>
          <ModalFooter gap="10px">
            <Button
              colorScheme="teal"
              // onClick={handleAddProductHitApi}
              onClick={openCityAndDateModal}
            >
              {" "}
              Done
            </Button>
            <Button
              onClick={closeModal}
              variant="outline"
              border="2px solid teal"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalForProduct;
