import React, { useEffect } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Icon,
  DrawerCloseButton,
  Image,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import fnp_logo from "../../images/fnp_logo.jpg";
import PartnerCatlog from "./PartnerCatlog";
  
  
  const PartnerSideDrawer = () => {
    const { pathname } = useLocation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [placement, setPlacement] = React.useState("left");
  
  
    // ////(pathname,"location")
    useEffect(() => {
      onClose();
    }, [pathname]);
    return (
      <div>
        <Icon
          w={"28px"}
          h={"28px"}
          mr={"-10px"}
          marginTop={"10px"}
          onClick={onOpen}
          as={GiHamburgerMenu}
        />
        <Drawer
          placement={placement}
          onClose={onClose}
          isOpen={isOpen}
          onOpen={onOpen}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth="1px">
              <Image
                src={fnp_logo}
                alt="fnp"
                height="70px"
                width="150px"
                margin="10px auto"
              />
              <DrawerCloseButton />
            </DrawerHeader>
            <DrawerBody>
              <PartnerCatlog />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </div>
    );
  };
  
  export default PartnerSideDrawer;