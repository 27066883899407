import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Flex,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Checkbox,
  Divider,
  Image,
  Text,
  FormLabel,
  useToast,
  Spinner,
  Input,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setToast } from "../../../../Fucntions/Toastfunction";
import { OrderAfterStartOrderEditing } from "../../../../redux/Admin/AppReducer/orders/action";
import { useNavigate, useParams } from "react-router-dom";


const ModalForAddOnProducts = ({ isOpen, onClose, prodId, setprodId, varId, setVariantId, parentId }) => {
  let { id } = useParams()
  const [loader, setLoader] = useState(false)
  const [selectedProd, setselectedProd] = useState([]);
  const [selectedProdId, setSelectedProdId] = useState("")
  const [products, setProducts] = useState([]);
  const [reasonedit, setReasonEdit] = useState(null)
  const toast = useToast();
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // *************    MODAL FOR CITY AND DATE   *********************
  // const [cityAndDateModalstatus,setCityAndDateModalStatus]=useState(false)


  // const closeCityAndDateModal=()=>setCityAndDateModalStatus(false)


  function handleAddRemoveAddOns(prod, id) {

    let arr = [];
    let pid = []
    if (selectedProdId.includes(id)) {
      arr = [];
      pid = []
    } else {
      arr.push(prod);
      pid.push(id)
    }
    setselectedProd(arr);
    setSelectedProdId(pid)
  }
  const handleAddoNProductHitApi = () => {
    setLoader(true)
    const headers = {
      "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
    };
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/product/addon/${prodId}`,
      headers
    })
      .then((r) => {
        setProducts(r.data);
        setLoader(false)
      })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        setToast(toast, `${err.response.data.err}`, "", "error");
        setLoader(false)
      });
  };

  const [addonLoader, setAddonLoader] = useState(false)
  function AddAddOnProducts() {
    if (addonLoader) {
      return
    }
    setAddonLoader(true)
    const headers = {
      "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
    };
    let data = {
      variantId: selectedProdId[0]?.split("/").slice(-1)[0],
      parent_id: varId,
      order_id: id,
      quantity: 1,
      product_image: selectedProd[0]?.featuredImage?.url,
      parent_line_item_id: parentId,
      staffNote: reasonedit
    }
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/order/addNewVariants`,
      data,
      headers,
    }).then((r) => {
      if (r.data.err) {
        setToast(toast, `${r.data.err}`, "", "error")
        setAddonLoader(false)
      } else {
        dispatch(OrderAfterStartOrderEditing(r.data));
        onClose()
        setAddonLoader(false)
        setReasonEdit(null)
        setToast(toast, "Addon Product Added successfully", "", "success")
      }

    })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        setToast(toast, `${err.response.data.err}`, "", "error")
        setAddonLoader(false)
      })
  }

  useEffect(() => {
    handleAddoNProductHitApi()
  }, []);

  function closeModal() {
    setprodId(null)
    setVariantId(null)
    setReasonEdit(null)
    onClose()
  }


  return (
    <div>
      <Modal closeOnOverlayClick={false} onClose={onClose} size={"2xl"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading
              as="h4"
              size="md"
              fontFamily="NexaText-Trial-Bold"
              mb="15px"
            >
              Add On Products
            </Heading>
          </ModalHeader>
          <ModalCloseButton onClick={closeModal} />
          <ModalBody
            maxH="400px"
            overflowX="auto"
            overflowY="auto"
            whiteSpace="nowrap"
          >
            <Divider />
            {loader ? <Text color="red.400" fontSize="40px"
              fontWeight="semibold" mt="30px" pb="30px">Loading ...</Text> :
              <TableContainer>
                <Table variant="simple">
                  <Tbody>
                    {products?.map((product, i) => (
                      <React.Fragment key={i}>
                        <FormLabel
                          htmlFor={`check_${i}`}
                          _hover={{ bgColor: "gray.100", cursor: "pointer" }}
                        >
                          <Tr>
                            <Td>
                              <Flex gap="20px">
                                <Image
                                  borderRadius="5px"
                                  src={product?.featuredImage?.url}
                                  alt="image"
                                  height="40px"
                                  width="40px"
                                />
                              </Flex>
                            </Td>

                            <Td
                              width="330px"
                              whiteSpace="normal"
                              wordBreak="break-word"
                            >
                              {product?.handle}
                            </Td>
                            <Td></Td>
                            <Td></Td>
                          </Tr>
                        </FormLabel>
                        {product?.variants?.edges?.map((variant, j) => (
                          <FormLabel
                            _hover={{ bgColor: "gray.100", cursor: "pointer" }}
                            key={`${i}-${j}`}
                          >
                            <Tr>
                              <Td>
                                <Flex gap="30px" marginLeft="30px">
                                  <Checkbox
                                    isChecked={selectedProdId.includes(variant?.node.id)}
                                    size="md"
                                    onChange={(e) =>
                                      handleAddRemoveAddOns(product, variant?.node.id)
                                    }
                                  />
                                  <Text>{variant?.node.title}</Text>
                                  <Text>SKU : {variant?.node.sku}</Text>
                                  <Text>Price : {variant?.node.price}</Text>
                                </Flex>
                              </Td>
                              <Td></Td>

                            </Tr>
                          </FormLabel>
                        ))}
                      </React.Fragment>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            }
          </ModalBody>
          <ModalFooter gap="50px">
            <Input placeholder="Write Reason For Edit" onChange={(e) => setReasonEdit((e.target.value))} />
            <Flex gap="20px">
              <Button colorScheme="teal"
                // tzonClick={handleAddProductHitApi}
                onClick={AddAddOnProducts}
                isDisabled={!reasonedit}
              >

                {addonLoader ? <Spinner /> : "Done"}
              </Button>
              <Button onClick={closeModal} variant="outline" border="2px solid teal">
                Close
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default ModalForAddOnProducts