import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Flex,
  FormLabel,
  Grid,
  Heading,
  IconButton,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AiOutlineUserAdd } from "react-icons/ai";
import CreateShops from "./CreateShops";
import axios from "axios";
import { FaUserCircle } from "react-icons/fa";
import { EditIcon } from "@chakra-ui/icons";
import { setToast } from "../../../Fucntions/Toastfunction";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../Fucntions/Modals/ConfirmationModal";
import ChangePasswordModal from "./changePasswordModal";
import EditShopDetails from "./EditShopDetails";
const Shop = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [shops, setShops] = useState([]);
  const [fetch, setFetch] = useState(false);
  const [createshopmodal, setCreateShopModal] = useState(false);
  const [confirmatiOnOpen, setConfirmationOpen] = useState(false);
  const [idForDeletingshop, setIdForDeletingShop] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [detailToBeEdit, setDetailToBeEdit] = useState({});
  const opencreateshopModal = () => {
    setCreateShopModal(true);
  };

  const closecreateshopModal = () => {
    setCreateShopModal(false);
  };

  const openConfirmationModal = (id) => {
    setConfirmationOpen(true);
    setIdForDeletingShop(id);
  };

  const closeConfirmationModal = () => {
    setConfirmationOpen(false);
  };

  const OpenEditModal = (item) => {
    setEditModal(true);
    setDetailToBeEdit(item);
  };

  const CloseEditModal = () => {
    setEditModal(false);
  };

  const [changedppasswordmodal, setChangedppasswordmodal] = useState(false);
  const [shoppartner_id, setshoppartner_id] = useState(null);

  const openChangePasswordModal = (id) => {
    setshoppartner_id(id);
    setChangedppasswordmodal(true);
  };
  const closeChangePasswordModal = () => {
    setChangedppasswordmodal(false);
  };

  let [shopLoader, setShopLoader] = useState(false);
  function getAllShopsDetail() {
    if (shopLoader) {
      return;
    }
    setShopLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("partnertoken")
      )}`,
    };
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/baker/all`,
      headers,
    })
      .then((r) => {
        console.log(r.data, "+++++++++++++++++++++");
        setShops(r.data.data);
        setShopLoader(false);
      })
      .catch((err) => {
        setShopLoader(false);
      });
  }

  const [delloader, setDelLoader] = useState(false);
  function handleDeletedpApiHit() {
    if (delloader) return;
    setDelLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("partnertoken")
      )}`,
    };
    axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/baker/delete`,
      data: {
        id: idForDeletingshop,
      },
      headers,
    })
      .then((r) => {
        setDelLoader(false);
        if (r.data.error) {
          setToast(toast, `${r.data.error}`, "", "error");
        }
        if (r.data.success) {
          setToast(toast, `${r.data.success}`, "", "success");
          closeConfirmationModal();
          setFetch(!fetch);
        }
      })
      .catch((err) => {
        setDelLoader(false);
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("partnertoken");
          navigate("partner/login");
          return;
        }
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  useEffect(() => {
    getAllShopsDetail();
  }, [fetch]);
  return (
    <div>
      <ConfirmationModal
        heading="Delete Partners Shop"
        action="Are you sure? You want to Delete This Shop"
        name=""
        onClickFunction={handleDeletedpApiHit}
        ConfirmButton="Yes Delete"
        onClose={closeConfirmationModal}
        isOpen={confirmatiOnOpen}
        loader={true}
        loading={delloader}
      />

      <ChangePasswordModal
        isOpen={changedppasswordmodal}
        onClose={closeChangePasswordModal}
        id={shoppartner_id}
      />

      <CreateShops
        isOpen={createshopmodal}
        onClose={closecreateshopModal}
        fetch={fetch}
        setFetch={setFetch}
      />

      <EditShopDetails
        setFetch={setFetch}
        detailToBeEdit={detailToBeEdit}
        setDetailToBeEdit={setDetailToBeEdit}
        isOpen={editModal}
        onClose={CloseEditModal}
        fetch={fetch}
      />
      <Heading
        textAlign="center"
        fontWeight="semibold"
        marginTop="-3"
        mb="20px"
      >
        Shops
      </Heading>
      <Flex>
        <Button
          leftIcon={<AiOutlineUserAdd fontSize="20px" />}
          fontWeight="semibold"
          // colorScheme="#A1BE28"
          bg="#A1BE28"
          color="white"
          _hover={{
            bg: "white",
            color: "#A1BE28",
            border: "1px solid #A1BE28",
          }}
          onClick={opencreateshopModal}
          fontSize={{ base: "12px", md: "16px", lg: "16px" }}
        >
          Add Shops
        </Button>
      </Flex>
      {shops.length > 0 ? (
        <Grid
          mt="30px"
          width="100%"
          templateColumns={[
            "repeat(1,1fr)",
            "repeat(2,1fr)",
            "repeat(2,1fr)",
            "repeat(2,1fr)",
            "repeat(3,1fr)",
          ]}
          gap="20px"
          justifyContent="center"
        >
          {shops.map((item, i) => (
            <Flex key={i} >
            <FormLabel >
              <Card
                width="350px"
                margin="auto"
                _hover={{
                  cursor: "pointer",
                  transition: "0.3s",
                  transform: "scale(1.03)",
                  boxShadow: "0 0 5px gray",
                }}
                wordBreak='break-word'
                flex={1}
              >
                <CardBody>
                  <Flex mt="-10px" justifyContent="space-between">
                    <Box></Box>

                    <IconButton
                      onClick={() => OpenEditModal(item)}
                      variant="outline"
                      border="1px solid white"
                      icon={<EditIcon fontSize="25px" color="gray" />}
                    />
                  </Flex>
                  <Flex justifyContent="space-between"  gap="20px">
                    <Box flex={2}>
                    <FaUserCircle fontSize="80px" color="lightgray"  /></Box>
                    <Flex
                      flexDirection="column"
                      gap="5px"
                      justifyContent="center"
                      flexWrap="wrap"
                      flex={7}
                  
                    >
                      <Text
                        fontSize="xl"
                        fontWeight=""
                        fontFamily="NexaText-Trial-Heavy"
                        color="#7D8035"
                     width="100%"
                      >
                        {item.name}
                      </Text>{" "}
                      <Text
                        fontSize="md"
                        fontFamily="NexaText-Trial-Regular"
                        color="gray.400"
                      >
                        {item.phone}
                      </Text>
                      <Text
                        fontSize="md"
                        fontFamily="NexaText-Trial-Regular"
                        color="gray.400"
                      >
                        {item.role}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex gap="15px" justifyContent="center" mt="15px">
                    <Button
                      variant="outline"
                      color="#A1BE28"
                      border="1px solid #A1BE28"
                      _hover={{ bg: "#A1BE28", color: "white" }}
                      onClick={() => openChangePasswordModal(item._id)}
                    >
                      Change Password
                    </Button>
                    <Button
                      variant="outline"
                      color="#A1BE28"
                      border="1px solid #A1BE28"
                      onClick={() => openConfirmationModal(item._id)}
                    >
                      Delete User
                    </Button>
                  </Flex>
                </CardBody>
              </Card>
            </FormLabel>
            </Flex>
          ))}
        </Grid>
      ) : (
        <Heading
          textAlign="center"
          fontWeight="semibold"
          marginTop="-3"
          mb="20px"
          mt="100px"
          color="red.400"
        >
          No Shops Yet
        </Heading>
      )}
    </div>
  );
};

export default Shop;
