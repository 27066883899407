// import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  useToast,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../../../Fucntions/Modals/ConfirmationModal";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCitysData,
  DeleteCitysDetails,
} from "../../../../redux/Admin/AppReducer/vendors/action";
import CityAddForm from "./CityAddForm";
import { FcDownload } from "react-icons/fc";
import axios from "axios";
import { saveAs } from "file-saver";
import csvtojson from "csvtojson";
import { utils as XLSXUtils, write as writeToBuffer } from "xlsx";
import { setToast } from "../../../../Fucntions/Toastfunction";

const Citys = () => {
  const [render, setRender] = useState(false);
  const dispatch = useDispatch();
  const navigate=useNavigate()
  let location = useLocation();
  const toast = useToast();
  const citys = useSelector((state) => state.VendorReducer.citys);
  const isLoading = useSelector((state) => state.VendorReducer.isloading);
  // console.log(citys, "citys");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [name, setName] = useState("");

  const openModal = (name) => {
    setIsConfirmOpen(true);
    setName(name);
  };

  const closeModal = () => {
    setIsConfirmOpen(false);
  };
  const [downloadLoading, setDownLoadLoading] = useState(false);
  function handleDownlaodCityData() {
    setDownLoadLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/citygroup/download`,
      headers,
    })
      .then((response) => {
        csvtojson()
          .fromString(response.data)
          .then((jsonData) => {
            const workbook = { SheetNames: ["Sheet1"], Sheets: {} };
            const worksheet = XLSXUtils.json_to_sheet(jsonData);
            workbook.Sheets["Sheet1"] = worksheet;
            const excelBuffer = writeToBuffer(workbook, { type: "buffer" });
            const blob = new Blob([excelBuffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, "filename.xlsx");
          });
        setDownLoadLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        setToast(toast,"something Went Wrong","","error")
        setDownLoadLoading(false);
      });
  }

  const handleDeleteData = () => {
    const payload = {
      name,
    };

    dispatch(
      DeleteCitysDetails(
        `${process.env.REACT_APP_API_URL}/citygroup/delete`,
        payload,
        toast,
        closeModal,
        `${process.env.REACT_APP_API_URL}/citygroup/read`,navigate
      )
    );
  };

  useEffect(() => {
    if (location || citys.length === 0) {
      dispatch(getCitysData(`${process.env.REACT_APP_API_URL}/citygroup/read`,navigate));
    }
  }, [location.search, render]);

  return (
    <Box width="100%">
      <Flex justifyContent="space-between">
        <Heading as="h4" size="lg" fontWeight="medium">
          Cities
        </Heading>
        {downloadLoading ? (
          <Button>
            <Spinner />
          </Button>
        ) : (
          <Button
            onClick={handleDownlaodCityData}
            variant="outline"
            border="2px solid teal"
            leftIcon={<FcDownload />}
          >
            Bulk Download
          </Button>
        )}
      </Flex>
      <br />
      <div
        style={{
          borderRadius: "7px",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          backgroundColor: "white",
        }}
        height="30px"
        width="30px"
      >
        <Flex justifyContent="flex-end" p={{ base: "1", md: "1", lg: "2" }}>
          <Flex gap="10px" fontSize={{ base: "12px", md: "16px", lg: "26px" }}>
            <Button
              colorScheme="teal"
              variant="outline"
              border="2px solid teal"
              fontSize={{ base: "10px", md: "16px", lg: "16px" }}
              // Open a Modal
              onClick={onOpen}
            >
              Create
            </Button>
          </Flex>
        </Flex>
        <hr />
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>
                  <Text fontSize="sm">Name</Text>
                </Th>
                <Th>
                  <Text fontSize="sm">Cities</Text>
                </Th>
                <Th>
                  <Text fontSize="sm">Actions</Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <Tr>
                  <Td>
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                  </Td>
                </Tr>
              ) : (
                citys.map((item, i) => (
                  <Tr key={i}>
                    <Td
                      _hover={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      <Text>{item.name}</Text>
                    </Td>
                    <Td display={"flex"} mt={"5"}>
                      {item?.cities?.map((elm, i) => {
                        return (
                          <Text key={i} mb={"5"}>
                            {i > 0 ? "," : null}
                            {elm}
                          </Text>
                        );
                      })}
                    </Td>
                    <Td flex="flexEnd">
                      <Button
                        colorScheme="red"
                        onClick={() => openModal(item.name)}
                      >
                        Delete
                      </Button>
                      {/* <IconButton
                        variant="ghost"
                        colorScheme="red"
                        fontSize="15px"
                        icon={<DeleteIcon />}
                      /> */}
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
      <ConfirmationModal
        heading="Delete City"
        action={"Are you Sure? You want to Delete this Data"}
        onClickFunction={handleDeleteData}
        ConfirmButton={"Yes Delete"}
        onClose={closeModal}
        isOpen={isConfirmOpen}
        loader={true}
        loading={isLoading}
      />

      {/* Modal */}
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <CityAddForm
            onClose={onClose}
            setRender={setRender}
            render={render}
          />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Citys;
