import OrderComponent from "../components/Orders/OrderComponent";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Box, Heading, Spinner } from "@chakra-ui/react";

const Allocated = () => {
  let [datatosend, setDatatosend] = useState(null);
  const [refreshdata, setRefreshdata] = useState(false);
  const { search } = useLocation();
  let partner_id = JSON.parse(sessionStorage.getItem("partner_id"));
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("bakertoken"))}`,
  };

  const [orderData, setOrderData] = useState([]);
  let [orderLoader, setOrderLoader] = useState(false);
  function getOrderData() {
    if (orderLoader) {
      return;
    }
    setOrderLoader(true)
    axios({
      url: `${process.env.REACT_APP_API_URL}/baker/home`,
      method: "POST",
      headers,
      data: datatosend,
    })
      .then((r) => {
        setOrderData(r.data.suborders);
        setOrderLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setOrderLoader(false);
      });
  }

  const todaydate = new Date();
  const momentDatetoday = moment(todaydate).format("YYYY-MM-DD");

  const tomorrowdate = new Date(todaydate); // Create a new date object with today's date
  tomorrowdate.setDate(todaydate.getDate() + 1); // Add one day to the new date object
  const momentDatetomorrow = moment(tomorrowdate).format("YYYY-MM-DD");

  useEffect(() => {
    if (datatosend) {
      getOrderData();
    }
  }, [datatosend, refreshdata]);
  useEffect(() => {
    if (search === "?filter=today") {
      setDatatosend({
        partner_id: partner_id,
        status1: "accepted",
        status2: "printed",
        status3: "allocated",
        future: false,
        date: momentDatetoday,
      });
    } else if (search === "?filter=tommorrow") {
      setDatatosend({
        partner_id: partner_id,
        status1: "accepted",
        status2: "printed",
        status3: "allocated",
        future: false,
        date: momentDatetomorrow,
      });
    } else {
      setDatatosend({
        partner_id: partner_id,
        status1: "accepted",
        status2: "printed",
        status3: "allocated",
        future: true,
        date: momentDatetomorrow,
      });
    }
  }, []);
  return (
    <div>
      <>
        {orderLoader ? (
          <Box mt="100px">
          <Spinner size="lg" />
          </Box>
        ) : (
          <>
            {orderData.length ? (
              <OrderComponent
                orderData={orderData}
                refreshdata={refreshdata}
                setRefreshdata={setRefreshdata}
              />
            ) : (
              <Heading
                textAlign="center"
                fontWeight="semibold"
                marginTop="-3"
                mb="20px"
                mt="100px"
                color="red.400"
              >
                No Orders Yet
              </Heading>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default Allocated;
