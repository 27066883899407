import React, { useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  FormLabel,
  useToast,
  Image,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setToast } from "../../../../Fucntions/Toastfunction";
import { getOrderData } from "../../../../redux/Admin/AppReducer/orders/action";

const ModalforEditOrder = ({ isOpen, onClose, line_item_id }) => {
  const [imgUrl, setImgurl] = useState(null);
  const [imgLoader, setImgLoader] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const ForOrderId = useSelector(
    (state) => state.OrderReducer.Order_Editing_Added_Product
  );
  const [cake_message, setCakeMessage] = useState(null);
  // console.log(ForOrderId,"id")
  //   const [detail,setDetail]=useState(
  //    {message:"",
  //   image:imgUrl,
  //   reason:""
  // }
  //   )

  //   function handleOnChange(e){
  //     const {name,value}=e.target
  //     if (value.charAt(0) === ' ') {
  //       e.target.value = value.slice(1);
  //       return
  //     }
  //     setDetail({...detail,
  //    [name]:value
  //   })
  //   }

  //   function onHAndleSaveHitApi(){

  //     const headers = {
  //       Authorization: `Bearer ${JSON.parse(
  //         sessionStorage.getItem("admintoken")
  //       )}`,
  //     };
  // let data={
  //   "order_id" :id,
  //   "line_item_id":ForOrderId.id,
  //   "action":"properties",
  //   "staffNote":detail.reason,
  //   "properties":[
  //         {
  //           "name": "message",
  //           "value": detail.message,
  //         },
  //         {
  //           "name": "image",
  //           "value": JSON.stringify([detail.image]),
  //         }
  //   ]
  // }
  // axios({
  //   method:"PUT",
  //   url:`${process.env.REACT_APP_API_URL}/order/editproperties`,
  //   data,
  //   headers
  //  }).then((r)=>{
  //   setToast(toast,"Data Successfully Changed","","success")
  //   dispatch(
  //     getOrderData(`${process.env.REACT_APP_API_URL}/order/read/${id}`, toast,navigate)
  //   );
  //   setDetail({message:"",
  //   image:imgUrl,
  //   reason:""
  // })
  //  }).catch((err)=>{
  //   if(err.response.data.err==="Invalid token"){
  //     sessionStorage.removeItem('admintoken');
  //     navigate("/admin/login")
  //     return
  //   }
  // setDetail(   {
  //   message:"",
  // image:imgUrl,
  // reason:""
  // })
  //   setToast(toast,`${err.response.data.err}`,"","error")
  //  })
  // onClose()
  //   }

  //   const fileInputRef = useRef(null);

  //   async function handlefileUpload(event){
  //     if(imgLoader) return
  //     setImgLoader(true)
  //     const fileInput = event.target;
  //     const formData = new FormData();
  //     formData.append("image", fileInput.files[0], fileInput.files[0].name);

  //     const requestOptions = {
  //       method: 'POST',
  //       body: formData,
  //       redirect: 'follow'
  //     };
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_API_URL}/images/upload`, requestOptions);
  //       const result = await response.json();
  //       // console.log(result,"result");
  //       setImgurl(result.imageUrls[0])
  //       setDetail({...detail,image:result.imageUrls[0]})
  //       setImgLoader(false)
  //     } catch (error) {
  //       // console.log('error', error);
  //       setImgLoader(false)
  //     }

  // }
  const [cake_loader, setCake_loader] = useState(false);
  function HandleMessageHitApi() {
    if(!cake_message){
      setToast(toast,"write someting to edit","","error")
    }
    if (cake_loader) {
      return;
    }
    setCake_loader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    let data = {
      id,
      line_itemId: line_item_id,
      message: cake_message,
      oldmessage:"old"
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/order/editcakemessage`,
      headers,
      data,
    })
      .then((r) => {
        console.log(r.data);
        setToast(toast,"Message Edited","","success")
        setCake_loader(false);
        setCakeMessage(null)
        onClose();
        dispatch(
          getOrderData(
            `${process.env.REACT_APP_API_URL}/order/read/${id}`,
            navigate
          )
        );
      })
      .catch((err) => {
        console.log(err);
        setToast(toast,`${err.response.data.err}`,"","error")
        setCake_loader(false);
        setCakeMessage(null)
        onClose();
      });
  }
  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Properties</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {/* <FormControl>
              <FormLabel>Upload your file </FormLabel>
              <Input type="file" accept="image/*" onChange={handlefileUpload} />
            </FormControl>
            <br />
          {imgLoader?<Spinner/>:<>{
            imgUrl && <Image src={imgUrl}  height="150px" width="150px"/>
          }
          </> } */}

            <FormControl>
              <FormLabel>Write your Message </FormLabel>
              <Input
                placeholder="Write your message"
                name="message"
                onChange={(e) => setCakeMessage(e.target.value)}
                pt="5px"
              />
            </FormControl>
            {/* <FormControl>
              <FormLabel>Reason For Edit </FormLabel>
              <Input placeholder="Write your reason for edit" 
              name="reason"
                onChange={handleOnChange}
               pt="5px"/>
             
            </FormControl> */}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={HandleMessageHitApi}>
              {cake_loader ? <Spinner /> : "Save"}
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalforEditOrder;
