import React from "react";
import { Route, Routes } from "react-router-dom";
import DpLogin from "../Pages/DpLogin";
import Authentication from "../Authentication/Authentication";
import DpHome from "../Pages/DpHome";
import DpAssignedOrders from "../Pages/DpAssignedOrders";
import DpAttemptedOrders from "../Pages/DpAttemptedOrders";
import DpOutForDeliveryOrders from "../Pages/DpOutForDeliveryOrders";
import DpDeliveredOrders from "../Pages/DpDeliveredOrders";

const DpRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/deliverypartner/login" element={<DpLogin />} />
        <Route
          path="/deliverypartner"
          element={
            <Authentication>
              <DpHome />
            </Authentication>
          }
        />
         <Route
          path="/deliverypartner/assigned"
          element={
            <Authentication>
              <DpAssignedOrders />
            </Authentication>
          }
        />
         <Route
          path="/deliverypartner/attempted"
          element={
            <Authentication>
              <DpAttemptedOrders />
            </Authentication>
          }
        />
         <Route
          path="/deliverypartner/outfordelivery"
          element={
            <Authentication>
              <DpOutForDeliveryOrders />
            </Authentication>
          }
        />
        <Route
          path="/deliverypartner/delivered"
          element={
            <Authentication>
              <DpDeliveredOrders/>
            </Authentication>
          }
        />
      </Routes>
    </div>
  );
};

export default DpRoutes;
