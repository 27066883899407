import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  ListItem,
  Spinner,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import ConfirmationModal from "../../../Fucntions/Modals/ConfirmationModal";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  UpdateSuborderData,
  getOrderData,
} from "../../../redux/Admin/AppReducer/orders/action";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setToast } from "../../../Fucntions/Toastfunction";
import axios from "axios";

const RightSidebar = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [note, setNote] = useState("");
  const [contactInfo, setContactInfo] = useState({
    email: "",
    phone: "",
    profileupdate: "",
  });
  let [render, setRender] = useState(false);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showEditButton, setShowEditButton] = useState(false);
  const suborder = useSelector((state) => state.OrderReducer.order);
  const tagsToShow = suborder?.tags?.split(",") || [];

  const [isOpenNotesModal, setIsOpenNotesModal] = useState(false);
  const [isOpenCustomerModal, setIsOpenCustomerModal] = useState(false);

  const [greeting_message_detail, setGreeting_message_detail] = useState({
    from: "",
    to: "",
    message: "",
  });

  function handleGreetingMessageChange(e) {
    const { name, value } = e.target;

    setGreeting_message_detail({
      ...greeting_message_detail,
      [name]: value,
    });
  }
  const greeting_message =
    suborder?.note_attributes &&
    suborder.note_attributes.find(
      (attr) => attr.name.trim() === "greeting_message"
    )?.value;
  const openNotesModal = () => {
    setIsOpenNotesModal(true);
  };

  const closeNotesModal = () => {
    setIsOpenNotesModal(false);
  };

  const openCustomerModal = () => {
    setIsOpenCustomerModal(true);
  };
  const closeCustomerModal = () => {
    setIsOpenCustomerModal(false);
  };
  function checkObjectareSame(obj1, obj2) {
    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
      return false;
    }

    let key1 = Object.keys(obj1);
    let key2 = Object.keys(obj2);

    if (key1.length !== key2.length) return false;

    for (let key of key1) {
      if (!obj2.hasOwnProperty(key)) return false;
    }

    return true;
  }

  let [noteSaverLoader, setNotesaverLoader] = useState(false);

  const handleNoteSaveHitApi = () => {
    if (noteSaverLoader) {
      return;
    }
    setNotesaverLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/order/updatenote/${id}`,
      data: { notes: note, order_id: suborder?.line_items[0].related_suborder },
      headers,
    })
      .then((r) => {
        dispatch(
          getOrderData(
            `${process.env.REACT_APP_API_URL}/order/read/${id}`,
            toast,
            navigate
          )
        );
        setNotesaverLoader(false);
        setNote("");
        closeNotesModal();
      })
      .catch((err) => {
        // console.log("_______________________",err)
        setNotesaverLoader(false);
      });
  };

  function handleContactInfoupdate(e) {
    const { name, value } = e.target;
    if (name === "profileupdate") {
      setContactInfo({
        ...contactInfo,
        profileupdate: e.target.checked,
      });
      return;
    }
    setContactInfo({
      ...contactInfo,
      [name]: value,
    });
  }

  function handleContactInfoSaveApiTrigger() {
    let newsuborder = { ...suborder };
    newsuborder.customer.email = contactInfo.email;
    newsuborder.customer.phone = contactInfo.phone;

    dispatch(
      UpdateSuborderData(
        `${process.env.REACT_APP_API_URL}/suborder/update/${id}`,
        newsuborder,
        toast,
        "Contact Information",
        navigate
      )
    );
    closeCustomerModal();
    setContactInfo({
      email: "",
      phone: "",
      profileupdate: "",
    });
  }

  // Adding and removing tags functionality
  const addTag = (tag) => {
    setTags((prevTags) => [...prevTags, tag]);
  };

  const removeTag = (index) => {
    setTags((prevTags) => prevTags.filter((_, i) => i !== index));
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.length > 0 && tags.length >= 0) {
      addTag(inputValue.trim());
      setInputValue("");
    }
    if (
      event.key === "Backspace" &&
      inputValue.length === 0 &&
      tags.length > 0
    ) {
      removeTag(tags.length - 1);
    }
  };
  const handleTagClick = (index) => {
    removeTag(index);
  };

  const sanitizeTag = (tag) => {
    // return tag
    //   .replace(/[^\w -]/g, "")
    //   .trim()
    //   .replace(/\W+/g, "-");
  };

  const handleInputBlur = () => {
    // if (inputValue.length > 0) {
    //   addTag(sanitizeTag(inputValue));
    //   setInputValue("");
    // }
  };

  function handleSaveTagsHitApi() {
    let newsuborder = { ...suborder };
    newsuborder.customer.tags = tags;
    dispatch(
      UpdateSuborderData(
        `${process.env.REACT_APP_API_URL}/suborder/update/${id}`,
        newsuborder,
        toast,
        "Tags",
        navigate
      )
    );
  }

  useEffect(() => {
    if (suborder) {
      setTags(suborder?.customer?.tags || []);
    }
  }, [suborder, render]);

  // *************************   FOR GREETINGS MESSAGE *******************************************
  let [greetingmessageinput, setGreetingMessageInput] = useState(null);
  const [greetingModal, setGreetingModal] = useState(false);
  const openGreetingModal = () => {
    if (
      !greeting_message_detail.message ||
      !greeting_message_detail.from ||
      !greeting_message_detail.to
    ) {
      setToast(toast, "Please Fill all the details", "", "info");
      return;
    }
    setGreetingModal(true);
  };
  const closeGreetingModal = () => setGreetingModal(false);
  // console.log(greetingmessageinput,"greret")

  const [greetingLoader, setGreetingLoader] = useState(false);

  const handleGreetingMessageEdit = () => {
    if (greetingLoader) {
      return;
    }

    setGreetingLoader(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    let note_attr = [...suborder?.note_attributes];
    console.log(note_attr, "atttttttttttttttt");
    if (note_attr.find((k) => k.name === "gift_message")?.value) {
      note_attr.find((k) => k.name === "gift_message").value =
        greeting_message_detail.message;
    } else {
      note_attr.push({
        name: "gift_message",
        value: greeting_message_detail.message,
      });
    }
    if (note_attr.find((k) => k.name === "message_sender")?.value) {
      note_attr.find((k) => k.name === "message_sender").value =
        greeting_message_detail.from;
    } else {
      note_attr.push({
        name: "message_sender",
        value: greeting_message_detail.from,
      });
    }
    if (note_attr.find((k) => k.name === "message_receiver")?.value) {
      note_attr.find((k) => k.name === "message_receiver").value =
        greeting_message_detail.to;
    } else {
      note_attr.push({
        name: "message_receiver",
        value: greeting_message_detail.to,
      });
    }
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/order/editmessage/${id}`,
      data: {
        note_attr: note_attr,
        suborder_id: suborder?.line_items[0].related_suborder,
      },
      headers,
    })
      .then((r) => {
        // console.log(r.data)
        setGreetingMessageInput(null);
        setGreetingModal(false);
        setGreetingLoader(false);
        setToast(toast, `Greeting message updated`, "", "success");
        setGreeting_message_detail({ from: "", to: "", message: "" });
        setShowEditButton(false);
        dispatch(
          getOrderData(
            `${process.env.REACT_APP_API_URL}/order/read/${id}`,
            toast,
            navigate
          )
        );
      })
      .catch((err) => {
        // console.log(err)
        setGreetingLoader(false);
        setToast(toast, `Error in updating greeting message`, "", "error");
      });
  };

  return (
    <Box>
      {/* **************************   GREETING_MESSEGE ******************************* */}
      {suborder?.suborder_id ? null : (
        <Box
          shadow={"md"}
          rounded={"md"}
          border={"1px"}
          borderColor={"gray.200"}
          p={4}
          my={5}
          bgColor={"rgba(255, 255, 255, 1)"}
          overflowWrap="break-word"
           wordBreak="break-word"
          >
          <Heading
            size={"md"}
            color={"black"}
            fontWeight={"400"}
            textAlign={"left"}
          >
            Edit Greeting Message
          </Heading>

          <Divider size={1} my={2} borderBottomColor={"black"} />

          <Text
            textAlign="right"
            color="blue"
            textDecoration="underline"
            _hover={{ cursor: "pointer" }}
            onClick={() => setShowEditButton(true)}
          >
            Edit
          </Text>
          <Flex direction="column" color="black">
            <Flex gap="10px" flexWrap="wrap">
              <Text>From :</Text>

              <Text>
                {" "}
                {
                  suborder?.note_attributes?.find(
                    (k) => k.name.trim() === "message_sender"
                  )?.value
                }
              </Text>
            </Flex>
            <Flex gap="10px" flexWrap="wrap">
              <Text>To :</Text>

              <Text>
                {" "}
                {
                  suborder?.note_attributes?.find(
                    (k) => k.name.trim() === "message_receiver"
                  )?.value
                }
              </Text>
            </Flex>
            <Flex gap="10px" flexWrap="wrap">
              <Text>Message :</Text>
              <Text>
                {" "}
                {
                  suborder?.note_attributes?.find(
                    (k) => k.name.trim() === "gift_message"
                  )?.value
                }
              </Text>
            </Flex>
          </Flex>
          <br />
          {showEditButton && (
            <Box>
              <Flex>
                <Flex color="black" direction="column" width="100%">
                  <FormControl mt="5px">
                    <FormLabel>From</FormLabel>
                    <Input
                      required={true}
                      placeholder="From"
                      name="from"
                      onChange={handleGreetingMessageChange}
                    />
                  </FormControl>
                  <FormControl mt="5px">
                    <FormLabel>To</FormLabel>
                    <Input
                      required={true}
                      placeholder="To"
                      name="to"
                      onChange={handleGreetingMessageChange}
                    />
                    <FormControl mt="5px">
                      <FormLabel>Message</FormLabel>
                      <Input
                        required={true}
                        placeholder="Message"
                        name="message"
                        onChange={handleGreetingMessageChange}
                      />
                    </FormControl>
                  </FormControl>
                </Flex>
              </Flex>
              <Button mt="10px" colorScheme="blue" onClick={openGreetingModal}>
                Save
              </Button>
            </Box>
          )}
          <Flex gap="10px">
            {/* <Input
              placeholder="Edit Messege"
              onChange={(e) => setGreetingMessageInput(e.target.value)}
              color="black"
            /> */}
          </Flex>
        </Box>
      )}
      {/* **************************   NOTES MODAL  ***************************** */}
      <ConfirmationModal
        heading="Edit Greeting Message"
        action={
          "Are you Sure? you want change the greeting message of this order"
        }
        onClickFunction={handleGreetingMessageEdit}
        ConfirmButton={"Edit"}
        color={"blue"}
        onClose={closeGreetingModal}
        isOpen={greetingModal}
        loader={true}
        loading={greetingLoader}
      />
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpenNotesModal}
        onClose={closeNotesModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"left"}>Add Note</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Input
                placeholder=""
                id="note"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeNotesModal}>
              Close
            </Button>
            <Button
              colorScheme="green"
              color="white"
              onClick={handleNoteSaveHitApi}
            >
              {noteSaverLoader ? <Spinner /> : "Save"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {suborder.suborder_id ? (
        <Box
          shadow={"md"}
          rounded={"md"}
          border={"1px"}
          borderColor={"gray.200"}
          p={4}
          my={5}
          bgColor={"rgba(255, 255, 255, 1)"}
        >
          <Flex justifyContent={"space-between"}>
            <Heading
              size={"md"}
              color={"black"}
              fontWeight={"400"}
              textAlign={"left"}
            >
              Notes
            </Heading>

            <></>
          </Flex>

          <Divider size={1} my={2} borderBottomColor={"black"} />

          {suborder.notes ? (
            <Text color={"black"} textAlign={"left"}>
              {suborder?.notes}
            </Text>
          ) : (
            <Text fontSize={"14px"} mt="5px" textAlign={"left"} color={"gray"}>
              No Notes
            </Text>
          )}
        </Box>
      ) : (
        <Box
          shadow={"md"}
          rounded={"md"}
          border={"1px"}
          borderColor={"gray.200"}
          p={4}
          my={5}
          bgColor={"rgba(255, 255, 255, 1)"}
        >
          <Flex justifyContent={"space-between"}>
            <Heading
              size={"md"}
              color={"black"}
              fontWeight={"400"}
              textAlign={"left"}
            >
              Notes
            </Heading>

            {/* edit Notes- modal */}
            <>
              <Button
                onClick={openNotesModal}
                color="blue"
                size={"sm"}
                _hover={{ bgColor: "transparent" }}
                bgColor={"transparent"}
              >
                Edit
              </Button>

            </>
          </Flex>

          <Divider size={1} my={2} borderBottomColor={"black"} />

          {suborder?.suborder_id ? null : suborder.note ? (
            <Text color=   {"black"} textAlign={"left"}>
              {suborder?.note}
            </Text>
          ) : (
            <Text fontSize={"14px"} mt="5px" textAlign={"left"} color={"gray"}>
              No Notes From the Customer
            </Text>
          )}
        </Box>
      )}

      {/* *********************************   CUSTOMER DETAILS   ***************************** */}
      <Box
        shadow={"md"}
        rounded={"md"}
        border={"1px"}
        borderColor={"gray.200"}
        p={4}
        my={5}
        bgColor={"rgba(255, 255, 255, 1)"}
      >
        <Flex justifyContent={"space-between"}>
          <Heading
            size={"md"}
            pb="20px"
            fontWeight={"400"}
            color={"black"}
            textAlign={"left"}
          >
            Customer
          </Heading>

          <>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpenCustomerModal}
              onClose={closeCustomerModal}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader textAlign={"left"}>
                  Edit Contact Information
                </ModalHeader>
                <Divider size={1} my={1} borderBottomColor={"grey"} />
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                      placeholder=" Enter Email"
                      name="email"
                      value={contactInfo.email}
                      onChange={handleContactInfoupdate}
                    />
                    <br></br>
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      placeholder="Enter Phone Number"
                      type="number"
                      value={contactInfo.phone}
                      name="phone"
                      onChange={handleContactInfoupdate}
                    />
                    <br></br>
                    <br></br>
                    <Checkbox
                      name="profileupdate"
                      onChange={handleContactInfoupdate}
                    >
                      Update Customer Profile
                    </Checkbox>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={closeCustomerModal}
                  >
                    Close
                  </Button>
                  <Button
                    colorScheme="green"
                    color="white"
                    onClick={handleContactInfoSaveApiTrigger}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        </Flex>
        <Text textAlign={"left"} fontSize={"16px"} color="#2463BC">
          {suborder?.customer?.first_name} {suborder?.customer?.last_name}
        </Text>
        <Text textAlign={"left"} fontSize={"15px"} color="black">
          {suborder?.line_items?.length} Orders
        </Text>
        <br />
        <Divider size={1} borderBottomColor={"gray.400"} mb="5px" />

        <Flex justifyContent="space-between" alignItems="center">
          <Heading
            alignItems="center"
            size={"md"}
            mt="10px"
            mb="5px"
            fontWeight={"400"}
            color={"black"}
            textAlign={"left"}
          >
            Contact information
          </Heading>
        </Flex>

        {suborder?.customer?.email ? (
          <Text textAlign={"left"} mt="5px" fontSize={"16px"} color="#2463BC">
            {suborder?.customer?.email}
          </Text>
        ) : (
          <Text textAlign={"left"} fontSize={"15px"} mt="5px" color="grey">
            No Email
          </Text>
        )}
        {suborder?.customer?.phone ? (
          <Text textAlign={"left"} mt="5px" fontSize={"16px"} color="#2463BC">
            {suborder?.customer?.phone}
          </Text>
        ) : (
          <Text textAlign={"left"} fontSize={"15px"} mt="5px" color="grey">
            No Phone Number
          </Text>
        )}

        {/* ***********************************   BILLING ADDRESS ******************************* */}
        <Divider size={1} borderBottomColor={"gray.400"} mb="20px" mt="30px" />

        <Heading
          size={"md"}
          mt="14px"
          fontWeight={"400"}
          color={"black"}
          textAlign={"left"}
        >
          Billing address
        </Heading>
        {/* {checkObjectareSame(suborder?.billing_address,suborder?.shipping_address)?<Text textAlign={"left"} pt="2px" fontSize={"15px"} color="grey">
          Same as shipping address
        </Text>: */}
        <Box>
          <Text fontSize={"14px"} mt="3px" textAlign={"left"} color={"black"}>
            {suborder?.billing_address?.country}
          </Text>
          <Text fontSize={"14px"} mt="3px" textAlign={"left"} color={"black"}>
            {suborder?.billing_address?.city}
          </Text>
          <Text fontSize={"14px"} mt="3px" textAlign={"left"} color={"black"}>
            {suborder?.billing_address?.address1}
          </Text>
          <Text fontSize={"14px"} mt="3px" textAlign={"left"} color={"black"}>
            {suborder?.billing_address?.zip}
          </Text>
        </Box>
        {/* } */}
      </Box>

      {/* ******************************  TAGS BOX ********************** */}
      {suborder.suborder_id ? null : (
        <Box
          shadow={"md"}
          rounded={"md"}
          border={"1px"}
          borderColor={"gray.200"}
          p={4}
          my={5}
          bgColor={"rgba(255, 255, 255, 1)"}
        >
          <Heading
            size={"md"}
            color={"black"}
            textAlign={"left"}
            fontWeight={"400"}
          >
            Tags
          </Heading>
          <Divider
            size={1}
            borderBottomColor={"gray.400"}
            mb="20px"
            mt="10px"
          />
          <Box my={5} py={3}>
            <UnorderedList
              display={"flex"}
              flexWrap={"wrap"}
              borderRadius={"3px"}
              px={4}
              py={3}
              m={0}
            >
              {suborder.tags
                ? tagsToShow?.map((renderTag, index) => {
                    return (
                      <ListItem
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        listStyleType={"none"}
                        key={index}
                        mr={2}
                        mb={2}
                        px={4}
                        h={"30px"}
                        bgColor={"#F5F5F9"}
                        color="black"
                      >
                        {renderTag}
                        {/* <Box
                    as="button"
                    ml={"10px"}
                    // onClick={() => handleTagClick(index)}
                  >
                    &times;
                  </Box> */}
                      </ListItem>
                    );
                  })
                : null}
              {/* <Input
              h={"30px"}
              flex={1}
              p={0}
              color="black"
              borderColor={"white"}
              outline={"unset"}
              focusBorderColor="transparent"
              value={inputValue}
              // onChange={handleInputChange}
              // onKeyDown={handleInputKeyDown}
              // onBlur={handleInputBlur}
            /> */}
            </UnorderedList>
          </Box>
          <Flex justifyContent="right">
            {/* <Button 
        colorScheme="whatsapp"
        // onClick={handleSaveTagsHitApi}
        >save</Button> */}
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default RightSidebar;
