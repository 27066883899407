import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Heading,
  Image,
  Input,
  Text,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";

import address_icon from "../../../images/icons/address.png";
import delivery_type from "../../../images/icons/delivery-type.png";
import phone_icon from "../../../images/icons/phone-icon.png";
import colander_icon from "../../../images/icons/colander.png";
import watch_icon from "../../../images/icons/watch.png";

const DpOrderCards = ({ item, remark, remark_notes, setRemark_notes }) => {
  function extractObjdata(obj) {
    let valuesArray = [];

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        valuesArray.push(obj[key]);
      }
    }

    return valuesArray;
  }

  function extractSenderName(str) {
    if (typeof str === "object") {
      return [str.firstName];
    }
    //  console.log("str", str);
    let parts;
    if (str?.includes("deliver to:")) {
      parts = str?.match(/\d/);
      if (parts) {
        let indexofnum = parts.index;
        let first = str?.substring(0, indexofnum - 1);
        let second = str?.substring(indexofnum);
        return [first.split(":")[1], second];
      }
    } else if (str?.includes("call")) {
      let arr = str?.split(" ");
      let k = [arr[2], arr[3]].join(" ");
      return [true, str, k];
    } else {
      let arr = str?.split(",");
      let first = arr.shift();
      let second = arr.slice();
      return [first, second.join(", ")];
    }
  }
  function findImagetoshow(obj) {
    let k = obj.properties.find((k) => k.name.trim() === "_imagesUrl");
    if (k) {
      return true;
    } else {
      return false;
    }
  }

  function messagetoshow(arr) {
    for (const item of arr) {
      const messageProperty = item.properties.find(
        (property) => property.name.trim() === "_message"
      );

      if (messageProperty) {
        return messageProperty.value;
      }
    }

    return null; // Return null if no image property was found
  }

  function imagetoshow(arr) {
    for (const item of arr) {
      const imageProperty = item.properties.find(
        (property) => property.name.trim() === "_imagesUrl"
      );

      if (imageProperty) {
        return imageProperty.value;
      }
    }

    return null; // Return null if no image property was found
  }
  function ForFindingProductDescription(arr) {
    const prodDescription = arr.find(
      (property) => property.name.trim() === "_product_description"
    );

    if (prodDescription) {
      return prodDescription.value;
    }

    return null; // Return null if no image property was found
  }

  const openImageInNewTab = (imageUrl) => {
    // Replace 'imageUrl' with the actual URL of the image you want to open
    let k = imageUrl.replace(/"/g, "").replace(/^\[|\]$/g, "");
    window.open(k, "_blank");
  };

  return (
    <Box p={6} bg="white">
      <Flex justifyContent="space-between">
        <Box w="40px">
          <img
            src={address_icon}
            alt="fnp"
            width="40px"
            height="40px"
            paddingRight="8px"
          />
        </Box>
        <Box w="100%" marginLeft="8px">
          {typeof item.shipping_address === "object" ? (
            <Flex gap="20px">
              <Text
                textAlign="left"
                fontWeight="bold"
                fontSize="14px"
                fontFamily="NexaText-Trial-Heavy"
                color="#2b2b2b"
              >
                {item?.shipping_address?.name}
              </Text>
            </Flex>
          ) : (
            <Text
              textAlign="left"
              fontWeight="400"
              fontSize="14px"
              fontFamily="NexaText-Trial-Heavy"
              color="#2b2b2b"
            >
              {extractSenderName(item.shipping_address) &&
              extractSenderName(item.shipping_address)[0] === true
                ? extractSenderName(item.shipping_address)[2]
                : extractSenderName(item.shipping_address)[0]}
            </Text>
          )}
          {item.allocated_driver_name && (
            <Text
              textAlign="left"
              fontWeight="400"
              fontSize="14px"
              fontFamily="NexaText-Trial-Heavy"
              color="#2b2b2b"
            >
              Allocated To : {item.allocated_driver_name}
            </Text>
          )}
          <Flex
            flexWrap="wrap"
            gap="5px"
            textAlign="left"
            fontFamily="NexaText-Trial-Regular"
            color="#2b2b2b"
            fontSize="14px"
            flexDirection="column"
          >
            {typeof item.shipping_address === "object" ? (
              <>
                <Flex gap="5px" flexDirection={{ base: "column", md: "row" }}>
                  {item.shipping_address.address1 && (
                    <Text>{item.shipping_address.address1}</Text>
                  )}
                  {item.shipping_address.address2 && (
                    <Text>{item.shipping_address.address2}</Text>
                  )}
                  {item.shipping_address.city && (
                    <Text>{item.shipping_address.city},</Text>
                  )}
                  {item.shipping_address.country && (
                    <Text>{item.shipping_address.country}</Text>
                  )}
                </Flex>
              </>
            ) : (
              <Text>
                {extractSenderName(item.shipping_address) &&
                  extractSenderName(item.shipping_address)[1]}
                ,
              </Text>
            )}
            <Wrap spacing={{ base: "10px", lg: "60px" }} mb="20px" mt="10px">
              <WrapItem>
                <Flex verticalAlign="middle">
                  <img
                    src={phone_icon}
                    alt="Phone No"
                    width="24px"
                    height="24px"
                  />
                  <Text marginLeft="8px" pt="3px">
                    {item.shipping_address.phone && (
                      <span>{item.shipping_address.phone}</span>
                    )}
                  </Text>
                </Flex>
              </WrapItem>
              <WrapItem>
                <Flex verticalAlign="middle">
                  <img
                    src={delivery_type}
                    alt="Delivery Type"
                    width="40px"
                    height="24px"
                  />
                  <Text marginLeft="8px" pt="3px">
                    {item.shipping_method}
                  </Text>
                </Flex>
              </WrapItem>
              <WrapItem>
                <Flex verticalAlign="middle">
                  <img
                    src={colander_icon}
                    alt="Delivery Date"
                    width="24px"
                    height="24px"
                  />
                  <Text marginLeft="8px" pt="3px">
                    {new Date(item?.to_be_shipped_on)?.toLocaleDateString(
                      "en-GB"
                    )}
                  </Text>
                </Flex>
              </WrapItem>
              <WrapItem>
                <Flex>
                  <img
                    src={watch_icon}
                    alt="Delivery Slot"
                    width="24px"
                    height="24px"
                  />
                  <Text marginLeft="8px" pt="3px">
                    {item?.time_slot}
                  </Text>
                </Flex>
              </WrapItem>
            </Wrap>
          </Flex>
        </Box>
      </Flex>
      <Box textAlign="left">
        {/* {item?.keepSecret === "false" && ( */}
        <Text borderTop="1px #ccc solid">
          Sender : {item?.customer?.first_name} {item?.customer?.last_name}
        </Text>
        {/* )} */}
        {messagetoshow(item.line_items) !== '""' ? (
          <Flex borderTop="1px #ccc solid" marginY="10px" paddingTop="10px">
            <Text>Message On Cake :</Text>
            <Text> {messagetoshow(item.line_items)?.replace(/"/g, "")}</Text>
          </Flex>
        ) : null}
        {imagetoshow(item.line_items) && (
          <>
            {imagetoshow(item.line_items) !== "[]" ? (
              <Flex
                alignItems="center"
                gap="10px"
                borderTop="1px #ccc solid"
                marginY="10px"
                paddingTop="10px"
              >
                <Text> Download Image :</Text>
                <Image
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    openImageInNewTab(
                      imagetoshow(item.line_items)?.replace(/"/g, "")
                    )
                  }
                  src={JSON.parse(imagetoshow(item.line_items))?.toString()}
                  height="50px"
                  width="50px"
                />
              </Flex>
            ) : null}
          </>
        )}
        {item.remark_notes && (
          <Text
            color="red"
            borderTop="1px #ccc solid"
            marginY="10px"
            paddingTop="10px"
          >
            Remarks : {item.remark_notes}{" "}
          </Text>
        )}
       
      </Box>
      <Box
        flex={12}
        marginTop="15px"
        fontFamily="NexaText-Trial-Regular"
        borderTop="1px #ccc solid"
        paddingTop="15px"
      >
        {item?.line_items?.map((prod, i) => (
          <Flex
            key={i}
            margin="auto"
            color="gray.500"
            mb="20px"
            justifyContent="space-between"
            flexDirection={{ base: "column", md: "row" }}
          >
            <Flex gap="30px" flexDirection={{ base: "column", md: "row" }}>
              <Image
                src={
                  prod.properties.filter(
                    (k) => k.name.trim() === "_product_image"
                  )[0]?.value
                }
                width={{ base: "100%", md: "200px" }}
                height={{ base: "100%", md: "200px" }}
              />
              <Box>
                <Flex flexDir="column" textAlign="left" marginBottom="20px">
                  <Text color="#2b2b2b" fontSize="14px" fontWeight="700">
                    {prod.title}
                  </Text>
                  <Text color="#2b2b2b" fontSize="14px">
                    SKU: {prod.sku}
                  </Text>
                  <Text textAlign="left" color="#e53e3e" fontSize="14px">
                    QTY: {prod.quantity}
                  </Text>
                </Flex>
                <Box
                  textAlign="left"
                  color="#2b2b2b"
                  marginLeft="15px"
                  fontSize="14px"
                  flex={5}
                  dangerouslySetInnerHTML={{
                    __html: ForFindingProductDescription(prod.properties),
                  }}
                ></Box>
              </Box>
            </Flex>
          </Flex>
        ))}
        {remark && (
          <Flex
            gap="20px"
            width="400px"
            fontFamily="NexaText-Trial-Regular"
            color="gray"
            fontSize="17px"
            alignItems="center"
          >
            <Text mt="4px">Reason </Text>
            <Text>:</Text>
            <Input
              placeholder="Reason"
              onChange={(e) => {
                let arr = [...remark_notes];
                arr.find((k) => k.id === item.suborder_id).remark =
                  e.target.value;
                setRemark_notes(arr);
              }}
            />
          </Flex>
        )}
         {item.notes && (
          <Text
            fontWeight="extrabold"
            borderTop="1px #ccc solid"
            marginY="10px"
            paddingTop="10px"
            textAlign="left"
            color="red"
            fontSize="18px"
          >
            Special Instructions : {item.notes}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default DpOrderCards;
