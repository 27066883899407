import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Input,
  Modal,
  FormControl,
  FormLabel,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Td,
  TableContainer,
  useDisclosure, Checkbox, useToast,
  IconButton, Grid, Spinner
} from "@chakra-ui/react";
import axios from "axios";
import { setToast } from "../../../Fucntions/Toastfunction";
import { CiEdit } from "react-icons/ci";
import { AiOutlineDelete } from "react-icons/ai";
import ConfirmationModal from "../../../Fucntions/Modals/ConfirmationModal";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const AdminRoles = () => {
  const [roleid, setRoleid] = useState(null);
  const [confirmisOpen, setConfirmisOpen] = useState(false);
  const openConfirmModal = (id) => {
    setConfirmisOpen(true);
    setRoleid(id);
  };
  const navigate=useNavigate()
  const closeConfirmModal = () => setConfirmisOpen(false);
  const [showupdatebutton, setShowupdateButton] = useState(false);
  const [render, setRender] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [roles, setRoles] = useState(null);
  const toast = useToast();
  const [roledetail, setRoleDetail] = useState({
    name: "",
    description: "",
  });
  const [rolecontroller, setRoleController] = useState({
    product: [],
    city_group: [],
    date_time_blocker: [],
    order: [],
    suborder: [],
    partner: [],
    shipping_method: [],
    user: [],
    user_group: [],
    vendor: [],
  });
  let accessroles = [
    "product",
    "city_group",
    "date_time_blocker",
    "order",
    "suborder",
    "partner",
    "shipping_method",
    "user",
    "user_group",
    "vendor",
  ];

  const handleAddRoleButtonClicked = () => {
    setShowupdateButton(false);
    setRoleController({
      product: [],
      city_group: [],
      date_time_blocker: [],
      order: [],
      suborder: [],
      partner: [],
      shipping_method: [],
      user: [],
      user_group: [],
      vendor: [],
    });
    setRoleDetail({
      name: "",
      description: "",
    });
    onOpen();
  };
  const handlerolechange = (e) => {
    const { name, value } = e.target;
    // Check if the first character is a space
    if (value.charAt(0) === ' ') {
      // Remove the first character (space)
      e.target.value = value.slice(1);
      return
    }
    setRoleDetail({
      ...roledetail,
      [name]: value,
    });
  };

  const handleAllrole = (e, name) => {
    let newobj = { ...rolecontroller };
    if (newobj[name].length < 3) {
      newobj[name] = [];
      newobj[name].push("read");
      newobj[name].push("write");
      newobj[name].push("delete");
    } else {
      newobj[name] = [];
    }
    setRoleController(newobj);
  };

  const handleReadRole = (e, name) => {
    let newobj = { ...rolecontroller };
    if (e.target.checked) {
      newobj[name].push("read");
    } else {
      newobj[name] = newobj[name].filter((item) => item !== "read");
    }
    setRoleController(newobj);
  };

  const handleWriteRole = (e, name) => {
    let newobj = { ...rolecontroller };
    if (e.target.checked) {
      newobj[name].push("write");
    } else {
      newobj[name] = newobj[name].filter((item) => item !== "write");
    }
    setRoleController(newobj);
  };

  const handleDeleteRole = (e, name) => {
    let newobj = { ...rolecontroller };
    if (e.target.checked) {
      newobj[name].push("delete");
    } else {
      newobj[name] = newobj[name].filter((item) => item !== "delete");
    }
    setRoleController(newobj);
  };

const [roleLoading,setRoleLoading]=useState(true)
  function getUserRolesfromBackend() {
    setRoleLoading(false)
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/usergroups`,
      headers,
    })
      .then((r) => {
        setRoles(r.data);
        setRoleLoading(true)
      })
      .catch((err) => {
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        // console.log(err)
        setToast(toast, `${err.response.data.err}`, "", "error");
        setRoleLoading(true)
      });
  }

  const [createloader,setCreateLoader]=useState(false)
  const handleCreateRoleHitApi = () => {
    if(createloader){
      return
    }
    setCreateLoader(true)
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/usergroups`,
      data: { ...roledetail, access_scopes: rolecontroller },
      headers,
    })
      .then((r) => {
        setToast(toast, `Role Created Successfully`, "", "success");
        onClose();
        setRender(!render);
        setCreateLoader(false)
      })
      .catch((err) => {
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        setCreateLoader(false)
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  };

  function handleEditRoleButtonClicked(item) {
    let obj = {};
    obj.name = item.name;
    obj.description = item.description;
    setRoleDetail(obj);
    let scope = { ...item.access_scopes };
    setRoleController(scope);
    setShowupdateButton(true);
    setRoleid(item._id);
    onOpen();
  }

  const [updateloader,setUpdateLoader]=useState(false)
  function handleupdateRoleHitApi() {
    if(updateloader){
      return
    }
    setUpdateLoader(true)
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/usergroups/${roleid}`,
      data: { ...roledetail, access_scopes: rolecontroller },
      headers,
    })
      .then((r) => {
        setToast(toast, "Role Updated Successfully", "", "success");
        onClose();
        setRender(!render);
        setUpdateLoader(false)
      })
      .catch((err) => {
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        setUpdateLoader(false)
        setToast(toast,`${err.response.data.err}`,"","error")
      });
  }

  const [deleteloader,setdeleteLoader]=useState(false)
  function handleDeleteRoleButtonClickedApiHit() {
    if(deleteloader){
      return
    }
    setdeleteLoader(true)
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/usergroups/${roleid}`,
      headers,
    })
      .then((r) => {
        setdeleteLoader(false)
        setToast(toast, "Role Deleted Successfully", "", "success");
        setRender(!render);
        closeConfirmModal();
      })
      .catch((err) => {
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        setdeleteLoader(false)
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  useEffect(() => {
    getUserRolesfromBackend();
  }, [render]);

  return (
    <>
    {roleLoading?<Box width="100%">
      <Flex justifyContent="space-between">
        <Heading as="h4" size="lg" fontWeight="medium">
          Roles
        </Heading>
        <Flex gap="10px">
          <Button leftIcon={<AddIcon/>}
          colorScheme="whatsapp" onClick={handleAddRoleButtonClicked}>
            Add Roles
          </Button>
        </Flex>
      </Flex>
      <br />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        p={3}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{showupdatebutton?"Update Role":"Create New Role"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={6} textAlign="left">
            <FormControl mb="10px">
              <FormLabel>Role Name</FormLabel>
              <Input
                name="name"
                onChange={handlerolechange}
                value={roledetail.name}
                placeholder="Enter Role"
              />
            </FormControl>

            <FormControl mb="10px">
              <FormLabel>Description</FormLabel>
              <Input
                name="description"
                onChange={handlerolechange}
                value={roledetail.description}
                placeholder="Enter description of the Role"
              />
            </FormControl>

            <FormControl mb="10px">
              <FormLabel>Select Access Control</FormLabel>
              <TableContainer>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th> </Th>
                      <Th>
                        <Text>Read</Text>
                      </Th>
                      <Th>
                        <Text>Write</Text>
                      </Th>
                      <Th>
                        <Text>Delete</Text>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {accessroles.map((item, i) => (
                      <Tr key={i}>
                        <Td>
                         
                            <FormControl>
                            <Flex gap="10px">
                            <Checkbox
                              isChecked={rolecontroller[item]?.length === 3}
                              onChange={(e) => handleAllrole(e, item)}
                            />
                            <FormLabel>{item.split("_").join(" ")}</FormLabel>
                            </Flex>
                            </FormControl>
                        
                        </Td>
                        <Td>
                          <Checkbox
                            isChecked={rolecontroller[item]?.includes("read")}
                            onChange={(e) => handleReadRole(e, item)}
                          />
                        </Td>
                        <Td>
                          <Checkbox
                            isChecked={rolecontroller[item]?.includes("write")}
                            onChange={(e) => handleWriteRole(e, item)}
                          />
                        </Td>
                        <Td>
                          <Checkbox
                            isChecked={rolecontroller[item]?.includes("delete")}
                            onChange={(e) => handleDeleteRole(e, item)}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </FormControl>

            <Grid gap="15px" templateColumns="repeat(3,1fr)"></Grid>
          </ModalBody>
          <ModalFooter gap="10px">
            {showupdatebutton ? (
              <Button
                onClick={handleupdateRoleHitApi}
                colorScheme="twitter"
                border="2px solid teal"
              >
                {updateloader?<Spinner/>:"Update"}
              </Button>
            ) : (
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handleCreateRoleHitApi}
              >
                {createloader?<Spinner/>:"Save"}
              </Button>
            )}

            <Button onClick={onClose} variant="outline" border="2px solid teal">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ConfirmationModal
        heading="Delete Role"
        action="Are you Sure? You want to Delete this Role"
        ConfirmButton="Yes Delete"
        onClickFunction={handleDeleteRoleButtonClickedApiHit}
        isOpen={confirmisOpen}
        onClose={closeConfirmModal}
        loader={true}
        loading={deleteloader}
      />

      <div
        style={{
          borderRadius: "7px",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          backgroundColor: "white",
        }}
        height="30px"
        width="30px"
      >
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>
                  <Text>S.No</Text>
                </Th>
                <Th>
                  <Text fontSize="sm">Name</Text>
                </Th>
                <Th>
                  <Text fontSize="sm">Description</Text>
                </Th>

                <Th>
                  <Text fontSize="sm" paddingLeft="20px">
                    Action
                  </Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {roles?.map((item, i) => (
                <Tr key={i}>
                  <Td width="10px" whiteSpace="normal" wordBreak="break-word"
                  >{i + 1}</Td>
                  <Td width="260px" whiteSpace="normal" wordBreak="break-word"
                  >{item.name}</Td>

                  <Td maxWidth="600px" whiteSpace="normal" wordBreak="break-word"
                  >{item.description}</Td>
                  <Td>
                    {item.name !=="Super Admin" ?<Flex gap="30px">
                      <IconButton
                      color="blue.500"
                        onClick={() => handleEditRoleButtonClicked(item)}
                        icon={<CiEdit fontSize="30px" />}
                        variant="outline"
                      />
                      <IconButton
                      color="red.500"
                        onClick={() => openConfirmModal(item._id)}
                        icon={<AiOutlineDelete fontSize="30px"  />}
                        variant="outline"
                      />
                    </Flex>:null}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </Box> :<Text color="gray.800" fontSize="40px" 
        fontWeight="semibold" mt="80px" pb="80px">Loading ...</Text>}
    </>
    
  );
};

export default AdminRoles;
