import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Flex,
  Input,
  Textarea,
  Select,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { setToast } from "../../../Fucntions/Toastfunction";

const InVoiceModal = ({ isOpen, onClose }) => {
    const toast=useToast()
    const navigate=useNavigate()
  const [user, setUser] = useState([]);
  const { id } = useParams();
  const [details, setDetails] = useState({
    order_id: id,
    to: "",
    from: "",
    subject: "",
    message: "",
  });
  const [email,setEmail]=useState("")

  const [sendLoader,setLoader]=useState(false)
  function handleSubmitHitApi() {
    if(sendLoader){
      return
    }
    setLoader(true)
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken") 
      )}`,
    };
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/order/sendInvoiceToCustomer`,
      headers,
      data: details,
    })
      .then((r) => {
      //  console.log(r.data);
      setLoader(false)
setToast(toast,"Invoice Sent Successfully","","success")
        onClose()
      })
      .catch((err) => {
        setLoader(false)
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
      //  console.log(err);
        setToast(toast,"Something Went Wrong","","error")
      });
  }
  function handleInvoiceChange(e) {
    const { name, value } = e.target;
    if (value.charAt(0) === ' ') {
      // Remove the first character (space)
      e.target.value = value.slice(1);
      return
    }
    setDetails({
      ...details,
      [name]: value,
    });
  }
  function getAllUsersFromBackend() {
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/user/all`,
      headers,
    })
      .then((r) => {
       // console.log(r.data);
        setUser(r.data);
      })
      .catch((err) => {
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
       // console.log(err);
      });
  }
 // console.log(details, "det");

  function getEmailToSend(){
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken") 
      )}`,
    };
    axios({
      method: "Get",
      url: `${process.env.REACT_APP_API_URL}/order/contactemail`,
      headers,
    })
      .then((r) => {
      //  console.log(r.data,"email");
        setEmail(r.data.data.shop.contactEmail)
      })
      .catch((err) => {
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
     //   console.log(err);
        setToast(toast,"Something Went Wrong","","error")
      });
  }
 // console.log(email,"email");

  useEffect(() => {
    getAllUsersFromBackend();
    getEmailToSend()
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="left">Send Invoive</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody p={3}>
          <Flex justifyContent="space-between" gap="20px">
            <FormControl>
              <FormLabel>To</FormLabel>
              <Input onChange={handleInvoiceChange} name="to" />
            </FormControl>
            <FormControl>
              <FormLabel>From</FormLabel>
              <Select onChange={handleInvoiceChange} name="from">
                <option value="">select Email</option>
                  <option value={email}>
                    {email}
                  </option>
              
              </Select>
            </FormControl>
          </Flex>
          <FormControl>
            <FormLabel>Subject</FormLabel>
            <Input onChange={handleInvoiceChange} name="subject" />
          </FormControl>
          <FormControl>
            <FormLabel>Message (optional)</FormLabel>
            <Textarea
              onChange={handleInvoiceChange}
              name="message"
              height="200px"
              resize="none"
              placeholder="Enter your text here..."
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            variant="outline"
            border="2px solid #007A5C"
            color="#007A5C"
            isDisabled={
              details.to === "" || details.from === "" || details.subject === ""
            }
            onClick={handleSubmitHitApi}
          >
            {sendLoader?<Spinner/>:"Send Invoice"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InVoiceModal;
