import React, { useEffect, useState } from "react";
import { Box, Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { Flex, Heading, Icon } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { BiCheckCircle } from "react-icons/bi";

const PaidComp = () => {
  const suborder = useSelector((state) => state.OrderReducer.order);
  let [totalItem, setTotalItem] = useState(0);

  function calculateTotalItem() {
    console.log("called again");
    let num = 0;
    for (let i = 0; i < suborder?.line_items?.length; i++) {
      if (
        suborder?.line_items[i].fulfillable_quantity !== 0 &&
        suborder?.line_items[i].fulfillment_status === null
      ) {
        //  setTotalItem((pre)=>pre+suborder?.line_items[i].fulfillable_quantity)
        num += suborder?.line_items[i].fulfillable_quantity;
      }
    }
    let line_arr = suborder?.fulfillments?.flatMap((item) => item.line_items);
    console.log(line_arr, "arr");
    for (let i = 0; i < line_arr.length; i++) {
      // /setTotalItem((pre)=>pre+line_arr[i].quantity)
      num += line_arr[i].quantity;
    }
    setTotalItem(num);
  }

  useEffect(() => {
    calculateTotalItem();
  }, [suborder]);

  return (
    <Box
      boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
      backgroundColor="white"
      borderRadius="7px"
      pb="3"
      mt="3"
      size={{ base: "30%", md: "60%", lg: "100%" }}
    >
      <Flex gap="15px" pl="10px" pt="10px" color="blackAlpha.800">
        <Box height="fit-content" border="5px solid #A1EDD6" borderRadius="50%">
          <BiCheckCircle fontSize="20px" color="#A1EDE9" />
        </Box>
        <Heading
          as="h5"
          size="md"
          fontFamily="NexaText-Trial-Bold"
          mb="15px"
          mt="5px"
        >
          paid
        </Heading>
      </Flex>

      <Box p={3} size={{ base: "30%", md: "60%", lg: "100%" }}>
        <Table colorScheme="gray">
          <Tbody>
            <Tr>
              <Td color={"black"}>Subtotal</Td>
              <Td color={"black"}>{totalItem} items</Td>
              <Td></Td>
              <Td color={"black"}>QAR {suborder?.current_subtotal_price}</Td>
            </Tr>

            <Tr>
              <Td color={"black"}>shipping</Td>
              <Td color={"black"}>{suborder?.shipping_lines[0]?.code}</Td>
              <Td></Td>
              <Td color={"black"}>QAR {suborder?.shipping_lines[0]?.price}</Td>
            </Tr>

            <Tr>
              <Td color={"black"}>Tax</Td>
              <Td color={"black"}>
                VAT {suborder?.tax_lines[0]?.rate * 100}%{" "}
              </Td>
              <Td></Td>
              <Td color={"black"}>QAR {suborder?.current_total_tax}</Td>
            </Tr>

            <Tr>
              <Td color={"black"}>Discount</Td>
              <Td></Td>
              <Td></Td>
              <Td color={"black"}>
                QAR {suborder?.current_total_discounts_set?.shop_money?.amount}
              </Td>
            </Tr>
            <Tr>
              <Td color={"black"}>Total </Td>
              <Td></Td>
              <Td></Td>
              <Td color={"black"}>QAR {suborder?.current_total_price}</Td>
            </Tr>

            <Tr>
              <Td color={"black"}>Paid by Customer</Td>
              <Td></Td>
              <Td></Td>
              <Td color={"black"}>QAR {suborder?.current_total_price}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default PaidComp;
