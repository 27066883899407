import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";

import ConfirmationModal from "../../../Fucntions/Modals/ConfirmationModal";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getOrderData } from "../../../redux/Admin/AppReducer/orders/action";
import { useNavigate, useParams } from "react-router-dom";
import { setToast } from "../../../Fucntions/Toastfunction";
import axios from "axios";

const ForGreetingsMessage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const suborder = useSelector((state) => state.OrderReducer.order);
  const [showEditButton, setShowEditButton] = useState(false);
  const [greeting_message_detail, setGreeting_message_detail] = useState({
    from: "",
    to: "",
    message: "",
  });

  function handleGreetingMessageChange(e) {
    const { name, value } = e.target;

    setGreeting_message_detail({
      ...greeting_message_detail,
      [name]: value,
    });
  }

  // *************************   FOR GREETINGS MESSAGE *******************************************
  let [greetingmessageinput, setGreetingMessageInput] = useState(null);
  const [greetingModal, setGreetingModal] = useState(false);
  const openGreetingModal = () => {
    if (!greeting_message_detail.message) {
      setToast(toast, "Please Fill all the details", "", "info");
      return;
    }
    setGreetingModal(true);
  };
  const closeGreetingModal = () => setGreetingModal(false);
  // console.log(greetingmessageinput,"greret")

  const [greetingLoader, setGreetingLoader] = useState(false);

  const handleGreetingMessageEdit = () => {
    if (greetingLoader) {
      return;
    }

    setGreetingLoader(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    let old_message =
      suborder?.note_attributes?.find((k) => k.name === "gift_message")
        ?.value || "";
    let note_attr = [...suborder?.note_attributes];
    if (note_attr.find((k) => k.name === "gift_message")?.value) {
      note_attr.find((k) => k.name === "gift_message").value =
        greeting_message_detail.message;
    } else {
      note_attr.push({
        name: "gift_message",
        value: greeting_message_detail.message,
      });
    }
    if (note_attr.find((k) => k.name === "message_sender")?.value) {
      note_attr.find((k) => k.name === "message_sender").value =
        greeting_message_detail.from;
    } else {
      note_attr.push({
        name: "message_sender",
        value: greeting_message_detail.from,
      });
    }
    if (note_attr.find((k) => k.name === "message_receiver")?.value) {
      note_attr.find((k) => k.name === "message_receiver").value =
        greeting_message_detail.to;
    } else {
      note_attr.push({
        name: "message_receiver",
        value: greeting_message_detail.to,
      });
    }
    if (note_attr.find((k) => k.name === "old_gift_message")?.value) {
      note_attr.find((k) => k.name === "old_gift_message").value = old_message;
    } else {
      note_attr.push({
        name: "old_gift_message",
        value: old_message,
      });
    }
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/order/editmessage/${id}`,
      data: {
        note_attr: note_attr,
        suborder_id: suborder?.line_items[0].related_suborder,
      },
      headers,
    })
      .then((r) => {
        // console.log(r.data)
        setGreetingMessageInput(null);
        setGreetingModal(false);
        setGreetingLoader(false);
        setToast(toast, `Greeting message updated`, "", "success");
        setGreeting_message_detail({ from: "", to: "", message: "" });
        setShowEditButton(false);
        dispatch(
          getOrderData(
            `${process.env.REACT_APP_API_URL}/order/read/${id}`,
            toast,
            navigate
          )
        );
      })
      .catch((err) => {
        // console.log(err)
        setGreetingLoader(false);
        setToast(toast, `Error in updating greeting message`, "", "error");
      });
  };

  return (
    <div>
      <ConfirmationModal
        heading="Edit Greeting Message"
        action={
          "Are you Sure? you want change the greeting message of this order"
        }
        onClickFunction={handleGreetingMessageEdit}
        ConfirmButton={"Edit"}
        color={"blue"}
        onClose={closeGreetingModal}
        isOpen={greetingModal}
        loader={true}
        loading={greetingLoader}
      />

      {suborder?.suborder_name ? (
        <Heading as="h4" p="4" size="sm" textAlign="left" bgColor="#f6f6f6">
          Card Message
        </Heading>
      ) : (
        <Heading as="h4" p="4" size="sm" textAlign="left" bgColor="#f6f6f6">
          Edit Card Message
        </Heading>
      )}
      <Box p="4">
        <Flex direction="column" color="black" gap="10px">
          {suborder?.suborder_id && suborder.old_gift_message && (
            <Text as="del" color="gray.600">
              <b>Old Message: </b>
              <span color="#222222">{suborder.old_gift_message}</span>
            </Text>
          )}
          {!suborder?.suborder_id &&
            suborder?.note_attributes?.find(
              (k) => k.name === "old_gift_message"
            )?.value && (
              <Text as="del" color="gray.600">
                <b>Old Message: </b>
                <span color="#222222">
                  {
                    suborder?.note_attributes?.find(
                      (k) => k.name === "old_gift_message"
                    )?.value
                  }
                </span>
              </Text>
            )}

          {/* <Flex gap="10px" flexWrap="wrap">
            <Text>To :</Text>

            {suborder?.suborder_id ? (
              <Text>{suborder?.message_receiver}</Text>
            ) : (
              <Text>
                {" "}
                {
                  suborder?.note_attributes?.find(
                    (k) => k.name.trim() === "message_receiver"
                  )?.value
                }
              </Text>
            )}
          </Flex> */}
          <Flex gap="10px" textAlign="left">
            {/* <Text>Message </Text> */}
            {/* <Text>:</Text> */}
            {suborder?.suborder_id ? (
              <Text>
                {suborder?.gift_message?.split("\n").map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </Text>
            ) : (
              <Text>
                {suborder?.note_attributes
                  ?.find((k) => k.name.trim() === "gift_message")
                  ?.value?.split("\n")
                  .map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
              </Text>
            )}
          </Flex>
          {/* <Flex gap="10px" flexWrap="wrap">
            <Text>From :</Text>

            {suborder?.suborder_id ? (
              <Text>{suborder?.message_sender}</Text>
            ) : (
              <Text>
                {
                  suborder?.note_attributes?.find(
                    (k) => k.name.trim() === "message_sender"
                  )?.value
                }
              </Text>
            )}
          </Flex> */}
        </Flex>
        <br />
        {showEditButton && (
          <Box>
            <Flex>
              <Flex color="black" direction="column" width="100%">
                <FormControl mt="5px">
                  {/* <FormLabel>To</FormLabel>
                  <Input
                    required={true}
                    placeholder="To"
                    name="to"
                    onChange={handleGreetingMessageChange}
                  /> */}
                  <FormControl mt="5px">
                    <FormLabel>Message</FormLabel>
                    <Textarea
  required={true}
  placeholder={`To:\n\nFrom:`}
  name="message"
  onChange={handleGreetingMessageChange}
/>
                  </FormControl>
                  {/* <FormControl mt="5px">
                    <FormLabel>From</FormLabel>
                    <Input
                      required={true}
                      placeholder="From"
                      name="from"
                      onChange={handleGreetingMessageChange}
                    />
                  </FormControl> */}
                </FormControl>
              </Flex>
            </Flex>
            {/* <Button mt="10px" colorScheme="blue" onClick={openGreetingModal}>
                Save
              </Button> */}
          </Box>
        )}

        <Flex gap="10px" mt="20px">
          {showEditButton && (
            <Text
              width="max-content"
              color="red"
              _hover={{
                textDecorationLine: "underline",
                cursor: "pointer",
              }}
              onClick={() => setShowEditButton(false)}
            >
              Cancel Edit
            </Text>
          )}
          {showEditButton && (
            <Text
              width="max-content"
              color="green"
              _hover={{
                textDecorationLine: "underline",
                cursor: "pointer",
              }}
              onClick={openGreetingModal}
            >
              {greetingLoader ? "...saving" : "Save Edit"}
            </Text>
          )}
        </Flex>
      </Box>
      <Box p="4">
        {!showEditButton && !suborder?.suborder_id && (
          <Text
            textAlign="left"
            color="blue"
            _hover={{
              textDecorationLine: "underline",
              cursor: "pointer",
            }}
            onClick={() => setShowEditButton(true)}
          >
            Edit Message
          </Text>
        )}
      </Box>
    </div>
  );
};

export default ForGreetingsMessage;
