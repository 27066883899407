import React from "react";
import DpOrdersStatus from "../Components/DpOrders/DpOrdersStatus";

const DpAssignedOrders = () => {
  return (
    <div>
      <DpOrdersStatus
        order_status="driver_allocated"
        heading={"Assigned Orders"}
        selection={true}
        challanshow={false}
        button_action={["out_for_delivery"]}
        buttons_line={["Out For Delivery"]}
        modal_fn={[
          
          {
            heading: "Out For Delivery",
            action: "Are You Sure You want to Mark Out For Delivery",
            ConfirmButton: "Out For Delivery",
            color: "whatsapp",
            toast_notification: "Order Out For Delivery",
          },
        ]}

        remark={false}

      />
    </div>
  );
};

export default DpAssignedOrders;
