import { Box, Button, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DateRangePicker({ startDate, setStartDate, endDate, setEndDate }) {
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    console.log(start, "end", end);
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <Flex gap="10px">
      <Flex
        className="date-picker"
        alignItems="center"
        border="1px solid gray"
        borderRadius="10px"
      >
        <label>
          <Flex>
            Choose Date
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              dateFormat="MM/dd/yyyy"
            />
          </Flex>
        </label>
      </Flex>
    </Flex>
  );
}

export default DateRangePicker;
