import React, { useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  Select,
  Checkbox,
  Grid,
  Box,
  Heading,
  Input,
  Badge,
  Spinner,
  useToast,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import DateSelector from "./DateSelector";
import { BsFillCalendarDateFill } from "react-icons/bs";
import "../orders/Date.css";
import DateRangePicker from "./DateSelector";
import { CloseIcon } from "@chakra-ui/icons";
import axios from "axios";
import { setToast } from "../../../Fucntions/Toastfunction";

const ModalForDateTimeBlocker = ({
  isOpen,
  onClose,
  renderComp,
  setRenderComp,
  edit,
  editableData,
}) => {
  const citys = useSelector((state) => state.VendorReducer.citys);
  const shipping = useSelector((state) => state.ShippingReducer.shipping);
  let time_slots = shipping?.flatMap((item) => item.time_slots);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const toast = useToast();
  let [DataForTimeBlocker, setDateTimeBlock] = useState(
    edit
      ? editableData
      : {
          blocked_time_slots: [],
          blocked_dates: [],
          effective_cities: [],
          blocked_days: [],
          enabled: true,
        }
  );
  const [dayOrDateSelector, setDayOrDateSelector] = useState(null); // For day Or Date Selector in Radio button
  let showDates =
    DataForTimeBlocker.blocked_dates.length > 0 ||
    dayOrDateSelector === "dates";
  let showDays =
    DataForTimeBlocker.blocked_days.length > 0 || dayOrDateSelector === "days";
  const [arrForWeekdays, setArrForWeekDays] = useState(
    DataForTimeBlocker.blocked_days
  ); // array for week days

  // function for handeling the array of week days
  function handleArrayOfWeekDays(e) {
    let arr = [...DataForTimeBlocker.blocked_days];
    if (arr.includes(e.target.value)) {
      let index_of_target = arr.indexOf(e.target.value);
      arr.splice(index_of_target, 1);
    } else {
      arr.push(e.target.value);
    }
    setArrForWeekDays(arr);
    setDateTimeBlock({ ...DataForTimeBlocker, blocked_days: arr });
  }
  console.log(arrForWeekdays, "days");
  console.log(DataForTimeBlocker, "data");

  function closeTheModal() {
    setStartDate(null);
    setEndDate(null);
    onClose();
    setDateTimeBlock({
      blocked_time_slots: [],
      blocked_dates: [],
      effective_cities: [],
      enabled: true,
    });
    setRenderComp(!renderComp);
  }

  const iconStyles = {
    cursor: "pointer",
    transition: "transform 0.3s, transform-origin 0.3s",
  };

  const handleMouseEnter = (event) => {
    event.currentTarget.style.transform = "rotate(180deg) scale(1.2)";
    event.currentTarget.style.transformOrigin = "center";
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.style.transform = "none";
    event.currentTarget.style.transformOrigin = "none";
  };

  function handleTimeSlots(e, time) {
    console.log(e.target.checked, "dsa");
    let str = `${time.start}-${time.end}`;
    let arr = [...DataForTimeBlocker.blocked_time_slots];
    if (e.target.checked) {
      arr.push(str);
    } else {
      arr = arr.filter((k) => k !== str);
    }
    setDateTimeBlock({
      ...DataForTimeBlocker,
      blocked_time_slots: arr,
    });
  }

  function handleCities(e, name, city) {
    console.log(name, "name");
    console.log(city, "city");
    let data = [...DataForTimeBlocker.effective_cities];
    const cityName = city;
    const nameToCheck = name;
    console.log(data, "data 1");
    const objectExists = data.some((item) => item.name === nameToCheck);
    if (e.target.checked) {
      if (objectExists) {
        // If an object with the name "l" exists, push "Jeddah" into its cities array
        data.forEach((item) => {
          if (item.name === nameToCheck) {
            item.cities.push(cityName);
          }
        });
      } else {
        // If no object with the name "l" exists, create a new object
        data.push({ name: nameToCheck, cities: [cityName] });
      }
      console.log(data, "data2");
    } else {
      console.log(data, "a");
      data.forEach((item) => {
        if (item.name === nameToCheck) {
          item.cities = item.cities.filter((k) => k !== city);
          if (item.cities.length === 0) {
            data = data.filter((k) => k.name !== nameToCheck);
          }
        }
      });
      console.log(data, "b");
    }
    setDateTimeBlock({
      ...DataForTimeBlocker,
      effective_cities: data,
    });
  }

  function handleFirstDateSave() {
    let startTime = new Date(startDate);
    let endTime = new Date(endDate);
    const StartDateToSave = `${startTime.getDate()}-${
      startTime.getMonth() + 1
    }-${String(startTime.getFullYear()).slice(2)}`;
    const EndDateToSave = `${endTime.getDate()}-${
      endTime.getMonth() + 1
    }-${String(endTime.getFullYear()).slice(2)}`;
    let data = [...DataForTimeBlocker.blocked_dates];
    data.push({ from: StartDateToSave, to: EndDateToSave });
    setDateTimeBlock({ ...DataForTimeBlocker, blocked_dates: data });
    setStartDate(null);
    setEndDate(null);
  }

  function handleRemoveDate(data) {
    console.log(data, "fata");
    let arr = [...DataForTimeBlocker.blocked_dates];
    arr = arr.filter((k) => k !== data);
    setDateTimeBlock({ ...DataForTimeBlocker, blocked_dates: arr });
  }

  let [saveLoader, setSaveLoader] = useState(false);
  function handleSaveDateTimeBlocker() {
    if (saveLoader) {
      return;
    }
    setSaveLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/datetimeblocker/create`,
      data: DataForTimeBlocker,
      headers,
    })
      .then((r) => {
        setSaveLoader(false);
        console.log(r.data);
        setToast(toast, `Blocker Successfully Created`, "", "success");
        closeTheModal();
      })
      .catch((err) => {
        setSaveLoader(false);
        console.log(err, "err");
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  const [updateLoader, setUpdateLoader] = useState(false);

  function handleUpdateDateTimeBlocker() {
    if (updateLoader) {
      return;
    }
    setUpdateLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    console.log(DataForTimeBlocker, "data");
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/datetimeblocker/update`,
      data: { data: DataForTimeBlocker },
      headers,
    })
      .then((r) => {
        setUpdateLoader(false);
        console.log(r.data);
        setToast(toast, `Blocker Successfully Updated`, "", "success");
        closeTheModal();
      })
      .catch((err) => {
        setUpdateLoader(false);
        console.log(err, "err");
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }


  // console.log(DataForTimeBlocker, "block");
  return (
    <div>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={onClose}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          {edit ? (
            <ModalHeader>Edit Date Time Blocker</ModalHeader>
          ) : (
            <ModalHeader>Create Date Time Blocker</ModalHeader>
          )}
          <ModalCloseButton onClick={closeTheModal} />
          <ModalBody>
            {/* **************************** TIME -SLOT   ************************************* */}
            <Flex
              justifyContent="space-between"
              textAlign="left"
              alignItems="center"
              mb="20px"
            >
              <Text flex="2">Choose Time Slots</Text>
              <Grid templateColumns="repeat(2,1fr)" flex="5">
                {time_slots.map((item, i) => (
                  <label key={i}>
                    <Flex gap="10px" ml="20px">
                      <Checkbox
                        isChecked={DataForTimeBlocker.blocked_time_slots.includes(
                          `${item.start}-${item.end}`
                        )}
                        onChange={(e) => handleTimeSlots(e, item)}
                      />
                      <Text>
                        {item.start} - {item.end}
                      </Text>
                    </Flex>
                  </label>
                ))}
              </Grid>
            </Flex>
            {/* *******************************   CITIES ********************* */}
            <Flex justifyContent="space-between" alignItems="center">
              <Text flex="2" textAlign="left">
                Choose Cities
              </Text>
              <Box flex="5">
                {citys.map((elem, i) => (
                  <Box mb="20px" key={i}>
                    <Heading as="h4" mb="10px" size="md" key={i}>
                      {elem.name}
                    </Heading>
                    <Grid templateColumns="repeat(3,1fr)">
                      {elem?.cities.map((name, ik) => (
                        <label key={ik}>
                          <Flex gap="10px">
                            <Checkbox
                              isChecked={DataForTimeBlocker.effective_cities
                                .flatMap((k) => k.cities)
                                .includes(name)}
                              onChange={(e) => handleCities(e, elem.name, name)}
                            />
                            <Text key={i}>{name}</Text>
                          </Flex>
                        </label>
                      ))}
                    </Grid>
                  </Box>
                ))}
              </Box>
            </Flex>

            <br />
            {/* *******************************  Days or Dates Block ********************* */}
            {showDates ? (
              <Flex justifyContent="space-between" alignItems="center">
                <Text flex="2" textAlign="left">
                  Choose Dates
                </Text>
                <Flex flex="5" justifyContent="space-around">
                  <DateRangePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                  <Button onClick={handleFirstDateSave}>Save</Button>
                </Flex>
              </Flex>
            ) : showDays ? (
              <Flex justifyContent="space-between" alignItems="center">
                <Text flex="2" textAlign="left">
                  Choose Days
                </Text>
                <Grid flex="5" templateColumns="repeat(3,1fr)" gap="3px">
                  <Checkbox
                    isChecked={arrForWeekdays.includes("sunday")}
                    onChange={handleArrayOfWeekDays}
                    value="sunday"
                  >
                    Sunday
                  </Checkbox>
                  <Checkbox
                    isChecked={arrForWeekdays.includes("monday")}
                    onChange={handleArrayOfWeekDays}
                    value="monday"
                  >
                    Monday
                  </Checkbox>
                  <Checkbox
                    isChecked={arrForWeekdays.includes("tuesday")}
                    onChange={handleArrayOfWeekDays}
                    value="tuesday"
                  >
                    Tuesday
                  </Checkbox>
                  <Checkbox
                    isChecked={arrForWeekdays.includes("wednesday")}
                    onChange={handleArrayOfWeekDays}
                    value="wednesday"
                  >
                    Wednesday
                  </Checkbox>
                  <Checkbox
                    isChecked={arrForWeekdays.includes("thursday")}
                    onChange={handleArrayOfWeekDays}
                    value="thursday"
                  >
                    Thursday
                  </Checkbox>
                  <Checkbox
                    isChecked={arrForWeekdays.includes("friday")}
                    onChange={handleArrayOfWeekDays}
                    value="friday"
                  >
                    Friday
                  </Checkbox>
                  <Checkbox
                    isChecked={arrForWeekdays.includes("saturday")}
                    onChange={handleArrayOfWeekDays}
                    value="saturday"
                  >
                    Saturday
                  </Checkbox>
                </Grid>
              </Flex>
            ) : (
              <>
                {/* *******************************  RADIO BUTTONS FOR DATE AND DAYS ********************* */}
                <Flex>
                  <Flex flex="2"> Choose Date Or Day</Flex>
                  <Flex flex="2">
                    <RadioGroup
                      onChange={setDayOrDateSelector}
                      value={dayOrDateSelector}
                    >
                      <Stack direction="row" gap="20px">
                        <Radio size="lg" value="days">
                          Days
                        </Radio>
                        <Radio size="lg" value="dates">
                          Dates
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Flex>
                </Flex>
              </>
            )}

            <Grid
              borderRadius="10px"
              border="1px solid gray"
              gap="10px"
              mt="20px"
              height="100px"
              width="100%"
              templateColumns="repeat(3,1fr)"
              padding="5px"
            >
              {DataForTimeBlocker.blocked_dates.map((item, i) => (
                <Badge
                  fontSize="14px"
                  height="25px"
                  borderRadius="10px"
                  key={i}
                >
                  <Flex gap="10px" display="inline-flex">
                    <Text>
                      {item.from.replaceAll("-", "/")} -{" "}
                      {item.to.replaceAll("-", "/")}
                    </Text>
                    <Flex alignItems="center">
                      <CloseIcon
                        onClick={() => handleRemoveDate(item)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        _hover={{
                          cursor: "pointer",
                          transition: "transform 0.3s",
                          transforOrigin: "0.3s",
                        }}
                      />
                    </Flex>
                  </Flex>
                </Badge>
              ))}
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeTheModal}>
              Close
            </Button>
            {edit ? (
              <Button colorScheme="green" onClick={handleUpdateDateTimeBlocker}>
                {updateLoader ? <Spinner /> : "Update"}
              </Button>
            ) : (
              <Button
                isDisabled={!dayOrDateSelector}
                colorScheme="green"
                onClick={handleSaveDateTimeBlocker}
              >
                {saveLoader ? <Spinner /> : "Save"}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ModalForDateTimeBlocker;
