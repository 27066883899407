import React from "react";
import { Route, Routes } from "react-router-dom";
import AcceptedOrder from "../page/Orders/AcceptedOrders";
import DeliveryBoy from "../page/DeliveryBoy/DeliveryBoy";
import Authentication from "../Authentication/Authentication";
import LoginPage from "../page/LoginPage";
import DeliveryDetails from "../page/Delivery/DeliveryDetails";
import HomePartner from "../page/HomePartner";
import AllocatedOrders from "../page/Orders/AllocatedOrders";
import RejectedOrders from "../page/Orders/RejectedOrders";
import DriverAllocated from "../page/Orders/DriverAllocated";
import OutForDelivery from "../page/Orders/OutForDelivery";
import DeliveredOrders from "../page/Orders/DeliveredOrders";
import AttemptedOrders from "../page/Orders/AttemptedOrders";
import PrintedOrders from "../page/Orders/PrintedOrders";
import Shop from "../page/ShopFlourist/Shop";
import Dashboard from "../page/Dashboard/Dashboard";
import PartnerRoles from "../page/Administration/Roles/PartnerRoles";
import PartnerUser from "../page/Administration/Users/PartnerUser";


const PartnerRoute = () => {
  const partner_admin=JSON.parse(sessionStorage.getItem("partner_type"))==="partner_admin"
  return (
    <div>
      <Routes>
        <Route path="/partner/login" element={<LoginPage />} />
        <Route
          path="/partner"
          element={
            <Authentication>
              <HomePartner />
            </Authentication>
          }
        />
        <Route
          path="/partner/acceptedorder"
          element={
            <Authentication>
              <AcceptedOrder />
            </Authentication>
          }
        />

        <Route
          path="/partner/attemptedorders"
          element={
            <Authentication>
              <AttemptedOrders />
            </Authentication>
          }
        />

        <Route
          path="/partner/printedorders"
          element={
            <Authentication>
              <PrintedOrders />
            </Authentication>
          }
        />

        <Route
          path="/partner/allocatedorders"
          element={
            <Authentication>
              <AllocatedOrders />
            </Authentication>
          }
        />

        <Route
          path="/partner/outfordelivery"
          element={
            <Authentication>
              <OutForDelivery />
            </Authentication>
          }
        />

        <Route
          path="/partner/deliveredorders"
          element={
            <Authentication>
              <DeliveredOrders />
            </Authentication>
          }
        />

        <Route
          path="/partner/rejectedorders"
          element={
            <Authentication>
              <RejectedOrders />
            </Authentication>
          }
        />

        <Route
          path="/partner/driverallocated"
          element={
            <Authentication>
              <DriverAllocated />
            </Authentication>
          }
        />

        <Route
          path="/partner/deliveryboys"
          element={
            <Authentication>
              <DeliveryBoy />
            </Authentication>
          }
        />

        <Route
          path="/partner/shop"
          element={
            <Authentication>
              <Shop />
            </Authentication>
          }
        />

        <Route
          path="/partner/dashboard/:status"
          element={
            <Authentication>
              <Dashboard/>
            </Authentication>
          }
        />
       { partner_admin&& <Route
          path="/partner/users"
          element={
            <Authentication>
              <PartnerUser/>
            </Authentication>
          }
        />}
        {partner_admin && <Route
          path="/partner/roles"
          element={
            <Authentication>
              <PartnerRoles/>
            </Authentication>
          }
        />}
      </Routes>
    </div>
  );
};

export default PartnerRoute;
