import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  FormLabel,
  Text,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { OrderAfterStartOrderEditing } from "../../../../redux/Admin/AppReducer/orders/action";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setToast } from "../../../../Fucntions/Toastfunction";
import { useNavigate, useParams } from "react-router-dom";

const ModalForAdjustQty = ({ isOpen, onClose, prod }) => {
  const toast = useToast();
  const { id } = useParams();
  const [qty, setQty] = useState(null);
  const navigate=useNavigate()
  const dispatch = useDispatch();
  const [reasonEdit, setReasonEdit] = useState(null);
  let [loadingState, setLoadingState] = useState(false);

  const handleeditOrderAdjustQty = () => {
    if (loadingState) {
      return;
    }
    setLoadingState(true);
    setToast(toast, `Qunatity change in process`, "", "info");
    // console.log(prod, "prod");
    let data = {
      order_id: id,
      lineItemId: `${prod.id}`,
      quantity: qty,
      restock: true,
      staffNote: reasonEdit,
    };
    // const headers = {
    //   Authorization: `Bearer ${JSON.parse(
    //     sessionStorage.getItem("admintoken")
    //   )}`,
    // };
    const headers = {
      "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
    };
    // console.log(headers,"head")
    axios({
      method:"PUT",
      url: `${process.env.REACT_APP_API_URL}/order/editLineItemQuantity`,
      data,headers
    })
      .then((r) => {
        if(r.data.err){
          setToast(toast, `${r.data.err}`, "", "error");
          onClose();
          setLoadingState(false)
        }
        else{
          // console.log(r,"r.data")
          dispatch(OrderAfterStartOrderEditing(r.data));
          setToast(toast, `Quantity Change Successfull`, "", "success");
          onClose();
          setQty(null);
          setLoadingState(false);
          setReasonEdit(null)
        }
      })
      .catch((err) => {
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        setToast(toast, `${err.response.data.err}`, "", "error");
        setLoadingState(false);
        setQty(null);
        setReasonEdit(null)
      });
  };
  return (
    <div>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adjust Quantity</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} textAlign="left">
            <Text mb="25px">Adjust The Quantity for {prod?.title}</Text>
            <FormControl>
              <FormLabel>Quantity</FormLabel>
              <Input
                type="number"
                min="0"
                placeholder="Adjsut Your price here"
                pt="5px"
                onChange={(e) => setQty(+e.target.value)}
              />
            </FormControl>

            <FormControl mt="10px">
              <FormLabel>Reason For Adjusting Quantity</FormLabel>
              <Input
                placeholder="Reason For Editing the quantity"
                onChange={(e) => setReasonEdit(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleeditOrderAdjustQty}
              isDisabled={!qty || !reasonEdit}
            >
              {loadingState ? <Spinner /> : "Done"}
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ModalForAdjustQty;
