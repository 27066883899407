import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  Flex,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { AiFillHome, AiFillShop, AiFillTag } from "react-icons/ai";
import { IoIosPeople } from "react-icons/io";
import { MdDashboard } from "react-icons/md";

const PartnerCatlog = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(null);
  const partner_admin=JSON.parse(sessionStorage.getItem("partner_type"))==="partner_admin"
  const handleAccordionClick = (index) => {
    setActiveItem(index);
  };

  return (
    <div>
      <Accordion allowToggle allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                navigate(`/partner/dashboard/${"all"}`);
                handleAccordionClick(0.1);
              }}
              bg={activeItem === 0.1 ? "gray.200" : ""}
              color={activeItem === 0.1   ? "#7D8035" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <MdDashboard  fontSize="20px" />
                  Dashboard
                </Flex>
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem>
      </Accordion>
      <Accordion allowToggle allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                navigate("/partner");
                handleAccordionClick(0);
              }}
              bg={activeItem === 0 ? "gray.200" : ""}
              color={activeItem === 0 ? "#7D8035" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <AiFillHome fontSize="20px" />
                  Home
                </Flex>
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem>
      </Accordion>

      <Accordion allowToggle allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                handleAccordionClick(4);
                // navigate("/partner/order");
              }}
              color={activeItem === 4 ? "#7D8035" : ""}
              bg={activeItem === 4 ? "gray.200" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <AiFillTag fontSize="20px" />
                  Orders
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <AccordionItem>
              <h2>
                <Flex
                  textAlign="left"
                  paddingLeft="18px"
                  height="40px"
                  alignItems="center"
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    handleAccordionClick(32);
                    navigate("/partner/allocatedorders");
                  }}
                  bg={activeItem === 32 ? "gray.200" : ""}
                  color={activeItem === 32 ? "#7D8035" : ""}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="bold"
                    fontSize="14px"
                  >
                    Allocated Orders
                  </Box>
                </Flex>
              </h2>
            </AccordionItem>

            <Accordion defaultIndex={[0]} allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton
                    onClick={(e) => {
                      handleAccordionClick(31);
                      navigate("/partner/acceptedorder");
                    }}
                    color={activeItem === 31 ? "#7D8035" : ""}
                    bg={activeItem === 31 ? "gray.200" : ""}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      <Flex
                        gap="10px"
                        alignItems="center"
                        fontSize="14px"
                        fontWeight="bold"
                      >
                        Accepted Orders
                      </Flex>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <AccordionItem>
                    <h2>
                      <Flex
                        textAlign="left"
                        paddingLeft="18px"
                        height="40px"
                        alignItems="center"
                        _hover={{ cursor: "pointer" }}
                        onClick={() => {
                          handleAccordionClick(31);
                          navigate("/partner/acceptedorder");
                        }}
                        bg={activeItem === 31 ? "gray.200" : ""}
                        color={activeItem === 31 ? "#7D8035" : ""}
                      >
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight="bold"
                          fontSize="14px"
                        >
                          Non-printed Orders
                        </Box>
                      </Flex>
                    </h2>
                  </AccordionItem>

                  <AccordionItem>
                    <h2>
                      <Flex
                        textAlign="left"
                        paddingLeft="18px"
                        height="40px"
                        alignItems="center"
                        _hover={{ cursor: "pointer" }}
                        onClick={() => {
                          handleAccordionClick(31.12);
                          navigate("/partner/printedorders");
                        }}
                        bg={activeItem === 31.12 ? "gray.200" : ""}
                        color={activeItem === 31.12 ? "#7D8035" : ""}
                      >
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight="bold"
                          fontSize="14px"
                        >
                          Printed Orders
                        </Box>
                      </Flex>
                    </h2>
                  </AccordionItem>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <AccordionItem>
              <h2>
                <Flex
                  textAlign="left"
                  paddingLeft="18px"
                  height="40px"
                  alignItems="center"
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    handleAccordionClick(33);
                    navigate("/partner/rejectedorders");
                  }}
                  bg={activeItem === 33 ? "gray.200" : ""}
                  color={activeItem === 33 ? "#7D8035" : ""}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="bold"
                    fontSize="14px"
                  >
                    Rejected Orders
                  </Box>
                </Flex>
              </h2>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <Flex
                  textAlign="left"
                  paddingLeft="18px"
                  height="40px"
                  alignItems="center"
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    handleAccordionClick(34);
                    navigate("/partner/driverallocated");
                  }}
                  bg={activeItem === 34 ? "gray.200" : ""}
                  color={activeItem === 34 ? "#7D8035" : ""}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="bold"
                    fontSize="14px"
                  >
                    Driver Allocated
                  </Box>
                </Flex>
              </h2>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <Flex
                  textAlign="left"
                  paddingLeft="18px"
                  height="40px"
                  alignItems="center"
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    handleAccordionClick(35);
                    navigate("/partner/outfordelivery");
                  }}
                  bg={activeItem === 35 ? "gray.200" : ""}
                  color={activeItem === 35 ? "#7D8035" : ""}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="bold"
                    fontSize="14px"
                  >
                    Out For Delivery
                  </Box>
                </Flex>
              </h2>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <Flex
                  textAlign="left"
                  paddingLeft="18px"
                  height="40px"
                  alignItems="center"
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    handleAccordionClick(36);
                    navigate("/partner/deliveredorders");
                  }}
                  bg={activeItem === 36 ? "gray.200" : ""}
                  color={activeItem === 36 ? "#7D8035" : ""}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="bold"
                    fontSize="14px"
                  >
                    Delivered Orders
                  </Box>
                </Flex>
              </h2>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <Flex
                  textAlign="left"
                  paddingLeft="18px"
                  height="40px"
                  alignItems="center"
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    handleAccordionClick(37);
                    navigate("/partner/attemptedorders");
                  }}
                  bg={activeItem === 37 ? "gray.200" : ""}
                  color={activeItem === 37 ? "#7D8035" : ""}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="bold"
                    fontSize="14px"
                  >
                    Attempted Orders
                  </Box>
                </Flex>
              </h2>
            </AccordionItem>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Accordion>
        <AccordionItem>
          <h2>
            <Flex
              textAlign="left"
              paddingLeft="18px"
              height="40px"
              alignItems="center"
              _hover={{ cursor: "pointer" }}
              onClick={() => {
                handleAccordionClick(71);
                navigate("/partner/deliveryboys");
              }}
              bg={activeItem === 71 ? "gray.200" : ""}
              color={activeItem === 71 ? "#7D8035" : ""}
            >
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight="bold"
                fontSize="14px"
              >
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <IoIosPeople fontSize="20px" />
                  Delivery Boys
                </Flex>
              </Box>
            </Flex>
          </h2>
        </AccordionItem>
      </Accordion>
      <Accordion>
        <AccordionItem>
          <h2>
            <Flex
              textAlign="left"
              paddingLeft="18px"
              height="40px"
              alignItems="center"
              _hover={{ cursor: "pointer" }}
              onClick={() => {
                handleAccordionClick(72);
                navigate("/partner/shop");
              }}
              bg={activeItem === 72 ? "gray.200" : ""}
              color={activeItem === 72 ? "#7D8035" : ""}
            >
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight="bold"
                fontSize="14px"
              >
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <AiFillShop fontSize="20px" />
                  Shop
                </Flex>
              </Box>
            </Flex>
          </h2>
        </AccordionItem>
      </Accordion>
      {partner_admin && <Accordion allowToggle>
      <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                handleAccordionClick(3);
              }}
              bg={activeItem === 321 ? "gray.200" : ""}
              color={activeItem === 321 ? "#7D8035" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <IoIosPeople fontSize="20px" />
                  Administration
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <AccordionItem>
              <h2>
                <Flex
                  textAlign="left"
                  paddingLeft="18px"
                  height="40px"
                  alignItems="center"
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/partner/roles");
                    handleAccordionClick(321.1);
                  }}
                  bg={activeItem === 321.1 ? "gray.200" : ""}
                  color={activeItem === 321.1 ? "#7D8035" : ""}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="bold"
                    fontSize="14px"
                  >
                    Roles
                  </Box>
                </Flex>
              </h2>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <Flex
                  textAlign="left"
                  paddingLeft="18px"
                  height="40px"
                  alignItems="center"
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/partner/users");
                    handleAccordionClick(321.2);
                  }}
                  bg={activeItem === 321.2 ? "gray.200" : ""}
                  color={activeItem === 321.2 ? "#7D8035" : ""}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                 
                    fontWeight="bold"
                    fontSize="14px"
                  >
                    Users
                  </Box>
                </Flex>
              </h2>
            </AccordionItem>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>}

      
    </div>
  );
};

export default PartnerCatlog;
