import React, { useEffect } from "react";
import SubOrderPage from "../SubOrders/SubOrderPage";
import { useDispatch } from "react-redux";
import { getOrderData } from "../../../redux/Admin/AppReducer/orders/action";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const SubOrderDetail = () => {
    const toast = useToast();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const order=useSelector((state)=>state.OrderReducer.order)

  useEffect(() => {
    dispatch(
      getOrderData(`${process.env.REACT_APP_API_URL}/suborder/read/${id}`, toast,navigate)
    );
  }, []);

  return (
    <div>
         <SubOrderPage />
    </div>
  )
}

export default SubOrderDetail