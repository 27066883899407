import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  FormLabel,
  Text,
  useToast,
  Divider,
  Spinner,
  Heading,
  Flex,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import AddedDiscount from "./AddedDiscount";

const ModalforDeliveryAddress = ({
  isOpen,
  onClose,
  address,
  setAddress,
  handleHitApiWithData,
  processstart,
  reasonEdit,
  setReasonEdit,
  showdiscount,
  setShowDiscount,
  radioval,
  setRadioval,
  discountamount,
  setDiscountAmount,
}) => {
  const timeSlot = useSelector((state) => state.OrderReducer.Selected_TimeSlot);
  const product = useSelector(
    (state) => state.OrderReducer.Order_Editing_Added_Product
  );
  const cityDateDetail = useSelector(
    (state) => state.OrderReducer.Order_Editing_CityDate_Detail
  );

  // console.log(cityDateDetail, "detail");
  //  console.log(processstart, "prod");
  // console.log(timeSlot, "timeslOT");

  function handleInputChange(e) {
    const { name, value } = e.target;
    if (value.charAt(0) === ' ') {
      // Remove the first character (space)
      e.target.value = value.slice(1);
      return
    }
    setAddress({
      ...address,
      [name]: value,
    });
  }

  function closemodal(){
    onClose()
    setShowDiscount(false)
    setRadioval("percentage")
  }

  return (
    <div>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delivery Address</ModalHeader>
          <ModalCloseButton  onClick={closemodal}/>
          <Divider />
          <ModalBody pb={6} textAlign="left">
            <Heading as="h2" size="lg" fontWeight="semibold">
              Product Detail
            </Heading>
            <br />
            <Flex gap="4px">
              <Text>Name : </Text>
              <Text> {product?.title}</Text>
            </Flex>
            <Flex gap="4px">
              <Text>Variant :</Text>
              <Text>{product?.variants?.find((id)=>id.id===cityDateDetail?.variant_id)?.title}</Text>
            </Flex>
            <Flex gap="4px">
              <Text>Time Slot :</Text>
              <Text>{timeSlot?.time_slot} </Text>
            </Flex>
            <Flex gap="4px">
              <Text>Shipping Method :</Text>
              <Text>{timeSlot?.shipping_method}</Text>
            </Flex>

            <Flex gap="4px">
              <Text> City :</Text>
              <Text>{cityDateDetail?.city}</Text>
            </Flex>
            <Flex gap="4px">
              <Text>Date :</Text>
              <Text>{cityDateDetail?.date}</Text>
            </Flex>
            <br />
            <AddedDiscount
              discount={showdiscount}
              setDiscount={setShowDiscount}
              discounttype={radioval}
              setDiscountype={setRadioval}
              amount={discountamount}
              setDiscountAmount={setDiscountAmount}
            />
            
            <Heading as="h2" size="lg" fontWeight="semibold">
              Edit Address
            </Heading>

            <Flex gap="10px" mt="15px">
              <FormControl mt="5px">
                <FormLabel>First Name</FormLabel>
                <Input
                  placeholder="Enter First Name"
                  name="fname"
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl mt="5px">
                <FormLabel>Last Name</FormLabel>
                <Input
                  placeholder="Enter Last Name"
                  name="lname"
                  onChange={handleInputChange}
                />
              </FormControl>
            </Flex>
            <FormControl mt="5px">
              <FormLabel>Address Line 1</FormLabel>
              <Input
                placeholder="Enter Address"
                name="address1"
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mt="5px">
              <FormLabel>Address Line 2</FormLabel>
              <Input
                placeholder="Enter Address"
                name="address2"
                onChange={handleInputChange}
              />
            </FormControl>
            <br />
            <FormControl mt="5px">
              <FormLabel>Reason For Edit</FormLabel>
              <Input
                placeholder="Reason For Edit"
                onChange={(e) => {  if (e.target.value.charAt(0) === ' ') {
                 
                  // Remove the first character (space)
                  e.target.value = e.target.value.slice(1);
                  return
                }
                  setReasonEdit(e.target.value)}}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                if(processstart){
                  return
                }
                handleHitApiWithData();
              }}
              isDisabled={
                !reasonEdit ||
                !address.fname ||
                !address.address1 ||
                !address.address2
              }
            >
              {processstart ? <Spinner /> : "Done"}
            </Button>
            <Button onClick={closemodal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ModalforDeliveryAddress;
