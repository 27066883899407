import React, { useEffect } from "react";
import SubOrderPage from "../SubOrders/SubOrderPage";
import { useDispatch } from "react-redux";
import { getOrderData } from "../../../redux/Admin/AppReducer/orders/action";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const OrderDetailpage = () => {
  const toast = useToast();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate=useNavigate()

  useEffect(() => {
    dispatch(
      getOrderData(`${process.env.REACT_APP_API_URL}/order/read/${id}`, toast,navigate)
    );
  }, []);
  return (
    <div>
      <SubOrderPage />
    </div>
  );
};

export default OrderDetailpage;
