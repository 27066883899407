import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const Authentication = ({children}) => {
    const isAuth = useSelector((state)=>state.AuthReducer.dpisAuth);
   console.log(isAuth,"dkksd")
    if (isAuth) {
      return children;
    }
    return <Navigate to="/deliverypartner/login" />;
}

export default Authentication