export const GET_SHIPPING_DATA_LOADING = "GET_SHIPPING_DATA_LOADING";
export const GET_SHIPPING_DATA_ERROR = "GET_SHIPPING_DATA_ERROR";
export const GET_SHIPPING_DATA_SUCCESS = "GET_SHIPPING_DATA_SUCCESS";

export const CREATE_SHIPPING_DATA_LOADING = "CREATE_SHIPPING_DATA_LOADING";
export const CREATE_SHIPPING_DATA_ERROR = "CREATE_SHIPPING_DATA_ERROR";
export const CREATE_SHIPPING_DATA_SUCCESS = "CREATE_SHIPPING_DATA_SUCCESS";

export const DELETE_SHIPPING_DATA_LOADING = "DELETE_SHIPPING_DATA_LOADING";
export const DELETE_SHIPPING_DATA_ERROR = "DELETE_SHIPPING_DATA_ERROR";
export const DELETE_SHIPPING_DATA_SUCCESS = "DELETE_SHIPPING_DATA_SUCCESS";

