import React, { useState } from "react";
import OrderStatusComp from "./OrderStatusComp";
import { Text } from "@chakra-ui/react";

const AcceptedOrders = () => {
  let [policy, setPolicy] = useState([]);
  let [buttons_name, setButtonname] = useState([]);
  let [button_modal, setButtonModal] = useState([]);
  return (
    <div>
      <OrderStatusComp
        status={"accepted"}
        order_status={"accepted_orders"}
        heading={"Accepted Orders"}
        selection={true}
        challanshow={true}
        // button_action={["printed", "rejected", "allocated"]}
        // buttons_line={["Printed", "Reject Orders", "Allocate Orders"]}
        // modal_fn={[
        //   {
        //     heading: "Printed Orders",
        //     action: "Are You Sure Your Want to Mark This Order As Printed",
        //     ConfirmButton: "Confirm",
        //     color: "blue",
        //     toast_notification: "Order Printed",
        //   },
        //   {
        //     heading: "Reject Order",
        //     action: "Are You Sure You want to Reject This order",
        //     ConfirmButton: "Reject Order",
        //     color: "red",
        //     toast_notification: "Order Rejected",
        //   },
        //   {
        //     heading: "Allocate Order",
        //     action: "Are You Sure You want to Allocate This order",
        //     ConfirmButton: "Allocated Order",
        //     color: "green",
        //     toast_notification: "Order Allocated",
        //   },
        // ]}
        button_action={policy}
        buttons_line={buttons_name}
        modal_fn={button_modal}
        setPolicy={setPolicy}
        setButtonModal={setButtonModal}
        setButtonname={setButtonname}
        policy={policy}
        buttons_name={buttons_name}
        button_modal={button_modal}
      />
    </div>
  );
};

export default AcceptedOrders;
