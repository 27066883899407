import React from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";

const PaginationComp = ({ page, setPage, isDisabledLogic }) => {
  return (
    <Flex mt="10px" gap="10px" justifyContent="center">
      <Button
        bg="black"
        color="white"
        isDisabled={page === 1}
        onClick={() => setPage((prev) => prev - 1)}
      >
        Previous Page
      </Button>
      <Box
        height="40px"
        pt="7px"
        width="40px"
        bg="black"
        borderRadius="10px"
        color="white"
      >
        <Text fontWeight="bold">{page}</Text>
      </Box>
      <Button
        bg="black"
        color="white"
        isDisabled={isDisabledLogic}
        onClick={() => setPage((prev) => prev + 1)}
      >
        Next Page
      </Button>
    </Flex>
  );
};

export default PaginationComp;
