import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import Allroutes from "./Admin/Routes/Allroutes";
import Navbar from "./Admin/Components/Navbar";
import Accordincatlog from "./Admin/Components/Accordincatlog";
import { useEffect, useState } from "react";
import PartnerRoute from "./PartnerFnp/Routes/PartnerRoute";
import PartnerCatlog from "./PartnerFnp/components/PartnerCatlog";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getVendorSingleData } from "./redux/Admin/AppReducer/vendors/action";
import PartnerNavbar from "./PartnerFnp/components/PartnerNavbar";
import { useLocation, useNavigate } from "react-router-dom";
import Error from "./Page/Error";
import DpRoutes from "./DeliveryPartner/Routes/DpRoutes";
import DpNavbar from "./DeliveryPartner/Components/DpNavbar";
import DpCatlog from "./DeliveryPartner/Components/DpCatlog";
import BakerRoute from "./Bakery-non-bakery/Routes/BakerRoute";
import BakerNavbar from "./Bakery-non-bakery/components/BakerNavbar";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [admin, setAdmin] = useState(true);
  const [screensize, setScreenSize] = useState(false);
  const screenwidth = window.screen.width;
  const partner = useSelector((state) => state.AuthReducer.PartnerisAuth);
  const adminisAuth = useSelector((state) => state.AuthReducer.AdminisAuth);
  const dpisAuth = useSelector((state) => state.AuthReducer.dpisAuth);
  const bakerisAuth=useSelector((state)=>state.AuthReducer.bakerisAuth)
  const { pathname } = useLocation();

  useEffect(() => {
    if (screenwidth < 800) {
      setScreenSize(true);
    }
  }, []);
  useEffect(() => {
    if (pathname.split("/")[1] === "admin") {
      setAdmin(0);
    } else if (pathname.split("/")[1] === "partner") {
      setAdmin(1);
    } else if (pathname.split("/")[1] === "deliverypartner") {
      setAdmin(2);
    }
    else if (pathname.split("/")[1] === "shop") {
      setAdmin(3);
    }
  }, [admin]);

  return (
    
    <Box scrollBehavior="smooth">
      {pathname === "/" ? (
        <Error />
      ) : (
        <>
          {admin === 0 ? (
            <>
              {adminisAuth ? <Navbar /> : null}
              <Flex bg="#F5F5F7" width="100%" minHeight="100vh">
                {adminisAuth ? (
                  <Box
                    display={{ base: "none", md: "block", lg: "block" }}
                    width={"15%"}
                  >
                    {sessionStorage.getItem("admintoken") && <Accordincatlog />}
                  </Box>
                ) : null}
                <Divider orientation="vertical" type="gray" />
                <Box
                  width={screensize ? "100%" : adminisAuth ? "85%" : "100%"}
                  p="3"
                >
                  <Allroutes />
                </Box>
              </Flex>
            </>
          ) : admin === 1 ? (
            <>
              {partner ? <PartnerNavbar /> : null}
              <Flex bg="#F5F5F7" width="100%" minHeight="120vh">
                {partner ? (
                  <Box
                    display={{ base: "none", md: "none", lg: "block" }}
                    width={"15%"}
                  >
                    {sessionStorage.getItem("partnertoken") && (
                      <PartnerCatlog />
                    )}
                  </Box>
                ) : null}
                <Box
                  width={screensize ? "100%" : partner ? "85%" : "100%"}
                  p="2"
                >
                  <PartnerRoute />
                </Box>
              </Flex>
            </>
          ) : admin === 2 ? (
            <>
              {" "}
              {dpisAuth ? <DpNavbar /> : null}
              <Flex bg="#F5F5F7" width="100%" minHeight="120vh">
                {dpisAuth ? (
                  <Box display={{ base: "none", md: "none", lg: "none" }}>
                    {sessionStorage.getItem("dptoken") && <DpCatlog />}
                  </Box>
                ) : null}
                <Box
                  width={ "100%"}
                  p="2"
                >
                  <DpRoutes />
                </Box>
              </Flex>{" "}
            </>
          ) :admin === 3 ? (
            <>
              {" "}
              {bakerisAuth ? <BakerNavbar /> : null}
              <Flex bg="#F5F5F7" width="100%" minHeight="120vh">
                <Box
                  width={ "100%"}
                  p="2"
                >
                  <BakerRoute />
                </Box>
              </Flex>{" "}
            </>
          ) : (
            <>
              {" "}
              <Text
                color="red.400"
                fontSize="40px"
                fontWeight="semibold"
                mt="80px"
                pb="80px"
              >
                Page Does Not Found
              </Text>
            </>
          )}
        </>
      )}
    </Box>
    
  );
}

export default App;
