import React, { Fragment, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  FormLabel,
  Box,
  InputRightElement,
  InputGroup,
  Flex,
  Checkbox,
  Grid,
  Badge,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { setToast } from "../../../../Fucntions/Toastfunction";
import { useNavigate } from "react-router-dom";

const UserModal = ({
  onClose,
  isOpen,
  title,
  userDetail,
  setUserDetail,
  render,
  edit,
  setRender,
  roles,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [pass, setPass] = useState(false);

  const handlepassClick = () => setPass(!pass);
  const [confirmpass, setConfirmpass] = useState(false);
  const handleconfirmpassClick = () => setConfirmpass(!confirmpass);
  const [showupdatepassword, setShowUpdatepassword] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [showSelectedRoles, setShowSelectedRoles] = useState([]);

  const headers = {
    Authorization: `Bearer ${JSON.parse(
      sessionStorage.getItem("partnertoken")
    )}`,
  };
  const addRolesHandler = (e) => {
    e.stopPropagation();
    setToggle(true);
  };

  function handlesaveSelectedRoles() {
    setToggle(false);
    setQuery("");
    setShowSelectedRoles(selectedRoles);
    setUserDetail({
      ...userDetail,
      role: [...selectedRoles],
    });
  }

  // *******************   FUNCTION TO GET ALL THE ROLES *************************

  function handleUserChange(e) {
    const { name, value } = e.target;
    setUserDetail({
      ...userDetail,
      [name]: value,
    });
  }
  const [createloader, setCreateLoader] = useState(false);
  function handleCreateUserHitApi() {
     if(!userDetail.name || !userDetail.email || !userDetail.password){
      setToast(toast, "Please fill all the details", "", "error");
      return;
     }
    if (userDetail.password !== userDetail.confirm_password) {
      setToast(toast, "Password Does not Match", "", "error");
      return;
    }
    if (createloader) {
      return;
    }
    setCreateLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("partnertoken")
      )}`,
    };
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/partner/users/create`,
      data: userDetail,
      headers,
    })
      .then((r) => {
        setCreateLoader(false);
        setToast(toast, `User Successfully Created`, "", "success");
        setRender(!render);
        onClose();
        setUserDetail({
          name: "",
          email: "",
          password: "",
          confirm_password: "",
          role: [],
        });
      })
      .catch((err) => {
        setCreateLoader(false);
        if(typeof(err.response.data.err)==="object"){
          setToast(toast, `${err.response.data.err?.keyValue?.email} already present`, "", "error");
          setCreateLoader(false);
          return
        }
     
        
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/partner/login");
          return;
        }
  
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  const [editloader, setEditLoader] = useState(false);
  function handleEditUserHitApi() {
    if (editloader) {
      return;
    }
    if(showupdatepassword){
      if (userDetail.password !== userDetail.confirm_password) {
        setToast(toast, "Password Does not Match", "", "error");
        return;
      }
    }
    else{
      delete userDetail.password
    }
    setEditLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("partnertoken")
      )}`,
    };
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/partner/users/edit`,
      data: userDetail,
      headers,
    })
      .then((r) => {
        setEditLoader(false);
        setToast(toast, `User Successfully Edit`, "", "success");
        setRender(!render);
        onClose();
        setUserDetail({
          name: "",
          email: "",
          password: "",
          confirm_password: "",
          role: [],
        });
      })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/partner/login");
          return;
        }
        setEditLoader(false);
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  useEffect(() => {
    setShowSelectedRoles(userDetail.role);
    setSelectedRoles(userDetail.role);
  }, [userDetail]);

  return (
    <div>
      <Modal onClose={onClose} size="md" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{edit ? "Edit Users" : "Create Users"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="10px">
              <FormLabel>User Name</FormLabel>
              <Input
                name="name"
                placeholder="Enter User Name"
                onChange={(e) => handleUserChange(e)}
                value={userDetail.name}
              />
            </FormControl>

            <FormControl mb="10px">
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                placeholder="Enter Email"
                onChange={(e) => handleUserChange(e)}
                value={userDetail.email}
              />
            </FormControl>
            {edit ? (
              <Box>
                <Flex justifyContent="space-between">
                  <Checkbox
                    size="lg"
                    colorScheme="orange"
                    fontWeight="semibold"
                    mb="20px"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setShowUpdatepassword(true);
                      } else {
                        setShowUpdatepassword(false);
                      }
                    }}
                  >
                    Update Password
                  </Checkbox>
                  <></>
                </Flex>
                {showupdatepassword ? (
                  <>
                    <FormControl mb="10px">
                      <FormLabel>Update Password</FormLabel>
                      <InputGroup size="md">
                        <Input
                          name="password"
                          onChange={(e) => handleUserChange(e)}
                          pr="4.5rem"
                          type={pass ? "text" : "password"}
                          placeholder="Enter password"
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={handlepassClick}
                          >
                            {pass ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                    <FormControl mb="10px">
                      <FormLabel>Confirm Password</FormLabel>
                      <InputGroup size="md">
                        <Input
                          name="confirm_password"
                          onChange={(e) => handleUserChange(e)}
                          pr="4.5rem"
                          type={confirmpass ? "text" : "password"}
                          placeholder="Confirm password"
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={handleconfirmpassClick}
                          >
                            {confirmpass ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  </>
                ) : null}
              </Box>
            ) : (
              <Box>
                <FormControl mb="10px">
                  <FormLabel>Enter Password</FormLabel>
                  <InputGroup size="md">
                    <Input
                      name="password"
                      pr="4.5rem"
                      type={pass ? "text" : "password"}
                      placeholder="Enter password"
                      onChange={(e) => handleUserChange(e)}
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handlepassClick}>
                        {pass ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl mb="10px">
                  <FormLabel>Confirm Password</FormLabel>
                  <InputGroup size="md">
                    <Input
                      name="confirm_password"
                      pr="4.5rem"
                      type={confirmpass ? "text" : "password"}
                      placeholder="Confirm password"
                      onChange={(e) => handleUserChange(e)}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={handleconfirmpassClick}
                      >
                        {confirmpass ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Box>
            )}
            <FormControl mb="10px">
              <FormLabel>Roles</FormLabel>
              <Input
                placeholder={"Select Roles"}
                onClick={(e) => {
                  addRolesHandler(e);
                }}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              {toggle && (
                <Box
                  textAlign="left"
                  position={"absolute"}
                  zIndex={"1"}
                  bgColor={"white"}
                  width={"100%"}
                  border="1px"
                  borderColor="gray.300"
                  rounded={"md"}
                >
                  <Flex width={"100%"} justifyContent={"flex-end"} py={2}>
                    <Button
                      size={"xs"}
                      colorScheme="whatsapp"
                      mx={2}
                      onClick={handlesaveSelectedRoles}
                    >
                      Save Changes
                    </Button>
                  </Flex>
                  <Flex
                    maxHeight={`${window.innerHeight - 400}px`}
                    overflowY={"scroll"}
                    overflowX={"hidden"}
                  >
                    <FormControl py={2} px={4} pt={0}>
                      {roles
                        .filter((e) =>
                          e.name
                            .toLowerCase()
                            .includes(query.toLocaleLowerCase())
                        )
                        .map((item, i) => {
                          return (
                            <Fragment key={i}>
                              <Checkbox
                                isChecked={selectedRoles.some(
                                  (val) => val === item._id
                                )}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    let arr = [...selectedRoles];
                                    arr.push(item._id);
                                    setSelectedRoles(arr);
                                  } else if (e.target.checked === false) {
                                    let arr = [...selectedRoles];
                                    let newarr = arr.filter(
                                      (val) => val !== item._id
                                    );
                                    setSelectedRoles(newarr);
                                  }
                                }}
                              >
                                {item.name}
                              </Checkbox>
                              <br />
                            </Fragment>
                          );
                        })}
                    </FormControl>
                  </Flex>
                </Box>
              )}
            </FormControl>
            <Grid gap="15px" templateColumns="repeat(3,1fr)">
              {showSelectedRoles.map((item, i) => (
                <Badge
                  key={i}
                  p={2}
                  width="fit-content"
                  color="GrayText"
                  borderRadius="5px"
                >
                  {roles.find((role) => item === role._id)?.name}
                </Badge>
              ))}
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Flex gap="20px">
              {edit ? (
                <Button onClick={handleEditUserHitApi} colorScheme="blue">
                  Edit
                </Button>
              ) : (
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={handleCreateUserHitApi}
                >
                  {false ? <Spinner /> : "Save"}
                </Button>
              )}
              <Button onClick={onClose}>Close</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default UserModal;
