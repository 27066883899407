import { useEffect, useState } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useToast,
  Box,
  CloseButton,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCitysData,
  PostCityDetails,
} from "../../../../redux/Admin/AppReducer/vendors/action";
import { setToast } from "../../../../Fucntions/Toastfunction";

function CityAddForm({ onClose, setRender, render }) {
  const [input, setInput] = useState("");
  const [city, setcity] = useState([]);
  const toast = useToast();
  const navigate=useNavigate()
  const isLoading = useSelector((state) => state.VendorReducer.isloading);
  const [showPlusIcon, setShowPluseIcon] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  // console.log(city, "city");
  const postError = useSelector((store) => store.VendorReducer.postError);

  //HANDLEINPUTCHANGE FUNCTIONS
  const handleInputChange = (e) => {
    const value = e.target.value;

    // Check if the first character is a space
    if (value.charAt(0) === " ") {
      // Remove the first character (space)
      e.target.value = value.slice(1);
      return;
    }
    setInput(e.target.value);
  };

  //HANDLEcityCHANGE FUNCTIONS
  const handlecityChange = (e) => {
    const value = e.target.value;

    // Check if the first character is a space
    if (value.charAt(0) === " ") {
      // Remove the first character (space)
      e.target.value = value.slice(1);
      return;
    }
    setInputValue(e.target.value);
  };

  const handleSaveCitys = (e) => {
    let arr = [...city];
    if (arr.includes(inputValue)) {
      setToast(toast, "City Already exist", "", "error");
      return;
    }
    setcity((prevData) => [...prevData, inputValue]);
    setShowPluseIcon(true);
    setInputValue("");
  };
  const isError = input === "";

  // HandleAddData in BE
  const handleAddButton = async () => {
  if(isLoading){
    return
  }
    const payload = {
      name: input,
      cities: city,
    };
    dispatch(
      PostCityDetails(
        `${process.env.REACT_APP_API_URL}/citygroup/create`,
        payload,
        toast,
        onClose,
        `${process.env.REACT_APP_API_URL}/citygroup/read`,navigate
      )
    );
    setRender(!render);
  };

  //Close Button
  const handleCloseButton = () => {
    dispatch(getCitysData(`${process.env.REACT_APP_API_URL}/citygroup/read`,navigate));
  };

  //Remove Data From City Input
  const handleRemoveCityData = (name) => {
    const value = city.filter((elm, i) => i !== name);

    setcity(value);
  };

  const handleKeyDown = (event) => {
    // console.log(inputValue,"val")
   if(inputValue===""){
    return
   }
    if (event.key === "Enter") {
      handleSaveCitys();
    }
  };

  useEffect(() => {
    dispatch(getCitysData(`${process.env.REACT_APP_API_URL}/citygroup/read`,navigate));
  }, [location.search]);

  return (
    <>
      <ModalHeader>Add City/City Group</ModalHeader>
      <ModalCloseButton onClick={handleCloseButton} />
      <ModalBody>
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input type="input" value={input} onChange={handleInputChange} />
          {!isError ? null : (
            <FormErrorMessage>Name is required.</FormErrorMessage>
          )}

          <FormLabel>Cities</FormLabel>
          <Box display={"flex"}>
            <Input
              width={"72%"}
              type="city"
              value={inputValue}
              onChange={handlecityChange}
              onKeyDown={handleKeyDown}
            />
            <Button
              width={"25%"}
              ml={"2%"}
              height={"43px"}
              onClick={handleSaveCitys}
              isDisabled={inputValue === "" || inputValue === " "}
            >
              Add
            </Button>
          </Box>
          <FormHelperText display={"flex"} flexWrap={"wrap"}>
            {showPlusIcon
              ? city.map((elm, i) => {
                  return (
                    <Box
                      ml={"2"}
                      key={i}
                      display={"flex"}
                      borderRadius="md"
                      bg="brown"
                      color="white"
                      p={"1"}
                      mt={"2"}
                    >
                      <Text fontSize={"18px"} mt={"2px"} ml={"1"}>
                        {elm}
                      </Text>
                      {city.length === 0 ? (
                        <></>
                      ) : (
                        <CloseButton onClick={() => handleRemoveCityData(i)} />
                      )}
                    </Box>
                  );
                })
              : null}
          </FormHelperText>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="blue" mr={3} onClick={handleAddButton}>
          {isLoading?<Spinner/>:"Submit"}
        </Button>
      </ModalFooter>
    </>
  );
}

export default CityAddForm;
