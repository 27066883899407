import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
} from "@chakra-ui/react";
import ReactToPrint from "react-to-print";
import "./print.css";

import { useSelector } from "react-redux";
import SubOrderChallan from "./SubOrderChallan";
import axios from "axios";
import { useParams } from "react-router-dom";

const SuborderPopupChallan = ({ isOpen, onClose, challan, id }) => {
  const [challandata, setChallanData] = useState([]);

challan && challan.length && challan.sort((a, b) => {
    // Extract the main number and sub-order from `subroder_name`
    const [mainA, subA] = a.suborder_name.slice(1).split('_').map(Number);
    const [mainB, subB] = b.suborder_name.slice(1).split('_').map(Number);
    
    // Compare the main numbers first
    if (mainA !== mainB) {
        return mainB - mainA; // Sort in descending order for the main number
    }
    // If the main numbers are the same, compare the sub-order numbers
    return subA - subB; // Sort in ascending order for the sub-order number
});

// console.log(challan, "challan ++++++++++++++++++++++++");
  let render = challan?.length > 0;
  const pdref = useRef();

  async function forFilterData() {
    let k = [];
    let newchallan = await foraligningLineItems(challan);
    for (let i = 0; i < newchallan?.length; i++) {
      let arr = k.filter((p) => p.suborder_id === newchallan[i].suborder_id);
      if (arr.length === 0) {
        k.push(newchallan[i]);
      }
    }
    setChallanData(k);
  }

  async function foraligningLineItems(arr) {
    let newarr = JSON.parse(JSON.stringify(arr));
    let modifed_line_items = [];
    for (let i = 0; i < newarr?.length; i++) {
      let obj = {
        name: newarr[i].name,
        line_items: newarr[i].line_items,
      };
      modifed_line_items.push(obj);
    }

    let newChallanarr = [];
    let includedsuborder = [];
    for (let k = 0; k < newarr?.length; k++) {
      newarr[k].line_items = [];
      if (includedsuborder.includes(newarr[k].name)) {
        continue;
      } else {
        includedsuborder.push(newarr[k].name);
        newChallanarr.push(newarr[k]);
      }
    }

    for (let i = 0; i < modifed_line_items.length; i++) {
      for (let l = 0; l < newChallanarr.length; l++) {
        if (modifed_line_items[i].name === newChallanarr[l].name) {
          newChallanarr[l].line_items.push(...modifed_line_items[i].line_items);
        }
      }
    }
    //  console.log(modifed_line_items,"line_item")
    //   console.log (newChallanarr,"modified")
    return newChallanarr;
  }
  function onCloseModal(){
    onClose()
    setChallanData([])
  }

  useEffect(() => {
    forFilterData();
  }, [challan]);
  return (
    <div>
      <Modal onClose={onCloseModal} size={"full"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ReactToPrint
              trigger={() => <Button colorScheme="twitter">Print</Button>}
              content={() => pdref.current}
            />
          </ModalHeader>
          <ModalCloseButton onClick={onCloseModal} />
          <ModalBody>
            {render ? (
              <Box ref={pdref}>
                {challandata.map((item, i) => (
                  <div key={i}>
                    <SubOrderChallan data={item} index={i} />
                  </div>
                ))}
              </Box>
            ) : (
              <Text color="red" fontSize="30px" fontWeight="semibold">
                No Challan Available
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SuborderPopupChallan;
