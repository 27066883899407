import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Button,
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Image,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const Dashboard = () => {
  const headers = {
    Authorization: `Bearer ${JSON.parse(
      sessionStorage.getItem("partnertoken")
    )}`,
  };
  const [suborder, setSuborder] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  let fil = pathname.split("/").splice(-1);
  const [orderstatus, setOrderStatus] = useState(fil[0]);
  let arr_of_button = [
    "All",
    "Accepted",
    "Printed",
    "Rejected",
    "Fulfilled",
    "Attempted",
    "Out_for_delivery",
    "driver_allocated",
  ];

  let arr_of_headings = [
    {
      name: "Order no.",
      style: {
        width: "42px",
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Sub-Order no.",
      style: {
        width: "42px",

        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Delivery date",
      style: {
        width: "76px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "10px",
      },
    },
    {
      name: "Time Slot",
      style: {
        width: "30px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "15px",
      },
    },
    {
      name: "Name",
      style: {
        width: "40px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "20px",
      },
    },
    {
      name: "Phone no.",
      style: {
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "5px",
      },
    },
    {
      name: "City",
      style: {
        width: "40px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "3px",
      },
    },
    {
      name: "items",
      style: {
        width: "35px",
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Order Amount",
      style: {
        width: "50px",
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "PP",
      style: {
        width: "50px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "10px",
      },
    },
    {
      name: "WP",
      style: {
        width: "50px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "10px",
      },
    },
    {
      name: "Payment mode",
      style: {
        width: "65px",
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Product name",
      style: {
        width: "52px",
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Order Status",
      style: {
        width: "40px",
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Driver Name",
      style: {
        width: "50px",
        marginLeft: "15px",
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Driver Status",
      style: {
        width: "50px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "10px",
      },
    },
    {
      name: "Status",
      style: {
        width: "50px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "1px",
      },
    },
  ];

  function handlePageChange(num) {
    setPage((pre) => pre + num);
  }

  // ******************  CONVERT DD-MM-YY in DD/MM/YY FORMAT ***************

  function formatDate(inputDate) {
    if (!inputDate) return;
    const dateParts = inputDate?.split("-");

    if (dateParts?.length === 3) {
      if (dateParts[0].length === 4) {
        return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      } else {
        return `${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`;
      }
    }
  }

  ////    CHECKBOX SELECT ALLLL
  function handleParentCheckbox(e) {
    // let arr = [];
    // for (let i = 0; i < suborder.length; i++) {
    //   let obj = {};
    //   obj.OrderNo = suborder[i].name;
    //   obj.subOrder_No = suborder[i].suborder_name;
    //   obj.Delivery_date = suborder[i].to_be_shipped_on;
    //   obj.Time_Slot = suborder[i].time_slot;
    //   obj.Name = suborder[i].shipping_address.name;
    //   obj.Phone_No = suborder[i].shipping_address.phone;
    //   obj.City = suborder[i].english_city;
    //   obj.Items = suborder[i].line_items.length;
    //   obj.Order_Amount = calculatetotalprice(suborder[i]);
    //   obj.Payment_mode =
    //     suborder[i].financial_status === "paid" ? "Paid" : "COD";
    //   obj.Product_name = suborder[i].line_items[0]?.name;
    //   obj.Allocated_vendor = suborder[i].allocated_vendor_name;
    //   obj.status = suborder[i].status;
    //   obj.Driver_name = suborder[i].allocated_driver_name;
    //   obj.Driver_Status =
    //     suborder[i].allocated_driver_status === "driver_allocated"
    //       ? null
    //       : suborder[i].allocated_driver_status;
    //   arr.push(obj);
    // }
    // console.log(arr, "csvarr");
    // if (selected_suborder.length === suborder?.length) {
    //   setSelectedSuborder([]);
    //   setSelected_suborder_forcsv([]);
    // } else {
    //   setSelectedSuborder(suborder);
    //   setSelected_suborder_forcsv(arr);
    // }
  }

  function handlechildrenCheckbox(i, item) {
    // let csv_arr = [...selected_suborder_forcsv];
    // let arr = [...selected_suborder];
    // let obj = {};
    // if (selected_suborder.some((k) => k.suborder_id === item.suborder_id)) {
    //   arr = arr.filter((k) => k.suborder_id !== item.suborder_id);
    //   csv_arr = csv_arr.filter((k) => k.subOrder_No !== item.suborder_name);
    // } else {
    //   arr.push(item);
    //   obj.OrderNo = item.name;
    //   obj.subOrder_No = item.suborder_name;
    //   obj.Delivery_date = item.to_be_shipped_on;
    //   obj.Time_Slot = item.time_slot;
    //   obj.Name = item.shipping_address.first_name;
    //   obj.Phone_No = item.shipping_address.phone;
    //   obj.City = item.english_city;
    //   obj.Items = item.line_items.length;
    //   obj.Order_Amount = calculatetotalprice(item);
    //   obj.Payment_mode = item.financial_status === "paid" ? "Paid" : "COD";
    //   obj.Product_name = item.line_items[0]?.name;
    //   obj.Allocated_vendor = item.allocated_vendor_name;
    //   obj.status = item.status;
    //   obj.Driver_name = item.allocated_driver_name;
    //   obj.Driver_Status =
    //     item.allocated_driver_status === "driver_allocated"
    //       ? null
    //       : item.allocated_driver_status;
    //   csv_arr.push(obj);
    // }
    // setSelectedSuborder(arr);
    // setSelected_suborder_forcsv(csv_arr);
  }

  function truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  }

  function calculatetotalprice(item) {
    let price = item.line_items.reduce((acc, curr) => {
      return acc + Number(curr.price);
    }, 0);
    if (item.suborder_name.split("_")[1] == 1) {
      price = price + item.shipping_charges;
    }
    return price;
  }

  function processString(inputString) {
    var processedString = inputString.toUpperCase().replace(/_/g, " ");
    return processedString;
  }

  const capatilizedWord = (str) => {
    if (!str) return;
    let word = str.split(" ");
    let final = word.map((element) => {
      return element.charAt(0).toUpperCase() + element.slice(1);
    });
    return final.join(" ");
  };

  //  FOR WEBSITE AND PURCHASE PRICE FIND
  function forSearchingWpAndPP(orderdata, type) {
    let value;
    if (type === "pp") {
        value= orderdata.variant_id_and_pp_wp.reduce((acc,curr)=>
           acc+Number(curr.price)
         ,0)
    } else {
     value= orderdata.variant_id_and_pp_wp.reduce((acc,curr)=>
        acc+Number(curr.wp)
      ,0)
    }
    return value;
  }

  ////// HOVERING ON ITEMS
  const [hoveredItemsShowProd, setHoveredItemsShowProd] = useState(null);

  const handleMouseEnteronItems = (index) => {
    setHoveredItemsShowProd(index);
  };

  const handleMouseLeaveonItems = () => {
    setHoveredItemsShowProd(null);
  };

  //// HOVERING ON PRODUCTSSS
  const [hoveredProd, setHoveredProd] = useState(null);

  const handleMouseEnteronProd = (index) => {
    setHoveredProd(index);
  };

  const handleMouseLeaveonProd = () => {
    setHoveredProd(null);
  };

  let [suborderLoader, setSuborderLoader] = useState(false);

  function getSuborderDataAccordingtoFilter() {
    if (suborderLoader) {
      return;
    }
    setSuborderLoader(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/partner/dashboard/${orderstatus}?page=${page}`,
      headers,
      data: {},
    })
      .then((r) => {
        console.log(r.data);
        setSuborder(r.data.suborders);
        setSuborderLoader(false);
      })
      .catch((err) => {
        console.log(err, "err");
        setSuborderLoader(false);
      });
  }

  useEffect(() => {
    getSuborderDataAccordingtoFilter();
  }, [orderstatus, page]);

  return (
    <div>
      <Box width="100%">
        <Flex justifyContent="space-between" alignItems="center">
          <Heading as="h4" size="lg" fontWeight="medium">
            {processString(orderstatus)} SUB-ORDERS
          </Heading>
          <Flex mt="10px" gap="10px" justifyContent="flex-end">
            <Button
              bg="black"
              color="white"
              isDisabled={page === 1}
              onClick={() => handlePageChange(-1)}
            >
              previous Page
            </Button>
            <Box
              height="40px"
              pt="7px"
              width="40px"
              bg="black"
              borderRadius="10px"
              color="white"
            >
              <Text fontWeight="bold">{page}</Text>
            </Box>
            <Button
              bg="black"
              color="white"
              isDisabled={suborder?.length < 25}
              onClick={() => handlePageChange(1)}
            >
              Next Page
            </Button>
          </Flex>
        </Flex>

        <br />
        <Flex
          borderRadius="7px"
          backgroundColor="white"
          boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
          justifyContent="space-between"
        >
          {/* {searchbutton ? ( */}
          <Flex
            justifyContent="space-between"
            p={{ base: "1", md: "1", lg: "2" }}
          >
            <Flex gap="10px" fontSize="10px">
              {arr_of_button.map((item, i) => (
                <Button
                  key={i}
                  colorScheme="teal"
                  variant={
                    orderstatus === item.toLocaleLowerCase() ? "solid" : "ghost"
                  }
                  fontSize="12px"
                  onClick={(e) => {
                    navigate(`/partner/dashboard/${item.toLocaleLowerCase()}`);
                    setOrderStatus(item.toLocaleLowerCase());
                  }}
                >
                  {item.replaceAll("_", " ")}
                </Button>
              ))}
            </Flex>
          </Flex>

          {/* *******************   FILTER BUTTON ************************ */}
          <Flex
            margin="5px 5px 5px 0px"
            justifyContent="space-between"
            fontSize={{ base: "12px", md: "16px", lg: "26px" }}
          ></Flex>
        </Flex>
        <br />

        {suborderLoader ? (
          <Spinner
            mt="30px"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : (
          <div
            style={{
              borderRadius: "7px",
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              backgroundColor: "white",
            }}
          >
            <hr />
            <br />
            {suborder.length > 0 ? (
              <TableContainer mt="-20px" width="100%" textAlign="left">
                <Table variant="simple">
                  <Thead p="0px 0px 0px 5px" bg="gray.100">
                    <Tr p="0px 0px 0px 5px" bg="gray.100">
                      {arr_of_headings.map((name) => {
                        return (
                          <Th textAlign="left" p="0px 0px 0px 5px">
                            <Text
                              fontSize="10px"
                              style={{ ...name.style, textAlign: "left" }}
                            >
                              {name.name}
                            </Text>
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {suborder?.map((item, i) => (
                      <Tr
                        fontSize="12px"
                        key={i}
                        _hover={{ cursor: "pointer" }}
                        // onClick={() =>
                        //   navigate(
                        //     `/partner/dashboard/${orderstatus}`
                        //   )
                        // }
                        width="100%"
                      >
                        <Td
                          width="50px"
                          p="0px"
                          pl="6px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {item?.name}
                        </Td>
                        <Td
                          width="50px"
                          p="0px"
                          pl="4px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {item?.suborder_name}
                        </Td>
                        <Td
                          width="50px"
                          p="0px"
                          pl="10px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {formatDate(item?.to_be_shipped_on)}
                        </Td>
                        <Td
                          whiteSpace="normal"
                          wordBreak="break-word"
                          p="0px"
                          width="80px"
                        >
                          {item?.time_slot}
                        </Td>
                        <Td
                          whiteSpace="normal"
                          wordBreak="break-word"
                          p="0px"
                          width="80px"
                          textAlign="center"
                        >
                          {capatilizedWord(item?.shipping_address.name)}
                        </Td>
                        <Td
                          width="80px"
                          p="0px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                          pl="5px"
                        >
                          {item?.shipping_address.phone}
                        </Td>
                        <Td
                          width="40px"
                          p="0px"
                          pl="8px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {capatilizedWord(item?.english_city)}
                        </Td>
                        <Td
                          width="35px"
                          p="0px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                          pl="3px"
                          onMouseEnter={() => handleMouseEnteronItems(i)}
                          onMouseLeave={handleMouseLeaveonItems}
                        >
                          <Popover
                            key={i}
                            isOpen={hoveredItemsShowProd === i}
                            onClose={() => setHoveredItemsShowProd(null)}
                            placement="right"
                          >
                            <PopoverTrigger>
                              <Box
                                onMouseEnter={() => handleMouseEnteronItems(i)}
                                onMouseLeave={handleMouseLeaveonItems}
                                cursor="pointer"
                              >
                                {item?.line_items?.length} Items
                              </Box>
                            </PopoverTrigger>
                            <PopoverContent
                              onMouseEnter={() => handleMouseEnteronItems(i)}
                              onMouseLeave={handleMouseLeaveonItems}
                              width="320px"
                            >
                              <PopoverBody>
                                <Flex p="5px" gap="10px" direction="column">
                                  {item.line_items.map((prod) => (
                                    <Flex
                                      justifyContent="space-between"
                                      gap="5px"
                                      alignContent="center"
                                      alignItems="center"
                                    >
                                      <Flex
                                        gap="10px"
                                        alignContent="center"
                                        alignItems="center"
                                      >
                                        <Image
                                          src={
                                            prod.properties.find(
                                              (k) => k.name === "_product_image"
                                            )?.value
                                          }
                                          h="60px"
                                          w="60px"
                                          borderRadius="20px"
                                        />
                                        <Flex direction="column">
                                          <Text
                                            color="blue"
                                            fontSize="16px"
                                            fontWeight="semibold"
                                            textAlign="left"
                                          >
                                            {prod.name}
                                          </Text>
                                          <Text
                                            color="gray"
                                            fontSize="14px"
                                            fontWeight="semibold"
                                            textAlign="left"
                                          >
                                            {prod.sku}
                                          </Text>
                                        </Flex>
                                      </Flex>
                                      <Flex>
                                        <Text
                                          fontSize="20px"
                                          fontWeight="semibold"
                                        >
                                          x
                                        </Text>
                                        <Text
                                          fontSize="20px"
                                          fontWeight="semibold"
                                        >
                                          {prod.fulfillable_quantity}
                                        </Text>
                                      </Flex>
                                    </Flex>
                                  ))}
                                </Flex>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </Td>
                        <Td
                          width="35px"
                          p="0px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                          pl="16px"
                        >
                          {calculatetotalprice(item)}
                        </Td>
                        
                        <Td
                          width="50px"
                          p="0p"
                          whiteSpace="normal"
                          wordBreak="break-word"
                          pl={"18px"}
                        >
                          {forSearchingWpAndPP(item,"pp")}
                        </Td>
                        <Td
                          width="50px"
                          p="0p"
                          whiteSpace="normal"
                          wordBreak="break-word"
                          pl={"18px"}
                        >
                           {forSearchingWpAndPP(item,"wp")}
                        </Td>
                        <Td
                          width="50px"
                          p="0p"
                          whiteSpace="normal"
                          wordBreak="break-word"
                          pl={"18px"}
                        >
                          {item?.financial_status === "paid" ? "Paid" : "COD"}
                        </Td>

                        <Td
                          width="60px"
                          p="0px"
                          pl="4px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                          onMouseEnter={() => handleMouseEnteronProd(i)}
                          onMouseLeave={handleMouseLeaveonProd}
                        >
                          <Popover
                            key={i}
                            isOpen={hoveredProd === i}
                            onClose={() => setHoveredProd(null)}
                            placement="right"
                          >
                            <PopoverTrigger>
                              <Box
                                onMouseEnter={() => handleMouseEnteronProd(i)}
                                onMouseLeave={handleMouseLeaveonProd}
                                cursor="pointer"
                              >
                                {truncateString(item.line_items[0]?.name, 10)}
                              </Box>
                            </PopoverTrigger>
                            <PopoverContent
                              onMouseEnter={() => handleMouseEnteronProd(i)}
                              onMouseLeave={handleMouseLeaveonProd}
                              width="fit-content"
                            >
                              <PopoverBody>
                                <Flex p="5px" gap="10px" direction="column">
                                  {item.line_items.map((prod) => (
                                    <Flex
                                      direction="column"
                                      justifyContent="center"
                                    >
                                      <Text
                                        fontSize="16px"
                                        fontWeight="semibold"
                                        textAlign="left"
                                      >
                                        {prod.name}
                                      </Text>
                                      <Text
                                        fontSize="14px"
                                        fontWeight="semibold"
                                        textAlign="left"
                                      >
                                        {prod.sku}
                                      </Text>
                                    </Flex>
                                  ))}
                                </Flex>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </Td>
                        <Td
                          width="50px"
                          p="0px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {capatilizedWord(item?.status)}
                        </Td>
                        <Td
                          width="80px"
                          p="0px 0px 0px 10px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {capatilizedWord(item?.allocated_driver_name)}
                        </Td>
                        <Td
                          width="80px"
                          p="0px"
                          pl="5px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {item?.allocated_driver_status ===
                            "out_for_delivery" ||
                          item?.allocated_driver_status === "attempted" ||
                          item?.allocated_driver_status === "fulfilled"
                            ? capatilizedWord(item?.allocated_driver_status)
                            : null}
                        </Td>
                        <Td
                          width="80px"
                          p="0px"
                          pl="5px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                          fontWeight="semibold"
                          color={item?.product_ready ? "green" : "orange"}
                        >
                          {item?.product_ready ? "Ready" : "Process"}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Flex justifyContent="center" mt="50px" paddingBottom="50px">
                <Text color="red" fontSize="20px" fontWeight="semibold">
                  No Data Available on this Page
                </Text>
              </Flex>
            )}
          </div>
        )}
        <Flex mt="10px" gap="10px" justifyContent="flex-end">
          <Button
            bg="black"
            color="white"
            isDisabled={page === 1}
            onClick={() => handlePageChange(-1)}
          >
            previous Page
          </Button>
          <Box
            height="40px"
            pt="7px"
            width="40px"
            bg="black"
            borderRadius="10px"
            color="white"
          >
            <Text fontWeight="bold">{page}</Text>
          </Box>
          <Button
            bg="black"
            color="white"
            isDisabled={suborder.length < 25}
            onClick={() => handlePageChange(1)}
          >
            Next Page
          </Button>
        </Flex>
      </Box>
    </div>
  );
};

export default Dashboard;
