import { Avatar, Box, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getOrderData } from "../../../redux/Admin/AppReducer/orders/action";
import { useParams } from "react-router-dom";

const TimelineComp = () => {
  const suborder = useSelector((state) => state.OrderReducer.order);
  const dispatch = useDispatch();
  const { id } = useParams();

  function convertGMTtoSaudiTime(gmtTime) {
    const options = {
      timeZone: "Asia/Riyadh",
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    const saudiTime = new Date(gmtTime).toLocaleString("en-US", options);
    return saudiTime;
  }
  function reverseArray(arr){
return arr.reverse()
  }
  return (
    <Box my={5} p={3}>
      <Heading size={"md"} mb={2} color={"black"} textAlign={"left"}>
        Timeline
      </Heading>
      <Divider size={1} my={2} borderBottomColor={"black"} mb="20px" />
      <Box>
        {suborder?.comments?.length>0 && reverseArray(suborder?.comments)?.map((item, i) => (
          <Flex key={i} alignItems="center">
            <Text color="black" width="20%"> 
              {item?.time.split("T")[0]}
            </Text>

            <Flex
              flexDirection="column"
              textAlign="left"
              width="75%"
              borderLeft="3px solid black"
            >
              <Flex gap="40px" alignItems="center" ml="-8px">
                <Box>
                  <Box
                    backgroundColor="black"
                    mt="4px"
                    height="15px"
                    width="15px"
                    borderRadius="50%"
                  ></Box>
                </Box>
                <Flex  ml="-25px" flexDirection="column" pb="8px" >
                  <Text color="black" fontWeight="semibold">{item.title}</Text>
                  <Text color="black">{item.action}</Text>
                  <Text color="black">{convertGMTtoSaudiTime(item.time)}</Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default TimelineComp;
