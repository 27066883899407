import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import ModalForProduct from "../EditSubOrder/ModalForProduct";
import ModalforEditOrder from "../EditSubOrder/ModalforEditOrder";
import { TbCircleDashed } from "react-icons/tb";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { OrderAfterStartOrderEditing } from "../../../../redux/Admin/AppReducer/orders/action";
import ModalForAdjustQty from "../EditSubOrder/ModalForAdjustQty";
import ModalforAddCustomItem from "../EditSubOrder/ModalforAddCustomItem";
import { BiCheckCircle } from "react-icons/bi";
import axios from "axios";
import { setToast } from "../../../../Fucntions/Toastfunction";
import ConfirmationModal from "../../../../Fucntions/Modals/ConfirmationModal";
import ModalForAddOnProducts from "../AddOn/ModalForAddOnProducts";

const EditMainOrder = () => {
  const { id } = useParams();
  const toast = useToast();
  const [notifyCustomer, setNotifyCustomer] = useState(false);
  const [reasonforEdit, setReasonForedit] = useState("");
  const [updatebuttonenable, setUpdatebuttonenable] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderAfterEditingStart = useSelector(
    (state) => state.OrderReducer.order_after_order_editing
  );

  // ****************/   FOR THE MODAL TO ADD CUSTOM ITEM  **********************
  const [AddcustomItemmodal, setAddcustomItemmodal] = useState(false);
  const openAddCustomItemModal = () => setAddcustomItemmodal(true);
  const closeAddCustomItemModal = () => setAddcustomItemmodal(false);

  // ****************   FOR THE MODAL OF ADJUST QUANTITY      *****************************************
  const [objForAdjQtyInModal, setobjForAdjQtyInModal] = useState({});
  const [adjustqtymodalstatus, setAdjustqtymodalstatus] = useState(false);
  const openAdjustQtyModal = (item) => {
    setAdjustqtymodalstatus(true);
    setobjForAdjQtyInModal(item);
    setUpdatebuttonenable(true);
  };
  const closeAdjustQtyModal = () => setAdjustqtymodalstatus(false);

  const [searchprodinput, setsearchprodinput] = useState(""); // Input for searching the product
  //  for Operating search product Modal
  const [productModalOpen, setProductModalOpen] = useState(false);
  const OnopenModalFunction = () => setProductModalOpen(true);
  const OnCloseModalFunction = () => {
    setProductModalOpen(false);
    setsearchprodinput("");
  };

  //  For Opearting Edit Order Modal
  const [ordermodalopen, setOrderModalOpen] = useState(false);
  const OnOpenOrderModal = () => setOrderModalOpen(true);
  const onCloseOrderModal = () => setOrderModalOpen(false);

  // ********************     FOR CONFIRMATION MODAL   ************************************
  const [confirmstatus, setConfirmstatus] = useState(false);
  const [selectedProd, setSelectedProd] = useState(null);

  const openConfirmationModal = (item) => {
    setConfirmstatus(true);
    setSelectedProd(item);
  };
  const closeConfirmationModal = () => {
    setConfirmstatus(false);
  };

  const [reasonInput, setReasonInput] = useState(""); // Reason For Edit For Remove
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveitem = () => {
    if (isLoading) {
      return;
    }
    setToast(toast, `Order removing in process`, "", "info");
    setIsLoading(true);
    let data = {
      order_id: id,
      lineItemId: selectedProd?.id,
      staffNote: reasonInput,
      quantity: 0,
      restock: true,
    };
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/order/editLineItemQuantity`,
        data,
        { headers }
      )
      .then((r) => {
        setIsLoading(false);
        if (r.data.err) {
          setToast(toast, `${r.data.err}`, "", "error");
          return;
        }
        dispatch(OrderAfterStartOrderEditing(r.data));
        setToast(toast, `Order Removed Successfully`, "", "success");
        closeConfirmationModal();
        setReasonInput(null);
      })

      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        setIsLoading(false);
        setReasonInput(null);
        setToast(toast, `Something Went wrong`, "", "error");
      });
  };

  function handleCommitEditingOrder() {
    let data = {
      staffNote: reasonforEdit,
      notifyCustomer,
    };
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };

    axios({
      method: "Put",
      url: `${process.env.REACT_APP_API_URL}/order/commitEditedOrder`,
      headers,
      data,
    })
      .then((r) => {
        if (r.data.err) {
          setToast(toast, `${r.data.err}`, "", "error");
        } else {
          setToast(
            toast,
            `${r.data.success.data.orderEditCommit.userErrors[0].message}`,
            "",
            "success"
          );
        }
      })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        setToast(toast, `${err.response.data.err}`, "", "error");
        setToast(toast, `Something Went Wrong`, "", "error");
      });
  }

  useEffect(() => {
    if (searchprodinput.length > 0) {
      OnopenModalFunction();
    }
  }, [searchprodinput]);

  function showBoxOrNot(arr) {
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i].fulfillment_status === "fulfilled") {
        return true;
      }
    }
    return false;
  }

  function ForSelectingWhichIsSuborder(arr = []) {
    let clone = [...arr];
    let parent = [];
    let SuborderCheck = [];
    clone.forEach((item) => {
      if (!item.related_suborder) {
        parent.push([item]);
      } else {
        if (!SuborderCheck.includes(item.related_suborder)) {
          const children = clone.filter(
            (x) => x.related_suborder === item.related_suborder
          );
          parent.push(children);
          SuborderCheck.push(item.related_suborder);
        }
      }
    });

    const newParent = parent.map((child) => {
      if (child.length > 1) {
        let parentItem = [];
        let children = [];
        child.forEach((item) => {
          if (item.properties.map((prop) => prop.name).includes("_parent_id")) {
            children.push(item);
          } else {
            parentItem.push(item);
          }
        });
        return [...parentItem, ...children];
      } else {
        return child;
      }
    });

    return newParent;
  }

  // ***************************  FOR ADDON PRODUCTS ********************************************
  let [addonstatus, setAddonStatus] = useState(false);
  const [productId, setProductId] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [parentId, setParentId] = useState(null);
  const openAddonModal = (prodId, varId, parId) => {
    setProductId(prodId);
    setVariantId(varId);
    setParentId(parId);
    setAddonStatus(true);
  };
  const closeAddonModal = () => {
    setAddonStatus(false);
  };
  return (
    <Box width="80%" margin="auto">
      {/*
       ********************   CONFIRMATION MODAL ******************************* */}

      <ConfirmationModal
        heading="Remove Product"
        action={"Are you Sure? You want to Remove this product"}
        onClickFunction={handleRemoveitem}
        ConfirmButton={"Remove"}
        onClose={closeConfirmationModal}
        isOpen={confirmstatus}
        loader={true}
        loading={isLoading}
        reason={true}
        setReasonInput={setReasonInput}
        reasonInput={reasonInput}
      />
      {/* *******************  Hedaing And Button ********************** */}
      <Flex gap="20px">
        <Button
          onClick={() => navigate(-1)}
          variant="outline"
          padding="10px"
          color="black"
          border="1px solid grey"
        >
          <MdKeyboardBackspace fontSize="30px" />
        </Button>
        <Heading as="h4" size="lg" fontWeight="medium">
          Edit Order
        </Heading>
      </Flex>

      {/* ********************************   FLEX WHICH CONTAIN ALL BOXES    ******************************* */}
      <Flex mt="2rem" justifyContent="space-between">
        {/* **********************************   LEFT SIDE BOX   ******************************** */}
        <Box width="65%">
          {/* **********************************   UNFULLFILLED BOX   ******************************** */}
          <Box
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            backgroundColor="white"
            borderRadius="7px"
            p="3"
            mb="20px"
            textAlign="left"
          >
            <Flex justifyContent="space-between">
              <Heading
                as="h4"
                size="md"
                fontFamily="NexaText-Trial-Bold"
                mb="15px"
              >
                Add Products
              </Heading>

              <Text
                color="blue"
                onClick={openAddCustomItemModal}
                _hover={{ cursor: "pointer", textDecoration: "underline" }}
              >
                Add Custom item
              </Text>
            </Flex>
            {/* ********************************   MODAL FOR SEARCHING PRODUCTS  ***************************************** */}
            <ModalForProduct
              isOpen={productModalOpen}
              onOpen={OnopenModalFunction}
              onClose={OnCloseModalFunction}
              searchprodinput={searchprodinput}
            />

            {/* *************************     MODAL FOR EDITING ORDER   ************************************* */}
            <ModalforEditOrder
              isOpen={ordermodalopen}
              onClose={onCloseOrderModal}
            />

            {/* *************************     MODAL FOR ADJUSTING QUANTITY OF A ORDER   ************************************* */}

            <ModalForAdjustQty
              isOpen={adjustqtymodalstatus}
              onClose={closeAdjustQtyModal}
              prod={objForAdjQtyInModal}
            />

            {/* *********************   MODAL FOR ADD CUSTOM ITEM *********************** */}
            <ModalforAddCustomItem
              isOpen={AddcustomItemmodal}
              onClose={closeAddCustomItemModal}
            />
            {/*
             **********************************  MODAL FOR ADD ON PRODUCT  ************************************* */}
            {productId && (
              <ModalForAddOnProducts
                isOpen={addonstatus}
                onClose={closeAddonModal}
                prodId={productId}
                setprodId={setProductId}
                varId={variantId}
                setVariantId={setVariantId}
                parentId={parentId}
              />
            )}

            <Flex gap="20px">
              <InputGroup size="md">
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon color="gray.500" />}
                />
                <Input
                  pr="4.5rem"
                  placeholder="Search Products"
                  onChange={(e) => setsearchprodinput(e.target.value)}
                  value={searchprodinput}
                />
              </InputGroup>
              <Button variant="outline" onClick={OnopenModalFunction}>
                Browse
              </Button>
            </Flex>
            <br />
            <Divider />
            <br />
            <Flex gap="15px" mb="10px">
              <Box
                height="fit-content"
                border="5px solid #FAC9A8"
                borderRadius="50%"
              >
                <TbCircleDashed fontSize="20px" color="#FAC9A9" />
              </Box>
              <Heading
                as="h5"
                size="md"
                fontFamily="NexaText-Trial-Bold"
                mb="15px"
                mt="5px"
              >
                Unfulfilled
              </Heading>
            </Flex>
            <Box>
              {ForSelectingWhichIsSuborder(
                orderAfterEditingStart?.line_items?.filter(
                  (j) => j.fulfillable_quantity !== 0
                )
              ).map((order, k) => (
                <Box key={k}>
                  <Box
                    mt="10px"
                    width="100%"
                    height="2px"
                    bg="black"
                    display={k == 0 ? "none" : "block"}
                  ></Box>
                  {order.map((item, i) => (
                    <Box key={i} mt="20px">
                      {!item.properties.find((k) => k.name === "_parent_id") ? (
                        <Box key={i} mb="30px">
                          {(item.fulfillable_quantity === 0 &&
                            item.fulfillment_status === null) ||
                          item?.fulfillment_status === "fulfilled" ? null : (
                            <>
                              <Flex justifyContent="space-between" mb="10px">
                                <Flex gap="20px">
                                  <Image
                                    src={
                                      item?.properties.find(
                                        (p) => p.name.trim() === "_product_image"
                                      )?.value
                                    }
                                    height="80px"
                                    width="80px"
                                  />
                                  <Flex
                                    direction="column"
                                    fontSize="14px"
                                    mt="-5px"
                                  >
                                    <Text fontWeight="semibold">
                                      {item.title}
                                    </Text>
                                    <Text
                                      display={
                                        item?.variant_id ? "none" : "block"
                                      }
                                      fontWeight="bold"
                                    >
                                      Custom Item
                                    </Text>
                                    <Text
                                      color="gray.500"
                                      display={
                                        item.variant_id ? "block" : "none"
                                      }
                                    >
                                      SKU: {item?.sku}
                                    </Text>
                                    <Text>
                                      {item.fulfillable_quantity} X QAR{" "}
                                      {item?.price}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <Text>
                                  QAR{" "}
                                  {+item?.fulfillable_quantity * +item?.price}
                                </Text>
                              </Flex>
                              <Flex gap="15px">
                                <Text
                                  _hover={{ cursor: "pointer" }}
                                  onClick={() => {
                                    openAdjustQtyModal(item);
                                  }}
                                  color="blue"
                                >
                                  Adjust Quantity
                                </Text>

                                {order.length === 1 ? (
                                  <Text
                                    color="blue"
                                    _hover={{ cursor: "pointer" }}
                                    onClick={() => openConfirmationModal(item)}
                                  >
                                    Remove Item
                                  </Text>
                                ) : (
                                  <Tooltip
                                    label="First Remove Addons"
                                    aria-label="A tooltip"
                                  >
                                    Remove Item
                                  </Tooltip>
                                )}
                                <Text
                                  _hover={{ cursor: "pointer" }}
                                  onClick={() => {
                                    openAddonModal(
                                      item?.product_id,
                                      item.variant_id,
                                      item.id
                                    );
                                  }}
                                  color="blue"
                                  display={item?.variant_id ? "block" : "none"}
                                >
                                  Add On Product
                                </Text>
                              </Flex>
                            </>
                          )}
                        </Box>
                      ) : (
                        <Box key={i} mb="30px" ml="80px">
                          {(item.fulfillable_quantity === 0 &&
                            item.fulfillment_status === null) ||
                          item?.fulfillment_status === "fulfilled" ? null : (
                            <>
                              <Flex justifyContent="space-between" mb="10px">
                                <Image
                                  src={
                                    item?.properties.find(
                                      (p) => p.name.trim() === "_product_image"
                                    )?.value
                                  }
                                  height="80px"
                                  width="80px"
                                />
                                <Flex gap="20px">
                                  <Flex
                                    direction="column"
                                    fontSize="14px"
                                    mt="-5px"
                                  >
                                    <Text fontWeight="semibold">
                                      {item.title}
                                    </Text>
                                    <Text
                                      display={
                                        item.related_suborder ? "none" : "block"
                                      }
                                      fontWeight="bold"
                                    >
                                      Custom Item
                                    </Text>
                                    <Text
                                      color="gray.500"
                                      display={
                                        item.variant_id ? "block" : "none"
                                      }
                                    >
                                      SKU: {item?.sku}
                                    </Text>
                                    <Text>
                                      {item.fulfillable_quantity} X QAR{" "}
                                      {item?.price}
                                    </Text>
                                  </Flex>
                                </Flex>

                                <Text>
                                  QAR{" "}
                                  {+item?.fulfillable_quantity * +item?.price}
                                </Text>
                              </Flex>
                              <Flex gap="15px" color="blue">
                                <Text
                                  _hover={{ cursor: "pointer" }}
                                  onClick={() => {
                                    openAdjustQtyModal(item);
                                  }}
                                >
                                  Adjust Quantity
                                </Text>
                                <Text
                                  _hover={{ cursor: "pointer" }}
                                  onClick={() => openConfirmationModal(item)}
                                >
                                  Remove Item
                                </Text>
                              </Flex>
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>

          {/* *********************************     FULLFILLED BOX    ************************************************* */}
          {showBoxOrNot(orderAfterEditingStart?.line_items) ? (
            <Box
              boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              backgroundColor="white"
              color="black"
              mt="30px"
              borderRadius="7px"
              p="3"
              mb="20px"
              textAlign="left"
            >
              <br />
              <Flex gap="15px" mb="10px">
                <Box
                  height="fit-content"
                  border="5px solid #A1EDD0"
                  borderRadius="50%"
                >
                  <BiCheckCircle fontSize="20px" color="#A1EDE9" />
                </Box>
                <Heading
                  as="h5"
                  size="md"
                  fontFamily="NexaText-Trial-Bold"
                  mb="15px"
                  mt="5px"
                >
                  fulfilled
                </Heading>
              </Flex>
              {ForSelectingWhichIsSuborder(
                orderAfterEditingStart?.line_items.filter(
                  (k) => k.fulfillment_status === "fulfilled"
                )
              )?.map((order, i) => (
                <>
                  <Box
                    mt="10px"
                    width="100%"
                    height="2px"
                    bg="black"
                    display={i == 0 ? "none" : "block"}
                  ></Box>
                  {order.map((item, i) => (
                    <Box key={i} mb="30px">
                      {!item.properties.some(
                        (obj) => obj.name === "_parent_id"
                      ) ? (
                        <Flex justifyContent="space-between" mb="10px">
                          <Flex gap="20px">
                            <Image
                              src={
                                item?.properties.find(
                                  (p) => p.name.trim() === "_product_image"
                                )?.value
                              }
                              height="80px"
                              width="80px"
                            />
                            <Flex direction="column" fontSize="14px" mt="-5px">
                              <Text fontWeight="semibold">{item.title}</Text>
                              <Text color="gray.500">SKU: {item?.sku}</Text>
                              <Text>
                                {item.quantity} X QAR {item?.price}
                              </Text>
                            </Flex>
                          </Flex>
                          <Text>QAR {+item?.quantity * +item?.price}</Text>
                        </Flex>
                      ) : (
                        <Box>
                          <Divider />
                          <Flex
                            justifyContent="space-between"
                            mb="10px"
                            ml="100px"
                            mt="20px"
                          >
                            <Flex gap="20px">
                              <Flex
                                direction="column"
                                fontSize="14px"
                                mt="-5px"
                              >
                                <Image
                                  src={
                                    item?.properties.find(
                                      (p) => p.name.trim() === "_product_image"
                                    )?.value
                                  }
                                  height="80px"
                                  width="80px"
                                />
                                <Text fontWeight="semibold">{item.title}</Text>
                                <Text color="gray.500">SKU: {item?.sku}</Text>
                                <Text>
                                  {item.quantity} X QAR {item?.price}
                                </Text>
                              </Flex>
                            </Flex>
                            <Text>QAR {+item?.quantity * +item?.price}</Text>
                          </Flex>
                        </Box>
                      )}
                    </Box>
                  ))}
                </>
              ))}
              <Divider />
              <Text mt="10px" color="gray.500" fontWeight="semibold">
                Fulfilled Orders Can't be Changed
              </Text>
            </Box>
          ) : null}
          {/* **********************************   PAYMENT BOX   ******************************** */}
          <Box
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            backgroundColor="white"
            borderRadius="7px"
            p="3"
            mb="20px"
            textAlign="left"
          >
            <Heading
              as="h4"
              size="md"
              fontFamily="NexaText-Trial-Bold"
              mb="15px"
              mt="15px"
            >
              Payment
            </Heading>
            <Flex justifyContent="space-between" mb="15px">
              <Text>Subtotal</Text>
              <Text>QAR {orderAfterEditingStart?.current_subtotal_price}</Text>
            </Flex>
            <Flex justifyContent="space-between" mb="15px">
              <Text>Shipping</Text>
              <Text>
                QAR{" "}
                {orderAfterEditingStart?.shipping_lines &&
                  orderAfterEditingStart?.shipping_lines[0]?.price}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" mb="15px">
              <Text>Tax</Text>
              <Text>QAR {orderAfterEditingStart?.current_total_tax}</Text>
            </Flex>
            <Flex
              fontWeight="semibold"
              justifyContent="space-between"
              mb="15px"
            >
              <Text>Total</Text>
              <Text>QAR {orderAfterEditingStart?.current_total_price}</Text>
            </Flex>
            {/* <Divider />
            <Flex mt="15px" justifyContent="space-between" mb="15px">
              <Text>Paid by customer</Text>
              <Text>QAR 789.27</Text>
            </Flex> */}
          </Box>

          {/* ***************************  REASON FOR EDIT MODAL   ************************************* */}
          {/* <Box
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            backgroundColor="white"
            borderRadius="7px"
            p="3"
            mb="20px"
            textAlign="left"
          >
            <Heading
              as="h4"
              size="md"
              fontFamily="NexaText-Trial-Bold"
              mb="15px"
              mt="15px"
            >
              Reason for edit
            </Heading>
            <Input onChange={(e) => setReasonForedit(e.target.value)} />
            <Text color="gray" mt="10px">
              Only you and other staff can see this reason.
            </Text>
          </Box> */}
        </Box>

        {/* ***********************************************   RIGHT SIDE BOX  ************************************** */}
        <Box width="30%">
          {/* ***********************************************    UPDATE ORDER BUTTON  ************************************** */}
          {/* <Box
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            backgroundColor="white"
            borderRadius="7px"
            p="3"
            mb="20px"
            textAlign="left"
          >
            <Button
              width="100%"
              isDisabled={reasonforEdit === ""}
              onClick={handleCommitEditingOrder}
              mb="10px"
            >
              Update Order
            </Button>

            <FormControl>
              <Flex gap="20px" alignItems="center">
                <Checkbox
                  size="lg"
                  onChange={(e) => setNotifyCustomer(e.target.checked)}
                />
                <FormLabel mt="3px">Notify Customer</FormLabel>
              </Flex>
            </FormControl>
          </Box> */}
        </Box>
      </Flex>
    </Box>
  );
};

export default EditMainOrder;
