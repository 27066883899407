// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".timeline_line__PN8oX::before {\n    width: 1px;\n    background-color: rgb(184,187,190);\n    left: 23.5px;\n    height: calc(100% - 50px); \n}\n  \n.timeline_line__PN8oX {\n    margin: 0px;\n    padding: 0px;\n    width: 100%;\n\n}\n  \n.timeline_container__2J0ek {\n    margin: 0px;\n}\n\n", "",{"version":3,"sources":["webpack://./src/Admin/Pages/SubOrders/style/timeline.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kCAAkC;IAClC,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;;AAEf;;AAEA;IACI,WAAW;AACf","sourcesContent":[".line::before {\n    width: 1px;\n    background-color: rgb(184,187,190);\n    left: 23.5px;\n    height: calc(100% - 50px); \n}\n  \n.line {\n    margin: 0px;\n    padding: 0px;\n    width: 100%;\n\n}\n  \n.container {\n    margin: 0px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"line": "timeline_line__PN8oX",
	"container": "timeline_container__2J0ek"
};
export default ___CSS_LOADER_EXPORT___;
