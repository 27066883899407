import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Grid,
  RadioGroup,
  Radio,
  Spinner,
  Flex,
  Box,
} from "@chakra-ui/react";
import axios from "axios";

const DeliveryPartnerForStatusChange = ({
  isOpen,
  onClose,
  alldeliveryPartner,
  setSelectedStatus,
  setIsOpen,
  suborder_id,
  currently_allocated_to,
  handleSuborderStatusUpdate,
  setSelectedDeliveryPartner,
  isLoading,
  selectedDeliveryPartner
}) => {
  function closeWithoutSelecting() {
    setSelectedStatus(null);
    setIsOpen(false);
    onClose();
    setSelectedDeliveryPartner(null)
  }

  console.log(selectedDeliveryPartner,"skjk")
  return (
    <Modal isOpen={isOpen} onClose={closeWithoutSelecting}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Delivery Partner</ModalHeader>
        <ModalCloseButton onClick={closeWithoutSelecting} />
        <ModalBody>
          <Box maxH="300px" overflowY="scroll">
            {" "}
            {/* Adjust max height as needed */}
            <RadioGroup
              defaultValue={undefined}
              onChange={(value) => setSelectedDeliveryPartner(value)}
            >
              <Grid templateColumns="repeat(2, 1fr)" gap="10px">
                {" "}
                {/* 50% for each column */}
                {alldeliveryPartner.map((item) => (
                  <Radio key={item.id} colorScheme="green" value={item.id}>
                    {item.name}
                  </Radio>
                ))}
              </Grid>
            </RadioGroup>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Flex gap="15px">
            <Button
              colorScheme="blue"
              onClick={() => {
                handleSuborderStatusUpdate(suborder_id, currently_allocated_to);
              }}
              isDisabled={!selectedDeliveryPartner}
            >
              {isLoading ? <Spinner /> : "Done"}
            </Button>
            <Button colorScheme="red" onClick={closeWithoutSelecting}>
              Close
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeliveryPartnerForStatusChange;
