import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
  import axios from "axios";
import { saveAs } from "file-saver";
import csvtojson from "csvtojson";
import { utils as XLSXUtils, write as writeToBuffer } from "xlsx";
import { setToast } from "../../../../Fucntions/Toastfunction";

const AllsuborderDatepicker = ({ startDate, setStartDate, endDate, setEndDate,isOpen,onClose,status }) => {
    const toast=useToast()
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        console.log(start, "end", end);
        setStartDate(start);
        setEndDate(end);
      };
      console.log(startDate,endDate,"dates") 

      const formatDateString = (date) => {
        const day = String(date.getDate()).padStart(2, '0'); // Ensure day has two digits
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure month has two digits
        const year = date.getFullYear(); // Get last two digits of the year
        return  `${year}-${month}-${day}`;
      };

      function generateDateRangeArray(startDate, endDate) {
        const dateArray = [];
        const currentDate = new Date(startDate);
        const end = new Date(endDate);
      
        while (currentDate <= end) {
          dateArray.push(formatDateString(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateArray;
      }

      function handleFirstDateSave() {
        let k= generateDateRangeArray(startDate, endDate)
        handleDownlaodAllSuborderData(k)
      }

      const [downloadLoading, setDownLoadLoading] = useState(false);
      function handleDownlaodAllSuborderData(arrofdates) {
        setDownLoadLoading(true);
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            sessionStorage.getItem("admintoken")
          )}`,
        };
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/suborder/downloadsheet`,
          headers,
          data: { status:status,date:arrofdates },
        })
          .then((response) => {
            csvtojson()
              .fromString(response.data)
              .then((jsonData) => {
                const workbook = { SheetNames: ["Sheet1"], Sheets: {} };
                const worksheet = XLSXUtils.json_to_sheet(jsonData);
                workbook.Sheets["Sheet1"] = worksheet;
                const excelBuffer = writeToBuffer(workbook, { type: "buffer" });
                const blob = new Blob([excelBuffer], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                saveAs(blob, "suborders.xlsx");
              });
            setDownLoadLoading(false);
            setStartDate(null)
            setEndDate(null)
            onClose()
          })
          .catch((error) => {
            console.log(error);
            setToast(toast, `${error.response.data.err}`, "", "error");
            setDownLoadLoading(false);
          });
      }
      return (
        
      <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Choose Dates</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <DatePicker
                 selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="yyyy/MM/dd"
                  inline
                />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="green" onClick={handleFirstDateSave}>Get CSV</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
                
      );
    }

export default AllsuborderDatepicker