import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem, Box,
  Badge
} from "@chakra-ui/react";
import "./search.css";

import { Flex, Input } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { FaSearch } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
const Search = (props) => {
  const [showfilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch=useDispatch()


  function handleFilter({ target: { textContent } }) {
    ////("The object is not empty");
    setFilter(textContent);
  }

  function clearFilter() {
    setShowFilter(false);
    setFilter("");
  }

  function handlesearch(e) {
    const value = e.target.value;

    // Check if the first character is a space
    if (value.charAt(0) === ' ') {
      // Remove the first character (space)
      e.target.value = value.slice(1);
      return
    }
 props.setSearch(e.target.value)
  }
  

  useEffect(() => {
    if (filter) {
      setShowFilter(true);
    }
    const delayDebounceFn=setTimeout(()=>{
      if(props.dispatchfunction && props.urltodispatch){
        dispatch(props.dispatchfunction(`${props.urltodispatch}?page=${1}&filterby=${filter}`))
      }
      ////(searchTerm,"searchedTerm")
     },1000)
      return ()=>clearTimeout(delayDebounceFn)
  }, [filter,searchTerm]);
  

  return (
    <Box textAlign="left">
      <Flex gap="10px" width="100%">
        <Box className="search-box" flex={3}>
          <div className="search-container">
            <Input
              type="text"
              onChange={handlesearch}
              placeholder={props.placeholder}
            />
            <span className="search-icon">
              <FaSearch />
            </span>
          </div>
        </Box>
      </Flex>
      {showfilter ? (
        <Badge
          color="GrayText"
          marginTop="10px"
          borderRadius="5px"
          paddingLeft="10px"
        >
          {filter}{" "}
          <CloseIcon
            fontSize="25px"
            padding="5px"
            borderRadius="50%"
            onClick={clearFilter}
            _hover={{
              cursor: "pointer",
              transition: "0.2s",
              transform: "scale(1.03)",
              boxShadow: "0 0 2px black",
            }}
          />
        </Badge>
      ) : null}
    </Box>
  );
};

export default Search;
