import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Heading,
  Flex,
  Text,
  Input,

  useToast,
  Grid,
  Badge,
  IconButton,

  FormControl,
  FormHelperText,
} from "@chakra-ui/react";
// import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import {
  AddIcon,

  CloseIcon,

} from "@chakra-ui/icons";
import { setToast } from "../../../Fucntions/Toastfunction";
// import Search from "../../Fucntions/search/Search";

export const EditSubgroup = ({
  title,
  subgroupdetails,
  
  subgrouparr,
  setSubgrouparr,
  index,
}) => {
  // ////(index)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cityarr, setCityarr] = useState(subgroupdetails.city || []);
  const [editsubgroupdetail, setEditSubGroupDetail] = useState({
    cutoff: subgroupdetails?.cutoff,
    name: subgroupdetails?.name,
    time_from: subgroupdetails?.time_from,
    time_to: subgroupdetails?.time_to,
    index: index && index,
  });
  const toast = useToast();
  const [singlecity, setSingleCity] = useState("");

  const minTime = subgroupdetails.time_from;

  //  ******************* ADDING SINGLE CITY ON ONCHANGE **********************
  function handlesinglecityvalue(e) {
    setSingleCity(e.target.value);
  }

  //  ******************* ADDING CITY IN THE ARRAY **********************
  function handlcityselect(singlecity) {
    if (singlecity === "") {
      setToast(toast, "Please Write The city Name first ", "", "error", "top");
      return;
    }
    let city = [...cityarr];
    if (!city.includes(singlecity)) {
      city.push(singlecity);
      setCityarr(city);
    }
    setSingleCity("");
  }

  //  ********************* REMOVING CITY FROM THE ARRAY ****************
  function removeselectedcity(i) {
    let arr = [...cityarr];
    arr.splice(i, 1);
    setCityarr(arr);
  }

  // ************  ONCHANGE INPUT FIELD ARE ADDING **************
  function handlesubgroupdetails(e) {
    if (e.target.name === "time_to") {
      if (e.target.value < subgroupdetails.time_from) {
        setToast(
          toast,
          "Wrong Delivery Time",
          "End time should be greater than start time",
          "warning",
          "bottom"
        );
        return;
      }
    }

    const { name, value } = e.target;
    if (value.charAt(0) === ' ') {
      // Remove the first character (space)
      e.target.value = value.slice(1);
      return
    }
    setEditSubGroupDetail({
      ...editsubgroupdetail,
      [name]: value,
    });
  }

  // **************************  ON SUBMITTING THE BUTTON ********************
  function handlesubgoupsubmit() {
    //cityarr
    //subgroupdetails
    //cityselect
    if (
      !editsubgroupdetail.name ||
      !editsubgroupdetail.cutoff ||
      !editsubgroupdetail.time_to ||
      !editsubgroupdetail.time_from
    ) {
      setToast(toast, "Please Select All the feilds2", "", "error");
      return;
    }
    if (cityarr.length < 1) {
      setToast(toast, "Please Select All the feilds2", "", "error");
      return;
    } else {
      let newsubgroup = {
        ...editsubgroupdetail,
        city: cityarr,
      };
      // ////("detail",subgroupdetails)
      setEditSubGroupDetail(newsubgroup);
      // ////(subgrouparr,"old")
      let newsubgrouparray = [...subgrouparr];
      newsubgrouparray[index] = newsubgroup;
      // ////(newsubgrouparray,"new")

      setSubgrouparr(newsubgrouparray);
    }
    // setSubgroupdetails({})
    // setCityarr([])
    onClose();
  }

  useEffect(() => {
    setCityarr(subgroupdetails.city || []);
  }, [subgroupdetails]);

  return (
    <div>
      <Text
        height="fit-content"
        _hover={{
          textDecoration: "underline",
          color: "blue",
          cursor: "pointer",
        }}
        onClick={onOpen}
      >
        {title}
      </Text> 
      <Modal closeOnOverlayClick={false}
      onClose={onClose} size="xl" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading as="h3" size="lg" fontWeight="medium" mb="10px">
              Add Sub-Groups
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody width="100%">
            {/* **************** Subgroup feild Started ****************** */}

            <Flex gap="20px" alignItems="center" mb="20px">
              <Text flex="4">SUB-GROUP NAME :</Text>
              <Input
                flex={9}
                name="name"
                value={editsubgroupdetail.name}
                onChange={handlesubgroupdetails}
              />
            </Flex>
            <Flex gap="20px" alignItems="center" mb="20px">
              <Text flex="4">CUT-OFF TIME :</Text>

              <FormControl as="fieldset" flex={10}>
                <Input
                  type="number"
                  placeholder="You can Only select time in Hour"
                  flex={9}
                  name="cutoff"
                  value={editsubgroupdetail.cutoff}
                  onChange={handlesubgroupdetails}
                />
                <FormHelperText>
                  You Can Only select time In Hours
                </FormHelperText>
              </FormControl>
            </Flex>
            <Flex gap="20px" alignItems="center" mb="20px">
              <Text flex="4"> ADD CITY :</Text>
              <Flex p="1" flex={10} gap="15px" justifyContent="space-between">
                <Input
                  placeholder="Add City"
                  value={singlecity}
                  onChange={handlesinglecityvalue}
                />
                <IconButton
                  onClick={() => handlcityselect(singlecity)}
                  colorScheme="twitter"
                  icon={<AddIcon />}
                />
              </Flex>
            </Flex>
            <Flex gap="20px" alignItems="center" mb="20px">
              <Text flex="4">CITY BOX:</Text>
              <Flex
                border="1px solid #EDF2F7"
                minHeight="100px"
                p="1"
                flex={10}
                gap="15px"
              >
                <Grid
                  templateColumns={{
                    base: "repeat(2, 1fr)",
                    md: "repeat(3,1fr)",
                    lg: "repeat(3,1fr)",
                  }}
                  gap="15px"
                >
                  {cityarr.map((item, i) => (
                    <Badge height="25px" mt="8px" key={i}>
                      <CloseIcon
                        fontSize="25px"
                        padding="5px"
                        borderRadius="50%"
                        onClick={() => removeselectedcity(i)}
                        _hover={{
                          cursor: "pointer",
                          transition: "0.2s",
                          transform: "scale(1.03)",
                          boxShadow: "0 0 2px black",
                        }}
                      />
                      {item}
                    </Badge>
                  ))}
                </Grid>
              </Flex>
            </Flex>
            <Flex gap="20px" alignItems="center" mb="20px">
              <Text flex="4">TIME SLOT</Text>
              <FormControl as="fieldset" flex={10}>
               
              <Flex justifyContent="space-between" flex={8}>
                <Flex alignItems="center" gap="20px">
                  <Text>From</Text>
                  <Input
                    type="time"
                    name="time_from"
                    value={editsubgroupdetail.time_from}
                    onChange={handlesubgroupdetails}
                  />
                </Flex>
                <Flex
                  alignItems="center"
                  gap="20px"
                  visibility={editsubgroupdetail.time_from ? "" : "hidden"}
                >
                  <Text>To</Text>
                  <Input
                    type="time"
                    name="time_to"
                    value={subgroupdetails.time_to}
                    onChange={handlesubgroupdetails}
                    min={minTime}
                  />
                </Flex>
              </Flex>
              <FormHelperText>
              Time Slot should have minimum 1 hour Gap
                </FormHelperText>
              </FormControl>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handlesubgoupsubmit}
              colorScheme="green"
              marginRight="20px"
              isDisabled={cityarr.length < 1}
            >
              SUBMIT
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              CANCEL
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
