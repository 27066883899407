import * as types from "./action.types";
const initialstate = {
  vendor: [],
  singlevendor: [],
  postvendor:[],
  citys : [],
  postError : true,
  isloading:false,
  orderStatusData:[]
};

export const VendorReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
    case types.POST_VENDOR_DETAILS_DATA_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.POST_VENDOR_DETAILS_DATA_ERROR: {
      return {
        ...state,
        isError: true,
        isloading: false,
      };
    }
    case types.POST_VENDOR_DETAILS_DATA_SUCCESS: {
      return {
        ...state,
        isloading: false,
        orderStatusData: payload,
      };
    }

    case types.GET_VENDOR_DATA_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.GET_VENDOR_DATA_ERROR: {
      return {
        ...state,
        isError: true,
        isloading: false,
      };
    }
    case types.GET_VENDOR_DATA_SUCCESS: {
      return {
        ...state,
        isloading: false,
        vendor: payload,
      };
    }

    case types.GET_SINGLE_VENDOR_DATA_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.GET_SINGLE_VENDOR_DATA_ERROR: {
      return {
        ...state,
        isError: true,
        isloading: false,
      };
    }
    case types.GET_SINGLE_VENDOR_DATA_SUCCESS: {
      return {
        ...state,
        isloading: false,
        singlevendor: [payload],
      };
    }

    case types.POST_VENDOR_DETAILS_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.POST_VENDOR_DETAILS_ERROR: {
      return {
        ...state,
        isError: true,
        isloading: false,
      };
    }
    case types.POST_VENDOR_DETAILS_SUCCESS: {
      return {
        ...state,
        isloading: false,
        postvendor: payload,
      };
    }

    case types.UPDATE_VENDOR_DETAILS_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.UPDATE_VENDOR_DETAILS_ERROR: {
      return {
        ...state,
        isError: true,
        isloading: false,
      };
    }
    case types.UPDATE_VENDOR_DETAILS_SUCCESS: {
      return {
        ...state,
        isloading: false,
      };
    }

    case types.DELETE_VENDOR_DATA_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.DELETE_VENDOR_DATA_ERROR: {
      return {
        ...state,
        isError: true,
        isloading: false,
      };
    }
    case types.DELETE_VENDOR_DATA_SUCCESS: {
      return {
        ...state,
        isloading: false,
      };
    }

    // GET CITYS DATA
    case types.GET_ALL_CITYS_DATA_LOADING: {
    
      return {
        ...state,
        isloading: true,
      };
    }
    case types.GET_ALL_CITYS_DATA_SUCCESS: {
      return {
        ...state,
        isloading: false,
        citys: payload,
      };
    }
    case types.GET_CITYS_DATA_ERROR: {
      return {
        ...state,
        isError: true,
        isloading: false,
      };
    }
    case types.POST_CITYS_DATA_LOADING: {
      return {
        ...state,
        isLoading : true,
        isError : false,
        postError : false,
      }
    }
    case types.POST_CITYS_DATA_SUCCESS: {
      return {
        ...state,
        isLoading : false,
        isError : false,
        postError : false,
        citys:payload
      }
    }
    case types.POST_CITYS_DATA_ERROR : {
      return {
        ...state,
        isError : true,
        isLoading : false,
        postError : payload
      }
    }

    case types.DELETE_CITYS_DATA_LOADING: {
      return {
        ...state,
        isLoading : true,
        isError : false,
         }
    }
    case types.DELETE_CITYS_DATA_SUCCESS: {
      return {
        ...state,
        isLoading : false,
        isError : false,
      }
    }
    case types.DELETE_CITYS_DATA_ERROR : {
      return {
        ...state,
        isError : true,
        isLoading : false,
      }
    }
    default: {
      return state;
    }
  }
};
