import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  useToast,
  Flex,
  Select,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToast } from "../../../Fucntions/Toastfunction";
import { getOrderData } from "../../../redux/Admin/AppReducer/orders/action";

const EditPropertiesAddressModal = ({ isOpen, onClose}) => {
  const order = useSelector((state) => state.OrderReducer.order);
  const [detail,setDetail]=useState({
    reason:"",
    fname: "",
    lname: "",
    address1: "",
    address2: "",
    city:"",
    phone:order?.shipping_address?.phone ||""
  }) 
// console.log(detail,"det")
  const toast=useToast()
  const {id}=useParams()
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const ForOrderId = useSelector(
    (state) => state.OrderReducer.Order_Editing_Added_Product
  );
  const citys = useSelector((state) => state.VendorReducer.citys);
  
  function handleOnChange(e){
    const {name,value}=e.target
    if (value.charAt(0) === ' ') {
      // console.log(value,"kjasskj")
      // Remove the first character (space)
      e.target.value = value.slice(1);
      return
    }
    setDetail({...detail,
   [name]:value
  })
  }
  
  function onHAndleSaveHitApi(){
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken") 
      )}`,
    };
     let newadd={...ForOrderId.shipping_address}
     newadd.first_name=detail.fname
     newadd.last_name=detail.lname
     newadd.address1=detail.address1
     newadd.address2=detail.address2
     newadd.city=detail.city
     newadd.phone=detail.phone

    axios({
      method:"POST",
      url:`${process.env.REACT_APP_API_URL}/suborder/editAddress`,
      data:{address:newadd,suborder_id:ForOrderId.suborder_id,id:id}
      ,headers
     }).then((r)=>{
      setToast(toast,"Data Successfully Changed","","success")
      dispatch(
        getOrderData(`${process.env.REACT_APP_API_URL}/order/read/${id}`, toast)
      );
     }).catch((err)=>{
      if(err.response.data.err==="Invalid token"){
        sessionStorage.removeItem('admintoken');
        navigate("/admin/login")
        return
      }
      console.log(err,"err")
      setToast(toast,`${err.response.data.err}`,"","error")
     })
    onClose()
      }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Address</ModalHeader>
        <ModalCloseButton onClick={()=>{
          setDetail({
            reason:"",
            fname: "",
            lname: "",
            address1: "",
            address2: "",
            city:"",
        
          })
        }} />
        <ModalBody>
        <Flex gap="10px" mt="15px">
              <FormControl mt="5px">
                <FormLabel>First Name</FormLabel>
                <Input
                  placeholder="Enter First Name"
                  name="fname"
                  onChange={handleOnChange}
                />
              </FormControl>
              <FormControl mt="5px">
                <FormLabel>Last Name</FormLabel>
                <Input
                  placeholder="Enter Last Name"
                  name="lname"
                  onChange={handleOnChange}
                />
              </FormControl>
            </Flex>
            <FormControl mt="5px">
              <FormLabel>Address Line 1</FormLabel>
              <Input
                placeholder="Enter Address"
                name="address1"
                onChange={handleOnChange}
              />
            </FormControl>
            <FormControl mt="5px">
              <FormLabel>Address Line 2</FormLabel>
              <Input
                placeholder="Enter Address"
                name="address2"
                onChange={handleOnChange}
              />
            </FormControl>
            <FormLabel>Select City</FormLabel>
            <Select
      value={detail.city}
      onChange={(e) =>{ 
        setDetail({
          ...detail,
          city:e.target.value
        })
        }}
      placeholder="Please select city"
    >
      {citys.map((item) => (
        <optgroup label={item.name} key={item.name}>
          {item.cities.map((city) => (
            <option value={city} key={city}>
              {city}
            </option>
          ))}
        </optgroup>
      ))}
    </Select>
    <FormControl mt="5px">
                <FormLabel>Phone</FormLabel>
                <Input
                  placeholder="Enter Phone"
                  type="number"
                  name="phone"
                  onChange={handleOnChange}
                  value={detail.phone}
                />
              </FormControl>
          <FormControl>
            <FormLabel>Reason For Edit</FormLabel>
            <Input   name="reason"
                onChange={handleOnChange}/>
          </FormControl>
        </ModalBody>
        <ModalFooter gap="10px">
          <Button colorScheme="red" mr={3} onClick={()=>{
            setDetail({
              reason:"",
              fname: "",
              lname: "",
              address1: "",
              address2: "",
              city:""
              
            })
            onClose()}
            }>
            Close
          </Button>
          <Button colorScheme="blue" isDisabled={!detail.reason || !detail.fname|| !detail.address1 ||!detail.address2}
          onClick={onHAndleSaveHitApi}>Done</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditPropertiesAddressModal;
