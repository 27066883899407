import React, { Fragment, useRef, useState, useEffect } from "react";
import {
  Flex,
  Heading,
  Text,
  Card,
  CardBody,
  Button,
  Divider,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  FormControl,
  Input,
  Box,
  Spinner,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Checkbox,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { BiArrowBack, BiChevronDown } from "react-icons/bi";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import axios from "axios";
import { EditIcon } from "@chakra-ui/icons";
const VariantDetails = () => {
  const [query, setQuery] = useState("");
  const [query2, setQuery2] = useState("");
  const [query3, setQuery3] = useState("");
  //   Data state --------
  const [allVariantData, setAllVariantData] = useState({}); // all variant data
  const [vendorSubGroup, setVendorSubGroup] = useState([]); // all vendorSubGroup data main payload array
  const [selectedData, setSelectedData] = useState({}); // selected shipment + vendor payload
  const [cityData, setCityData] = useState({}); // all city list
  const [shippingData, setShippingData] = useState({}); // all shipping data
  const [partnerData, setPartnerData] = useState({}); // all partner data
  const [selectedShipment, setSelectedShipment] = useState([]); // selected shipment to add shipment for payload
  const [selectedPartner, setSelectedPartner] = useState([]); // selected partner to add shipment
  const [showSelecetedPartner, setShowSelectedPartner] = useState(null); // to show selected partner on click save change ** add shipment

  const [selectedCityShippingAdd, setSelectedCityShippingAdd] = useState(""); // save selected city when click on add shipment method
  const [editPartner, setEditPartner] = useState([]); // edit partner details
  const [editShipment, setEditShipment] = useState([]); // edit shipment details
  const [editData, setEditData] = useState({}); // edited partner + shipment



  // -------- MODAL & dropdown toggle STATE FOR ONOPEN/ONCLOSE----------
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const [saveChangesToggle, setSaveChangesToggle] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpendelete1, setIsOpendelete1] = useState(false); // for deleting vendorSubGroup
  const [vendorSubgroupName, setVendorSubGroupName] = useState("");
  const [shippingNO, setShippingNo] = useState(null);
  const [x, setX] = useState("");
  const [DeleteSubgroup, setDeletesubgroup] = useState(true);

  const onOpenDelete1 = (name, x, i) => {
    setIsOpendelete1(true);
    setVendorSubGroupName(name);
    if (x) {
      setX(x);
      setDeletesubgroup(false);
      setShippingNo(`Shipping Method - ${i + 1}`);
    } else {
      setShippingNo(null);
      setDeletesubgroup(true);
    }
  };
  const onCloseDelete1 = () => setIsOpendelete1(false);
  const cancelRef = React.useRef();
  const onClose1 = () => setIsOpen1(false);
  const onClose2 = () => setIsOpen2(false);
  const onClose3 = () => setIsOpen3(false);
  const onClose4 = () => setIsOpen4(false);
  const onOpen1 = () => setIsOpen1(true);
  const onOpen2 = () => setIsOpen2(true);
  const onOpen3 = () => setIsOpen3(true);
  const onOpen4 = () => setIsOpen4(true);



  function handleDelteSubGroupData() {

    toast({
      title: `${vendorSubgroupName} city deleted`,
      status: "success",
      isClosable: true,
      position: "top",
    });
    const tempCityData = vendorSubGroup.filter(
      (x) => x.city_name !== vendorSubgroupName
    );
    setVendorSubGroup(tempCityData);
    setSelectedData({});
    setSelectedPartner([]);
    setSelectedShipment([]);
    setShowSelectedPartner([]);
    setEditData({});
    setEditPartner([]);
    setEditShipment([]);
    setSaveChangesToggle(true);
    onCloseDelete1();
  }

  function HandleDelteShippinMethodData() {
    setVendorSubGroup((prev) =>
      prev?.map((p) =>
        p.city_name === vendorSubgroupName
          ? {
              ...p,
              citywiseShippingAndVendors: vendorSubGroup
                .filter((z) => z.city_name === vendorSubgroupName)
                .map((k) =>
                  k.citywiseShippingAndVendors.filter(
                    (l) => l.unique_id !== x.unique_id
                  )
                )[0]
                .map((ex) => ex),
            }
          : p
      )
    );
    setSelectedData({});
    setEditData({});
    toast({
      title: `Shipment detials deleted from ${vendorSubgroupName}`,
      status: "success",
      isClosable: true,
      position: "top",
    });
    onCloseDelete1();
  }

  const navigate = useNavigate();
  //   const { isOpen, onOpen, onClose } = useDisclosure()
  const { pid, vid } = useParams();
  const productContainer = useRef(null);
  const toast = useToast();
  const unique_id = uuid();

  //   All Variant Details on first load
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/product/${pid}/variant/${vid}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(
          sessionStorage.getItem("admintoken")
        )}`,
      }, 
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response,"responssjdafgjkgkjdfs")
        setAllVariantData(response.data || []);
        setVendorSubGroup(response.data.vendor_subgroup_data);
      })
      .catch((err) => {
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
      });
  }, [pid, vid]);

  //   functionality to hide product dropdown
  const handleClickOutside = (event) => {
    if (
      productContainer.current &&
      !productContainer.current.contains(event.target)
    ) {
      if (!event.target.tagName.toLowerCase().match(/input|label/)) {
        setToggle(false);
      }
    }
  };
  // Dropdown useEffect
  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Product handler & response json
  const cityHandler = async (e) => {
    e.stopPropagation();
    setToggle(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/citygroup/read`,
      headers: {
        Authorization: `Bearer ${JSON.parse(
          sessionStorage.getItem("admintoken")
        )}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
   
        setCityData(response.data);
      })
      .catch((err) => {
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        // console.log(err);
      });
  };

  //   Add Vendor Handler
  const addVendorHandler = (e) => {
    e.stopPropagation();
    setToggle2(true);
  };

  // Edit Vendor Handler

  const editVendorHandler = (e) => {
    e.stopPropagation();
    setToggle2(true);
  };

  // addShippingMethodHandler
  const addShippingMethodHandler = (cityName) => {
    // request for shipping
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/shipping/read`,
      headers: {
        Authorization: `Bearer ${JSON.parse(
          sessionStorage.getItem("admintoken")
        )}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setShippingData(response.data);
      })
      .catch((error) => {
        if(error.err==="Invalid token"){
          navigate("/admin/login")
          return
        }
        // console.log(error);
      });

    //   request for partner
    let config2 = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/partner/all`,
      headers: {
        Authorization: `Bearer ${JSON.parse(
          sessionStorage.getItem("admintoken")
        )}`,
      },
    };

    axios
      .request(config2)
      .then((response) => {
        setPartnerData(response.data);
      })
      .catch((error) => {
        if(error.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        // console.log(error);
      });

    //Add new shipping method & vendor functinality
    setSelectedCityShippingAdd(cityName);
  };

  // Add Shipment functionality
  const updateShipmentObject = (updatedObj) => {
    setSelectedShipment((prevArray) =>
      prevArray?.map((obj) => (obj.name === updatedObj.name ? updatedObj : obj))
    );
  };

  // Edit Shipment functionality
  const editShipmentObject = (updatedObj) => {
    setEditShipment((prevArray) =>
      prevArray?.map((obj) => (obj.name === updatedObj.name ? updatedObj : obj))
    );
  };

  // shipping method modal save handler final
  const saveShipmentDataHandler = () => {
    if (selectedPartner.length > 0 && selectedShipment.length > 0) {
      if (
        selectedPartner.some((d) => d.inventory) &&
        selectedPartner.some((d) => d.vendor_name) &&
        selectedPartner.some((d) => d.purchase_price) &&
        selectedPartner.some((d) => d.vendor_id)
      ) {
        if (selectedShipment.some((d) => d.time_slots.length > 0)) {
          setSelectedData({
            associated_vendors: selectedPartner,
            shipping_methods: selectedShipment,
            unique_id: unique_id,
          });
          onClose3();
          toast({
            title: `Shipping method added to ${selectedCityShippingAdd}`,
            status: "success",
            isClosable: true,
            position: "top",
          });
          setSaveChangesToggle(true);
        } else {
          toast({
            title: `Please add all required details`,
            status: "error",
            isClosable: true,
            position: "top",
          });
        }
      } else {
        toast({
          title: `Please add all required details`,
          status: "error",
          isClosable: true,
          position: "top",
        });
      }
    } else {
      toast({
        title: `Please add all required details`,
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
    setRenderforprimaryvendor(!renderforprimaryvendor);
  };

  // edit shipping method modal save handler final
  const editSaveChangeHandler = () => {
    setToggle2(false);
    setToggle3(false);
    if (editPartner.length > 0 && editPartner.length > 0) {

      if (
        editPartner.some((d) => d.vendor_name.length) &&
        editPartner.some((d) => d.vendor_id.length)
      ) {
        if (editShipment.some((d) => d.time_slots.length > 0)) {
          setEditData({
            unique_id: editData.unique_id,
            associated_vendors: editPartner,
            shipping_methods: editShipment,
          });
          onClose2();
          toast({
            title: `Shipping method upadated in ${selectedCityShippingAdd}`,
            status: "success",
            isClosable: true,
            position: "top",
          });
          setSaveChangesToggle(true);
        } else {
          toast({
            title: `Please add all required details `,
            status: "error",
            isClosable: true,
            position: "top",
          });
        }
      } else {
        toast({
          title: `Please add all required details `,
          status: "error",
          isClosable: true,
          position: "top",
        });
      }
    } else {
      toast({
        title: `Please add all required details `,
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
    setRenderforprimaryvendor(renderforprimaryvendor);
  };

  // to set final vendor added shipping method data
  useEffect(() => {
    if (Object.keys(selectedData).length > 0) {
      setVendorSubGroup((prev) =>
        prev?.map((d) =>
          d.city_name === selectedCityShippingAdd
            ? {
                ...d,
                citywiseShippingAndVendors: [
                  ...d.citywiseShippingAndVendors,
                  selectedData,
                ],
              }
            : d
        )
      );
    }
    if (Object.keys(editData).length > 1) {
      setVendorSubGroup((prev) =>
        prev?.map((d) =>
          d.city_name === selectedCityShippingAdd
            ? {
                ...d,
                citywiseShippingAndVendors: [
                  ...d.citywiseShippingAndVendors.map((l) =>
                    l.unique_id === editData.unique_id ? editData : l
                  ),
                ],
              }
            : d
        )
      );
    }
  }, [selectedData, selectedCityShippingAdd, editData]);

  // ------ FINAL SAVE PAYLOAD --------
  const finalSaveHandler = () => {
    if (vendorSubGroup.every((x) => x.citywiseShippingAndVendors.length > 0)) {
      let data = JSON.stringify(vendorSubGroup);

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/product/${pid}/variant/${vid}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            sessionStorage.getItem("admintoken")
          )}`,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
         
          toast({
            title: `Variant detials updated`,
            status: "success",
            isClosable: true,
            position: "top",
          });
          setSaveChangesToggle(false);
        })
        .catch((error) => {
          if(error.response.data.err==="Invalid token"){
            sessionStorage.removeItem('admintoken');
            navigate("/admin/login")
            return
          }
          toast({
            title: `Something went wrong please try agian later`,
            status: "error",
            isClosable: true,
            position: "top",
          });
          setSaveChangesToggle(true);
        });
    } else {
      toast({
        title: `Please add shipment method`,
        status: "error",
        isClosable: true,
        position: "top",
      });
    }
  };

  // ****************************  SETTING PRIMARY VENDOR  *****************************

  let [vendorforprimaryvendorarray, setVendorforprimaryvendorarray] = useState([]);
  const [renderforprimaryvendor, setRenderforprimaryvendor] = useState(false);
  const [getcityidforprimaryvendor, setGetcityidforprimaryvendor] =
    useState(null);

    const isButtonDisabled = vendorSubGroup?.some(
      (ven) => ven.primary_vendor_id === null || ven.primary_vendor_id === ""
    );
    const [Radiovalue, setRadiovalue] = React.useState(null);
  const [modalstatus, setmodalstatus] = useState(false);
  const primarymodalopen = (d) => {
    setmodalstatus(true);
    // console.log(d,"d")
    setRadiovalue(d.primary_vendor_id)
    setGetcityidforprimaryvendor(d._id);
  };
  const primarymodalclose = () => setmodalstatus(false);

 
// console.log(Radiovalue,"val")

  useEffect(() => {
    let k = [];
    let arr = [];
    for (let i = 0; i < vendorSubGroup?.length; i++) {
      let obj = {
        all_vendors: [],
      };
      // let primaryobj = {
      //   city_id: vendorSubGroup[i]?.city_id,
      //   vendor: null,
      //   vendor_id: null,
      // };
      // arr.push(primaryobj);
      for (let j = 0;j < vendorSubGroup[i]?.citywiseShippingAndVendors?.length;j++) {
        obj._id = vendorSubGroup[i]?._id;
        obj.all_vendors = [
          ...obj.all_vendors,
          ...vendorSubGroup[i]?.citywiseShippingAndVendors[j]
            ?.associated_vendors,
        ];
      }
      k.push(obj);
    }
    setVendorforprimaryvendorarray(k);
  }, [vendorSubGroup, renderforprimaryvendor]);
  
  // console.log(vendorSubGroup,"vendorsubgroup")
  // console.log(vendorforprimaryvendorarray,"arr vendoe")

  function handleSetPrimaryVendorInRadio(name, id) {
    // console.log(vendorSubGroup,"sub 1")
    let arr = [...vendorSubGroup];
    
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i]._id === getcityidforprimaryvendor) {
        // console.log(i,"i")
        arr[i].primary_vendor_name = name;
        arr[i].primary_vendor_id = id;
        break
      }
    }
    setVendorSubGroup(arr);
    setSaveChangesToggle(true);
    primarymodalclose();
    setRadiovalue(null)
  }




  return (
    <Fragment>
      <Flex
        widht={"100%"}
        bgColor={"#f5f5f7"}
        h={"100vh"}
        p={5}
        flexDirection={"column"}
      >
        {/* Variant Title & Back button */}
        <Flex width={"100%"}>
          <Text
            as={"span"}
            onClick={() => {
              if (saveChangesToggle) {
                onOpen4();
              } else {
                navigate(-1);
              }
            }}
            flexBasis={"5%"}
            mt={"3px"}
            cursor={"pointer"}
          >
            <BiArrowBack fontSize={"28px"} />
          </Text>
          <AlertDialog isOpen={isOpen4} onClose={onClose4} isCentered>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Back
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure? if go back your changes will not save
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button onClick={onClose4}>Cancel</Button>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      navigate(`/admin/products/${pid}`);
                      onClose4();
                    }}
                    ml={3}
                  >
                    Back
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
          <Flex flexBasis={"95%"} justifyContent={"space-between"}>
            <Heading fontSize={"26px"}>{allVariantData?.title}</Heading>
            <Flex justifyContent={"flex-end"}>
              <Button colorScheme={"facebook"} onClick={onOpen1} mr={3}>
                Add City
              </Button>
              {saveChangesToggle > 0 && (
                <>
                {isButtonDisabled ? (
                  <Tooltip label="Please Select The Primary Vendor First">
                    <Button
                      isDisabled={isButtonDisabled}
                      colorScheme="whatsapp"
                      onClick={finalSaveHandler}
                    >
                      Save Changes
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    isDisabled={isButtonDisabled}
                    colorScheme="whatsapp"
                    onClick={finalSaveHandler}
                  >
                    Save Changes
                  </Button>
                )}
              </>
              )}
            </Flex>
          </Flex>
        </Flex>
        {/* List of City ---- map */}
   
        {Object.keys(allVariantData)?.length > 0 &&
          vendorSubGroup?.length > 0 &&
          vendorSubGroup?.map((d, i) => {
            return (
              <Card my={6} shadow={"md"} bgColor={"white"} key={i}>
                <CardBody px={0} pt={0}>
                  {/*  */}
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    bgColor={"#DAD0C2"}
                    px={4}
                    py={3}
                    mb={6}
                  >
                    <Flex justifyContent={"center"} flexBasis={"92%"}>
                      <Heading
                        fontSize={"24px"}
                        fontWeight={"semibold"}
                        color={"black"}
                        letterSpacing={"1px"}
                      >
                        {d.city_name}
                      </Heading>
                    </Flex>
                    {/* DELETE CITY */}
                    <MdDelete
                      cursor={"pointer"}
                      fontSize={"34px"}
                      color={"red"}
                      onClick={(e) => {
                        onOpenDelete1(d.city_name);
                      }}
                    />
                  </Flex>
                  {/* ***************************  ALERT DILOG FOR DELETING VENDORSUBGROUP    ******************************************* */}
                  <AlertDialog
                    isOpen={isOpendelete1}
                    leastDestructiveRef={cancelRef}
                    onClose={onCloseDelete1}
                  >
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                          {shippingNO
                            ? `Delete ${shippingNO} From ${vendorSubgroupName}`
                            : `Delete ${vendorSubgroupName}`}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                          Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                          <Button ref={cancelRef} onClick={onCloseDelete1}>
                            Cancel
                          </Button>
                          <Button
                            colorScheme="red"
                            onClick={
                              DeleteSubgroup
                                ? handleDelteSubGroupData
                                : HandleDelteShippinMethodData
                            }
                            ml={3}
                          >
                            Delete
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>

                  {/* **************************  MODAL FOR PRIMARY VENDOR   ******************************************* */}
                  <Modal
                    closeOnOverlayClick={false}
                    isOpen={modalstatus}
                    onClose={primarymodalclose}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Choose Primary Vendor</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody pb={6}>
                        <Grid templateColumns="repeat(1,1fr)" gap="10px">
                          {[
                            ...new Set(
                              vendorforprimaryvendorarray
                                ?.filter(
                                  (id) =>
                                    id._id === getcityidforprimaryvendor
                                )[0]
                                ?.all_vendors?.map(
                                  (item) =>
                                    item.vendor_id + "_" + item.vendor_name
                                )
                            ),
                          ].map((vendor, index) => {
                            const [vendorId, vendorName] = vendor.split("_");

                            return (
                              <RadioGroup
                                key={vendorId}
                                onChange={setRadiovalue}
                                value={Radiovalue}
                              >
                                <Stack direction="row">
                                  <Radio
                                    size="md"
                                    key={index}
                                  
                                    onChange={() =>
                                      handleSetPrimaryVendorInRadio(
                                        vendorName,
                                        vendorId
                                      )
                                    }
                                    onClick={() =>
                                      handleSetPrimaryVendorInRadio(
                                        vendorName,
                                        vendorId
                                      )
                                    }
                                    value={vendorId}
                                  >
                                    {vendorName}
                                  </Radio>
                                </Stack>
                              </RadioGroup>
                            );
                          })}
                        </Grid>
                      </ModalBody>

                      <ModalFooter>
                        {/* <Button colorScheme="blue" mr={3}
                        onClick={()=>handleSavePrimaryVendorWithRadio()}
                        >
                          Save
                        </Button> */}
                        <Button onClick={primarymodalclose}>Cancel</Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  {/* list of vendor & shipping method --- map */}
                  {d?.citywiseShippingAndVendors?.map((x, i) => {
                    return (
                      <Fragment key={i}>
                        <Flex
                          width={"auto"}
                          boder={"1px solid gray"}
                          shadow={"md"}
                          flexDirection={"column"}
                          my={2}
                          mx={4}
                          mb={6}
                        >
                          {/* Delete and Edit shipping method Icon */}
                          <Flex
                            height={"45px"}
                            width={"100%"}
                            bgColor={"#D8D8D8"}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box></Box>
                            <Heading
                              fontSize={"20px"}
                              alignItems="center"
                              fontWeight={"semibold"}
                              color={"black"}
                              letterSpacing={"1px"}
                            >
                              Shipping Method - {i + 1}
                            </Heading>

                            <Flex p={3}>
                              {/* Edit shipment method */}
                              <MdEdit
                                fontSize={"24px"}
                                color={"blue"}
                                cursor={"pointer"}
                                onClick={() => {
                                  onOpen2();
                                  setEditData({});
                                  setEditPartner([]);
                                  setEditShipment([]);
                                  addShippingMethodHandler(d.city_name);
                                  setEditPartner(x.associated_vendors);
                                  setEditShipment(x.shipping_methods);
                                  setEditData({ unique_id: x.unique_id });
                                  setSelectedData({});
                                }}
                              />
                              {/* Delete Shipment method  */}
                              <MdDelete
                                fontSize={"24px"}
                                color={"red"}
                                cursor={"pointer"}
                                onClick={() => {
                                  onOpenDelete1(d.city_name, x, i);
                                }}
                              />
                            </Flex>
                          </Flex>
                          {/* Vendor & shipping method data */}

                          <Flex>
                            <Flex
                              flexBasis={"45%"}
                              py={2}
                              pb={3}
                              alignItems={"center"}
                            >
                              <TableContainer
                                borderColor={"black"}
                                width={"100%"}
                              >
                                <Table variant="simple">
                                  <Thead>
                                    <Tr>
                                      <Th
                                        fontSize={"12px"}
                                        borderBottom={"1px solid #b3b3b3"}
                                      >
                                        Vendor
                                      </Th>
                                      <Th
                                        textAlign={"center"}
                                        fontSize={"12px"}
                                        borderBottom={"1px solid #b3b3b3"}
                                      >
                                        Purchase
                                        <br /> Price
                                      </Th>
                                 
                                      <Th
                                      textAlign={"center"}
                                        fontSize={"12px"}
                                        borderBottom={"1px solid #b3b3b3"}
                                      >
                                        Inventory
                                      </Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    {/* Vendor */}
                                    {x?.associated_vendors?.map((y, index) => {
                                      return (
                                        <Fragment key={index}>
                                          <Tr>
                                            <Td
                                              fontSize={"13px"}
                                              borderBottom={"1px solid #b3b3b3"}
                                            >
                                              {y?.vendor_name}
                                            </Td>
                                            <Td
                                              textAlign={"center"}
                                              fontSize={"13px"}
                                              borderBottom={"1px solid #b3b3b3"}
                                              borderTop={"1px solid #b3b3b3"}
                                            >
                                              {y?.purchase_price}
                                            </Td>
                                            
                                            <Td
                                              textAlign={"center"}
                                              fontSize={"13px"}
                                              borderBottom={"1px solid #b3b3b3"}
                                              borderTop={"1px solid #b3b3b3"}
                                            >
                                              {y?.inventory}
                                            </Td>
                                        
                                          </Tr>
                                        </Fragment>
                                      );
                                    })}
                                  </Tbody>
                                </Table>
                              </TableContainer>
                            </Flex>

                            <Center height="auto">
                              <Divider
                                orientation="vertical"
                                border={"1px solid gray"}
                              />
                            </Center>
                            {/* Shipping Method */}

                            <Grid
                              flexBasis={"54%"}
                              py={2}
                              alignItems={"center"}
                              ml={6}
                              templateColumns={{
                                base: "repeat(1,1fr)",
                                md: "repeat(2,1fr)",
                                lg: "repeat(2,1fr)",
                              }}
                            >
                              {x?.shipping_methods?.map((z, indx) => {
                                return (
                                  <Fragment key={indx}>
                                    <Menu>
                                      <MenuButton
                                        as={Button}
                                        rightIcon={<BiChevronDown />}
                                        width="fit-content"
                                        p="0px 10px 0px 10px"
                                        mb="20px"
                                      >
                                        {z.name}
                                      </MenuButton>
                                      <MenuList>
                                        {z?.time_slots?.map(
                                          (timeSlot, indx2) => {
                                            return (
                                              <Fragment key={indx2}>
                                                <MenuItem>
                                                  {`${timeSlot.start} - ${timeSlot.end}`}
                                                </MenuItem>
                                              </Fragment>
                                            );
                                          }
                                        )}
                                      </MenuList>
                                    </Menu>
                                  </Fragment>
                                );
                              })}
                            </Grid>
                          </Flex>
                        </Flex>
                      </Fragment>
                    );
                  })}

                  {/* add shipping method button */}
                  <Flex
                    justifyContent={"flex-start"}
                    widht={"100%"}
                    gap="20px"
                    my={4}
                    ml={4}
                    alignItems="center"
                  >
                    <Button
                      colorScheme="twitter"
                      onClick={() => {
                        onOpen3();
                        addShippingMethodHandler(d.city_name);
                        setSelectedPartner([]);
                        setSelectedShipment([]);
                        setSelectedData({});
                        setEditData({});
                        setEditPartner([]);
                        setEditShipment([]);
                        setToggle2(false);
                        setToggle3(false);
                        setShowSelectedPartner([]);
                      }}
                    >
                      Add Shipping Method
                    </Button>

                    <Flex gap="10px">
                      <Text
                        fontSize="xl"
                        pt="7px"
                        fontFamily="NexaText-Trial-Bold"
                        color="red"
                      >
                        :-* Primary Vendor :
                      </Text>
                      <Text
                        fontSize="xl"
                        pt="7px"
                        fontFamily="NexaText-Trial-Bold"
                        color="red"
                      >
                       
                        {vendorSubGroup.filter((ven) => ven._id === d._id)[0]
                          ?.primary_vendor_name
                          ? vendorSubGroup.filter((ven) => ven._id === d._id)[0]
                              ?.primary_vendor_name
                          : "-"}
                      </Text>

                      <IconButton
                        onClick={() => primarymodalopen(d)}
                        colorScheme="blue"
                        aria-label="Search database"
                        icon={<EditIcon fontSize="25px" />}
                      />
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            );
          })}
      </Flex>

      {/* #################### Add City Modal  ########## M O D A L*/}
      <Modal closeOnOverlayClick={false} isOpen={isOpen1} onClose={onClose1}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Select City</ModalHeader>

          <ModalCloseButton mt={"8px"} />
          <ModalBody mb={4}>
            <FormControl>
              <Input
                placeholder={"Select city"}
                onClick={(e) => {
                  cityHandler(e);
                }}
                onChange={(e) => setQuery(e.target.value)}
              />

              {/* Add & Search City */}
              {toggle && (
                <Box
                  ref={productContainer}
                  position={"absolute"}
                  zIndex={"99"}
                  bgColor={"white"}
                  width={"100%"}
                  border="1px"
                  borderColor="gray.300"
                  rounded={"md"}
                  maxHeight={`${window.innerHeight - 250}px`}
                  overflowY={"scroll"}
                  overflowX={"hidden"}
                >
                  {Object.keys(cityData)?.length > 0 ? (
                    <>
                    
                      {cityData?.data
                        .filter((e) =>
                          e.name
                            .toLowerCase()
                            .includes(query.toLocaleLowerCase())
                        )
                        .map((d, i) => {
                          return (
                            <Fragment key={i}>
                              <Text
                                bgColor={"gray.100"}
                                py={2}
                                px={4}
                                _hover={{
                                  cursor: "pointer",
                                  bgColor: "gray.200",
                                }}
                                onClick={() => {
                                  setToggle(false);
                                  onClose1();
                                  const isCityExist = vendorSubGroup?.filter(
                                    (x) => x.city_name === d.name
                                  );
                                  if (isCityExist?.length > 0) {
                                    toast({
                                      title: `${d.name} city already added`,
                                      status: "error",
                                      isClosable: true,
                                      position: "top",
                                    });
                                  } else {
                                    toast({
                                      title: `${d.name} city added`,
                                      status: "success",
                                      isClosable: true,
                                      position: "top",
                                    });
                                    if (vendorSubGroup?.length > 0) {
                                      let tempArr = [
                                        ...vendorSubGroup,
                                        {
                                          city_name: d.name,
                                          city_list: d.cities,
                                          _id:d._id,
                                          citywiseShippingAndVendors: [],
                                          primary_vendor_id: null,
                                          primary_vendor_name: null,
                                        },
                                      ];
                                      setVendorSubGroup(tempArr.reverse());
                                    } else {
                                      setVendorSubGroup([
                                        {
                                          city_name: d.name,
                                          city_list: d.cities,
                                          _id:d._id,
                                          citywiseShippingAndVendors: [],
                                          primary_vendor_id: null,
                                          primary_vendor_name: null,
                                        },
                                      ]);
                                    }
                                  }
                                }}
                              >
                                {d.name}
                              </Text>
                              <Divider border={"1px solid gray"} />
                            </Fragment>
                          );
                        })}
                    </>
                  ) : (
                    <Flex
                      justifyContent={"center"}
                      alignItems={"center"}
                      py={3}
                    >
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="lg"
                      />
                    </Flex>
                  )}
                </Box>
              )}
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* ######################## Add Shipping Method button ####################### M O D A L */}
      <Modal isOpen={isOpen3} onClose={onClose3}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Add Shipping Method</ModalHeader>
          <Divider border={"1px solid #f5f5f7"} />
          <ModalCloseButton mt={"8px"} />

          <ModalBody mb={4}>
            {/* Select Shipping Method / time-slot */}
            <Box>
              <Text fontSize={"17px"} fontWeight={"semibold"} my={2}>
                Select Shipping Method / Time-Slot
              </Text>
              {/* Accordion */}
              <Accordion allowMultiple border={"1px solid #EEEEEE"}>
                {/* Shipping Data map */}
                {shippingData?.shipping_methods?.map((d, i) => {
                  return (
                    <AccordionItem key={i} mb={1}>
                      <Heading>
                        <AccordionButton bgColor={"#EEEEEE"}>
                          <Box as="span" flex="1" textAlign="left">
                            {d.name}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </Heading>
                      <AccordionPanel pb={4}>
                        <Flex>
                          <FormControl py={2} px={4} pt={0}>
                            {d?.time_slots?.map((x, index) => {
                              return (
                                <Fragment key={index}>
                                  <Checkbox
                                    onChange={(e) => {
                                      if (e.target.checked === true) {
                                        if (selectedShipment?.length > 0) {
                                          if (
                                            selectedShipment?.some((n) =>
                                              n._id.includes(n._id)
                                            )
                                          ) {
                                            // update shipment details -- time_slot
                                            let tempArr =
                                              selectedShipment?.filter(
                                                (y) => y._id === d._id
                                              );
                                            if (tempArr?.length === 1) {
                                              updateShipmentObject({
                                                ...tempArr[0],
                                                time_slots: [
                                                  ...tempArr[0].time_slots,
                                                  x,
                                                ],
                                              });
                                            } else {
                                              // append new shipment {obj}
                                              setSelectedShipment((prev) => [
                                                ...prev,
                                                { ...d, time_slots: [x] },
                                              ]);
                                            }
                                          }
                                        } else {
                                          setSelectedShipment([
                                            { ...d, time_slots: [x] },
                                          ]);
                                        }
                                      } else if (e.target.checked === false) {
                                        let tempArr = selectedShipment?.filter(
                                          (y) => y._id === d._id
                                        );
                                        if (tempArr?.length === 1) {
                                          let tempArr2 =
                                            tempArr[0].time_slots.filter(
                                              (k) => k._id !== x._id
                                            );
                                          updateShipmentObject({
                                            ...tempArr[0],
                                            time_slots: tempArr2,
                                          });
                                          if (tempArr2?.length === 0) {
                                            setSelectedShipment((prev) =>
                                              prev.filter(
                                                (f) => f._id !== tempArr[0]._id
                                              )
                                            );
                                          }
                                        }
                                      }
                                    }}
                                  >
                                    {" "}
                                    {x.start + " " + x.end}{" "}
                                  </Checkbox>
                                  <br />
                                </Fragment>
                              );
                            })}
                          </FormControl>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </Box>
            {/* Assign to Vendor */}
            <Box my={7}>
              <FormControl>
                <FormLabel
                  fontSize={"17px"}
                  mb={1}
                  fontWeight={"semibold"}
                  letterSpacing={"wide"}
                >
                  Assign to :-
                </FormLabel>
                <Input
                  placeholder={"Select vendor"}
                  onClick={(e) => {
                    addVendorHandler(e);
                  }}
                  value={query2}
                  onChange={(e) => setQuery2(e.target.value)}
                />

                {/* Add & Search vendor */}
                {toggle2 && (
                  <Box
                    textAlign="left"
                    position={"absolute"}
                    zIndex={"99"}
                    bgColor={"white"}
                    width={"100%"}
                    border="1px"
                    borderColor="gray.300"
                    rounded={"md"}
                  >
                    <Flex width={"100%"} justifyContent={"flex-end"} py={2}>
                      <Button
                        size={"xs"}
                        colorScheme="whatsapp"
                        mx={2}
                        onClick={() => {
                          setShowSelectedPartner(selectedPartner);
                          setToggle2(false);
                          setQuery2("");
                        }}
                      >
                        Save Changes
                      </Button>
                    </Flex>
                    <Flex
                      maxHeight={`${window.innerHeight - 400}px`}
                      overflowY={"scroll"}
                      overflowX={"hidden"}
                    >
                      <FormControl py={2} px={4} pt={0}>
                        {partnerData?.partnerList?.filter((e) =>
                            e.partner_name
                              .toLowerCase()
                              .includes(query2.toLocaleLowerCase())
                          )
                          .map((d, i) => {
                            return (
                              <Fragment key={i}>
                                <Checkbox
                                  isChecked={selectedPartner?.some(
                                    (isP) => isP.vendor_id === d._id
                                  )}
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      if (selectedPartner?.length === 0) {
                                        setSelectedPartner([
                                          {
                                            vendor_id: d._id,
                                            vendor_name: d.partner_name,
                                          },
                                        ]);
                                      } else {
                                        setSelectedPartner((prev) => [
                                          ...prev,
                                          {
                                            vendor_id: d._id,
                                            vendor_name: d.partner_name,
                                          },
                                        ]);
                                      }
                                    } else if (e.target.checked === false) {
                                   
                                      setSelectedPartner((prev) =>
                                        prev.filter(
                                          (h) => h.vendor_id !== d._id
                                        )
                                      );
                                    }
                                  }}
                                >
                                  {" "}
                                  {d.partner_name}{" "}
                                </Checkbox>
                                <br />
                              </Fragment>
                            );
                          })}
                      </FormControl>
                    </Flex>
                  </Box>
                )}
              </FormControl>
              {/* Added / Selected Vendor */}
              {showSelecetedPartner?.map((sp, index) => {
                const handlePurchasePriceChange = (e) => {
                  const updatedPartners = [...showSelecetedPartner];
                  updatedPartners[index] = {
                    ...updatedPartners[index],
                    purchase_price: e.target.value,
                  };
                  setSelectedPartner(updatedPartners);
                  setShowSelectedPartner(updatedPartners);
                };

                const handleInventoryChange = (e) => {
                  const updatedPartners = [...showSelecetedPartner];
                  updatedPartners[index] = {
                    ...updatedPartners[index],
                    inventory: e.target.value,
                  };
                  setSelectedPartner(updatedPartners);
                  setShowSelectedPartner(updatedPartners);
                };

                return (
                  <Flex
                    key={index}
                    my={3}
                    width={"100%"}
                    border={"1px solid #EEEEEE"}
                    justifyContent={"space-between"}
                    shadow={"md"}
                    rounded={"md"}
                    alignItems={"center"}
                    py={2}
                    px={4}
                  >
                    <Text flexBasis={"32%"} pb="10px">
                      {sp?.vendor_name}
                    </Text>
                    {/* pp */}
                    <Flex
                      flexBasis={"37%"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <FormControl>
                        <Input
                          placeholder="Purchase Price"
                          value={sp?.purchase_price || ""}
                          onChange={handlePurchasePriceChange}
                          type={"number"}
                          step={"any"}
                          onWheel={(e) => e.currentTarget.blur()}
                          required
                        />
                        <FormHelperText>Purchase Price 1</FormHelperText>
                      </FormControl>
                    </Flex>
                    {/* Inventory */}
                    <Flex
                      flexBasis={"28%"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <FormControl>
                        <Input
                          placeholder="Inventory"
                          value={sp?.inventory || ""}
                          type={"number"}
                          step={"any"}
                          onWheel={(e) => e.currentTarget.blur()}
                          onChange={handleInventoryChange}
                          required
                        />
                        <FormHelperText>Inventory</FormHelperText>
                      </FormControl>
                    </Flex>
                  </Flex>
                );
              })}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose3}>
              Cancel
            </Button>
            <Button
              colorScheme="whatsapp"
              onClick={saveShipmentDataHandler}
              isDisabled={
                selectedPartner.some((price) => price?.purchase_price === "") ||
                selectedPartner.some(
                  (inventory) => inventory?.inventory === ""
                ) ||
                selectedPartner.some(
                  (price) => !price.hasOwnProperty("purchase_price")
                ) ||
                selectedPartner.some(
                  (price) => !price.hasOwnProperty("inventory")
                )
              }
            >
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* $$$$$$$$$$$$$$$$$$$$$$$$ -- Add / Edit Shipping Method M O D A L -- $$$$$$$$$$$$$$$$$$$$$$ */}
      <Modal isOpen={isOpen2} onClose={onClose2}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Edit Shipping Method</ModalHeader>
          <Divider border={"1px solid #f5f5f7"} />
          <ModalCloseButton mt={"8px"} />

          <ModalBody mb={4}>
            {/* Select Shipping Method / time-slot */}
            <Box>
              <Text fontSize={"17px"} fontWeight={"semibold"} my={2}>
                Selected Shipping Method / Time-Slot
              </Text>
              {/* Accordion */}
              <Accordion allowMultiple border={"1px solid #EEEEEE"}>
                {/* Shipping Data map */}
                {shippingData?.shipping_methods?.map((d, i) => {
                  return (
                    <AccordionItem key={i} mb={1}>
                      <Heading>
                        <AccordionButton bgColor={"#EEEEEE"}>
                          <Box as="span" flex="1" textAlign="left">
                            {d.name}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </Heading>
                      <AccordionPanel pb={4}>
                        <Flex>
                          <FormControl py={2} px={4} pt={0}>
                            {d?.time_slots?.map((x, index) => {
                              return (
                                <Fragment key={index}>
                                  <Checkbox
                                    isChecked={editShipment
                                      .filter((s) => s._id === d._id)
                                      .some((d) =>
                                        d.time_slots.some(
                                          (k) => k._id === x._id
                                        )
                                      )}
                                    onChange={(e) => {
                                      if (e.target.checked === true) {
                                        if (editShipment?.length > 0) {
                                          if (
                                            editShipment?.some((n) =>
                                              n._id.includes(n._id)
                                            )
                                          ) {
                                            // update shipment details -- time_slot
                                            let tempArr = editShipment?.filter(
                                              (y) => y._id === d._id
                                            );
                                            if (tempArr?.length === 1) {
                                              editShipmentObject({
                                                ...tempArr[0],
                                                time_slots: [
                                                  ...tempArr[0].time_slots,
                                                  x,
                                                ],
                                              });
                                            } else {
                                              // append new shipment {obj}
                                              setEditShipment((prev) => [
                                                ...prev,
                                                { ...d, time_slots: [x] },
                                              ]);
                                            }
                                          }
                                        } else {
                                          setEditShipment([
                                            { ...d, time_slots: [x] },
                                          ]);
                                        }
                                      } else if (e.target.checked === false) {
                                        let tempArr = editShipment?.filter(
                                          (y) => y._id === d._id
                                        );
                                        if (tempArr?.length === 1) {
                                          let tempArr2 =
                                            tempArr[0].time_slots.filter(
                                              (k) => k._id !== x._id
                                            );
                                          editShipmentObject({
                                            ...tempArr[0],
                                            time_slots: tempArr2,
                                          });
                                          if (tempArr2?.length === 0) {
                                            setEditShipment((prev) =>
                                              prev.filter(
                                                (f) => f._id !== tempArr[0]._id
                                              )
                                            );
                                          }
                                        }
                                      }
                                    }}
                                  >
                                    {" "}
                                    {x.start + " " + x.end}{" "}
                                  </Checkbox>
                                  <br />
                                </Fragment>
                              );
                            })}
                          </FormControl>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </Box>
            {/* Assign to Vendor */}
            <Box my={7}>
              <FormControl>
                <FormLabel
                  fontSize={"17px"}
                  mb={1}
                  fontWeight={"semibold"}
                  letterSpacing={"wide"}
                >
                  Assign to :-
                </FormLabel>
                <Input
                  placeholder={"Select vendor"}
                  onClick={(e) => {
                    editVendorHandler(e);
                    setToggle3(true);
                  }}
                  value={query3}
                  onChange={(e) => setQuery3(e.target.value)}
                />

                {/* Add & Search Product */}
                {toggle3 && (
                  <Box
                    position={"absolute"}
                    zIndex={"99"}
                    bgColor={"white"}
                    width={"100%"}
                    border="1px"
                    borderColor="gray.300"
                    rounded={"md"}
                    maxHeight={`${window.innerHeight - 320}px`}
                    overflowY={"scroll"}
                    overflowX={"hidden"}
                  >
                    <Flex width={"100%"} justifyContent={"flex-end"} mt={2}>
                      {/* <Button size={'xs'} colorScheme='blue' onClick={() => setToggle3(false)}>Cancel</Button> */}
                      <Button
                        size={"xs"}
                        colorScheme="whatsapp"
                        mx={2}
                        onClick={() => {
                          setToggle3(false);
                          setQuery3("");
                        }}
                      >
                        Save Changes
                      </Button>
                    </Flex>
                    <Flex textAlign="left">
                      <FormControl py={2} px={4} pt={0}>
                        {partnerData?.partnerList
                          .filter((e) =>
                            e.partner_name
                              .toLowerCase()
                              .includes(query3.toLocaleLowerCase())
                          )
                          .map((d, i) => {
                            return (
                              <Fragment key={i}>
                                <Checkbox
                                  isChecked={editPartner?.some(
                                    (isP) => isP.vendor_id === d._id
                                  )}
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      if (editPartner?.length === 0) {
                                        setEditPartner([
                                          {
                                            vendor_id: d._id,
                                            vendor_name: d.partner_name,
                                          },
                                        ]);
                                      } else {
                                        setEditPartner((prev) => [
                                          ...prev,
                                          {
                                            vendor_id: d._id,
                                            vendor_name: d.partner_name,
                                          },
                                        ]);
                                      }
                                    } else if (e.target.checked === false) {
                                 

                                      setEditPartner((prev) =>
                                        prev.filter(
                                          (h) => h.vendor_id !== d._id
                                        )
                                      );
                                    }
                                  }}
                                >
                                  {" "}
                                  {d.partner_name}{" "}
                                </Checkbox>
                                <br />
                              </Fragment>
                            );
                          })}
                      </FormControl>
                    </Flex>
                  </Box>
                )}
              </FormControl>
              {/* Added / Selected Vendor */}
              {editPartner?.map((sp, index) => {
                const handlePurchasePriceChange = (e) => {
                  const updatedPartners = [...editPartner];
                  updatedPartners[index] = {
                    ...updatedPartners[index],
                    purchase_price: e.target.value,
                  };
                  setEditPartner(updatedPartners);
                };

                const handleInventoryChange = (e) => {
                  const updatedPartners = [...editPartner];
                  updatedPartners[index] = {
                    ...updatedPartners[index],
                    inventory: e.target.value,
                  };
                  setEditPartner(updatedPartners);
                };

                return (
                  <Flex
                    key={index}
                    my={3}
                    width={"100%"}
                    border={"1px solid #EEEEEE"}
                    justifyContent={"space-between"}
                    shadow={"md"}
                    rounded={"md"}
                    alignItems={"center"}
                    py={2}
                    px={4}
                  >
                    <Text flexBasis={"32%"} pb="10px">
                      {sp?.vendor_name}
                    </Text>
                    {/* pp */}
                    <Flex
                      flexBasis={"37%"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <FormControl>
                        <Input
                          placeholder="Purchase Price"
                          value={sp?.purchase_price || ""}
                          onChange={handlePurchasePriceChange}
                          type={"number"}
                          step={"any"}
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                        <FormHelperText>Purchase Price</FormHelperText>
                      </FormControl>
                    </Flex>
                    {/* Inventory */}
                    <Flex
                      flexBasis={"28%"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <FormControl>
                        <Input
                          placeholder="Inventory"
                          value={sp?.inventory || ""}
                          type={"number"}
                          step={"any"}
                          onChange={handleInventoryChange}
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                        <FormHelperText>Inventory</FormHelperText>
                      </FormControl>
                    </Flex>
                  </Flex>
                );
              })}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose2}>
              Cancel
            </Button>
            <Button
              colorScheme="whatsapp"
              onClick={editSaveChangeHandler}
              isDisabled={
                editPartner.some((price) => price.purchase_price === "") ||
                editPartner.some((inventory) => inventory.inventory === "") ||
                editPartner.some(
                  (price) => !price.hasOwnProperty("purchase_price")
                ) ||
                editPartner.some((price) => !price.hasOwnProperty("inventory"))
              }
            >
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default VariantDetails;
