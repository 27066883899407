import * as types from "./action.types";
import axios from "axios";
import { setToast } from "../../../../Fucntions/Toastfunction";

export const getVendorData = (url, navigate) => async (dispatch) => {
  dispatch({ type: types.GET_VENDOR_DATA_LOADING });
  const headers = {
    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`,
  };
  return axios({
    method: "get",
    withCredentials: false,
    url: url,
    headers: headers,
  })
    .then((r) => {
      // console.log(r,"dasj")
      dispatch({
        type: types.GET_VENDOR_DATA_SUCCESS,
        payload: r.data.partnerList,
      });
    })
    .catch((err) => {
      if (err.response.data.err === "Invalid token") {
        sessionStorage.removeItem("admintoken");
        navigate("/admin/login");
        return;
      }
      dispatch({ type: types.GET_VENDOR_DATA_ERROR });
    });
};

export const getVendorSingleData =
  (url, token, navigate, data) => async (dispatch) => {
    console.log(data, "data");
    dispatch({ type: types.GET_SINGLE_VENDOR_DATA_LOADING });
    const headers = {
      Authorization: `Bearer ${JSON.parse(sessionStorage.getItem(token))}`,
    };
    return axios({
      method: "post",
      withCredentials: false,
      url: url,
      data,
      headers,
    })
      .then((r) => {
        // console.log(r,"rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
        dispatch({
          type: types.GET_SINGLE_VENDOR_DATA_SUCCESS,
          payload: r.data,
        });
      })
      .catch((err) => {
        console.log(err, "err");
        if (err?.response?.data?.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        dispatch({ type: types.GET_SINGLE_VENDOR_DATA_ERROR });
      });
  };

export const getVendorSingleDataPartner =
  (url, token, navigate) => async (dispatch) => {
    dispatch({ type: types.GET_SINGLE_VENDOR_DATA_LOADING });
    const headers = {
      Authorization: `Bearer ${JSON.parse(sessionStorage.getItem(token))}`,
    };
    return axios({
      method: "get",
      withCredentials: false,
      url: url,
      headers,
    })
      .then((r) => {
        dispatch({
          type: types.GET_SINGLE_VENDOR_DATA_SUCCESS,
          payload: r.data,
        });
      })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        dispatch({ type: types.GET_SINGLE_VENDOR_DATA_ERROR });
      });
  };

export const PostVendorDetails =
  (url, param, toast, navigate,setLoader) => async (dispatch) => {
    dispatch({ type: types.POST_VENDOR_DETAILS_LOADING });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    return axios({
      method: "POST",
      url: url,
      data: param,
      headers: headers,
    })
      .then((r) => {
        //(r,"sdakjf")
        setLoader(false)
        dispatch({
          type: types.POST_VENDOR_DETAILS_SUCCESS,
          payload: r.data.partner,
        });
        if (r.data.err) {
          setToast(toast, `${r.data.err}`, "", "error", "top");
        } else {
          setToast(toast, "Vendor Created Successfully", "", "success", "top");
          navigate(`/admin/vendor/${r.data.partner._id}`);
        }
      })
      .catch((err) => {
        setLoader(false)
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
     
        dispatch({ type: types.POST_VENDOR_DETAILS_ERROR });
        setToast(toast, `${err.response.data.err}`, "", "error", "top");
      });
  };

export const UpdateVendorDetails =
  (url, data, toast, newurl) => async (dispatch) => {
    dispatch({ type: types.UPDATE_VENDOR_DETAILS_LOADING });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    return axios({
      method: "PUT",
      url: url,
      data: data,
      headers: headers,
    })
      .then((r) => {
        //(r,"response...................")
        dispatch({ type: types.UPDATE_VENDOR_DETAILS_SUCCESS });
        setToast(
          toast,
          "Vendor Details Updated Successfully",
          "",
          "success",
          "top"
        );
        getVendorSingleData(newurl);
      })
      .catch((e) => {
        dispatch({ type: types.UPDATE_VENDOR_DETAILS_ERROR });
      });
  };

export const DeleteVendorDetails =
  (url, data, toast, navigate) => async (dispatch) => {
    dispatch({ type: types.DELETE_VENDOR_DATA_LOADING });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    return axios({
      method: "DELETE",
      url: url,
      data: data,
      headers: headers,
    })
      .then((r) => {
        dispatch({ type: types.DELETE_VENDOR_DATA_SUCCESS });
        setToast(toast, "Vendor Deleted Successfully", "", "success", "top");
      })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        dispatch({ type: types.DELETE_VENDOR_DATA_ERROR });
      });
  };

// GET CITYS
export const getCitysData = (url, navigate) => async (dispatch) => {
  dispatch({ type: types.GET_ALL_CITYS_DATA_LOADING });
  const headers = {
    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`,
  };
  return axios({
    method: "get",
    withCredentials: false,
    url: url,
    headers: headers,
  })
    .then((r) => {
      dispatch({
        type: types.GET_ALL_CITYS_DATA_SUCCESS,
        payload: r.data.data,
      });
    })
    .catch((err) => {
      if (err.response.data.err === "Invalid token") {
        sessionStorage.removeItem("admintoken");
        navigate("/admin/login");
        return;
      }
      dispatch({ type: types.GET_CITYS_DATA_ERROR });
    });
};

// POST CITY DATA
export const PostCityDetails =
  (url, param, toast, onClose, newurl, navigate) => async (dispatch) => {
    dispatch({ type: types.POST_CITYS_DATA_LOADING });
    // console.log(newurl,"newurl")
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    return axios({
      method: "POST",
      url: url,
      data: param,
      headers: headers,
    })
      .then((r) => {
        dispatch({ type: types.POST_CITYS_DATA_SUCCESS });
        dispatch(getCitysData(newurl));
        setToast(toast, "Data Created Successfully", "", "success", "top");
        onClose();
      })
      .catch((e) => {
        if (e.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        // console.log(e,"err")
        dispatch({ type: types.POST_CITYS_DATA_ERROR, payload: true });
        setToast(
          toast,
          `${e.response.status === 400 ? e.response.data.err : null}`,
          "",
          "error",
          "top"
        );
      });
  };

export const DeleteCitysDetails =
  (url, data, toast, close, newurl, navigate) => async (dispatch) => {
    dispatch({ type: types.DELETE_CITYS_DATA_LOADING });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    return axios({
      method: "DELETE",
      url: url,
      data: data,
      headers: headers,
    })
      .then((r) => {
        dispatch({ type: types.DELETE_CITYS_DATA_SUCCESS });
        setToast(toast, "Data Delete Successfully", "", "success", "top");
        close();
        dispatch(getCitysData(newurl));
      })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        dispatch({ type: types.DELETE_CITYS_DATA_ERROR });
      });
  };

export const GetOrderStatusData =
  (url, data, toast, token,navigate) => async (dispatch) => {
    dispatch({ type: types.POST_VENDOR_DETAILS_DATA_LOADING });
    const headers = {
      Authorization: `Bearer ${JSON.parse(sessionStorage.getItem(token))}`,
    };
    return axios({
      method: "post",
      withCredentials: false,
      url: url,
      data,
      headers,
    })
      .then((r) => {
        dispatch({
          type: types.POST_VENDOR_DETAILS_DATA_SUCCESS,
          payload: r.data.order,
        });
      })
      .catch((err) => {
        console.log(err, "err");
        if (err?.response?.data?.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        dispatch({ type: types.POST_VENDOR_DETAILS_DATA_ERROR });
      });
  };
