import * as types from "./action.types";
const initialstate = {
  order: [],
  singleorder: {},
  isloading: "",
  isError: "",
  order_after_order_editing: null,
  Order_Editing_Added_Product: {},
  Order_Editing_Variantid_Of_Product: "",
  Order_Editing_CityDate_Detail: {},
  Order_Editing_Delivery_Slot: [],
  Selected_TimeSlot: {},
  Order_noteAttr: [],
  shop_future_dates:[]
};

export const OrderReducer = (state = initialstate, { type, payload }) => {
  // console.log(
  //   payload,
  //   "payload*/////////////////////////////////////////////////"
  // );
  switch (type) {
    case types.SET_ORDER_ALLOCATED_FUTURE_FOR_SHOP: {
      return {
        ...state,
        shop_future_dates: payload,
      };
    }
    case types.SET_SELECTED_TIME_SLOT: {
      return {
        ...state,
        Selected_TimeSlot: payload,
      };
    }
    case types.ORDER_EDITING_ORDER_NOTE_ATTR_SUCCESS: {
      return { ...state, Order_noteAttr: payload };
    }
    case types.ORDER_EDITING_DATE_CITY_DATA_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.ORDER_EDITING_DATE_CITY_DATA_ERROR: {
      return {
        ...state,
        isloading: false,
        isError: true,
      };
    }
    case types.ORDER_EDITING_DATE_CITY_DATA_SUCCESS: {
      return {
        ...state,
        Order_Editing_CityDate_Detail: payload,
      };
    }
    case types.ORDER_EDITING_PRODUCT_AND_VARIANTID_ADDED_LOADING: {
      return {
        ...state,
      };
    }
    case types.ORDER_EDITING_PRODUCT_AND_VARIANTID_ADDED_ERROR: {
      return {
        ...state,
        isError: true,
      };
    }
    case types.ORDER_EDITING_PRODUCT_AND_VARIANTID_ADDED_SUCCESS: {
      return {
        ...state,
        Order_Editing_Added_Product: payload[0],
        Order_Editing_Variantid_Of_Product: payload[1],
      };
    }

    case types.ORDER_EDITING_DELIVERY_SLOT_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.ORDER_EDITING_DELIVERY_SLOT_ERROR: {
      return {
        ...state,
        isloading: false,
        isError: true,
      };
    }
    case types.ORDER_EDITING_DELIVERY_SLOT_SUCCESS: {
      return {
        ...state,
        Order_Editing_Delivery_Slot: payload,
      };
    }

    case types.UPDATE_SUBORDER_DATA_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.UPDATE_SUBORDER_DATA_ERROR: {
      return {
        ...state,
        isloading: false,
        isError: true,
      };
    }
    case types.UPDATE_SUBORDER_DATA_SUCCESS: {
      return {
        ...state,
        order_after_order_editing: payload,
      };
    }

    case types.ORDER_EDITING_START_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.ORDER_EDITING_START_SUCCESS: {
      return {
        ...state,
        isloading: false,
        order_after_order_editing: payload,
      };
    }
    case types.ORDER_EDITING_START_ERROR: {
      return {
        ...state,
        isloading: false,
        isError: true,
      };
    }

    case types.GET_ORDER_DATA_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.GET_ORDER_DATA_SUCCESS: {
      return {
        ...state,
        isloading: false,
        order: payload,
      };
    }
    case types.GET_ORDER_DATA_ERROR: {
      return {
        ...state,
        isloading: false,
        isError: true,
      };
    }
    default: {
      return state;
    }
  }
};
