import * as types from "./action.types";
import axios from "axios";
import { setToast } from "../../../../Fucntions/Toastfunction";
import { getVendorSingleData } from "../vendors/action";

export const getData = (url,toast ,navigate) => async (dispatch) => {
  dispatch({ type: types.GET_PRODUCT_DATA_LOADING });
  const headers = {

    "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
  };
  return axios({
    method: "get",
    withCredentials: false,
    url: url,
    headers: headers,
  })
    .then((r) => {
  // console.log(r.data,"r")
  if(r.data.err){
    setToast(toast, `${r.data.err.split(":")[0]}`, "", "error");
    return
  }
      dispatch({
        type: types.GET_PRODUCT_DATA_SUCCESS,
        payload: r.data,
      });
    })
    .catch((err) => {
      if(err.response.data.err==="Invalid token"){
        sessionStorage.removeItem('admintoken');
        navigate("/admin/login")
        return
      }
      dispatch({ type: types.GET_PRODUCT_DATA_ERROR });
      setToast(toast, `${err.response.data.err}`, "", "error");
    });
};

export const getSingleData = (url,toast,navigate) => async (dispatch) => {
  dispatch({ type: types.GET_SINGLE_PRODUCT_DATA_LOADING });
  const headers = {

    "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
  };
  return axios({
    method: "get",
    withCredentials: false,
    url: url,
    headers: headers,
  })
    .then((r) => {
      dispatch({
        type: types.GET_SINGLE_PRODUCT_DATA_SUCCESS,
        payload: r.data.product,
      });
    })
    .catch((err) => {
      if(err.response.data.err==="Invalid token"){
        sessionStorage.removeItem('admintoken');
        navigate("/admin/login")
        return
      } 
      dispatch({ type: types.GET_SINGLE_PRODUCT_DATA_ERROR });
      setToast(toast, `${err.response.data.err}`, "", "error");
    });
};

export const getSearchedData = (url, param) => async (dispatch) => {
  
  dispatch({ type: types.SEARCHED_VENDOR_DATA_LOADING });
  const headers = {
  
    "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
  };
  return axios({
    method: "get",
    withCredentials: false,
    url: url,
    headers: headers,
  })
    .then((r) => {
      dispatch({ type: types.SEARCHED_VENDOR_DATA_SUCCESS, payload: r.data });
    })
    .catch((e) => {
      dispatch({ type: types.SEARCHED_VENDOR_DATA_ERROR });
    });
};


export const updateVendorInProduct = (url, param,toast) => async (dispatch) => {
  dispatch({ type: types.UPDATE_VENDOR_DETAIL_IN_PRODUCT_LOADING });
  const headers = {
    "Content-Type": "application/json",
    "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
  };
  return axios({
    method: "POST",
    url: url,
    data: param,
    headers: headers,
  })
    .then((r) => {
      dispatch({ type: types.UPDATE_VENDOR_DETAIL_IN_PRODUCT_SUCCESS });
      setToast(toast, "Vendor Detail Successfully Updated", "", "success", "top");
      getVendorSingleData()
    })
    .catch((e) => {
      dispatch({ type: types.UPDATE_VENDOR_DETAIL_IN_PRODUCT_ERROR });
    });
};

export const UpdateVariantDetailInProductDetail=(data)=>async(dispatch)=>{
  try{

    dispatch({type:types.UPDATE_VARIANTS_DETAIL_IN_PRODUCTDETAILPAGE_LOADING})
  
    dispatch({type:types.UPDATE_VARIANTS_DETAIL_IN_PRODUCTDETAIL_PAGE_SUCCESS,payload:data})
  }
catch{
  dispatch({type:types.UPDATE_VARIANTS_DETAIL_IN_PRODUCTDETAIL_PAGE_ERROR})
}

}



// export const updateProductInVendor = (url, param,toast) => async (dispatch) => {
//   dispatch({ type: types.UPDATE_PRODUCT_DATA_IN_VENDOR_LOADING });
//   const headers = {
//     "Content-Type": "application/json",
//   };
//   return axios({
//     method: "PUT",
//     url: url,
//     data: param,
//     headers: headers,
//   })
//     .then((r) => {
//       dispatch({ type: types.UPDATE_PRODUCT_DATA_IN_VENDOR_SUCCESS });
//       setToast(toast, "Product Successfully Updated", "", "success", "top");
//       getVendorSingleData();
//     })
//     .catch((e) => {
//       dispatch({ type: types.UPDATE_PRODUCT_DATA_IN_VENDOR_ERROR });
//     });
// };