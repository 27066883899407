import * as types from "./action.types";

const initialstate = {
  PartnerisAuth: JSON.parse(sessionStorage.getItem("partnertoken"))  ? true : false,
  AdminisAuth:  JSON.parse(sessionStorage.getItem("admintoken"))  ? true : false,
  isLoading: false,
  isError: false,
  partnerToken: JSON.parse(sessionStorage.getItem("partnertoken")) || "",
  AdminToken:JSON.parse(sessionStorage.getItem("admintoken")) || "",
  dpisAuth:JSON.parse(sessionStorage.getItem("dptoken")) ? true:false,
  bakerisAuth:JSON.parse(sessionStorage.getItem("bakertoken"))?true:false,
  baker:{},
  partner: {},
  admin: {},
  dp:{}
};              

export const AuthReducer = (state = initialstate, { type, payload }) => {
 
  switch (type) {  

    case types.BAKER_PARTNER_LOGOUT_SUCCESS: {
      return {
        ...state,
        isError: false,
        isLoading: false,
        bakerisAuth:false,
        baker:{}
      };
    }
    case types.BAKER_PARTNER_LOGIN_FAILED: {
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    }
    case types.BAKER_PARTNER_LOGIN_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.BAKER_PARTNER_LOGIN_SUCCESS: {
      return {
        ...state,
        baker: payload?.shop,
        isLoading: false,
        bakerisAuth:true,
        PartnerisAuth: false,
        AdminisAuth: false,
      };
    }
    case types.DELIVERY_PARTNER_LOGOUT_SUCCESS: {
      return {
        ...state,
        isError: false,
        isLoading: false,
        dpisAuth:false,
        dp:{}
      };
    }
    
    case types.DELIVERY_PARTNER_LOGIN_FAILED: {
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    }
    case types.DELIVERY_PARTNER_LOGIN_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.DELIVERY_PARTNER_LOGIN_SUCCESS: {
      return {
        ...state,
        dp: payload?.partner,
        isLoading: false,
        dpisAuth:true,
        PartnerisAuth: false,
        AdminisAuth: false,
      };
    }
  
    case types.PARTNER_LOGIN_FAILED: {
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    }
    case types.PARTNER_LOGIN_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.PARTNER_LOGIN_SUCCESS: {
      return {
        ...state,
        partner: payload?.partner,
        partnerToken: payload?.token,
        isLoading: false,
        PartnerisAuth: true,
        AdminisAuth: false,
      };
    }

    case types.ADMIN_LOGIN_FAILED: {
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    }
    case types.ADMIN_LOGIN_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case types.ADMIN_LOGIN_SUCCESS: {
      return {
        ...state,
        admin: payload?.user,
        isLoading: false,
        PartnerisAuth: false,
        AdminisAuth: true,
      };
    }
    case types.PARTNER_LOGOUT_ERROR: {
        return {
          ...state,
          isError: true,
          isLoading: false,
        };
      }
      case types.PARTNER_LOGOUT_LOADING: {
        return {
          ...state,
          isLoading: true,
        };
      }
    case types.PARTNER_LOGOUT_SUCCESS: {
        return {
          ...state,
          partner:{},
          partnerToken: "",
          isLoading: false,
          PartnerisAuth: false,
          AdminisAuth: false,
        };
      }


      case types.ADMIN_LOGOUT_ERROR: {
        return {
          ...state,
          isError: true,
          isLoading: false,
        };
      }
      case types.ADMIN_LOGOUT_LOADING: {
        return {
          ...state,
          isLoading: true,
        };
      }
    case types.ADMIN_LOGOUT_SUCCESS: {
        return {
          ...state,
          admin:{},
          AdminToken: "",
          isLoading: false,
          PartnerisAuth: false,
          AdminisAuth: false,
        };
      }
    default: {
      return state;
    }
  }
};
