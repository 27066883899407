import axios from "axios";
import React, { useEffect, useState } from "react";
import DpOrderCards from "./DpOrderCards";
import {
  Box,
  Flex,
  Grid,
  Text,
  Checkbox,
  useToast,
  Input,
  Button,
  Menu,
  Heading,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import { FaHome, FaPrint } from "react-icons/fa";
import { setToast } from "../../../Fucntions/Toastfunction";
import ConfirmationModal from "../../../Fucntions/Modals/ConfirmationModal";
import SuborderPopupChallan from "../../../SuborderChallan/SuborderPopupChallan";
import { useNavigate } from "react-router-dom";
import PaginationComp from "../../../Fucntions/Pagination/PaginationComp";

import { CloseIcon, SearchIcon } from "@chakra-ui/icons";

const DpOrdersStatus = ({
  order_status,
  button_action,
  heading,
  selection,
  buttons_line,
  challanshow,
  modal_fn,
  remark,
}) => {
  let id = JSON.parse(sessionStorage.getItem("id")) || null;
  const navigate = useNavigate();
  let [orderData, setOrderData] = useState([]);
  const [page, setPage] = useState(1);
  const toast = useToast();
  const [data_for_outForDelivery, setDataForOutForDelivery] = useState([]);
  const [remark_notes, setRemark_notes] = useState(null);
  const [showsearchButton, setShowSearchButton] = useState(true);
  const [orderSearch, setOrderSearch] = useState("");
  const [dataforAction, setDataforAction] = useState([]);
  let [datatosend, setDataTosend] = useState({
    filter: `${order_status}`,
    search: false,
  });
  const [actionconfirm, setActionConfirm] = useState(false);
  const [modaldata, setModalData] = useState(null);
  const openActionModal = (i) => {
    if (order_status === "out_for_delivery" && i === 0) {
      if (data_for_outForDelivery.some((k) => k.remark === null)) {
        setToast(toast, "Please Write the reason", "", "error");
        return;
      }
    }
    if (dataforAction.length === 0) {
      setToast(toast, "Please Select Any Order First", "", "error");
      return;
    }
    setModalData(i);
    setActionConfirm(true);
  };
  const closeActionModal = () => {
    setActionConfirm(false);
  };

  //  **************************** MODAL FOR ALL CHALLAN *****************************
  const [isAllChallan, setIsAllChallan] = useState(false);
  const openAllChallanModal = () => {
    setIsAllChallan(true);
  };
  const closeAllChallanModal = () => {
    setIsAllChallan(false);
  };

  // ***********************    MOADAL FOR PRINT CHALLAN   ***************************
  const [challan, setChallan] = useState("");
  const [challanstatus, setChallanStatus] = useState(false);
  const openChallanBox = (id) => {
    let d = orderData?.filter((k) => k._id === id);
    setChallan(d);
    setChallanStatus(true);
  };
  const closeChallanBox = () => {
    setChallanStatus(false);
  };

  function handleParentCheckbox(e) {
    if (orderData.length === dataforAction.length) {
      setDataforAction([]);
      setDataForOutForDelivery([]);
    } else {
      const itemarr = orderData.map((order) => order.suborder_id);
      const outForDeliveryArr = orderData.map((item) => {
        return { id: item.suborder_id, remark: null };
      });
      setDataforAction(itemarr);
      setDataForOutForDelivery(outForDeliveryArr);
    }
  }

  function handlechildrenCheckbox(id) {
    if (dataforAction.includes(id)) {
      let dataarr = [...dataforAction];
      dataarr = dataarr.filter((sub_id) => sub_id !== id);
      setDataforAction(dataarr);
      // FOR OUT FOR DELIERY
      let deliveryarr = [...data_for_outForDelivery];
      let finaldata = deliveryarr.filter((k) => k.id !== id);
      setDataForOutForDelivery(finaldata);
    } else {
      let datarr = [...dataforAction];
      datarr.push(id);
      setDataforAction(datarr);
      // FOR OUT FOR DELIERY
      let deliveryarr = [...data_for_outForDelivery];
      deliveryarr.push({ id, remark: null });
      setDataForOutForDelivery(deliveryarr);
    }
  }
  let [buttonLoader, setButtonLoader] = useState(false);
  const ButtontOrderHitApi = () => {
    if (buttonLoader) {
      return;
    }
    setButtonLoader(true);
    let data = {
      partner_id: JSON.parse(sessionStorage.getItem("vendor_id")),
      driver_id: JSON.parse(sessionStorage.getItem("id")),
      status: `${button_action[modaldata]}`,
      suborder_ids: dataforAction,
      remark_notes: data_for_outForDelivery,
    };
    const headers = {
      Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("dptoken"))}`,
    };
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/dp/multiple_update`,
      data,
      headers,
    })
      .then((r) => {
        if (r.data.err) {
          setToast(toast, `${r.data.err}`, "", "error");
        } else {
          setToast(
            toast,
            `${modal_fn[modaldata]?.toast_notification}`,
            "",
            "success"
          );
        }
        // console.log(r.data, "sakjdj");
        setDataforAction([]);
        closeActionModal();
        setButtonLoader(false);
        getOrderData();
      })
      .catch((err) => {
        setButtonLoader(false);
        console.log(err, "err");
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("partnertoken");
          navigate("partner/login");
          return;
        }
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  };
  let [data_loader, setDataLoader] = useState(false);
  function getOrderData() {
    if (data_loader) {
      return;
    }
    setDataLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("dptoken"))}`,
    };
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/dp/orderbystatus`,
      data: {
        status: order_status,
        id,
        page,
      },
      headers,
    })
      .then((r) => {
        // console.log(r.data);
        setOrderData(r.data);
        setDataLoader(false);
      })
      .catch((err) => {
        console.log(err, "err");
        setDataLoader(false);
      });
  }

  useEffect(() => {
    getOrderData();
  }, []);
  return (
    <Box>
      {modal_fn && (
        <ConfirmationModal
          isOpen={actionconfirm}
          onClose={closeActionModal}
          heading={modal_fn[modaldata]?.heading}
          action={modal_fn[modaldata]?.action}
          onClickFunction={ButtontOrderHitApi}
          ConfirmButton={modal_fn[modaldata]?.ConfirmButton}
          color={modal_fn[modaldata]?.color}
          loader={true}
          loading={buttonLoader}
        />
      )}
      <SuborderPopupChallan
        isOpen={isAllChallan}
        challan={orderData}
        onClose={closeAllChallanModal}
      />
      <SuborderPopupChallan
        challan={challan}
        isOpen={challanstatus}
        onClose={closeChallanBox}
      />
      {/* *********************  STICKY PART ********************* */}
      <Flex
        justifyContent="space-between"
        flexDir={{ base: "column", md: "row", lg: "row" }}
        p="10px"
        bg="gray.100"
        mb="20px"
        style={{
          position: "-webkit-sticky",
          position: "sticky",
          top: "50px", // Stick to the top
          zIndex: "100", // Adjust z-index as needed
        }}
      >
        <Box>
          {orderData.length > 0 ? (
            <>
              {selection && (
                <Flex alignItems="left" gap="10px" mb="10px">
                  <Checkbox
                    bg="white"
                    size="lg"
                    height="fit-content"
                    onChange={handleParentCheckbox}
                    isChecked={dataforAction.length === orderData?.length}
                  />
                  <Text>Select All</Text>
                </Flex>
              )}
            </>
          ) : null}
        </Box>

        <Heading
          pt="10px"
          as="h3"
          size="lg"
          fontFamily="NexaText-Trial-Heavy"
          mb="20px"
        >
          {heading}
        </Heading>

        <Flex gap="10px" flexDir="column">
          <Flex gap="10px" justifyContent="flex-end">
            {/* order rejecting acception button */}

            {showsearchButton && (
              <Flex gap="10px">
                {/* <>
                  {challanshow && (

                    <Button
                      bg="#A1BE28"
                      color="white"
                      fontFamily="NexaText-Trial-Heavy"

                      pt="6px"

                      _hover={{
                        bg: "white",
                        color: "#A1BE28",
                        border: "1px solid #A1BE28",
                      }}

                      onClick={openAllChallanModal}
                    >
                      <FaPrint />
                    </Button>
                  )}
                </> */}
                <Flex gap="10px">
                  {buttons_line.map((item, i) => (
                    <div key={i}>
                      <Button
                        bg="#A1BE28"
                        color="white"
                        fontFamily="NexaText-Trial-Heavy"
                        pt="6px"
                        _hover={{
                          bg: "white",
                          color: "#A1BE28",
                          border: "1px solid #A1BE28",
                        }}
                        onClick={() => openActionModal(i)}
                        maxWidth="180px"
                      >
                        {buttonLoader ? <Spinner /> : item}
                      </Button>
                    </div>
                  ))}
                </Flex>
              </Flex>
            )}
            <Flex gap="20px">
              {!showsearchButton && (
                <Input
                  placeholder="search order name"
                  onChange={(e) => setOrderSearch(e.target.value)}
                  width="200px"
                  bg="white"
                  flex="flexEnd"
                />
              )}
              <IconButton
                onClick={() => {
                  setShowSearchButton(!showsearchButton);
                }}
                colorScheme={showsearchButton ? "blue" : "red"}
                aria-label="Search database"
                icon={showsearchButton ? <SearchIcon /> : <CloseIcon />}
              />
            </Flex>
          </Flex>
          <Flex
            justifyContent={{ base: "center", md: "center", lg: "flex-end" }}
            gap="20px"
            flexDirection={{ base: "column", md: "row", lg: "row" }}
          >
            {/* // pagination button */}
          </Flex>
        </Flex>
      </Flex>
      {console.log(orderData,"data")}
      {data_loader ? (
        <Spinner size="xl" marginTop="50px" fontWeight="bold" />
      ) : (
        orderData.length>0?
        <Grid
          templateColumns={{
            base: "repeat(1,1fr)",
            md: "repeat(1,1fr)",
            lg: "repeat(1,1fr",
          }}
          gap="30px"
        >
          {orderData.map((item, i) => (
            <>
              {item.status === order_status && (
                <Box
                  key={i}
                  boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
                  borderRadius="7px"
                  bg="white"
                >
                  <Flex
                    bg="#A9BE79"
                    justifyContent="space-between"
                    p="20px 10px 20px 10px"
                    fontWeight="bold"
                  >
                    <Flex gap="10px" alignItems="center">
                      {selection && (
                        <Checkbox
                          bg="white"
                          size="lg"
                          onChange={() =>
                            handlechildrenCheckbox(item.suborder_id)
                          }
                          height="fit-content"
                          isChecked={dataforAction.includes(item.suborder_id)}
                        />
                      )}

                      <Text alignItems="center">{item.suborder_name}</Text>
                      <Text alignItems="center">({item.suborder_id})</Text>
                    </Flex>
                  </Flex>
                  {item.status === order_status && (
                    <DpOrderCards
                      item={item}
                      selection={false}
                      remark_notes={data_for_outForDelivery}
                      setRemark_notes={setDataForOutForDelivery}
                      remark={
                        dataforAction.includes(item.suborder_id) && remark
                      }
                    />
                  )}

                  <Flex
                    justifyContent="space-between"
                    width="90%"
                    margin="auto"
                    mb="20px"
                  >
                    <></>

                    {challanshow && (
                      <Button onClick={() => openChallanBox(item._id)}>
                        view challan
                      </Button>
                    )}
                  </Flex>
                </Box>
              )}
            </>
          ))}
        </Grid>:<><Text
              fontSize="35px"
              fontWeight="semibold"
              color="red.300"
              mt="130px"
              mb="100px"
            >
              No {heading}
            </Text></>
      )}
      <PaginationComp
        page={page}
        setPage={setPage}
        isDisabledLogic={orderData?.length < 25}
      />
    </Box>
  );
};

export default DpOrdersStatus;
