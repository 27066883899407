export const PARTNER_LOGIN_SUCCESS = "PARTNER_LOGIN_SUCCESS";
export const PARTNER_LOGIN_FAILED = "PARTNER_LOGIN_FAILED";
export const PARTNER_LOGIN_LOADING = "PARTNER_LOGIN_LOADING";

export const PARTNER_LOGOUT_SUCCESS = "PARTNER_LOGOUT_SUCCESS";
export const PARTNER_LOGOUT_ERROR = "PARTNER_LOGOUT_ERROR";
export const PARTNER_LOGOUT_LOADING = "PARTNER_LOGOUT_LOADING";

export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAILED = "ADMIN_LOGIN_FAILED";
export const ADMIN_LOGIN_LOADING = "ADMIN_LOGIN_LOADING";

export const ADMIN_LOGOUT_SUCCESS = "ADMIN_LOGOUT_SUCCESS";
export const ADMIN_LOGOUT_ERROR = "ADMIN_LOGOUT_ERROR";
export const ADMIN_LOGOUT_LOADING = "ADMIN_LOGOUT_LOADING";

export const DELIVERY_PARTNER_LOGIN_SUCCESS = "DELIVERY_PARTNER_LOGIN_SUCCESS";
export const DELIVERY_PARTNER_LOGIN_FAILED = "DELIVERY_PARTNER_LOGIN_FAILED";
export const DELIVERY_PARTNER_LOGIN_LOADING = "DELIVERY_PARTNER_LOGIN_LOADING";

export const DELIVERY_PARTNER_LOGOUT_SUCCESS =  "DELIVERY_PARTNER_LOGOUT_SUCCESS";
export const DELIVERY_PARTNER_LOGOUT_ERROR = "DELIVERY_PARTNER_LOGOUT_ERROR";
export const DELIVERY_PARTNER_LOGOUT_LOADING = "DELIVERY_PARTNER_LOGOUT_LOADING";

export const BAKER_PARTNER_LOGIN_SUCCESS = "BAKER_PARTNER_LOGIN_SUCCESS";
export const BAKER_PARTNER_LOGIN_FAILED = "BAKER_PARTNER_LOGIN_FAILED";
export const BAKER_PARTNER_LOGIN_LOADING = "BAKER_PARTNER_LOGIN_LOADING";

export const BAKER_PARTNER_LOGOUT_SUCCESS =  "BAKER_PARTNER_LOGOUT_SUCCESS";
export const BAKER_PARTNER_LOGOUT_ERROR = "BAKER_PARTNER_LOGOUT_ERROR";
export const BAKER_PARTNER_LOGOUT_LOADING = "BAKER_PARTNER_LOGOUT_LOADING";


