import React from 'react'

import DpOrdersStatus from '../Components/DpOrders/DpOrdersStatus'

const DpDeliveredOrders = () => {
  return (
    <div><DpOrdersStatus
    order_status="fulfilled"
    heading={"Delivered Orders"}
    selection={false}
    challanshow={false}
  button_action={[]}
    buttons_line={[]}  
     modal_fn={[
    
    ]}
    remark={false}
  /></div>

  )
}

export default DpDeliveredOrders