import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  Grid,
  Switch,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import ModalForDateTimeBlocker from "./ModalForDateTimeBlocker";
import { useDispatch } from "react-redux";
import { getShippingMethodsData } from "../../../redux/Admin/AppReducer/Shipping/action";
import { useSelector } from "react-redux";
import { getCitysData } from "../../../redux/Admin/AppReducer/vendors/action";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../Fucntions/Modals/ConfirmationModal";
import { setToast } from "../../../Fucntions/Toastfunction";

const DateTimeBlocker = () => {
  const [itemIdTodelete, setItemIdToDelete] = useState(null);
  const [itemtoEdit, setItemToEdit] = useState(null);
  const [renderComp, setRenderComp] = useState(false);
  const navigate = useNavigate();
  const [dateTimeBlock, setDateTImeBlock] = useState(null); // for storing data of datetime blocker
  const dispatch = useDispatch();
  const toast = useToast();
  // *************************For Opening the Modal of date time blocker *****************************
  const [modaldatetimeblocker, setModalDateTimeBlocker] = useState(false);

  const openDateTimeBlocker = () => {
    setModalDateTimeBlocker(true);
  };

  const closeDateTimeBlocker = () => {
    setModalDateTimeBlocker(false);
  };

  // ********************   FOR EDITING THE MO DATE TIME BLOCKER ********************
  const [editmodalDateTimeBlocker, seteditmodalDateTimeBlocker] =
    useState(false);

  const openEditDateTimeBlocker = (data) => {
    // console.log(data, "data");
    setItemToEdit(data);
    seteditmodalDateTimeBlocker(true);
  };

  const closeEditDateTimeBlocker = () => {
    seteditmodalDateTimeBlocker(false);
  };

  //  ************************   FOO CONFIRMATION MODAL    **********************************
  const [confirmisOpen, setConfirmisOpen] = useState(false);
  const openConfirmModal = (id) => {
    setConfirmisOpen(true);
    setItemIdToDelete(id);
    console.log(id, "id");
  };

  const closeConfirmModal = () => setConfirmisOpen(false);

  const getDateTimeBlock = () => {
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/datetimeblocker/read`,
      headers,
    })
      .then((r) => {
        console.log(r.data);
        setDateTImeBlock(r.data.data);
      })
      .catch((err) => console.log(err));
  };

  function handleSwitchDateBlocker(switchValue, data) {
    console.log(switchValue, "vcal");
    console.log(data, "data");
    let updatedData = { ...data, enabled: switchValue };
    console.log(updatedData, "updateddata");
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/datetimeblocker/update`,
      data: { data: updatedData },
      headers,
    })
      .then(() => {
        setRenderComp(!renderComp);
      })
      .catch((err) => {
        console.log(err, "err");

        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  const [deleteloader, setdeleteLoader] = useState(false);
  function deleteDateTimeBlock() {
    if (deleteloader) {
      return;
    }
    setdeleteLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/datetimeblocker/delete`,
      data: { id: itemIdTodelete },
      headers,
    })
      .then((r) => {
        setToast(toast, "Blocker Deleted Successfully", "", "success");
        setRenderComp(!renderComp);
        setdeleteLoader(false);
        closeConfirmModal();
      })
      .catch((err) => {
        closeConfirmModal();
        setdeleteLoader(false);
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  useEffect(() => {
    getDateTimeBlock();
    dispatch(
      getCitysData(`${process.env.REACT_APP_API_URL}/citygroup/read`, navigate)
    );
    dispatch(
      getShippingMethodsData(`${process.env.REACT_APP_API_URL}/shipping/read`)
    );
  }, [renderComp]);

  return (
    <Box width="100%">
      {/* **************   CREATE MODAL   ****************** */}
      {modaldatetimeblocker && (
        <ModalForDateTimeBlocker
          isOpen={modaldatetimeblocker}
          onClose={closeDateTimeBlocker}
          renderComp={renderComp}
          setRenderComp={setRenderComp}
        />
      )}
      {/* ************* EDIT MODAL************** */}
      {editmodalDateTimeBlocker && (
        <ModalForDateTimeBlocker
          isOpen={editmodalDateTimeBlocker}
          onClose={closeEditDateTimeBlocker}
          renderComp={renderComp}
          setRenderComp={setRenderComp}
          edit={true}
          editableData={itemtoEdit}
        />
      )}
      <ConfirmationModal
        heading="Delete This Blocker"
        action="Are you Sure? You want to Delete this Blocker"
        ConfirmButton="Yes Delete"
        onClickFunction={deleteDateTimeBlock}
        isOpen={confirmisOpen}
        onClose={closeConfirmModal}
        loader={true}
        loading={deleteloader}
      />

      <Heading as="h4" size="lg" fontWeight="medium" mb="20px">
        Date Time Blocker
      </Heading>

      <div
        style={{
          borderRadius: "7px",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          backgroundColor: "white",
        }}
        height="30px"
        width="30px"
      >
        <Flex justifyContent="flex-end" p={{ base: "1", md: "1", lg: "2" }}>
          <Flex gap="10px" fontSize={{ base: "12px", md: "16px", lg: "26px" }}>
            <Button
              colorScheme="teal"
              variant="outline"
              border="2px solid teal"
              fontSize={{ base: "10px", md: "16px", lg: "16px" }}
              onClick={openDateTimeBlocker}
            >
              Create
            </Button>
          </Flex>
        </Flex>
        <hr />
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>
                  <Text fontSize="sm">Time Slots</Text>
                </Th>
                <Th>
                  <Text fontSize="sm">Dates/Days</Text>
                </Th>
                
                <Th>
                  <Text fontSize="sm">Cities</Text>
                </Th>
                <Th>
                  <Text fontSize="sm">Switch</Text>
                </Th>
                <Th>
                  <Text fontSize="sm">Actions</Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {dateTimeBlock?.map((item, i) => (
                <Tr key={i}>
                  <Td>
                    <Grid templateColumns="repeat(2,1fr)" gap="10px">
                      {item.blocked_time_slots?.map((time_slot, i) => (
                        <Badge
                          key={i}
                          fontSize="16px"
                          p="1"
                          width="fit-content"
                        >
                          {time_slot}
                        </Badge>
                      ))}
                    </Grid>
                  </Td>
                  {item.blocked_dates.length>0?<Td>
                    <Grid templateColumns="repeat(1,1fr)" gap="10px">
                      {item.blocked_dates?.map((dates, i) => (
                        <Badge
                          key={i}
                          fontSize="16px"
                          p="1"
                          width="fit-content"
                        >
                          {dates.from.replaceAll("-", "/")} -{" "}
                          {dates.to.replaceAll("-", "/")}
                        </Badge>
                      ))}
                    </Grid>
                  </Td>:
                  <Td>
                    <Grid templateColumns="repeat(2,1fr)" gap="10px">
                      {item.blocked_days?.map((day, i) => (
                        <Badge
                          key={i}
                          fontSize="16px"
                          p="1"
                          width="fit-content"
                        >
                          {day}
                       
                        </Badge>
                      ))}
                    </Grid>
                  </Td>}
                  <Td flex="flexEnd">
                    <Grid templateColumns="repeat(2,1fr)" gap="10px">
                      {item.effective_cities
                        ?.flatMap((k) => k.cities)
                        .map((city, i) => (
                          <Text key={i}>{city}</Text>
                        ))}
                    </Grid>
                  </Td>
                  <Td flex="flexEnd">
                    <Switch
                      size="lg"
                      isChecked={item.enabled}
                      onChange={(e) =>
                        handleSwitchDateBlocker(e.target.checked, item)
                      }
                    />
                  </Td>
                  <Td flex="flexEnd">
                    <Flex gap="10px">
                      <Box _hover={{ cursor: "pointer" }}>
                        <AiFillEdit
                          fontSize="30px"
                          color="blue"
                          onClick={() => openEditDateTimeBlocker(item)}
                        />
                      </Box>
                      <Box _hover={{ cursor: "pointer" }}>
                        <AiFillDelete
                          fontSize="30px"
                          color="red"
                          onClick={() => openConfirmModal(item._id)}
                        />
                      </Box>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );
};

export default DateTimeBlocker;
