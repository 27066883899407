import React from "react";
import { Route, Routes } from "react-router-dom";
import BakerLogin from "../Pages/BakerLogin";
import BakerAuthentication from "../Authentication/BakerAuthentication";
import { BakerHome } from "../Pages/BakerHome";
import Allocated from "../Pages/Allocated";

const BakerRoute = () => {
  return (
    <div>
      <Routes>
        <Route path="/shop/login" element={<BakerLogin />} />
        <Route
          path="/shop"
          element={
            <BakerAuthentication>
              <BakerHome />
            </BakerAuthentication>
          }
        />
        <Route
          path="shop/allocated"
          element={
            <BakerAuthentication>
              <Allocated />
            </BakerAuthentication>
          }
        />
      </Routes>
    </div>
  );
};

export default BakerRoute;
