import { legacy_createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { ProductReducer } from "./Admin/AppReducer/Products/AppReducer";
import { AuthReducer } from "./AuthReducer/AuthReducer";
import { VendorReducer } from "./Admin/AppReducer/vendors/VendorReducer";
import { ShippingReducer } from "./Admin/AppReducer/Shipping/ShipppingReducer";
import { OrderReducer } from "./Admin/AppReducer/orders/OrderReducer";
const rootReducer = combineReducers({
  ProductReducer,
  VendorReducer,
  AuthReducer,
  ShippingReducer,
  OrderReducer,
});

const store = legacy_createStore(rootReducer, applyMiddleware(thunk));

export default store;
