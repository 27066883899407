import * as types from "./action.types";
import axios from "axios";
import { setToast } from "../../Fucntions/Toastfunction";
import { removeTokenCookie } from "../../Fucntions/Cookie/Cookies";

export const PartnerLogin =
  (url, data, toast, nav, navigate) => async (dispatch) => {
    dispatch({ type: types.PARTNER_LOGIN_LOADING });
    return axios({
      method: "POST",
      url: url,
      data: data,
    })
      .then((r) => {
        dispatch({ type: types.PARTNER_LOGIN_SUCCESS, payload: r.data });
        // setTokenCookie("token", r?.data?.token);
        console.log(r.data,"r.data")
        sessionStorage.setItem("partnertoken", JSON.stringify(r.data.token));
        sessionStorage.setItem("id", JSON.stringify(r.data.partner._id));
        if(r.data.partner?.partner_email){
          sessionStorage.setItem(
            "email",
            JSON.stringify(r.data.partner?.partner_email)
          );
        }
        if(r.data.partner?.email){
          sessionStorage.setItem(
            "email",
            JSON.stringify(r.data.partner?.email)
          );
          sessionStorage.setItem(
            "roles",
            JSON.stringify(r.data.partner?.role)
          );
          sessionStorage.setItem(
            "partner_id",
            JSON.stringify(r.data.partner?.partner_id
            )
          );
        }
        sessionStorage.setItem(
          "partner_type",
          JSON.stringify(r.data.type));
        setToast(toast, "Login Successfull", "", "success");
        navigate(nav);
      })
      .catch((err) => {
        dispatch({ type: types.PARTNER_LOGIN_FAILED });
   console.log(err)
   if(err.response.data.err)
         setToast(toast, `${err.response.data.err}`, "", "error");
      });
  };

export const AdminLogin =
  (url, data, toast, nav, navigate) => async (dispatch) => {
    dispatch({ type: types.ADMIN_LOGIN_LOADING });
    return axios({
      method: "POST",
      url: url,
      data: data,
    })
      .then((r) => {
        // console.log(r,"r")
        dispatch({ type: types.ADMIN_LOGIN_SUCCESS, payload: r.data });
        // setTokenCookie("token", r?.data?.token);
        sessionStorage.setItem("admintoken", JSON.stringify(r.data.token));
        sessionStorage.setItem("name", JSON.stringify(r.data.user.name));
        sessionStorage.setItem(
          "username",
          JSON.stringify(r.data.user.username)
        );
        setToast(toast, "Login Successfull", "", "success");
        navigate(nav);
      })
      .catch((err) => {
        console.log(err, "err");
        dispatch({ type: types.ADMIN_LOGIN_FAILED });

        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  };

export const partnerLogOut = (toast) => async (dispatch) => {
  try {
    dispatch({ type: types.PARTNER_LOGOUT_LOADING });
    removeTokenCookie("token");
    sessionStorage.removeItem("partnertoken");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("partner_type");
    sessionStorage.removeItem("partner_id");
    sessionStorage.removeItem("roles");
    dispatch({ type: types.PARTNER_LOGOUT_SUCCESS });
    setToast(toast, "Logout Successfull", "", "success");
  } catch (err) {
    dispatch({ type: types.PARTNER_LOGOUT_ERROR });
  }
};

export const AdminLogOut = (toast) => async (dispatch) => {
  try {
    dispatch({ type: types.ADMIN_LOGOUT_LOADING });
    //  removeTokenCookie("token");
    sessionStorage.removeItem("admintoken");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("name");
    dispatch({ type: types.ADMIN_LOGOUT_SUCCESS });
    setToast(toast, "Logout Successfull", "", "success");
  } catch (err) {
    dispatch({ type: types.ADMIN_LOGOUT_ERROR });
  }
};

export const deliveryPartnerLogin =
  (url, data, toast, nav, navigate) => async (dispatch) => {
    console.log(nav);
    dispatch({ type: types.DELIVERY_PARTNER_LOGIN_LOADING });

    return axios({
      method: "POST",
      url,
      data,
    })
      .then((r) => {
        dispatch({
          type: types.DELIVERY_PARTNER_LOGIN_SUCCESS,
          payload: r.data,
        });
        sessionStorage.setItem("dptoken", JSON.stringify(r.data.token));
        sessionStorage.setItem("id", JSON.stringify(r.data.partner._id));
        sessionStorage.setItem("name", JSON.stringify(r.data.partner.name));
        sessionStorage.setItem("email", JSON.stringify(r.data.partner.email));
        sessionStorage.setItem(
          "vendor_id",
          JSON.stringify(r.data.partner.vendor_id)
        );
        setToast(toast, `Login Successfull`, "", "success");
        navigate(nav);
      })
      .catch((err) => {
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  };

export const dpLogOut = (toast) => async (dispatch) => {
  try {
    dispatch({ type: types.DELIVERY_PARTNER_LOGOUT_LOADING });
    //  removeTokenCookie("token");
    sessionStorage.removeItem("dptoken");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("vendor_id");
    dispatch({ type: types.DELIVERY_PARTNER_LOGOUT_SUCCESS });
    setToast(toast, "Logout Successfull", "", "success");
  } catch (err) {
    dispatch({ type: types.DELIVERY_PARTNER_LOGOUT_ERROR });
  }
};

export const BakerPartnerLogin =
  (url, data, toast, nav, navigate) => async (dispatch) => {
    dispatch({ type: types.BAKER_PARTNER_LOGIN_LOADING });
    return axios({
      method: "POST",
      url,
      data,
    })
      .then((r) => {
        dispatch({ type: types.BAKER_PARTNER_LOGIN_SUCCESS, payload: r.data });
        sessionStorage.setItem("bakertoken", JSON.stringify(r.data.token));
        sessionStorage.setItem("id", JSON.stringify(r.data.shop._id));
        sessionStorage.setItem("name", JSON.stringify(r.data.shop.name));
        sessionStorage.setItem("role", JSON.stringify(r.data.shop.role));
        sessionStorage.setItem(
          "partner_id",
          JSON.stringify(r.data.shop.partner_id)
        );
        setToast(toast, `Login Successfull`, "", "success");
        navigate(nav);
      })
      .catch((err) => {
        setToast(toast, `${err.response.data.err}`, "", "error");
        dispatch({ type: types.BAKER_PARTNER_LOGIN_FAILED });
      });
  };

export const BakerPartnerLogout = (toast,navigate,nav) => async (dispatch) => {
  try {
    dispatch({ type: types.BAKER_PARTNER_LOGOUT_LOADING });
      // removeTokenCookie("token");
    sessionStorage.removeItem("bakertoken");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("partner_id");
    sessionStorage.removeItem("role");
    dispatch({ type: types.BAKER_PARTNER_LOGOUT_SUCCESS });
    setToast(toast, "Logout Successfull", "", "success");
    navigate(nav)
  } catch (err) {
    dispatch({ type: types.BAKER_PARTNER_LOGOUT_ERROR });
  }
};
