import * as types from "./action.types";
import axios from "axios";
import { setToast } from "../../../../Fucntions/Toastfunction";


export const getShippingMethodsData=(url,data)=>async(dispatch)=>{
  
dispatch({type:types.GET_SHIPPING_DATA_LOADING})
const headers = {
  
    "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
  };
  return axios({
    method: "get",
    withCredentials: false,
    url: url,
    headers: headers,
  })
  .then((r)=>{
    dispatch({type:types.GET_SHIPPING_DATA_SUCCESS,payload:r.data.shipping_methods})
  })
  .catch((err)=>{
    dispatch({ type: types.GET_SHIPPING_DATA_ERROR });
  })
}


export const CreateShippingMethodDetails=(url,data,toast,newurl,navigate,onClose,set)=>async(dispatch)=>{
 // console.log(data,"datajsadhfka")
  dispatch({type:types.CREATE_SHIPPING_DATA_LOADING})
  const headers = {
    "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
  };
  return axios({
    method: "POST",
    url: url,
    headers: headers,
    data:data
  })
  .then((r)=>{
    // console.log(r.data,"response data")
    dispatch({type:types.CREATE_SHIPPING_DATA_SUCCESS})
    setToast(toast, "Shipping Method Created Successfully", "", "success", "top");
    dispatch(getShippingMethodsData(newurl))
    navigate(`/admin/shippingmethods`)
    set({
      name:'',
      message:'',
      price:'',
      warning_message:'',
      time_slots:[]
    })
    onClose()
  })
  .catch((err)=>{
    if(err.response.data.err==="Invalid token"){
      sessionStorage.removeItem('admintoken');
      navigate("/admin/login")
      return
    }
    setToast(toast, `${err.response.data.err}`, "", "error", "top");
    dispatch({type:types.CREATE_SHIPPING_DATA_ERROR})
    
  })
}

export const DeleteShippingMethodDetails=(url,data,toast,newurl,close,navigate)=>async(dispatch)=>{
// console.log(url,"url")
// console.log(newurl,"url")
  dispatch({type:types.DELETE_SHIPPING_DATA_LOADING})
  const headers = {
    "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
  };
  let name={
    "name":data
  }
  return axios({
    method: "DELETE",
    url: url,
    data:name,
    headers
  })
  .then((r)=>{
    dispatch({type:types.DELETE_SHIPPING_DATA_SUCCESS})
    setToast(toast, "Shipping Method Deleted ", "", "success", "top");
    dispatch(getShippingMethodsData(newurl))
    close()
  })
  .catch((e)=>{
    if(e.response.data.err==="Invalid token"){
      sessionStorage.removeItem('admintoken');
      navigate("/admin/login")
      return
    }
    // console.log(e)
    setToast(toast, `${e.response.data.err}`, "", "error", "top");
    dispatch({type:types.DELETE_SHIPPING_DATA_ERROR})
  })
}