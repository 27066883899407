import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
  FormControl,
  FormLabel,
  Spinner,
} from "@chakra-ui/react";

const SenderDetailEditModal = ({
  isOpen,
  onClose,
  onClickFn,
  senderdetails,
  setSenderDetails,
  loader
}) => {
  function handleOnChange(e) {
    const { name, value } = e.target;
    setSenderDetails({
      ...senderdetails,
      [name]: value,
    });
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Sender Details</ModalHeader>
        <ModalCloseButton  onClick={onClose}/>
        <ModalBody>
          <FormControl mt="5px">
            <FormLabel>Sender Name</FormLabel>
            <Input
              placeholder="Enter Name"
              name="sender_name"
              onChange={handleOnChange}
              value={senderdetails.sender_name}
            />
          </FormControl>
          <FormControl mt="5px">
            <FormLabel>Sender Phone</FormLabel>
            <Input
              placeholder="Enter Phone"
              name="sender_phone"
              onChange={handleOnChange}
              value={senderdetails.sender_phone}
            />
          </FormControl>
          <FormControl mt="5px">
            <FormLabel>Sender Email</FormLabel>
            <Input
              placeholder="Enter Email"
              name="sender_email"
              onChange={handleOnChange}
              value={senderdetails.sender_email}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={onClickFn}>
            {loader?<Spinner/>:"Save"}
          </Button>
          <Button colorScheme="red" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SenderDetailEditModal;
