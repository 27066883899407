import React from "react";
// import Accordincatlog from "../Components/Accordincatlog";
import { Box, Heading } from "@chakra-ui/react";

const Home = () => {
  return <Box width="100%">
    <Heading>Home</Heading>
  </Box>;
};

export default Home;
