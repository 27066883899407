import React from "react";
import { Box, Flex, IconButton, Image, Text, useToast } from "@chakra-ui/react";

import fnp_logo from "../../images/fnp_logo.jpg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { dpLogOut } from "../../redux/AuthReducer/action";

import { RiLogoutCircleRFill } from "react-icons/ri";

import DpSideDrawer from "./DpSideDrawer";

import { useNavigate } from "react-router-dom";

const DpNavbar = () => {
  const navigate=useNavigate()

    const dispatch = useDispatch();
  const toast = useToast();

  const dpdetails = useSelector((state) => state.AuthReducer);

  function handleLogout() {
    dispatch(dpLogOut(toast));
  }

  return (
    <Flex
      position="sticky"
      top="0"
      zIndex="1002"
      bg="white"
      justifyContent="space-between"
      paddingLeft="20px"
      paddingRight="10px"
    >
      <Flex gap="40px">
        <Box
          onClick={() => navigate(-1)}
          display={{ base: "block", md: "block", lg: "block" }}
        >
          <Image
            src={fnp_logo}
            alt="fnp"
            height="70px"
            width="150px"
            margin="10px auto"
          />
        </Box>
        <Box mt="8px" display={{ base: "none", md: "none", lg: "none" }}>
          <DpSideDrawer />
        </Box>
      </Flex>
      <Flex
        direction={{ base: "column", md: "row", lg: "row" }}
        alignItems="center"
      >
        <Box mr="15px"></Box>
        <Flex gap="10px" alignItems="center">
          {dpdetails?.dpisAuth ? (
            <IconButton
              onClick={handleLogout}
              fontSize={{ base: "12px", md: "16px", lg: "16px" }}
              colorScheme="red"
              icon={
                <RiLogoutCircleRFill
                  style={{
                    fontFamily: "NexaText-Trial-Heavy",
                    fontSize: "30px",
                  }}
                />
              }
            />
          ) : (
            <></>
          )}
          <Flex direction="column" textAlign="left">
            <Text>{JSON.parse(sessionStorage.getItem("name"))}</Text>
            <Text>{JSON.parse(sessionStorage.getItem("email"))}</Text>
            {/* <Text>{JSON.parse(sessionStorage.getItem("id"))}</Text> */}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default DpNavbar