import * as types from "./action.types";
const initialstate = {
  shipping: [],
  isloading: false,
  isError:false,
};

export const ShippingReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
    case types.GET_SHIPPING_DATA_LOADING: {
      return {
        ...state,
        isloading: true,
      };
    }
    case types.GET_SHIPPING_DATA_SUCCESS: {
      return {
        ...state,
        isloading: false,
        shipping: payload,
      };
    }
    case types.GET_SHIPPING_DATA_ERROR: {
      return {
        ...state,
        isError: true,
        isloading: false,
      };
    }
      case types.CREATE_SHIPPING_DATA_SUCCESS: {
        return {
          ...state,
          isloading: false,
        };
      }
      case types.GET_SHIPPING_DATA_ERROR: {
        return {
          ...state,
          isError: true,
          isloading: false,
        };
      }
      case types.DELETE_SHIPPING_DATA_SUCCESS: {
        return {
          ...state,
          isloading: false,
        };
      }
      case types.GET_SHIPPING_DATA_ERROR: {
        return {
          ...state,
          isError: true,
          isloading: false,
        };
      }
    default: {
      return state;
    }
  }
};
