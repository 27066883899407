import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { setToast } from "../../../Fucntions/Toastfunction";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const CreateDeliveryBoy = ({ isOpen, onClose,fetch,setFetch }) => {
  const id=JSON.parse(sessionStorage.getItem("id"))
  const toast = useToast();
  const [deliveryDetail, setDeliveryDetail] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    password:""
  });
  const navigate=useNavigate()
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  function handleCreateDeliveryBoy(e) {
    const { name, value } = e.target;
    if (value.charAt(0) === ' ') {
      // Remove the first character (space)
      e.target.value = value.slice(1);
      return
    }
    setDeliveryDetail({
      ...deliveryDetail,
      [name]: value,
    });
  }

  const [createloader,setCreateLoader]=useState(false)
  function handleDeliveryBoyCreateHitApi() {
    if(createloader){
      return
    }
    setCreateLoader(true)
    setNameError(false);
    setPhoneError(false);

    if (deliveryDetail.name === "") {
      setNameError(true);
    }

    if (deliveryDetail.phone === "") {
      setPhoneError(true);
    }
    for (let key in deliveryDetail) {
      if (deliveryDetail.name === "" || deliveryDetail.phone === "") {
        setToast(toast, "Please Fill All the Required Feilds", "", "error");
        return;
      }
    }
    const headers = {

      "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("partnertoken"))}`
    };
    axios({
      method:"POST",
      url:`${process.env.REACT_APP_API_URL}/partner/dp/create`,
      data:{
        name:deliveryDetail.name,
        email:deliveryDetail.email,
        phone:deliveryDetail.phone,
        vendor_id:id,
        address:deliveryDetail.address,
        password:deliveryDetail.password
      },
      headers
    })
    .then((r)=>{
setCreateLoader(false)
      if(r.data.err){
        setToast(toast, `${r.data.err}`, "", "error");
      }
      if(r.data.success){
        setToast(toast, `${r.data.success}`, "", "success");
        setDeliveryDetail({
          name: "",
          email: "",
          phone: "",
          address: "",
          vendor_id: id,
          password:""
        })
        onClose()
        setFetch(!fetch)
      }
    })
    .catch((err)=>{
      setCreateLoader(false)
            if(err.response.data.err==="Invalid token"){
        sessionStorage.removeItem('partnertoken');
        navigate("partner/login")
        return
      }
      setToast(toast, `${err.response.data.err}`, "", "error");
    })
  }

  return (
    <Modal closeOnOverlayClick={false}
    isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontFamily="NexaText-Trial-bold" color="gray">
          Create New Delivey Partner
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody color="gray.500">
          <FormControl mb="10px" isRequired isInvalid={nameError}>
            <FormLabel>Enter Name</FormLabel>
            <Input
              placeholder="Enter Name"
              name="name"
              onChange={handleCreateDeliveryBoy}
            />
          </FormControl>

          <FormControl mb="10px">
            <FormLabel>Enter Email</FormLabel>
            <Input
              type="email"
              placeholder="Enter Email"
              name="email"
              onChange={handleCreateDeliveryBoy}
            />
          </FormControl>

          <FormControl mb="10px" isRequired isInvalid={nameError}>
            <FormLabel>Enter Phone</FormLabel>
            <Input
              placeholder="Enter Phone"
              name="phone"
              type="number"
              onChange={handleCreateDeliveryBoy}
            />
          </FormControl>

          <FormControl mb="10px">
            <FormLabel>Enter Password</FormLabel>
            <Input
              placeholder="Enter password"
              name="password"
              onChange={handleCreateDeliveryBoy}
            />
          </FormControl>

          <FormControl mb="10px">
            <FormLabel>Enter address</FormLabel>
            <Input
              placeholder="Enter Address"
              name="address"
              onChange={handleCreateDeliveryBoy}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            color="#A1BE28"
            variant="outline"
            border="1px solid #A1BE28"
            mr={3}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            bg="#A1BE28"
            color="white"
            _hover={{
              bg: "white",
              color: "#A1BE28",
              border: "1px solid #A1BE28",
            }}
            onClick={handleDeliveryBoyCreateHitApi}
          >
            {createloader?<Spinner/> :"Create"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateDeliveryBoy;
