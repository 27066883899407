import React from "react";
import SideDrawer from "../Components/SideDrawer";
import { Box, Flex, IconButton, Image, Text, useToast } from "@chakra-ui/react";

import fnp_logo from "../../images/fnp_logo.jpg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AdminLogOut } from "../../redux/AuthReducer/action";

import { RiLogoutCircleRFill } from "react-icons/ri";

const Navbar = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const admindetails = useSelector((state) => state.AuthReducer);

  function handleLogout() {
    dispatch(AdminLogOut(toast));
  }

  return (
    <Flex justifyContent="space-between" paddingLeft="20px" paddingRight="10px">
      <Flex gap="40px">
        <Box display={{ base: "none", md: "block", lg: "block" }}>
          <Image
            src={fnp_logo}
            alt="fnp"
            height="70px"
            width="150px"
            margin="10px auto"
          />
        </Box>
        <Box display={{ base: "block", md: "none", lg: "none" }}>
          <SideDrawer />
        </Box>
      </Flex>
      <Flex
        direction={{ base: "column", md: "row", lg: "row" }}
        alignItems="center"
      >
        <Box mr="15px"></Box>
        <Flex gap="10px" alignItems="center">
          {admindetails?.AdminisAuth ? (
            <IconButton
              onClick={handleLogout}
              fontSize={{ base: "12px", md: "16px", lg: "16px" }}
              colorScheme="red"
              icon={
                <RiLogoutCircleRFill
                  style={{
                    fontFamily: "NexaText-Trial-Heavy",
                    fontSize: "30px",
                  }}
                />
              }
            />
          ) : (
            <></>
          )}
          <Flex direction="column" textAlign="left">
            <Text>{JSON.parse(sessionStorage.getItem("name"))}</Text>
            <Text>{JSON.parse(sessionStorage.getItem("username"))}</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Navbar;
