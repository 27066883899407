import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Box,
  Input,
  Image,
  Button,
  Text,
  InputGroup,
  InputRightElement,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { PartnerLogin } from "../../redux/AuthReducer/action";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import fnp_logo from "../../images/fnp_logo.jpg"

const LoginPage = () => {
    const navigate=useNavigate()
    const location=useLocation()
    const toast=useToast()
    const loginstatus=useSelector((state)=>state.AuthReducer.PartnerisAuth)
    const dispatch=useDispatch()
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [logincred,setLoginCred]=useState({
    "email":"",
    "password":""
  })

  function handlechange(e){
    const {name,value}=e.target;
     setLoginCred({
        ...logincred,
        [name]:value
     })
  }

  const [logLoader,setLoader]=useState(false)
 async function handleLoginSave(){
  if(logLoader){
    return
  }
  setLoader(true)
    let nav="/partner" 
  await  dispatch(PartnerLogin(`${process.env.REACT_APP_API_URL}/partner/login`,logincred,toast,nav,navigate))
   setLoader(false)
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLoginSave()
    }
  };
  // const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
useEffect(()=>{
if(loginstatus){
navigate("/partner")
}
},[])
  return (
    <Box display="flex" justifyContent="center">
      <Box
        boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
        borderRadius="10px"
        width={{ base: "100%", md: "80%", lg: "400px" }}
        p="0px 20px 20px 20px"
        bg="white"
      >
        <Image
          src={fnp_logo}
          alt="fnp"
          height="70px"
          width="150px"
          margin="10px auto"
        />
        <FormControl>
          <FormLabel>User Name</FormLabel>
          <Input
            type="email"
            placeholder="Enter Email"
            name="email"
            onChange={handlechange}
          />
        </FormControl>
        <br />
        <FormControl>
          <FormLabel>Password</FormLabel>
          <InputGroup size="md">
            <Input
              name="password"
              onChange={handlechange}
              pr="4.5rem"
              type={show ? "text" : "password"}
              placeholder="Enter password"
              onKeyDown={handleKeyDown}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Text
          mt="15px"
          textAlign="left"
          color="blue"
          cursor="pointer"
          textDecoration="underline"
          textUnderlineOffset="4px"
          pb="10px"
        >
          Forgot Password
        </Text>
        <Button w="100%" mt="15px" onClick={() => handleLoginSave()}>
          {logLoader ? <Spinner /> : "Partner Login"}
        </Button>
      </Box>
    </Box>
  );
};

export default LoginPage;
