import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const BakerAuthentication = ({ children }) => {
  const isAuth = useSelector((state) => state.AuthReducer.bakerisAuth);
  if (isAuth) {
    return children;
  }
  return <Navigate to="/shop/login" />;
};

export default BakerAuthentication;
