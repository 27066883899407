import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import ModalForProduct from "./ModalForProduct";
import ModalforEditOrder from "./ModalforEditOrder";
import { TbCircleDashed } from "react-icons/tb";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  editOrderData,
  getOrderData,
} from "../../../../redux/Admin/AppReducer/orders/action";
import ModalForAdjustQty from "./ModalForAdjustQty";
import ModalforAddCustomItem from "./ModalforAddCustomItem";

const EditSubOrder = () => {
  const [reasonforEdit,setReasonForedit]=useState("")
  const[finalprice,setFinalPrice]=useState(0)
  const [Shippingprice,setShippingPrice]=useState(0)
  const [updatebuttonenable, setUpdatebuttonenable] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const suborder = useSelector((state) => state.OrderReducer.order);


  // ****************/   FOR THE MODAL TO ADD CUSTOM ITEM  **********************
  const [AddcustomItemmodal,setAddcustomItemmodal]=useState(false)
  const openAddCustomItemModal=()=>setAddcustomItemmodal(true)
  const closeAddCustomItemModal=()=>setAddcustomItemmodal(false)

  // ****************   FOR THE MODAL OF ADJUST QUANTITY      *****************************************
  const [objForAdjQtyInModal, setobjForAdjQtyInModal] = useState({});
  const [adjustqtymodalstatus, setAdjustqtymodalstatus] = useState(false);
  const openAdjustQtyModal = (item) => {
    setAdjustqtymodalstatus(true);
    setobjForAdjQtyInModal(item);
    setUpdatebuttonenable(true);
  };
  const closeAdjustQtyModal = () => setAdjustqtymodalstatus(false);

  const [searchprodinput, setsearchprodinput] = useState(""); // Input for searching the product
  //  for Operating search product Modal
  const [productModalOpen, setProductModalOpen] = useState(false);
  const OnopenModalFunction = () => setProductModalOpen(true);
  const OnCloseModalFunction = () => {
    setProductModalOpen(false);
    setsearchprodinput("");
  };

  //  For Opearting Edit Order Modal
  const [ordermodalopen, setOrderModalOpen] = useState(false);
  const OnOpenOrderModal = () => setOrderModalOpen(true);
  const onCloseOrderModal = () => setOrderModalOpen(false);

  const handleRemoveitem = (obj) => {
    if (obj) {
      let newsuborder = JSON.parse(JSON.stringify(suborder));
      for (let i = 0; i < newsuborder?.line_items.length; i++) {
        if (newsuborder.line_items[i].id === obj.id) {
          newsuborder.line_items[i].quantity = 0;
        }
      }
      dispatch(editOrderData(newsuborder));
      setUpdatebuttonenable(true);
    }
  };

  function calculateTotalPrice(){
    let arr=suborder.line_items
    arr=arr?.map((item)=>(item.quantity)* (+(item.price)))

    let num=arr?.reduce((acc,curr)=>acc+curr,0)
  
    setFinalPrice(num)
  }
  
 // console.log(suborder,"**************************************")
 
  useEffect(() => {
    if (searchprodinput.length > 0) {
      OnopenModalFunction();
    }
  }, [searchprodinput]);

  useEffect(() => {
    dispatch(
      getOrderData(`${process.env.REACT_APP_API_URL}/suborder/read/${id}`,navigate)
    );
  }, []);

  useEffect(()=>{
    calculateTotalPrice()
  },[suborder])
  
  //  console.log(clonedsuborder,"clone")
  //  console.log(suborder, "order");

  return (
    <Box width="80%" margin="auto">
      {/* *******************  Hedaing And Button ********************** */}
      <Flex gap="20px">
        <Button
          onClick={() => navigate(-1)}
          variant="outline"
          padding="10px"
          color="black"
          border="1px solid grey"
        >
          <MdKeyboardBackspace fontSize="30px" />
        </Button>
        <Heading as="h4" size="lg" fontWeight="medium">
          Edit SubOrder
        </Heading>
      </Flex>

      {/* ********************************   FLEX WHICH CONTAIN ALL BOXES    ******************************* */}
      <Flex mt="2rem" justifyContent="space-between">
        {/* **********************************   LEFT SIDE BOX   ******************************** */}
        <Box width="65%">
          {/* **********************************   UNFULLFILLED BOX   ******************************** */}
          <Box
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            backgroundColor="white"
            borderRadius="7px"
            p="3"
            mb="20px"
            textAlign="left"
          >
            <Flex justifyContent="space-between">
            <Heading
              as="h4"
              size="md"
              fontFamily="NexaText-Trial-Bold"
              mb="15px"
            >
              Add Products
            </Heading>
            <Text color="blue"
            onClick={openAddCustomItemModal}
            _hover={{ cursor: "pointer",textDecoration:"underline" }}>Add Custom item</Text>
            </Flex>
            {/* ********************************   MODAL FOR SEARCHING PRODUCTS  ***************************************** */}
            <ModalForProduct
              isOpen={productModalOpen}
              onOpen={OnopenModalFunction}
              onClose={OnCloseModalFunction}
              searchprodinput={searchprodinput}
              
            />

            {/* *************************     MODAL FOR EDITING ORDER   ************************************* */}
            <ModalforEditOrder
              isOpen={ordermodalopen}
              onClose={onCloseOrderModal}
            />

            {/* *************************     MODAL FOR ADJUSTING QUANTITY OF A ORDER   ************************************* */}

            <ModalForAdjustQty
              isOpen={adjustqtymodalstatus}
              onClose={closeAdjustQtyModal}
              prod={objForAdjQtyInModal}
            />

            {/* *********************   MODAL FOR ADD CUSTOM ITEM *********************** */}
            <ModalforAddCustomItem
            isOpen={AddcustomItemmodal}
            onClose={closeAddCustomItemModal}/>
            <Flex gap="20px">
              <InputGroup size="md">
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon color="gray.500" />}
                />
                <Input
                  pr="4.5rem"
                  placeholder="Search Products"
                  onChange={(e) => setsearchprodinput(e.target.value)}
                  value={searchprodinput}
                />
              </InputGroup>
              <Button variant="outline" onClick={OnopenModalFunction}>
                Browse
              </Button>
            </Flex>
            <br />
            <Divider />
            <br />
            <Flex gap="15px" mb="10px">
              <Box
                height="fit-content"
                border="5px solid #FAC9A8"
                borderRadius="50%"
              >
                <TbCircleDashed fontSize="20px" color="#FAC9A9" />
              </Box>
              <Heading
                as="h5"
                size="md"
                fontFamily="NexaText-Trial-Bold"
                mb="15px"
                mt="5px"
              >
                Unfulfilled
              </Heading>
            </Flex>

            {suborder?.line_items?.map((item, i) => (
              <Box key={i} mb="30px">
                <Flex justifyContent="space-between" mb="10px">
                  <Flex direction="column" fontSize="14px" mt="-5px">
                    <Text fontWeight="semibold">{item.name}</Text>
                    <Text color="gray.500">SKU: {item?.sku}</Text>
                    <Text>
                      {item.quantity} X QAR {item.price}
                    </Text>
                  </Flex>

                  <Text>QAR {+(item?.quantity) * +(item?.price)}</Text>
                </Flex>
                <Flex gap="15px" color="blue">
                  <Text
                    _hover={{ cursor: "pointer" }}
                    onClick={() => {
                      openAdjustQtyModal(item);
                    }}
                  >
                    Adjust Quantity
                  </Text>
                  <Text
                    _hover={{ cursor: "pointer" }}
                    onClick={()=>handleRemoveitem(item)}
                  >
                    Remove Item
                  </Text>
                  <Text
                    _hover={{ cursor: "pointer" }}
                    onClick={OnOpenOrderModal}
                  >
                    Add more Detail
                  </Text>
                </Flex>
                
              </Box>
            ))}
          </Box>

          {/* **********************************   PAYMENT BOX   ******************************** */}
          <Box
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            backgroundColor="white"
            borderRadius="7px"
            p="3"
            mb="20px"
            textAlign="left"
          >
            <Heading
              as="h4"
              size="md"
              fontFamily="NexaText-Trial-Bold"
              mb="15px"
              mt="15px"
            >
              Payment
            </Heading>
            <Flex justifyContent="space-between" mb="15px">
              <Text>Subtotal</Text>
              <Text>QAR {finalprice}</Text>
            </Flex>
            <Flex justifyContent="space-between" mb="15px">
              <Text>Shipping</Text>
              <Text>QAR {Shippingprice}.00</Text>
            </Flex>
            <Flex fontWeight="semibold"
            justifyContent="space-between" mb="15px">
              <Text>Total</Text>
              <Text>QAR {Shippingprice+finalprice}</Text>
            </Flex>
            <Divider />
            <Flex mt="15px" justifyContent="space-between" mb="15px">
              <Text>Paid by customer</Text>
              <Text>QAR 789.27</Text>
            </Flex>
          </Box>

{/* ***************************  REASON FOR EDIT MODAL   ************************************* */}
          <Box
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            backgroundColor="white"
            borderRadius="7px"
            p="3"
            mb="20px"
            textAlign="left"
          >
            <Heading
              as="h4"
              size="md"
              fontFamily="NexaText-Trial-Bold"
              mb="15px"
              mt="15px"
            >
              Reason for edit
            </Heading>
            <Input onChange={(e)=>setReasonForedit(e.target.value)}/>
           <Text color="gray" mt="10px"
           >Only you and other staff can see this reason.</Text>
          </Box>
        </Box>

        

        {/* ***********************************************   RIGHT SIDE BOX  ************************************** */}
        <Box width="30%">
          {/* ***********************************************    UPDATE ORDER BUTTON  ************************************** */}
          <Box
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            backgroundColor="white"
            borderRadius="7px"
            p="3"
            mb="20px"
            textAlign="left"
          >
            <Button width="100%" isDisabled={!updatebuttonenable  || reasonforEdit===""}>
              Update Order
            </Button>
          </Box>
          {/* <Box
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            backgroundColor="white"
            borderRadius="7px"
            p="3"
            mb="20px"
            textAlign="left"
          ></Box> */}
        </Box>
      </Flex>
    </Box>
  );
};

export default EditSubOrder;
