import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Spinner,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { setToast } from "../../../Fucntions/Toastfunction";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const EditShopDetails = ({ isOpen, onClose, fetch, setFetch ,detailToBeEdit}) => {
  const id = JSON.parse(sessionStorage.getItem("id"));
  const toast = useToast();
  const [role, setRole] = useState(null);
  const [shopdetails, setShopDetails] = useState({
    name: "",
    phone: "",
    role,
    password: "",
    confirm_password: "",
    created_at: new Date(),
  });
  const navigate = useNavigate();
  console.log(shopdetails, "details");
  console.log(role, "role");
  function handleCreateShops(e) {
    const { name, value } = e.target;
    if (value.charAt(0) === " ") {
      // Remove the first character (space)
      e.target.value = value.slice(1);
      return;
    }
    setShopDetails({
      ...shopdetails,
      [name]: value,
    });
  }

  const [createloader, setCreateLoader] = useState(false);
  function handleShopCreateHitApi() {
  
    if (createloader) {
      return;
    }
    setCreateLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("partnertoken")
      )}`,
    };
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/baker/update`,
      data: {
        name: shopdetails.name,
        role: shopdetails.role,
        phone: shopdetails.phone,
        partner_id: id,
        id:detailToBeEdit._id
      },
      headers,
    })
      .then((r) => {
        console.log(r.data);
        setCreateLoader(false);
        if (r.data.err) {
          setToast(toast, `${r.data.err}`, "", "error");
        }
        if (r.data.success) {
          setToast(toast, `${r.data.success}`, "", "success");
          onClose();
          setFetch(!fetch);
        }
        setShopDetails({
          name: "",
          phone: "",
          role,
          password: "",
          confirm_password: "",
          created_at: new Date(),
        });
        setRole(null);
      })
      .catch((err) => {
        setCreateLoader(false);
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("partnertoken");
          navigate("partner/login");
          return;
        }
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }
 console.log(shopdetails,"edit++++++++++++++")
  useEffect(()=>{
  let obj={...detailToBeEdit}
  setShopDetails(obj)
  },[detailToBeEdit])
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontFamily="NexaText-Trial-bold" color="gray">
          Edit Shops
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody color="gray.500">
          <FormControl mb="10px">
            <FormLabel>Enter Name</FormLabel>
            <Input
            value={shopdetails.name}
              placeholder="Enter Name"
              name="name"
              onChange={handleCreateShops}
            />
          </FormControl>

          <FormControl mb="10px">
            <FormLabel>Enter Phone</FormLabel>
            <Input
                     value={shopdetails.phone}
              placeholder="Enter Phone"
              name="phone"
              type="number"
              onChange={handleCreateShops}
            />
          </FormControl>
          <FormControl mb="10px">
            <FormLabel>Role </FormLabel>
            <RadioGroup
              onChange={(e) => {
                console.log(e);
                setRole(e);
                let obj = { ...shopdetails };
                obj.role = e;
                setShopDetails(obj);
              }}
              value={role || shopdetails.role}
            >
              <Stack direction="row" gap="20px">
                <Radio value="cakes" size="lg">
                  Bakery
                </Radio>
                <Radio value="mixed_types" size="lg">
                  Non Bakery
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            color="#A1BE28"
            variant="outline"
            border="1px solid #A1BE28"
            mr={3}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            bg="#A1BE28"
            color="white"
            _hover={{
              bg: "white",
              color: "#A1BE28",
              border: "1px solid #A1BE28",
            }}
            onClick={handleShopCreateHitApi}
          >
            {createloader ? <Spinner /> : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditShopDetails ;
