import React, { useState } from "react";
import OrderStatusComp from "./OrderStatusComp";
import { Text } from "@chakra-ui/react";

const DeliveredOrders = () => {
  let [policy, setPolicy] = useState([]);
  let [buttons_name, setButtonname] = useState([]);
  let [button_modal, setButtonModal] = useState([]);
  return (
    <div>
 
      <OrderStatusComp
        order_status={"delivered_orders"}
      
       heading={"Delivered Orders"}
        selection={true}
       
        challanshow={false}
        status={"fulfilled"}
        // button_action={["out_for_delivery"]}
        // buttons_line={["Out For Delivery"]}
        // modal_fn={[
        //   {
        //     heading: "Out For Delivery Order",
        //     action:
        //       "Are You Sure You want to Mark This order As Out For Delivery",
        //     ConfirmButton: "Mark As out For Delivery",
        //     color: "teal",
        //     toast_notification: "Order Out For Delivery",
        //   },
        // ]}
        button_action={policy}
        buttons_line={buttons_name}
        modal_fn={button_modal}
        setPolicy={setPolicy}
        setButtonModal={setButtonModal}
        setButtonname={setButtonname}
        policy={policy}
        buttons_name={buttons_name}
        button_modal={button_modal}
      />
    </div>
  );
};

export default DeliveredOrders;
