export const removeTokenCookie = (key) => {
  document.cookie = `${key}=""; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
  };
  
  // Example usage
  export const setTokenCookie = (key,value) => {
    // const expirationDate = new Date();
    // expirationDate.setTime(expirationDate.getTime() + (15 * 60 * 60 * 1000)); // 15 hours
  
    document.cookie = `${key}=${value};`;
  };

 export  function getTokenFromCookie(value) {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
  
      // Check if the cookie starts with 'token='
      if (cookie.startsWith(`${value}=`)) {
        // Extract and return the token value
        return (cookie.substring(`${value}=`.length));
      }
    }
    // Token not found in the cookies
    return "";
  };