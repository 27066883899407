import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Text,
  Flex,
  Box,
  Grid,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { setToast } from "../../../../Fucntions/Toastfunction";
import axios from "axios";

const RolesModal = ({
  onClose,
  isOpen,
  title,
  roleDetail,
  setRoleDetail,
  render,
  edit,
  setRender,
}) => {
  const toast = useToast();
  const headers = {
    Authorization: `Bearer ${JSON.parse(
      sessionStorage.getItem("partnertoken")
    )}`,
  };
  let initial_role_detail = {
    name: "",
    description: "",
    policy: [
      {
        status: "allocated",
        access: [],
      },
      {
        status: "accepted",
        access: [],
      },
      {
        status: "printed",
        access: [],
      },
      {
        status: "rejected",
        access: [],
      },
      {
        status: "driver_allocated",
        access: [],
      },

      {
        status: "out_for_delivery",
        access: [],
      },
      {
        status: "fulfilled",
        access: [],
      },
      {
        status: "attempted",
        access: [],
      },
    ],
  };

  // **********************   ALL STATUS TO MAP ***************************
  let Status = [
    "allocated",
    "accepted",
    "rejected",
    "printed",
    "driver_allocated",
    "out_for_delivery",
    "fulfilled",
    "attempted",
  ];

  // **********************   FOR INPUT OF ROLES ***************************
  function handleChangeRole(e, status) {
    if (e.target.name === "name" || e.target.name === "description") {
      setRoleDetail({
        ...roleDetail,
        [e.target.name]: e.target.value,
      });
    } else {
      let arr = [...roleDetail.policy];
      let arrtomodify = arr.filter((k) => k.status === status);
      let arrnottomodify = arr.filter((k) => k.status !== status);
      if (arrtomodify[0].access.includes(e.target.value)) {
        arrtomodify[0].access = arrtomodify[0].access.filter(
          (k) => k !== e.target.value
        );
      } else {
        arrtomodify[0].access.push(e.target.value);
      }
      let finalarr = [...arrnottomodify, ...arrtomodify];
      setRoleDetail({
        ...roleDetail,
        policy: finalarr,
      });
    }
    console.log(e.target.name);
  }

  // **********************   FOR CAPATILIZING WORD ***************************
  function convertFirstLetterInCpaital(word) {
    if (word === "fulfilled") {
      word = "delivered";
    }
    word = word.split("");
    word[0] = word[0].toUpperCase();
    word = word.join("").replaceAll("_", " ");
    return word;
  }

  // **********************   FOR EDITING ROLES ***************************
  const [editloader, setEditLoader] = useState(false);
  function handleEditRoles() {
    if (editloader) {
      return;
    }
    setEditLoader(true);
    if (!roleDetail.name) {
      setToast(toast, "Please Write Name Of the Role", "", "error");
      return;
    }
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/partner/role/edit`,
      data: roleDetail,
      headers,
    })
      .then((r) => {
        console.log(r.data);
        setToast(toast, `Role Edited Successfully`, "", "success");
        onClose();
        setRender(!render);
        setRoleDetail(initial_role_detail);
        setEditLoader(false);
      })
      .catch((err) => {
        if (typeof err.response.data.err === "object") {
          setToast(
            toast,
            `${err.response.data.err.keyValue.name} is already present`,
            "",
            "error"
          );
        } else {
          setToast(toast, `${err.response.data.err}`, "", "error");
        }
        console.log(err);
        setEditLoader(false);
      });
  }

  // **********************   FOR SAVING ROLES ***************************
  const [saveloader, setSaveLoader] = useState(false);
  function handleSaveRoles() {
    if (saveloader) {
      return;
    }

    setSaveLoader(true);
    if (!roleDetail.name) {
      setToast(toast, "Please Write Name Of the Role", "", "error");
      return;
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/partner/role/create`,
      data: roleDetail,
      headers,
    })
      .then((r) => {
        console.log(r.data);
        setToast(toast, `Role Created Successfully`, "", "success");
        onClose();
        setRender(!render);
        setRoleDetail(initial_role_detail);
        setSaveLoader(false);
      })
      .catch((err) => {
        if (typeof err.response.data.err === "object") {
          setToast(
            toast,
            `${err.response.data.err.keyValue.name} is already present`,
            "",
            "error"
          );
        } else {
          setToast(toast, `${err.response.data.err}`, "", "error");
        }
        console.log(err);
        setSaveLoader(false);
      });
  }
  return (
    <div>
      {" "}
      <Modal onClose={onClose} size={"2xl"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH={"80vh"} overflow="auto">
            <FormControl mb="10px">
              <FormLabel>Role Name</FormLabel>
              <Input
                name="name"
                placeholder="Enter Role"
                onChange={(e) => handleChangeRole(e)}
                value={roleDetail.name}
              />
            </FormControl>

            <FormControl mb="10px">
              <FormLabel>Description</FormLabel>
              <Input
                name="description"
                placeholder="Enter description of the Role"
                onChange={(e) => handleChangeRole(e)}
                value={roleDetail.description}
              />
            </FormControl>
            <FormLabel mt={"20px"}>Select Switching Control</FormLabel>
            <Box mt="20px">
              <Flex justifyContent="space-between">
                <Text fontWeight="bold" flex="1" textAlign="left">
                  From
                </Text>
                <Box flex="3">
                  <Text fontWeight="bold" textAlign="left" ml="10px">
                    To
                  </Text>
                </Box>
              </Flex>
              {Status.map((item) => (
                <Box >
                  <Flex alignItems="center" gap="40px">
                    <Text fontWeight="semibold">
                      {convertFirstLetterInCpaital(item)}
                    </Text>
                    <Grid templateColumns="repeat(3,1fr)" width="100%" gap="10px" padding="10px" >
                      {Status.map(
                        (state) =>
                          item !== state && (
                            <Flex alignItems="center" gap="6px" justifyContent="start">
                              <Checkbox
                                onChange={(e) => handleChangeRole(e, item)}
                                value={state}
                                isChecked={roleDetail.policy
                                  .find((k) => k.status === item)
                                  ?.access.includes(state)}
                              />
                              <Text whiteSpace="nowrap">{convertFirstLetterInCpaital(state)}</Text>
                            </Flex>
                          )
                      )}
                    </Grid>
                  </Flex>
                  <Divider />
                </Box>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Flex gap="20px">
              {edit ? (
                <Button colorScheme="green" onClick={handleEditRoles}>
                  Edit
                </Button>
              ) : (
                <Button colorScheme="green" onClick={handleSaveRoles}>
                  Save
                </Button>
              )}
              <Button onClick={onClose}>Close</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default RolesModal;
