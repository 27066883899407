import { AiOutlineUserAdd } from "react-icons/ai";
import {
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Flex,
  FormLabel,
  Grid,
  Heading,
  IconButton,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { EditIcon } from "@chakra-ui/icons";
import { FaUserCircle } from "react-icons/fa";
import CreateDeliveryBoy from "./CreateDeliveryBoy";
import axios from "axios";
import { setToast } from "../../../Fucntions/Toastfunction";
import ConfirmationModal from "../../../Fucntions/Modals/ConfirmationModal";
import EditDeliveryBoy from "./EditDeliveryBoy";
import { useNavigate } from "react-router-dom";
import ChangePasswordDeliveryBoy from "./ChangePasswordDeliveryBoy";

const DeliveryBoy = () => {
  const [confirmatiOnOpen, setConfirmationOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const [editedPhone, setEditedPhone] = useState("");
  // const [editdpDetail, setEditdpDetail] = useState(false);
  const [deliveryBoys, setDeliveryBoys] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fetch, setFetch] = useState(false);
  const toast = useToast();
  const [check, setcheck] = useState([]);
  // const [indexforfilter, setIndexForFilter] = useState("");
  const [editModal, setEditModal] = useState(false);
  const id = JSON.parse(sessionStorage.getItem("id"));
  const [detailToBeEdit, setDetailToBeEdit] = useState({});
  const navigate = useNavigate();

  const [changedppasswordmodal, setChangedppasswordmodal] = useState(false);
  const [dp_id, setDp_id] = useState(null);
  const openChangePasswordModal = (id) => {
    setDp_id(id);
    setChangedppasswordmodal(true);
  };
  const closeChangePasswordModal = () => {
    setChangedppasswordmodal(false);
  };

  const OpenEditModal = (item) => {
    setEditModal(true);
    setDetailToBeEdit(item);
  };

  const CloseEditModal = () => {
    setEditModal(false);
  };
  const openModal = (phone) => {
    setConfirmationOpen(true);
    setPhone(phone);
  };

  const closeModal = () => {
    setConfirmationOpen(false);
  };

  function handleDeliverySelect(i) {
    if (check.includes(i)) {
      let arr = [];
      setcheck(arr);
    } else {
      let arr = [];
      arr.push(i);
      setcheck(arr);
    }
  }
  
  const [loader, setLoader] = useState(false);
  const fetchDeliveryBoysData = async () => {
    if (loader) return;
    setLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("partnertoken")
      )}`,
    };
    try {
      let data = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/partner/dp/read?partner=${id}`,
        headers,
      });
      setDeliveryBoys(data.data.data);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      if (err.response.data.err === "Invalid token") {
        sessionStorage.removeItem("partnertoken");
        navigate("partner/login");
        return;
      }
      setToast(toast, `${err.response.data.err}`, "", "error");
    }
  };

  const [delloader, setDelLoader] = useState(false);
  function handleDeletedpApiHit() {
    if (delloader) return;
    setDelLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("partnertoken")
      )}`,
    };
    axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/partner/dp/delete`,
      data: {
        phone,
        vendor_id: id,
      },
      headers,
    })
      .then((r) => {
        setDelLoader(false);
        if (r.data.error) {
          setToast(toast, `${r.data.error}`, "", "error");
        }
        if (r.data.success) {
          setToast(toast, `${r.data.success}`, "", "success");
          closeModal();
          setFetch(!fetch);
        }
      })
      .catch((err) => {
        setDelLoader(false);
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("partnertoken");
          navigate("partner/login");
          return;
        }
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  useEffect(() => {
    fetchDeliveryBoysData();
  }, [fetch]);

  return (
    <>
      <ChangePasswordDeliveryBoy
        isOpen={changedppasswordmodal}
        onClose={closeChangePasswordModal}
        id={dp_id}
      />
      {loader ? (
        <Spinner size="xl" mt="30px" />
      ) : (
        <>
          <Heading
            textAlign="center"
            fontWeight="semibold"
            marginTop="-3"
            mb="20px"
          >
            Delivery Boys
          </Heading>
          <Flex mb="20px" justifyContent="space-between">
            <Button
              leftIcon={<AiOutlineUserAdd fontSize="20px" />}
              fontWeight="semibold"
              // colorScheme="#A1BE28"
              bg="#A1BE28"
              color="white"
              _hover={{
                bg: "white",
                color: "#A1BE28",
                border: "1px solid #A1BE28",
              }}
              onClick={onOpen}
              fontSize={{ base: "12px", md: "16px", lg: "16px" }}
            >
              Add Delivery Boy
            </Button>
            <CreateDeliveryBoy
              isOpen={isOpen}
              onClose={onClose}
              setFetch={setFetch}
              fetch={fetch}
            />
          </Flex>
          {deliveryBoys.length > 0 ? (
            <Grid
              width="100%"
              templateColumns={[
                "repeat(1,1fr)",
                "repeat(2,1fr)",
                "repeat(2,1fr)",
                "repeat(2,1fr)",
                "repeat(3,1fr)",
              ]}
            >
              {deliveryBoys.map((item, i) => (
                <FormLabel key={i}>
                  <Card
                    overflow="hidden"
                    width="330px"
                    margin="auto"
                    _hover={{
                      cursor: "pointer",
                      transition: "0.3s",
                      transform: "scale(1.03)",
                      boxShadow: "0 0 5px gray",
                    }}
                  >
                    <CardBody>
                      <Flex mt="-10px" justifyContent="space-between">
                        <Box></Box>
                        <EditDeliveryBoy
                          setFetch={setFetch}
                          detailToBeEdit={detailToBeEdit}
                          setDetailToBeEdit={setDetailToBeEdit}
                          isOpen={editModal}
                          onClose={CloseEditModal}
                          fetch={fetch}
                        />

                        <IconButton
                          onClick={() => OpenEditModal(item)}
                          variant="outline"
                          border="1px solid white"
                          icon={<EditIcon fontSize="25px" color="gray" />}
                        />
                      </Flex>
                      <Flex
                        justifyContent="space-between"
                        p="0px 10px 0px 10px"
                      >
                        <FaUserCircle fontSize="80px" color="lightgray" />
                        <Flex
                          flexDirection="column"
                          gap="5px"
                          justifyContent="center"
                        >
                          <Text
                            fontSize="2xl"
                            fontWeight=""
                            fontFamily="NexaText-Trial-Heavy"
                            color="#7D8035"
                          >
                            {item.name}
                          </Text>{" "}
                          <Text
                            fontSize="md"
                            fontFamily="NexaText-Trial-Regular"
                            color="gray.400"
                          >
                            {item.phone}
                          </Text>
                          <Text
                            fontSize="md"
                            fontFamily="NexaText-Trial-Regular"
                            color="gray.400"
                          >
                            {item.email}
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex gap="15px" justifyContent="center" mt="15px">
                        <Button
                          variant="outline"
                          color="#A1BE28"
                          border="1px solid #A1BE28"
                          _hover={{ bg: "#A1BE28", color: "white" }}
                          onClick={() => openChangePasswordModal(item._id)}
                        >
                          Change Password
                        </Button>
                        <Button
                          variant="outline"
                          color="#A1BE28"
                          border="1px solid #A1BE28"
                          onClick={() => openModal(item.phone)}
                        >
                          Delete User
                        </Button>
                      </Flex>
                    </CardBody>
                  </Card>
                </FormLabel>
              ))}
            </Grid>
          ) : (
            <Heading
              textAlign="center"
              fontWeight="semibold"
              marginTop="-3"
              mb="20px"
              mt="100px"
              color="red.400"
            >
              No Delivery Boys Yet
            </Heading>
          )}

          <ConfirmationModal
            heading="Delete Delivery Partner"
            action="Are you sure? You want to Delete This Delivery Partner"
            name=""
            onClickFunction={handleDeletedpApiHit}
            ConfirmButton="Yes Delete"
            onClose={closeModal}
            isOpen={confirmatiOnOpen}
            loader={true}
            loading={delloader}
          />
        </>
      )}
    </>
  );
};

export default DeliveryBoy;
