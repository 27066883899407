import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Select,
  Input,
  Checkbox,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { setToast } from "../../../../Fucntions/Toastfunction";
import { useDispatch } from "react-redux";
import { getOrderData } from "../../../../redux/Admin/AppReducer/orders/action";

const OnHoldModal = ({ onOpen, onClose, isOpen }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const [hold_detail, setHold_detail] = useState({
    reason: "",
    reasonNotes: "",
    notify: false,
    user: JSON.parse(sessionStorage.getItem("username")),
  });

  function handledatafunction(e) {
    const { name, value, checked } = e.target;
    if (name === "notify") {
      setHold_detail({
        ...hold_detail,
        [name]: checked,
      });
    } else {
      setHold_detail({
        ...hold_detail,
        [name]: value,
      });
    }
  }
  
  const [saveloader, setSaveLoader] = useState(false);
  function handlesubmitdetailhitApi() {
    if (saveloader) {
      return;
    }
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    setSaveLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/order/onhold/${id}`,
      data: hold_detail,
      headers,
    })
      .then((r) => {
      
        setSaveLoader(false);
        onClose();
        if (r.data?.data.data?.fulfillmentOrderHold?.userErrors?.length > 0) {
          setToast(
            toast,
            `${r.data?.data?.data?.fulfillmentOrderHold?.userErrors[0].message}`,
            "",
            "error"
          );
          return;
        }
        dispatch(
          getOrderData(
            `${process.env.REACT_APP_API_URL}/order/read/${id}`,
            navigate
          )
        );
        setToast(
          toast,
          `Successfully on ${r.data?.data?.data?.fulfillmentOrderHold?.fulfillmentOrder.status}`,
          "",
          "success"
        );
      })
      .catch((err) => {
        setSaveLoader(false);
        onClose();
        setToast(toast, `something went wrong`, "", "error");
      });
  }

  return (
    <div>
      <>
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Mark Order As Hold</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Select Reason</FormLabel>
                <Select
                  placeholder="Select Reason"
                  onChange={handledatafunction}
                  name="reason"
                >
                  <option value="AWAITING_PAYMENT">AWAITING PAYMENT</option>
                  <option value="HIGH_RISK_OF_FRAUD">
                    {" "}
                    HIGH RISK OF FRAUD
                  </option>
                  <option value="INCORRECT_ADDRESS">INCORRECT ADDRESS</option>
                  <option value="INVENTORY_OUT_OF_STOCK">
                    INVENTORY OUT OF STOCK
                  </option>
                  <option value="UNKNOWN_DELIVERY_DATE">
                    UNKNOWN DELIVERY DATE
                  </option>
                  <option value="OTHER">OTHER</option>
                </Select>
              </FormControl>
              <br />
              <FormControl>
                <FormLabel>Write Reason Notes</FormLabel>
                <Input
                  placeholder="Write Reason Notes"
                  onChange={handledatafunction}
                  name="reasonNotes"
                />
              </FormControl>
              <br />
              <FormControl gap="10px" display="flex">
                <FormLabel>Notify Merchant :</FormLabel>
                <Checkbox
                  size="lg"
                  onChange={handledatafunction}
                  name="notify"
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handlesubmitdetailhitApi}
                isDisabled={!hold_detail.reason || !hold_detail.reasonNotes}
              >
                {saveloader ? <Spinner /> : "Save"}
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </div>
  );
};

export default OnHoldModal;
