import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  ListItem,
  Spinner,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import ConfirmationModal from "../../../Fucntions/Modals/ConfirmationModal";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  UpdateSuborderData,
  getOrderData,
} from "../../../redux/Admin/AppReducer/orders/action";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setToast } from "../../../Fucntions/Toastfunction";
import axios from "axios";

const SpecialInstructions = () => {
    const suborder = useSelector((state) => state.OrderReducer.order);
    const { id } = useParams();
    const toast = useToast();
    const dispatch = useDispatch();
    const [isOpenNotesModal, setIsOpenNotesModal] = useState(false);
    const [note, setNote] = useState("");
    const navigate = useNavigate();

    const openNotesModal = () => {
        setIsOpenNotesModal(true);
      };
    
      const closeNotesModal = () => {
        setIsOpenNotesModal(false);
      };


      
  let [noteSaverLoader, setNotesaverLoader] = useState(false);

  const handleNoteSaveHitApi = () => {
    if (noteSaverLoader) {
      return;
    }
    setNotesaverLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/order/updatenote/${id}`,
      data: { notes: note, order_id: suborder?.line_items[0].related_suborder },
      headers,
    })
      .then((r) => {
        dispatch(
          getOrderData(
            `${process.env.REACT_APP_API_URL}/order/read/${id}`,
            toast,
            navigate
          )
        );
        setNotesaverLoader(false);
        setNote("");
        closeNotesModal();
      })
      .catch((err) => {
        // console.log("_______________________",err)
        setNotesaverLoader(false);
      });
  };
  return (
    <div>
      {" "}
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpenNotesModal}
        onClose={closeNotesModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"left"}>Add Note</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Input
                placeholder=""
                id="note"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeNotesModal}>
              Close
            </Button>
            <Button
              colorScheme="green"
              color="white"
              onClick={handleNoteSaveHitApi}
            >
              {noteSaverLoader ? <Spinner /> : "Save"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {suborder?.suborder_id ? (
        <Box>
          {suborder.notes ? (
            <Text color={"black"} textAlign={"left"}>
              {suborder?.notes}
            </Text>
          ) : (
            <Text fontSize={"14px"} mt="5px" textAlign={"left"} color={"gray"}>
              No Notes
            </Text>
          )}
        </Box>
      ) : (
        <Flex flexDirection="column" gap="30px"
        >
          {suborder?.suborder_id ? null : suborder.note ? (
            <Text color=   {"black"} textAlign={"left"}>
              {suborder?.note.includes("gid")?
               <Text fontSize={"14px"} mt="5px" textAlign={"left"} color={"gray"}>
              No Notes 
              </Text>
              :suborder?.note}
            </Text>
          ) : (
            <Text fontSize={"14px"} mt="5px" textAlign={"left"} color={"gray"}>
              No Notes 
            </Text>
          )}
          <Box>
          <Text
                onClick={openNotesModal}
                width="max-content"
                        color="blue"
                        _hover={{
                          textDecorationLine: "underline",
                          cursor: "pointer",
                        }}
              >
                Edit  Special Instructions
              </Text>
              </Box>
        </Flex>
      )}
    </div>
  );
};

export default SpecialInstructions;
