import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  Flex,
} from "@chakra-ui/react";
// import { FaBoxesPacking } from "react-icons/fa";

const DpAccordincatlog = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveItem(index);
  };

  return (
    <div>
      <Accordion width="210px" allowToggle mt="-10px">
        {/* <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                navigate("/deliverypartner");
                handleAccordionClick(0);
              }}
              bg={activeItem === 0 ? "gray.200" : ""}
              color={activeItem === 0 ? "#7D8035" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <AiFillHome fontSize="20px" />
                  Home
                </Flex>
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem> */}

        <AccordionItem>       
          <h2>
            <AccordionButton
              onClick={() => {
                navigate("/deliverypartner/assigned");
                handleAccordionClick(2);
              }}
              color={activeItem === 2 ? "#7D8035" : ""}
              bg={activeItem === 2 ? "gray.200" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  {/* <FaBoxesPacking fontSize="20px" /> */}
                  Assigned Orders
                </Flex>
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                navigate("/deliverypartner/outfordelivery");
                handleAccordionClick(4);
              }}
              color={activeItem === 4 ? "#7D8035" : ""}
              bg={activeItem === 4 ? "gray.200" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  {/* <FaBoxesPacking fontSize="20px" /> */}
                  Out For Delivery Orders
                </Flex>
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                navigate("/deliverypartner/attempted");
                handleAccordionClick(5);
              }}
              color={activeItem === 5 ? "#7D8035" : ""}
              bg={activeItem === 5 ? "gray.200" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  {/* <FaBoxesPacking fontSize="22px" /> */}
                  Attempted Orders
                </Flex>
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                navigate("/deliverypartner/delivered");
                handleAccordionClick(6);
              }}
              color={activeItem === 6 ? "#7D8035" : ""}
              bg={activeItem === 6 ? "gray.200" : ""}
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  {/* <FaBoxesPacking fontSize="22px" /> */}
                  Delivered Orders
                </Flex>
              </Box>
            </AccordionButton>
          </h2>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default DpAccordincatlog;
