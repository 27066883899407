import React, { useState } from "react";
import OrderStatusComp from "./OrderStatusComp";
import { Text } from "@chakra-ui/react";

const AllocatedOrders = () => {
  let [policy, setPolicy] = useState([]);
  let [buttons_name, setButtonname] = useState([]);
  let [button_modal, setButtonModal] = useState([]);
  return (
    <div>
      <OrderStatusComp
        status={"allocated"}
        order_status={"allocated_orders"}
        heading={"Allocated Orders"}
        selection={true}
        challanshow={false}
        // button_action={["accepted", "rejected"]}
        // buttons_line={["Accept Order", "Reject Order"]}
        // modal_fn={[
        //   {
        //     heading: "Accept Order",
        //     action: "Are You Sure You want to Accept This order",
        //     ConfirmButton: "Accept Order",
        //     color: "whatsapp",
        //     toast_notification: "Order Accepted",
        //   },
        //   {
        //     heading: "Reject Order",
        //     action: "Are You Sure You want to Reject This order",
        //     ConfirmButton: "Reject Order",
        //     color: "red",
        //     toast_notification: "Order Rejected",
        //   },
        // ]}
        button_action={policy}
        buttons_line={buttons_name}
        modal_fn={button_modal}
        setPolicy={setPolicy}
        setButtonModal={setButtonModal}
        setButtonname={setButtonname}
        policy={policy}
        buttons_name={buttons_name}
        button_modal={button_modal}
      />
    </div>
  );
};

export default AllocatedOrders;
