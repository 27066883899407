import React, { useEffect } from 'react'
import EditMainOrder from './EditMainOrder/EditMainOrder'
import { OrderEditingBegin, getOrderData } from '../../../redux/Admin/AppReducer/orders/action';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const OrderEditingPage = () => {
  const dispatch=useDispatch()
  const {id}=useParams()
  const toast=useToast()
  const navigate=useNavigate()
 
  useEffect(() => {
    dispatch(OrderEditingBegin(`${process.env.REACT_APP_API_URL}/order/read/${id}`,navigate))
    dispatch(getOrderData(`${process.env.REACT_APP_API_URL}/order/read/${id}`,toast,navigate));
  }, []);
  return (
    <div>
        <EditMainOrder/>
    </div>
  )
}

export default OrderEditingPage