import React, { useEffect } from "react";
import TopBar from "./TopBar";
import { useSelector } from "react-redux";
import { Box, Spinner, Text } from "@chakra-ui/react";

const SubOrderPage = () => {
  const loader = useSelector((state) => state.OrderReducer.isloading);
  return (
    <>
      {loader ? (
        <Box mt="80px">
          <Spinner
            thickness="3px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Box>
      ) : (
        <TopBar />
      )}
    </>
  );
};

export default SubOrderPage;
