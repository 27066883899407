export const GET_VENDOR_DATA_LOADING = "GET_VENDOR_DATA_LOADING";
export const GET_VENDOR_DATA_SUCCESS = "GET_VENDOR_DATA_SUCCESS";
export const GET_VENDOR_DATA_ERROR = "GET_VENDOR_DATA_ERROR";

export const GET_SINGLE_VENDOR_DATA_LOADING = "GET_SINGLE_VENDOR_DATA_LOADING";
export const GET_SINGLE_VENDOR_DATA_SUCCESS = "GET_SINGLE_VENDOR_DATA_SUCCESS";
export const GET_SINGLE_VENDOR_DATA_ERROR = "GET_SINGLE_VENDOR_DATA_ERROR";

export const POST_VENDOR_DETAILS_LOADING = "POST_VENDOR_DETAILS_LOADING";
export const POST_VENDOR_DETAILS_SUCCESS = "POST_VENDOR_DETAILS_SUCCESS";
export const POST_VENDOR_DETAILS_ERROR = "POST_VENDOR_DETAILS_ERROR";

export const UPDATE_VENDOR_DETAILS_LOADING = "UPDATE_VENDOR_DETAILS_LOADING";
export const UPDATE_VENDOR_DETAILS_SUCCESS = "UPDATE_VENDOR_DETAILS_SUCCESS";
export const UPDATE_VENDOR_DETAILS_ERROR = "UPDATE_VENDOR_DETAILS_ERROR";


export const DELETE_VENDOR_DATA_LOADING = "DELETE_VENDOR_DATA_LOADING";
export const DELETE_VENDOR_DATA_SUCCESS = "DELETE_VENDOR_DATA_SUCCESS";
export const DELETE_VENDOR_DATA_ERROR = "DELETE_VENDOR_DATA_ERROR";

export const GET_ALL_CITYS_DATA_LOADING = "GET_ALL_CITYS_DATA_LOADING";
export const GET_ALL_CITYS_DATA_SUCCESS = "GET_ALL_CITYS_DATA_SUCCESS";
export const GET_CITYS_DATA_ERROR = "GET_CITYS_DATA_ERROR";

export const POST_CITYS_DATA_LOADING = "POST_CITYS_DATA_LOAING";
export const POST_CITYS_DATA_SUCCESS = "POST_CITYS_DATA_SUCCESS"
export const POST_CITYS_DATA_ERROR = "POST_CITYS_DATA_ERROR";

export const DELETE_CITYS_DATA_LOADING = "DELETE_CITYS_DATA_LOADING";
export const DELETE_CITYS_DATA_SUCCESS = "DELETE_CITYS_DATA_SUCCESS";
export const DELETE_CITYS_DATA_ERROR = "DELETE_CITYS_DATA_ERROR";


export const POST_VENDOR_DETAILS_DATA_LOADING = "POST_VENDOR_DETAILS_DATA_LOADING";
export const POST_VENDOR_DETAILS_DATA_SUCCESS = "POST_VENDOR_DETAILS_DATA_SUCCESS";
export const POST_VENDOR_DETAILS_DATA_ERROR = "POST_VENDOR_DETAILS_DATA_ERROR";
