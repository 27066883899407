import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  Editable,
  EditableTextarea,
  EditablePreview,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import React from "react";

const AddSubOrder = () => {
  return (
    <Box width="100%">
      {/* *******************  Hedaing And Button ********************** */}
      <Flex justifyContent="space-between">
        <Heading as="h4" size="lg" fontWeight="medium">
          Add Order
        </Heading>
        {/* ************************  Buttons OF HEADING    ************************* */}
        <Flex gap="30px">
          <Button colorScheme="facebook">ADD</Button>
          <Button colorScheme="facebook">DISCARD</Button>
        </Flex>
      </Flex>

      {/* ********************************   Customer Details and Shipping container    ******************************* */}
      <Flex mt="2rem" justifyContent="space-between">
        {/* **********************************   LEFT SIDE BOX   ******************************** */}
        <Box width="65%">
          {/* **********************************   CUSTOMER DETAILS BOX   ******************************** */}
          <Box
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            backgroundColor="white"
            borderRadius="7px"
            p="3"
            mb="20px"
            textAlign="left"
          >
            <Flex justifyContent="space-between">
              <Heading as="h4" size="lg" fontWeight="bold">
                Customer Details
              </Heading>
            </Flex>
            <br />
            <hr style={{ color: "black" }} />
            <br />
            <Flex gap="20px" alignItems="center">
              <Text>Customet Name</Text>
              <InputGroup width={{}}>
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon />}
                />
                <Input type="text" placeholder="Search" />
              </InputGroup>
            </Flex>
            <Box
              width="95%"
              margin="auto"
              border="1px solid gray"
              mt="20px"
              p="1"
              borderRadius="7px"
            >
              <Editable defaultValue="Customer Details" minHeight="100px">
                <EditablePreview />
                <EditableTextarea />
              </Editable>
            </Box>
          </Box>
          {/* **********************************   PRODUCT DETAILS BOX   ******************************** */}
          <Box
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            backgroundColor="white"
            borderRadius="7px"
            p="3"
            mb="20px"
            textAlign="left"
          >
            <Heading as="h4" size="lg" fontWeight="bold">
              Product Details
            </Heading>

            <br />
            <hr style={{ color: "black" }} />
            <br />
            <Flex gap="20px" alignItems="center">
              <Text>Product</Text>
              <InputGroup width={{}}>
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon />}
                />
                <Input type="text" placeholder="Search" width={{}} />
              </InputGroup>
            </Flex>
            {/* *************************  PRODUCT DETAILS ********************************* */}
            <Box></Box>
          </Box>
          {/* **********************************   ORDER SUMMARY BOX   ******************************** */}
          <Box
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            backgroundColor="white"
            borderRadius="7px"
            p="3"
            mb="20px"
            textAlign="left"
          >
            <Heading as="h4" size="md" fontWeight="bold">
              Order Summary
            </Heading>
            <hr style={{ color: "black" }} />
            <br />
            <Box>
              <Flex justifyContent="space-between" mb="10px">
                <Text fontSize="md" fontWeight="bold">
                  Sub Total
                </Text>
                <Text>Price (QAR 100)</Text>
              </Flex>
              <Flex justifyContent="space-between" mb="10px">
                <Text fontSize="md" fontWeight="bold">
                  Discount
                </Text>
                <Text>Price</Text>
              </Flex>
              <Flex justifyContent="space-between" mb="10px">
                <Text fontSize="md" fontWeight="bold">
                  Coupon Code
                </Text>
                <Text>Coupon Code</Text>
              </Flex>
              <Flex justifyContent="space-between" mb="10px">
                <Text fontSize="md" fontWeight="bold">
                  Tax
                </Text>
                <Text>Price</Text>
              </Flex>
              <hr />
              <Flex justifyContent="space-between" mt="10px">
                <Text fontSize="md" fontWeight="bold">
                  Total
                </Text>
                <Text>Price</Text>
              </Flex>
            </Box>
          </Box>
        </Box>

        {/* ***********************************************   RIGHT SIDE BOX  ************************************** */}
        <Box width="30%">
          {/* ***********************************************    SHIPPING DETAILS BOX  ************************************** */}
          <Box
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            backgroundColor="white"
            borderRadius="7px"
            p="3"
            mb="20px"
            textAlign="left"
          >
            <Heading as="h5" size="sm" textDecoration="underline">
              Shipping Address
            </Heading>
            <Box
              width="95%"
              margin="auto"
              border="1px solid gray"
              mt="10px"
              p="1"
              borderRadius="7px"
            >
              <Editable defaultValue="Shipping Address" minHeight="100px">
                <EditablePreview />
                <EditableTextarea />
              </Editable>
            </Box>
            <Heading as="h5" size="sm" textDecoration="underline" mt="20px">
              Billing Address
            </Heading>
            <Box
              width="95%"
              margin="auto"
              border="1px solid gray"
              mt="10px"
              p="1"
              borderRadius="7px"
            >
              <Editable defaultValue="Billing Address" minHeight="100px">
                <EditablePreview />
                <EditableTextarea />
              </Editable>
            </Box>
          </Box>
          <Box
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            backgroundColor="white"
            borderRadius="7px"
            p="3"
            mb="20px"
            textAlign="left"
          >
            <Box
              width="95%"
              margin="auto"
              border="1px solid gray"
              mt="10px"
              p="1"
              borderRadius="7px"
            >
              <Editable defaultValue="Comments" minHeight="200px">
                <EditablePreview />
                <EditableTextarea />
              </Editable>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default AddSubOrder;
