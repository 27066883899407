import {
  Box,
  Checkbox,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

const AddedDiscount = ({
  discount,
  setDiscount,
  discounttype,
  setDiscountype,
  amount,
  setDiscountAmount,
}) => {



  return (
    <>
      <Heading as="h2" size="lg" fontWeight="semibold">
        Discount
      </Heading>

      <br />
      <Checkbox
        size="lg"
        colorScheme="orange"
        onChange={(e) => setDiscount(e.target.checked)}
      >
        Discount
      </Checkbox>
      <br />
      {discount && (
        <Box>
          <Text fontSize="18px" fontWeight="bold">
            Discount Type
          </Text>
          <RadioGroup onChange={setDiscountype} value={discounttype}>
            <Stack direction="row">
              <Radio value="percentage">Percentage</Radio>
              <Radio value="amount">Amount</Radio>
            </Stack>
          </RadioGroup>
          <br />
          <Input
            placeholder="type number"
            type="number"
            onChange={(e) => setDiscountAmount(e.target.value)}
          />
        </Box>
      )}
      <br />
    </>
  );
};

export default AddedDiscount;
