import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Button,
  IconButton,
  Checkbox,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Search from "../../../Fucntions/search/Search";
import { getVendorData } from "../../../redux/Admin/AppReducer/vendors/action";
import { FcDownload } from "react-icons/fc";
import axios from "axios";
import { saveAs } from "file-saver";
import csvtojson from "csvtojson";
import { utils as XLSXUtils, write as writeToBuffer } from "xlsx";
import { setToast } from "../../../Fucntions/Toastfunction";

const Vendor = () => {
  const [search, setSearch] = useState("");
  const [allChecked, setAllChecked] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchbutton, setSearchButton] = useState(true);
  const [filterbutton, setFilterButton] = useState(true);
  // const [tablesize, settableSize] = useState(false);
  const vendor = useSelector((state) => state.VendorReducer.vendor);
  const loadingvendor = useSelector((state) => state.VendorReducer.isloading);
  // console.log(loadingvendor, "ven");
  const screenwidth = window.screen.width;
  const toast = useToast();
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams({ page, search });

  let [loader, setLoader] = useState(false);
  function handleDownlaodVendorData() {
    setLoader(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/partner/download`,
      headers,
    })
      .then((response) => {
        csvtojson()
          .fromString(response.data)
          .then((jsonData) => {
            const workbook = { SheetNames: ["Sheet1"], Sheets: {} };
            const worksheet = XLSXUtils.json_to_sheet(jsonData);
            workbook.Sheets["Sheet1"] = worksheet;
            const excelBuffer = writeToBuffer(workbook, { type: "buffer" });
            const blob = new Blob([excelBuffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, "filename.xlsx");
          });
        setLoader(false);
      })
      .catch((err) => {
        setToast(toast, `${err.response.data.err}`, "", "error");
        setLoader(false);
      });
  }

  useEffect(() => {
    if (!vendor.length > 0) {
      dispatch(getVendorData(`${process.env.REACT_APP_API_URL}/partner/all`,navigate));
    }
    if (screenwidth < 500 && searchbutton === false) {
      setFilterButton(false);
    } else {
      setFilterButton(true);
    }
  }, [searchbutton]);

  useEffect(() => {
    let timeoutId;
    const debounceSearch = () => {
      setSearchParams({ page, search });
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        dispatch(
          getVendorData(
            `${process.env.REACT_APP_API_URL}/partner/all?query=${
              search
            }&page=${page}`,toast,navigate
        ))
      }, 300);
    };
    debounceSearch();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [page]);

  useEffect(() => {
    let timeoutId;
    const debounceSearch = () => {
      setPage(1);
      setSearchParams({ page, search });
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        dispatch(
          getVendorData(
            `${process.env.REACT_APP_API_URL}/partner/all?query=${
                 search
            }&page=${page}`,toast,navigate
        ))
      }, 300);
    };
    debounceSearch();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);

  useEffect(() => {
    if (search) {
      setSearchButton(false);
    }
  }, []);

  return (
    <>
      {loadingvendor && searchbutton ? (
        <Text
          color="gray.800"
          fontSize="40px"
          fontWeight="semibold"
          mt="80px"
          pb="80px"
        >
          Loading ...
        </Text>
      ) : (
        <Box width="100%">
          <Flex justifyContent="space-between">
            <Heading as="h4" size="lg" fontWeight="medium">
              Vendors
            </Heading>
            <Flex gap="10px">
              {loader ? (
                <Button>
                  <Spinner />
                </Button>
              ) : (
                <Button
                  onClick={handleDownlaodVendorData}
                  variant="outline"
                  border="2px solid teal"
                  leftIcon={<FcDownload />}
                  fontSize={{ base: "12px", md: "16px", lg: "16px" }}
                >
                  Bulk Download
                </Button>
              )}
              <Button
                colorScheme="whatsapp"
                onClick={() => navigate("/admin/createvendor")}
                fontSize={{ base: "12px", md: "16px", lg: "16px" }}
              >
                Add Vendors
              </Button>
            </Flex>
          </Flex>
          <br />
          <div
            style={{
              borderRadius: "7px",
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              backgroundColor: "white",
            }}
            height="30px"
            width="30px"
          >
            <Flex p={{ base: "1", md: "1", lg: "2" }} justifyContent="flex-end">
              <Flex>
                {searchbutton ? null : (
                  <Box mr="10px">
                    <Search
                      search={search}
                      setSearch={setSearch}
                      hidefilter={true}
                      page={page}
                    />
                  </Box>
                )}
                <IconButton
                  onClick={() => {
                    setSearchButton(!searchbutton);
                    setSearch("");
                  }}
                  colorScheme={searchbutton ? "blue" : "red"}
                  aria-label="Search database"
                  icon={searchbutton ? <SearchIcon /> : <CloseIcon />}
                />
              </Flex>
            </Flex>
            <hr />
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    {/* <Th>
                  <Checkbox
                    size="lg"
                    isIndeterminate={
                      allChecked.length !== vendor.length &&
                      allChecked.length > 0
                    }
                    isChecked={allChecked.length === vendor.length}
                    onChange={(e) => {
                      handleAllChecked(e);
                    }}
                  ></Checkbox>
                </Th> */}
                    <Th>
                      {" "}
                      <Text fontSize="sm">Name</Text>
                    </Th>
                    <Th>
                      <Text fontSize="sm">Email</Text>
                    </Th>
                    <Th>
                      <Text fontSize="sm">Id</Text>
                    </Th>
                    <Th>
                      <Text fontSize="sm">Phone</Text>{" "}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    // navigate(`/vendors/${item.id}`)
                    vendor.map((item, i) => (
                      <Tr
                        key={i}
                        onClick={(e) => {
                          if (!e.target.closest(".checkbox_check"))
                            navigate(`/admin/vendor/${item._id}`);
                        }}
                      >
                        {/* <Td>
                      <Checkbox
                        className="checkbox_check"
                        id={`check_${i}`}
                        size="md"
                        isChecked={allChecked.includes(i) ? true : false}
                        onChange={(e) => handleAllChildrenClick(e, i, item)}
                      ></Checkbox>
                    </Td> */}
                        <Td
                          _hover={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          {item.partner_name}
                        </Td>
                        <Td>{item.partner_email}</Td>
                        <Td>{item._id}</Td>
                        <Td>{item.partner_phone}</Td>
                      </Tr>
                    ))
                  }
                </Tbody>
              </Table>
            </TableContainer>
          </div>
          <Flex mt="10px" gap="10px" justifyContent="center">
            <Button
              bg="black"
              color="white"
              isDisabled={page === 1}
              onClick={() => setPage((prev) => prev - 1)}
            >
              Previous page
            </Button>
            <Box
              height="40px"
              pt="7px"
              width="40px"
              bg="black"
              borderRadius="10px"
              color="white"
            >
              <Text fontWeight="bold">{page}</Text>
            </Box>
            <Button
              bg="black"
              color="white"
              isDisabled={loadingvendor || vendor.length < 25}
              onClick={() => setPage((prev) => prev + 1)}
            >
              Next page
            </Button>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default Vendor;
