import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Button,
  IconButton,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useToast,
  Select,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getOrderData } from "../../../redux/Admin/AppReducer/orders/action";
import { setToast } from "../../../Fucntions/Toastfunction";

const AllOrders = () => {
  const [searchbutton, setSearchButton] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const order = useSelector((state) => state.OrderReducer.order);
  const loader = useSelector((state) => state.OrderReducer.isloading);
  console.log("order", order);

  const [page, setPage] = useState(
    JSON.parse(sessionStorage.getItem("orderpage")) || 1
  );
  const [cursork, setCursorK] = useState(
    JSON.parse(sessionStorage.getItem("ordercursor")) || null
  );
  const [querycursor, setQueryCursor] = useState(
    JSON.parse(sessionStorage.getItem("orderquery")) || null
  );
  const [pageload, setPageLoad] = useState(false);

  // const [searchParams, setSearchParams] = useSearchParams({ page });
  // console.log(searchParams, "searhc");
  function handlePageChange(num) {
    if (loader) {
      return;
    }
    let k;
    if (num === 1) {
      k = order?.orders[order?.orders.length - 1].cursor;
      setQueryCursor("after");
      sessionStorage.setItem("orderquery", JSON.stringify("after"));
    } else {
      k = order?.orders[0]?.cursor;
      setQueryCursor("before");
      sessionStorage.setItem("orderquery", JSON.stringify("before"));
    }
    setPage((pre) => pre + num);
    setCursorK(k);
    sessionStorage.setItem("ordercursor", JSON.stringify(k));
  }

  let [filtersearch, setFilterSearch] = useState(null);
  const [filterSelected, setFilterSelected] = useState("name");
  function handleFilterSearch(e) {
    if (!filterSelected) {
      setToast(toast, "Plese Selct Filter", "", "info");
      return;
    }
    setFilterSearch(e.target.value);
  }

  function handleFilterSelect(e) {
    console.log(e.target.value, "valvalval");
    setFilterSelected(e.target.value);
  }

  useEffect(() => {
    // setSearchParams({ page });
    let timeoutId;
    const debounceSearch = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (filtersearch) {
          dispatch(
            getOrderData(
              `${process.env.REACT_APP_API_URL}/order/all?page=${page}&query=${filterSelected}:${filtersearch}`,
              toast,
              navigate
            )
          );
          setPage(1);
        } else {
          dispatch(
            getOrderData(
              `${process.env.REACT_APP_API_URL}/order/all?page=${page}&${querycursor}=${cursork}`,
              toast,
              navigate
            )
          );
        }
      }, 300);
    };

    debounceSearch();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [filtersearch]);

  useEffect(() => {
    setPageLoad(true);
    if (filtersearch) {
      dispatch(
        getOrderData(
          `${process.env.REACT_APP_API_URL}/order/all?page=${page}&${querycursor}=${cursork}&query=${filterSelected}:${filtersearch}`,
          toast,
          navigate
        )
      );
    } else {
      dispatch(
        getOrderData(
          `${process.env.REACT_APP_API_URL}/order/all?page=${page}&${querycursor}=${cursork}`,
          toast,
          navigate
        )
      );
      setPageLoad(false);
    }
    // setSearchParams({ page });
    window.scrollTo({ top: 0, behavior: "smooth" });
    sessionStorage.setItem("orderpage", JSON.stringify(page));
  }, [page]);

  return (
    <Box scrollBehavior="smooth">
      {loader && searchbutton ? (
        <Text
          color="gray.800"
          fontSize="40px"
          fontWeight="semibold"
          mt="80px"
          pb="80px"
        >
          Loading ...
        </Text>
      ) : (
        <Box width="100%">
          <Flex justifyContent="space-between" alignItems="center">
            <Heading as="h4" size="lg" fontWeight="medium">
              ALL ORDERS
            </Heading>
            <Flex mt="10px" gap="10px" justifyContent="flex-end">
              <Button
                bg="black"
                color="white"
                isDisabled={page === 1}
                onClick={() => handlePageChange(-1)}
              >
                Previous Page
              </Button>
              <Box
                height="40px"
                pt="7px"
                width="40px"
                bg="black"
                borderRadius="10px"
                color="white"
              >
                <Text fontWeight="bold">{page}</Text>
              </Box>
              <Button
                bg="black"
                color="white"
                isDisabled={!order?.hasNextPage}
                onClick={() => handlePageChange(1)}
              >
                Next Page
              </Button>
            </Flex>
          </Flex>

          <br />
          <div
            style={{
              borderRadius: "7px",
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              backgroundColor: "white",
            }}
          >
            <Flex
              justifyContent="space-between"
              p={{ base: "1", md: "1", lg: "2" }}
            >
              <Button
                colorScheme="teal"
                variant="ghost"
                fontSize={{ base: "12px", md: "16px", lg: "16px" }}
              >
                All
              </Button>

              <Flex
                justifyContent="space-between"
                fontSize={{ base: "12px", md: "16px", lg: "26px" }}
              >
                <Flex gap="10px">
                  {searchbutton ? null : (
                    <Flex alignItems="center" gap="10px">
                      {/* <Select
                        placeholder="Select option"
                        onChange={handleFilterSelect}
                      >
                        <option value="name">Name</option>
                        <option value="email">Email</option>
                        <option value="customer_id">Customer Id</option>
                      </Select> */}
                      <InputGroup>
                        <InputLeftElement pointerEvents="none">
                          <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                          type="tel"
                          placeholder="Search Filter"
                          onChange={handleFilterSearch}
                        />
                      </InputGroup>
                    </Flex>
                  )}
                  {searchbutton ? (
                    <IconButton
                      onClick={() => {
                        setSearchButton(!searchbutton);
                        setFilterSearch("");
                      }}
                      colorScheme={searchbutton ? "blue" : "red"}
                      aria-label="Search database"
                      icon={<SearchIcon />}
                    />
                  ) : (
                    <IconButton
                      onClick={() => {
                        setSearchButton(!searchbutton);
                        setFilterSearch("");
                      
                        setPage(1);
                        sessionStorage.removeItem("orderquery");
                        sessionStorage.removeItem("ordercursor");
                        setQueryCursor(null);
                        setCursorK(null);
                        dispatch(
                          getOrderData(
                            `${process.env.REACT_APP_API_URL}/order/all?page=1`,
                            toast,
                            navigate
                          )
                        );
                      }}
                      colorScheme={searchbutton ? "blue" : "red"}
                      aria-label="Search database"
                      icon={<CloseIcon />}
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
            <hr />
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th
                      width="100px"
                      // whiteSpace="normal"
                      // wordBreak="break-word"
                    >
                      <Text
                        fontSize="11px"
                        fontWeight="700"
                        textAlign="center"
                        width="50px"
                        // whiteSpace="normal"
                      >
                        ORDER NO
                      </Text>
                    </Th>
                    <Th
                      width="180px"
                      whiteSpace="normal"
                      wordBreak="break-word"
                    >
                      <Text
                        fontSize="11px"
                        fontWeight="700"
                        textAlign="center"
                        // width="50px"
                        whiteSpace="normal"
                      >
                        ORDER ID
                      </Text>
                    </Th>
                    <Th pl="0px" paddingInlineEnd="2px">
                      <Text
                        fontSize="11px"
                        fontWeight="700"
                        textAlign="center"
                        // width="88px"
                        whiteSpace="normal"
                      >
                        DELIVERY DATE
                      </Text>
                    </Th>
                    <Th>
                      <Text
                        fontSize="11px"
                        fontWeight="700"
                        textAlign="center"
                        // width="88px"
                        whiteSpace="normal"
                      >
                        Time Slot
                      </Text>
                    </Th>
                    {/* <Th>
                      <Text fontSize="sm">created at</Text>
                    </Th> */}
                    <Th>
                      <Text
                        fontSize="11px"
                        fontWeight="700"
                        textAlign="center"
                        // width="88px"
                        whiteSpace="normal"
                      >
                        Name
                      </Text>
                    </Th>
                    <Th>
                      <Text
                        fontSize="11px"
                        fontWeight="700"
                        textAlign="center"
                        // width="88px"
                        whiteSpace="normal"
                      >
                        Phone No.
                      </Text>{" "}
                    </Th>
                    <Th>
                      <Text
                        fontSize="11px"
                        fontWeight="700"
                        textAlign="center"
                        // width="88px"
                        whiteSpace="normal"
                      >
                        City
                      </Text>{" "}
                    </Th>
                    <Th>
                      <Text
                        fontSize="11px"
                        fontWeight="700"
                        textAlign="center"
                        width="88px"
                        whiteSpace="normal"
                      >
                        No of ITEMS
                      </Text>{" "}
                    </Th>
                    <Th>
                      <Text
                        fontSize="11px"
                        fontWeight="700"
                        textAlign="center"
                        // width="80px"
                        whiteSpace="normal"
                      >
                        Order Amount
                      </Text>{" "}
                    </Th>
                    <Th pl="0px" paddingInlineEnd="2px">
                      <Text
                        fontSize="11px"
                        fontWeight="700"
                        textAlign="center"
                        // width="80px"
                        whiteSpace="normal"
                      >
                        Payment Status
                      </Text>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {order?.orders?.map((item, i) => (
                    <Tr
                      key={i}
                      _hover={{ cursor: "pointer" }}
                      onClick={() => {
                        sessionStorage.removeItem("orderquery");
                        sessionStorage.removeItem("ordercursor");
                        sessionStorage.removeItem("orderpage");
                        navigate(`/admin/order/${item.id}`);
                      }}
                    >
                      <Td
                        width="100px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                        fontSize="12px"
                        textAlign="center"
                      >
                        {item.name}
                      </Td>
                      <Td
                        width="180px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                        fontSize="12px"
                        textAlign="center"
                      >
                        {item.id}
                      </Td>
                      <Td
                        width="180px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                        fontSize="12px"
                        textAlign="center"
                      >
                        {
                          item.note_attribute.find(
                            (k) => k.key === "shipping_date"
                          )?.value
                        }
                      </Td>
                      <Td
                        width="180px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                        fontSize="12px"
                        textAlign="center"
                      >
                        {
                          item.note_attribute.find(
                            (k) => k.key === "shipping_time"
                          )?.value
                        }
                      </Td>

                      <Td
                        width="150px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                        fontSize="12px"
                        textAlign="center"
                      >
                        {item?.customer?.first_name} {item?.customer?.last_name}
                      </Td>
                      <Td
                        width="180px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                        fontSize="12px"
                        textAlign="center"
                      >
                        {
                          item.note_attribute.find(
                            (k) => k.key === "sender_phoneNum"
                          )?.value
                        }
                      </Td>
                      <Td
                        width="180px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                        fontSize="12px"
                        textAlign="center"
                      >
                        {
                          item.note_attribute.find(
                            (k) => k.key === "english_city"
                          )?.value
                        }
                      </Td>
                      <Td fontSize="12px" textAlign="center">
                        {item?.line_items?.length}
                      </Td>
                      <Td fontSize="12px" textAlign="center">
                        {item?.total_price}
                      </Td>
                      <Td
                        width="150px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                        fontSize="12px"
                        textAlign="center"
                      >
                        {item?.financial_status}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
          <Flex mt="10px" gap="10px" justifyContent="flex-end">
            <Button
              bg="black"
              color="white"
              isDisabled={page === 1}
              onClick={() => handlePageChange(-1)}
            >
              Previous Page
            </Button>
            <Box
              height="40px"
              pt="7px"
              width="40px"
              bg="black"
              borderRadius="10px"
              color="white"
            >
              <Text fontWeight="bold">{page}</Text>
            </Box>
            <Button
              bg="black"
              color="white"
              isDisabled={!order?.hasNextPage}
              onClick={() => handlePageChange(1)}
            >
              Next Page
            </Button>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default AllOrders;
