import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Button,
  IconButton,
  Text,
  Badge,
  Input,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../../redux/Admin/AppReducer/Products/action";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Search from "../../../Fucntions/search/Search";
import {AiOutlineCloudUpload} from "react-icons/ai"
import axios from "axios";
import { saveAs } from 'file-saver';
import csvtojson from 'csvtojson';
import { utils as XLSXUtils, write as writeToBuffer } from 'xlsx';
import { setToast } from "../../../Fucntions/Toastfunction";
const Products = () => {
  const [prodsearch,setProdSearch]=useState(1)
  const toast=useToast()
  const [search, setSearch] = useState("");
  const [allChecked, setAllChecked] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchbutton, setSearchButton] = useState(true);
  const [filterbutton, setFilterButton] = useState(true);
  const [tablesize, settableSize] = useState(false);
  const product = useSelector((state) => state.ProductReducer.prod);
  const loadingprod=useSelector((state)=>state.ProductReducer.isloading)
  // console.log(loadingprod,"prod")
  const screenwidth = window.screen.width;
  let [page,setPage]=useState(1)
  const [searchParams, setSearchParams] = useSearchParams({ page, search });
//console.log(product,"check product")
  const handleAllChecked = (e) => {
    if (allChecked.length !== product.length) {
      let arr = product.map((x, i) => i);
      setAllChecked(arr);
    } else {
      setAllChecked([]);
    }
  };

  // const handleAllChildrenClick = (e, i, item) => {
  //   if (allChecked.includes(i)) {
  //     setAllChecked((prev) => {
  //       let arr = prev.filter((x) => x !== i);
  //       return arr;
  //     });
  //     e.target.checked = false;
  //   } else {
  //     setAllChecked((prev) => {
  //       let arr = [...prev, i];
  //       return arr;
  //     });
  //     e.target.checked = true;
  //   }

  // };

  useEffect(() => {
    if (screenwidth < 500 && searchbutton === false) {
     
      setFilterButton(false);
    } else {
      setFilterButton(true);
    }
  }, [searchbutton]);

   const fileInputRef = useRef(null);

  const handleFileChange = () => {
    const file = fileInputRef.current.files[0];
    if (file) {
      console.log('Selected file:', file);
    }
   
  };

  let [uplaodload,setUplaodLoad]=useState(false)
  const handleButtonClick = () => {
    if(uplaodload){
      return
    }
    setUplaodLoad(true)
    const file = fileInputRef.current.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('csvFile', file);
      const headers = {
        "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
      };
      axios({
        method:"POST",
        url:`${process.env.REACT_APP_API_URL}/variant/bulk_update`,
        data:formData,
        headers
      })
        .then((res) => {
          setUplaodLoad(false)
          console.log('Upload success:', res);
  if(res.data.success){
setToast(toast,"Data uploaded Successfully","","success")
  }
  else{
    csvtojson()
    .fromString(res.data)
    .then((jsonData) => {
      const workbook = { SheetNames: ['Sheet1'], Sheets: {} };
      const worksheet = XLSXUtils.json_to_sheet(jsonData);
      workbook.Sheets['Sheet1'] = worksheet;
      const excelBuffer = writeToBuffer(workbook, { type: 'buffer' });
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'filename.xlsx');
    });
  }
        })
        .catch((error) => {
          console.log(error)
          setToast(toast,`${error.response.data.err}`,"","error")
          setUplaodLoad(false)
        });
    }
    if (!file) {
      setToast(toast,"please slect any file first","","error")
      setUplaodLoad(false)
    }
  };

  useEffect(() => {
      setSearchParams({ page, search });
        dispatch(
          getData(
            `${process.env.REACT_APP_API_URL}/product/search?query=${
              search
            }&page=${page}`,toast
          )
        );
        window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  useEffect(() => {
    let timeoutId;
    const debounceSearch = () => {
      setPage(1)
      setSearchParams({ page, search });
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        dispatch(
          getData(
            `${process.env.REACT_APP_API_URL}/product/search?query=${
              search
            }&page=${page}`,toast,navigate
          )
        );
      }, 300);
    };
    debounceSearch();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);

  useEffect(() => {
    dispatch(
      getData(
        `${process.env.REACT_APP_API_URL}/product/search?query=${
          search
        }`,toast
      )
    );

    if (screenwidth < 500) {
      settableSize(true);
    }
  }, []);

  return (
    <>
   {loadingprod && searchbutton? <Text color="gray.800" fontSize="40px" 
        fontWeight="semibold" mt="80px" pb="80px">Loading ...</Text>
   : <Box width="100%">
      <Flex justifyContent="space-between" flexDirection={{base:"column",md:"row",lg:"row"}}>
      <Heading as="h4" size="lg" fontWeight="medium" mb="20px">
        Products
      </Heading>
      <Flex gap="10px" flexDirection={{base:"column",md:"row",lg:"row"}}>
      <Flex gap="10px"
      flexDirection={{base:"column",md:"row",lg:"row"}}>
      <Input
      margin="auto"
        type="file"
        id="fileInput"
        ref={fileInputRef}
        maxWidth="250px"
       bg="white"
       pt="3px"
       mt="-0px"
        accept=".csv"
        onChange={handleFileChange}
      />
      <Button 
      leftIcon={<AiOutlineCloudUpload/>}
      paddingLeft="10px" paddingRight="10px"
      onClick={handleButtonClick}variant="outline" border="2px solid teal">
      {uplaodload?<Spinner/>: "Bulk Upload"}
      </Button>
    </Flex>
      {/* <Button
          colorScheme="whatsapp"
          onClick={() => navigate("/admin/createvendor")}
        >
          Add Vendors
        </Button> */}
      </Flex>
      </Flex>
      <br />
      <div
        style={{
          borderRadius: "7px",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          backgroundColor: "white",
        }}
        height="30px"
        width="30px"
      >
        <Flex
          justifyContent="space-between"
          p={{ base: "1", md: "1", lg: "2" }}
        >
          {filterbutton ? (
            <Flex
              gap="10px"
              fontSize={{ base: "12px", md: "16px", lg: "26px" }}
            >
            </Flex>
          ) : (
            <div></div>
          )}
          <Flex gap="10px">
            {searchbutton ? null : (
              <Box>
                <Search search={search} setSearch={setSearch} hidefilter={true} />
              </Box>
            )}
            <IconButton
              onClick={() => { 
                setSearchButton(!searchbutton)
                  setSearch("")
              }}
              colorScheme={searchbutton ? "blue" : "red"}
              aria-label="Search database"
              icon={searchbutton ? <SearchIcon /> : <CloseIcon />}
            />
          </Flex>
        </Flex>
        <hr />
        {product.length>0 ?
        <TableContainer>
          {tablesize ? (
            <Box>
              {product &&
                product.map((item, i) => (
                  <Box onClick={() => navigate(`/admin/products/${item.id}`)}>
                  
                    <Flex
                      justifyContent="space-between"
                      p="2"
                      alignItems="center"
                      flexWrap="wrap"
                    >
                      <img
                        src={item.images[0]}
                        alt=""
                        style={{ height: "40px", width: "40px" }}
                      />
                      <Flex flexDirection="column" textAlign="left">
                        <Text>{item.title}</Text>
                        {item.variants.length > 0 ? (
                          <>
                            {item.variants[0].inventory_quantity < 0 ? (
                              <Text color="red">
                                {item.variants[0].inventory_quantity}
                              </Text>
                            ) : (
                              <Text>{item.variants[0].inventory_quantity}</Text>
                            )}
                          </>
                        ) : null}
                        <Flex gap="10px" color="gray">
                          <Text>{item.product_type}</Text>
                          <Text>{item.vendor}</Text>
                        </Flex>
                      </Flex>

                      <Text>
                        {item.status === "active" ? (
                          <Badge colorScheme="green">{item.status}</Badge>
                        ) : (
                          <Badge colorScheme="red">{item.status}</Badge>
                        )}
                      </Text>
                    </Flex>
                    <hr />
                  </Box>
                ))}
            </Box>
          ) : (
            <Table variant="simple">
              <Thead>
                <Tr>
                  {/* <Th>
                    <Checkbox
                      size="lg"
                      isIndeterminate={
                        allChecked.length !== product.length &&
                        allChecked.length > 0
                      }
                      isChecked={allChecked.length === product.length}
                      onChange={(e) => {
                        handleAllChecked(e);
                      }}
                    ></Checkbox>
                  </Th> */}
                  <Th>
                    {" "}
                    <Text fontSize="sm"></Text>
                  </Th>
                  <Th>
                    {" "}
                    <Text fontSize="sm">Products</Text>
                  </Th>
                  <Th>
                    <Text fontSize="sm">status</Text>
                  </Th>
                  <Th>
                    <Text fontSize="sm">Inventory</Text>{" "}
                  </Th>
                  <Th>
                    <Text fontSize="sm">Type</Text>
                  </Th>
                  <Th>
                    <Text fontSize="sm">Vendor</Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {product &&
                  // navigate(`/products/${item.id}`)
                  product.map((item, i) => (
                    <Tr
                      key={i}
                      onClick={(e) => {
                      
                        if (!e.target.closest(".checkbox_check"))
                          navigate(`/admin/products/${item.id}`);
                      }}
                    >
                      {/* <Td>
                        <Checkbox
                          className="checkbox_check"
                          id={`check_${i}`}
                          size="md"
                          isChecked={allChecked.includes(i) ? true : false}
                          onChange={(e) => handleAllChildrenClick(e, i, item)}
                        ></Checkbox>
                      </Td> */}
                      <Td>
                        <img
                          src={item.images[0]}
                          alt=""
                          style={{ height: "30px", width: "30px" }}
                        />
                      </Td>
                      <Td
                        _hover={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        {item.title}
                      </Td>
                      <Td>
                        {item.status === "active" ? (
                          <Badge colorScheme="green">{item.status}</Badge>
                        ) : (
                          <Badge colorScheme="red">Removed</Badge>
                        )}
                      </Td>
                     
                      {item.variants.length > 0 ? (
                        <>
                          {item.variants[0].inventory_quantity < 0 ? (
                            <Td color="red" fontWeight="bold">
                              {item.variants[0].inventory_quantity}
                            </Td>
                          ) : (
                            <Td color="green" fontWeight="bold">
                              {item.variants[0].inventory_quantity}
                            </Td>
                          )}
                        </>
                      ) : null}
                      <Td>{item.product_type}</Td>
                      <Td>{item.vendor}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>:
        <Text color="red.400" fontSize="40px" 
        fontWeight="semibold" mt="80px" pb="80px">No Product Found</Text>}
      </div>
      <Flex mt="10px" gap="10px" justifyContent="center" >
          <Button bg="black"  color="white"
          isDisabled={page===1}
          onClick={()=>setPage((prev)=>prev-1)}
        >Previous Page</Button>
          <Box height="40px" pt="7px"
          width="40px" bg="black" borderRadius="10px" color="white">
            <Text fontWeight="bold">{page}</Text>
            </Box>
          <Button bg="black"  color="white"
          isDisabled={loadingprod || product.length<25}
           onClick={()=>setPage((prev)=>prev+1)}
          >Next Page</Button>
        </Flex>
    </Box>}
    </>
  );
};

export default Products;
