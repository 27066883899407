import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  FormLabel,
  Text,
  useToast,
  Divider,
  Spinner,
  Box,
  Flex,
  Checkbox,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import { setToast } from "../../../../Fucntions/Toastfunction";
import { useDispatch } from "react-redux";
import { DeliverySlotsAfterHittingApi, OrderEditingDateAndCityDeail } from "../../../../redux/Admin/AppReducer/orders/action";
import ModalForDeliverySlot from "./ModalForDeliverySlot";
import { useSelector } from "react-redux";
import { getCitysData } from "../../../../redux/Admin/AppReducer/vendors/action";
import { useNavigate } from "react-router-dom";

const ModalForCityAndDate = ({ isOpen, onClose}) => {
  const toast = useToast();
  const navigate=useNavigate()
  const citys = useSelector((state) => state.VendorReducer.citys);
  const dispatch = useDispatch();
  const productdetail=useSelector(
    (state) => state.OrderReducer.Order_Editing_Added_Product);
    const variantId=useSelector(
      (state) => state.OrderReducer.Order_Editing_Variantid_Of_Product);
 
  const [cityanddatedetail, setCityAndDateDetail] = useState({
    city:"",
    date: "",
    cut_off: "",
    variant_id: variantId,
    time_slot:""
  });
  
  const today = new Date().toISOString().split("T")[0];
// // console.log(cityanddatedetail,"city")
//  console.log(productdetail,"detail for prod")

  function handleCityDate(e) {
    let { name, value } = e.target;
    console.log(name,value,"value")
    if (value.charAt(0) === ' ') {
      e.target.value = value.slice(1);
      return
    }
    if (name === "date") {
      const parts = value.split("-");
      value = `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    setCityAndDateDetail({
      ...cityanddatedetail,
      [name]: value,
    });
  }
const [processing,setPorcessing]=useState(false)
  function handleSubmitDataHitApi() {
    console.log(cityanddatedetail,"city")
    setPorcessing(true)
    dispatch(OrderEditingDateAndCityDeail(cityanddatedetail))
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/product/shipping_methods`,
      data:cityanddatedetail,
      headers,
    })
      .then((r) => {
        if (r.data.error) {
          setToast(toast, `${r.data.error}`, "", "error");
          onClose()
        } else {
          // console.log(r.data);
          dispatch(DeliverySlotsAfterHittingApi(r.data));
          openDeliverySlotModal()
          onClose()
        }
        setPorcessing(false)
        // console.log(r, "r");
        setCityAndDateDetail({
          city:"",
          date: "",
          cut_off: "",
          variant_id: variantId,
          time_slot:""
        })
      })
      .catch((err) => {
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        setToast(toast,`${err.response.data.err}`,"","error")
         setPorcessing(false)
         setCityAndDateDetail({
          city:"",
          date: cityanddatedetail.date,
          cut_off: "",
          variant_id: variantId,
          time_slot:""
        })
      });
   
  }

  function closeModal() {
    setCityAndDateDetail({
      city: "",
      date: "",
      cut_off: "",
      variant_id: "",
    });
    onClose();
  }

  useEffect(() => {
    if(productdetail?.properties){
      for (let i = 0; i < productdetail.properties.length; i++) {
        if (productdetail.properties[i].name === "time_slot") {
          const k = productdetail?.properties[i].value;
          setCityAndDateDetail((prevState) => ({
            ...prevState,
            time_slot: k,
          }));
          break;
        }
      }
    }
    else{
      for (let i = 0; i < productdetail?.metafields?.length; i++) {
        if (productdetail?.metafields[i].key === "cut_off_time") {
          const k = productdetail?.metafields[i].value;
          setCityAndDateDetail((prevState) => ({
            ...prevState,
            cut_off: k,
          }));
          break;
        }
      }
    }
    setCityAndDateDetail((prevState) => ({
      ...prevState,
      variant_id: variantId,
    }));
  }, [variantId,productdetail]); 


  // ****************    MODAL FOR DELIVERY SLOT ********************

  const [deliverySlotStatus,setDeliverySlotStatus]=useState(false)
  const openDeliverySlotModal=()=>{
    setDeliverySlotStatus(true)
  }
  const closeDeliverySlotModal=()=>{
    setDeliverySlotStatus(false)
  }

 

  useEffect(()=>{
    dispatch(getCitysData(`${process.env.REACT_APP_API_URL}/citygroup/read`,navigate));
  },[])

  return (
    <div>
      <ModalForDeliverySlot
        isOpen={deliverySlotStatus}
        onClose={closeDeliverySlotModal}
      />
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Time And Date</ModalHeader>
          <ModalCloseButton onClick={() => closeModal()} />
          <ModalBody pb={6} textAlign="left">
            <Divider />
            <br />
            <Select
      value={cityanddatedetail.city}
      onChange={(e) =>{ 
        setCityAndDateDetail({
          ...cityanddatedetail,
          city:e.target.value
        })
        }}
      placeholder="Please select city"
    >
      {citys.map((item) => (
        <optgroup label={item.name} key={item.name}>
          {item.cities.map((city) => (
            <option value={city} key={city}>
              {city}
            </option>
          ))}
        </optgroup>
      ))}
    </Select>
            <br />
            <FormControl>
              <FormLabel>Select Date</FormLabel>
              <Input
                type="date"
                min={today}
                name="date"
                onChange={handleCityDate}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter gap="20px">
            <Button onClick={() => closeModal()} colorScheme="red">Cancel</Button>
            <Button
              colorScheme="blue"
              mr={3}
              isDisabled={cityanddatedetail.date === ""}
              onClick={handleSubmitDataHitApi}
            >
             {processing?<Spinner/>:"Done"} 
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ModalForCityAndDate;
