import React, { useState } from "react";
import OrderStatusComp from "./OrderStatusComp";
import { Text } from "@chakra-ui/react";

const AttemptedOrders = () => {
  let [policy, setPolicy] = useState([]);
  let [buttons_name, setButtonname] = useState([]);
  let [button_modal, setButtonModal] = useState([]);
  return (
    <div>
      <OrderStatusComp
        status={"attempted"}
        order_status={"attempted_orders"}
        heading={"Attempted Orders"}
        selection={true}
        challanshow={false}
        // button_action={["allocated", "out_for_delivery", "driver_allocated"]}
        // buttons_line={["Allocate Orders","Out For Delivery","Allocate Driver",]}
        // modal_fn={[
        //   {
        //     heading: "Allocate Order",
        //     action: "Are You Sure You want to Allocate This order",
        //     ConfirmButton: "Allocated Order",
        //     color: "green",
        //     toast_notification: "Order Allocated",
        //   },
        //   {
        //     heading: "Out For Delivery Order",
        //     action:
        //       "Are You Sure You want to Mark This order As Out For Delivery",
        //     ConfirmButton: "Mark As out For Delivery",
        //     color: "teal",
        //     toast_notification: "Order Out For Delivery",
        //   },
        //   {
        //     heading: "Allocate To Driver",
        //     action: "Are You Sure Your Want to Allocate this order to",
        //     ConfirmButton: "Confirm",
        //     color: "blue",
        //     toast_notification: "Order Allocated To Driver",
        //   },
        // ]}
        button_action={policy}
        buttons_line={buttons_name}
        modal_fn={button_modal}
        setPolicy={setPolicy}
        setButtonModal={setButtonModal}
        setButtonname={setButtonname}
        policy={policy}
        buttons_name={buttons_name}
        button_modal={button_modal}
      />
    </div>
  );
};

export default AttemptedOrders;
