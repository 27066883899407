import { Box, Card, CardBody, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { BsDownload } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const DpHome = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Flex mt="20px" flexDirection="column" gap="20px" fontWeight="semibold">
        <Card
          bg="#A1BE28"
          color="white"
          _hover={{ cursor: "pointer" }}
          onClick={() => navigate("/deliverypartner/assigned")}
        >
          <CardBody>
            <Flex
              justifyContent="space-around"
              fontFamily="NexaText-Trial-Regular"
            >
              <Flex
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                borderRadius="7px"
                justifyContent="center"
                alignItems="center"
                color="#A1BE28"
                bg="white"
                height="40px"
                pt="5px"
                width="200px"
                fontSize={"20px"}
              >
                Assigned Orders
              </Flex>
            </Flex>
          </CardBody>
        </Card>
        <Card
          bg="#A1BE28"
          color="white"
          _hover={{ cursor: "pointer" }}
          onClick={() => navigate("/deliverypartner/outfordelivery")}
        >
          <CardBody>
            <Flex
              justifyContent="space-around"
              fontFamily="NexaText-Trial-Regular"
            >
              <Flex
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                borderRadius="7px"
                justifyContent="center"
                alignItems="center"
                color="#A1BE28"
                bg="white"
                height="40px"
                pt="5px"
                width="200px"
                fontSize={"20px"}
              >
               Out For Delivery
              </Flex>
            </Flex>
          </CardBody>
        </Card>
        <Card
          bg="#A1BE28"
          color="white"
          _hover={{ cursor: "pointer" }}
          onClick={() => navigate("/deliverypartner/attempted")}
        >
          <CardBody>
            <Flex
              justifyContent="space-around"
              fontFamily="NexaText-Trial-Regular"
            >
              <Flex
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                borderRadius="7px"
                justifyContent="center"
                alignItems="center"
                color="#A1BE28"
                bg="white"
                height="40px"
                pt="5px"
                width="200px"
                fontSize={"20px"}
              >
                Attempted Orders
              </Flex>
            </Flex>
          </CardBody>
        </Card>
        <Card
          bg="#A1BE28"
          color="white"
          _hover={{ cursor: "pointer" }}
          onClick={() => navigate("/deliverypartner/delivered")}
        >
          <CardBody>
            <Flex
              justifyContent="space-around"
              fontFamily="NexaText-Trial-Regular"
            >
              <Flex
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                borderRadius="7px"
                justifyContent="center"
                alignItems="center"
                color="#A1BE28"
                bg="white"
                height="40px"
                pt="5px"
                width="200px"
                fontSize={"20px"}
              >
                Delivered Orders
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </div>
  );
};

export default DpHome;
