import React, { useEffect } from "react";
import fnp_logo from "../../images/fnp_logo.jpg";

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Icon,
  DrawerCloseButton,
  Image,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import DpAccordincatlog from "./DpAccordinCatlog";

const DpSideDrawer = () => {
  const { pathname } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement, setPlacement] = React.useState("left");

  useEffect(() => {
    onClose();
  }, [pathname]);

  return (
    <div>
      <Icon
        w={"28px"}
        h={"28px"}
        mr={"-10px"}
        onClick={onOpen}
        as={GiHamburgerMenu}
      />
      <Drawer
        placement={placement}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <Image
              src={fnp_logo}
              alt="fnp"
              height="70px"
              width="150px"
              margin="10px auto"
            />
            <DrawerCloseButton />
          </DrawerHeader>
          <DrawerBody>
            <DpAccordincatlog />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default DpSideDrawer;
