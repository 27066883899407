import React, { useEffect, useState } from "react";
import OrderCards from "./OrderCards";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const OrderComponent = ({ orderData, refreshdata, setRefreshdata }) => {
  const { search } = useLocation();
  const futuredates = useSelector((K) => K.OrderReducer.shop_future_dates);
  let future = futuredates?.future ? futuredates?.future : [];
  return (
    <div>
      {orderData.map((item) =>
        search === "?filter=future" ? (
          future.includes(item.to_be_shipped_on) ? (
            <OrderCards
              key={item.id}
              data={item}
              refreshdata={refreshdata}
              setRefreshdata={setRefreshdata}
            />
          ) : null
        ) : (
          <OrderCards
            key={item.id}
            data={item}
            refreshdata={refreshdata}
            setRefreshdata={setRefreshdata}
          />
        )
      )}
    </div>
  );
};

export default OrderComponent;
