import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
  Box,
  Flex,
  Text,
  Grid,
  Select,
  useToast,
} from "@chakra-ui/react";
import AllsuborderDatepicker from "./Datepickerfororder/AllsuborderDatepicker";
import { MdOutlineDateRange } from "react-icons/md";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getCitysData,
  getVendorData,
} from "../../../redux/Admin/AppReducer/vendors/action";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getShippingMethodsData } from "../../../redux/Admin/AppReducer/Shipping/action";
import { setToast } from "../../../Fucntions/Toastfunction";

const FilterModal = ({
  isOpen,
  onClose,
  selectedDataForFilter,
  setSelectedDataForFilter,
}) => {
  const navigate = useNavigate();
  const datePick = useRef(null);
  const citys = useSelector((state) => state.VendorReducer.citys);
  const shipping = useSelector((state) => state.ShippingReducer.shipping);
  const vendor = useSelector((state) => state.VendorReducer.vendor);
  const dispatch = useDispatch();
  const toast = useToast();
  let [filterObj, setFilterObject] = useState(selectedDataForFilter);

  const [alldeliveryPartner, setAllDeliveryPartner] = useState([]);
  function fetchAlldeliveryPartner() {
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/suborder/admin_status_update/delivery_partner`,
        { vendor_id: filterObj.partner },
        { headers }
      )
      .then((r) => {
        setAllDeliveryPartner(r.data.delivery_partner);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  useEffect(() => {
    fetchAlldeliveryPartner();
  }, [filterObj.partner]);

  function filterChange(e) {
    const { name, value } = e.target;
    //  if(name==="driver"){
    //   if(!filterObj.partner){
    //     setToast(toast,"Please Select Partner First","","error")
    //     return
    //   }
    //  }
    setFilterObject({
      ...filterObj,
      [name]: value,
      filter: true,
    });
  }
  console.log(filterObj, "filterObj");

  function handleFinalChangeFilter() {
    setSelectedDataForFilter(filterObj);
    onClose();
  }

  // function checkFilterTrueOrFalse(){
  //  let hasValue= Object.values(selectedDataForFilter).some((value)=>value !== null && value !== undefined && value !== "")
  //    setSelectedDataForFilter({
  //     ...selectedDataForFilter,
  //     filter:hasValue
  //    })
  // }
  // useEffect(()=>{

  //  checkFilterTrueOrFalse()
  // },[selectedDataForFilter])

  console.log(selectedDataForFilter, "setSelectedDataForFilter");
  useEffect(() => {
    dispatch(
      getCitysData(`${process.env.REACT_APP_API_URL}/citygroup/read`, navigate)
    );
    dispatch(
      getShippingMethodsData(`${process.env.REACT_APP_API_URL}/shipping/read`)
    );
    dispatch(
      getVendorData(`${process.env.REACT_APP_API_URL}/partner/all`, navigate)
    );
  }, []);
  console.log(shipping, "shipping");
  return (
    <div>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        size="lg"
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Suborder Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Grid templateColumns="repeat(3,1fr)" gap="10px">
              {/* ******************** CITY FILTER *************************** */}
              <Select
                placeholder="City"
                width="140px"
                onChange={(e) => filterChange(e)}
                name="city"
                value={filterObj.city}
              >
                {citys.map((item, i) => (
                  <optgroup label={item.name} key={i}>
                    {item.cities.map((city, j) => (
                      <option value={city} key={j}>
                        {city}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Select>
              {/* ******************** PAYMENT FILTER *************************** */}
              <Select
                placeholder="Payment"
                width="140px"
                name="payment"
                onChange={(e) => filterChange(e)}
                value={filterObj.payment}
              >
                <option value={"pending"} key={"city"}>
                  {"COD"}
                </option>
                <option value={"paid"} key={"city"}>
                  {"Paid"}
                </option>
              </Select>

              {/* ******************** DATE FILTER *************************** */}
              <Flex
                width="140px"
                p="0px 10px 0px 10px"
                backgroundColor="white"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
                justifyContent="center"
                borderRadius="10px"
                alignItems="center"
                alignContent="center"
                _hover={{ cursor: "pointer" }}
                gap="10px"
              >
                <Button bg="white">
                  <label
                    for="#78342345"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "0px 10px 0px 10px",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <MdOutlineDateRange fontSize="20px" color="gray" />
                    <Text fontSize={"12px"}>Filter By Date</Text>
                  </label>
                  <input
                    type="date"
                    pattern="\d{2}-\d{2}-\d{4}"
                    id="#78342345"
                    class="datepicker"
                    name="date"
                    onChange={(e) => filterChange(e)}
                  />
                  <input
                    type="date"
                    style={{ display: "none" }}
                    ref={datePick}
                  />
                </Button>
              </Flex>

              {/* ******************** SHIPPING FILTER *************************** */}
              <Select
                placeholder="Shipping"
                width="140px"
                name="time_slot"
                onChange={(e) => filterChange(e)}
                value={filterObj.time_slot}
              >
                {shipping?.map((item) => (
                  <optgroup label={item.name} key={item.name}>
                    {item.time_slots?.map((slots, i) => (
                      <option value={`${slots.start} - ${slots.end}`} key={i}>
                        {slots.start}- {slots.end}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Select>

              {/* ******************** PARTNER FILTER *************************** */}
              {console.log(vendor, "vendor")}
              <Select
                placeholder="Partner"
                width="140px"
                name="partner"
                onChange={(e) => filterChange(e)}
                value={filterObj.partner}
              >
                {vendor.map((item) => (
                  <option value={item._id} key={item._id}>
                    {item.partner_name}
                  </option>
                ))}
              </Select>

              {/* ******************** DELIVERY PARTNER FILTER *************************** */}
              <Select
                placeholder="Delivery Partner"
                width="140px"
                name="driver"
                onChange={(e) => filterChange(e)}
                disabled={!filterObj.partner}
                value={filterObj.driver}
              >
                {alldeliveryPartner.map((item) => (
                  <option value={item.name} key={item._id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleFinalChangeFilter}>
             Apply
            </Button>
            <Button mr={3}
              colorScheme="red"
              onClick={() => {
                setSelectedDataForFilter({});
                setFilterObject({})
                onClose();
              }}
            >
              Clear 
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default FilterModal;
