import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Spinner,
  Input,
  FormControl,
  FormLabel,
  Box,
  Radio,
  Checkbox,
  Flex,
  Text,
} from "@chakra-ui/react";

const ConfirmationModal = (props) => {
  // isOpen, onClose,heading,action,name,onCLickfunctiona

  const cancelRef = React.useRef();
  return (
    <>
      <AlertDialog
        isOpen={props.isOpen}
        leastDestructiveRef={cancelRef}
        onClose={props.onClose}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {props.heading}
            </AlertDialogHeader>
            {props.is_Select_vendor ? (
              <AlertDialogBody>
                {props.action} {sessionStorage.getItem("dp_name")}
              </AlertDialogBody>
            ) : (
              <AlertDialogBody>{props.action}</AlertDialogBody>
            )}
            {props.reason && (
              <FormControl width="80%" margin="auto" mt="10px">
                <FormLabel margin="auto">Reason For Edit</FormLabel>
                <Input
                  placeholder="write Reason For Edit"
                  onChange={(e) => props.setReasonInput(e.target.value)}
                />
              </FormControl>
            )}
            {props.notify && <Flex justifyContent="center" gap="10px">
              <Checkbox
                size="lg"
                onChange={(e) => props.setDontNotify_customer(e.target.checked)}
              />
              <Text fontWeight="bold">Don't Notify customer</Text>
            </Flex>}

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={props.onClose}>
                Cancel
              </Button>
              {props.loader ? (
                <Button
                  colorScheme={props.color ? props.color : "red"}
                  onClick={props.onClickFunction}
                  ml={3}
                  isDisabled={props.reason && !props.reasonInput}
                >
                  {props.loading ? <Spinner /> : props.ConfirmButton}
                </Button>
              ) : (
                <Button
                  colorScheme={props.color ? props.color : "red"}
                  onClick={props.onClickFunction}
                  ml={3}
                  isDisabled={props.reason && !props.reasonInput}
                >
                  {props.ConfirmButton}
                </Button>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ConfirmationModal;
