import { Button } from "@chakra-ui/react";
import React from "react";

const OnHoldButton = ({ name, onClickFn }) => {
  return (
    <div>
      <Button
        onClick={onClickFn}
        colorScheme="teal"
        variant="outline"
        border="1px solid teal"
        fontSize={{ base: "12px", md: "16px", lg: "16px" }}
      >
        {name}
      </Button>
    </div>
  );
};

export default OnHoldButton;
