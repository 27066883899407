import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  Divider,
  CSSReset,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import { Flex, Heading, Icon } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { TbCircleDashed } from "react-icons/tb";
import InVoiceModal from "./InVoiceModal";
const Partiallypaid = () => {
  let [totalItem, setTotalItem] = useState(0);
  const suborder = useSelector((state) => state.OrderReducer.order);

  //   **************************     MODAL FOR SENDING INVOICE   *********************************
  const [invoicemodal, setInvoicemodal] = useState(false);
  const openInvoiceModal = () => {
    setInvoicemodal(true);
  };
  const closeInvoiceModal = () => {
    setInvoicemodal(false);
  };

  function calculateTotalItem() {
    let num = 0;
    for (let i = 0; i < suborder?.line_items?.length; i++) {
      if (
        suborder?.line_items[i].fulfillable_quantity !== 0 &&
        suborder?.line_items[i].fulfillment_status === null
      ) {
        //  setTotalItem((pre)=>pre+suborder?.line_items[i].fulfillable_quantity)
        num += suborder?.line_items[i].fulfillable_quantity;
      }
    }
    let line_arr = suborder?.fulfillments?.flatMap((item) => item.line_items);
    for (let i = 0; i < line_arr?.length; i++) {
      num += line_arr[i].quantity;
    }
    setTotalItem(num);
  }

  useEffect(() => {
    calculateTotalItem();
  }, [suborder]);

  return (
    <Box
      position="relative"
      boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
      backgroundColor="white"
      borderRadius="7px"
      p={3}
      mt="3"
      mb="50px"
      color="blackAlpha.800"
      size={{ base: "30%", md: "60%", lg: "100%" }}
    >
      {/* ************************************   MODAL FOR INVOICE   ******************************* */}
      <InVoiceModal isOpen={invoicemodal} onClose={closeInvoiceModal} />

      <Flex gap="15px" mb="10px">
        <Box height="fit-content" border="5px solid #FAC9A8" borderRadius="50%">
          <TbCircleDashed fontSize="20px" color="#FAC9A9" />
        </Box>
        <Heading
          as="h5"
          size="md"
          fontFamily="NexaText-Trial-Bold"
          mb="15px"
          mt="5px"
        >
          {suborder?.financial_status}
        </Heading>
      </Flex>
      <Box p={3} size={{ base: "30%", md: "60%", lg: "100%" }}>
        <CSSReset />
        <Table
          colorScheme="gray"
          variant="simple"
          style={{ borderCollapse: "collapse" }}
        >
          <Tbody>
            <Tr>
              <Td color={"black"}>Subtotal</Td>
              <Td color={"black"}>{totalItem} items</Td>

              <Td color={"black"}>QAR {suborder?.current_subtotal_price}</Td>
            </Tr>
{/* 
            {suborder?.current_total_discounts_set?.presentment_money
              ?.amount !== "0.00" && (
              <Tr>
                <Td color={"black"}>Discount</Td>
                <Td color={"black"}></Td>

                <Td color={"black"}>
                  QAR -
                  {
                    suborder?.current_total_discounts_set?.presentment_money
                      ?.amount
                  }
                </Td>
              </Tr>
            )} */}

            <Tr>
              <Td color={"black"}>shipping</Td>
              <Td color={"black"}>
                {suborder?.shipping_lines && suborder?.shipping_lines[0]?.code}
              </Td>

              <Td color={"black"}>
                QAR{" "}
                {suborder?.shipping_lines && suborder?.shipping_lines[0]?.price}
              </Td>
            </Tr>

            <Tr>
              <Td color={"black"}>Tax</Td>
              <Td color={"black"}>
                VAT {suborder?.tax_lines && suborder?.tax_lines[0]?.rate * 100}%
                (included){" "}
              </Td>

              <Td color={"black"}>QAR {suborder?.current_total_tax}</Td>
            </Tr>

            <Tr>
              <Td color={"black"}>Discount</Td>
              <Td></Td>

              <Td color={"black"}>
                QAR {suborder?.current_total_discounts_set?.shop_money?.amount}
              </Td>
            </Tr>
            <Tr>
              <Td color={"black"}>Total </Td>
              <Td></Td>

              <Td color={"black"}>QAR {suborder?.current_total_price}</Td>
            </Tr>

            <Tr>
              <Td color={"black"}>balance (Customer Owes You)</Td>
              <Td></Td>

              <Td color={"black"}>QAR {suborder?.total_outstanding}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>

      <Flex mt="10px" gap="10px">
        <Button
          variant="outline"
          border="2px solid gray"
          onClick={openInvoiceModal}
        >
          Send Invoice
        </Button>
        <Accordion allowToggle position="relative">
          <AccordionItem position="absolute" width="max-content">
            <h2>
              {/* <AccordionButton bg="#007A5C"  color="white"_hover={{bg:"#007A5C"}}  borderRadius="5px">
        <Box as="span" flex='1' textAlign='left' fontWeight="semibold" mr="10px">
          Collect Payment
        </Box>
        <br />
        <AccordionIcon />
      </AccordionButton> */}
            </h2>
            <AccordionPanel
              pb={4}
              bg="white"
              fontWeight="semibold"
              _hover={{ bg: "gray.200", cursor: "pointer" }}
              opacity="unset"
            >
              Enter Credit Card
            </AccordionPanel>
            <AccordionPanel
              pb={4}
              bg="white"
              fontWeight="semibold"
              _hover={{ bg: "gray.200", cursor: "pointer" }}
            >
              Mark As Paid
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    </Box>
  );
};

export default Partiallypaid;
