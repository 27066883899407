export const GET_ORDER_DATA_LOADING = "GET_ORDER_DATA_LOADING";
export const GET_ORDER_DATA_ERROR = "GET_ORDER_DATA_ERROR";
export const GET_ORDER_DATA_SUCCESS = "GET_ORDER_DATA_SUCCESS";

// export const CREATE_ORDER_DATA_LOADING = "CREATE_ORDER_DATA_LOADING";
// export const CREATE_ORDER_DATA_ERROR = "CREATE_ORDER_DATA_ERROR";
// export const CREATE_ORDER_DATA_SUCCESS = "CREATE_ORDER_DATA_SUCCESS";

export const ORDER_EDITING_START_LOADING = "ORDER_EDITING_START_LOADING";
export const ORDER_EDITING_START_ERROR = "ORDER_EDITING_START_ERROR";
export const ORDER_EDITING_START_SUCCESS = "ORDER_EDITING_START_SUCCESS";

export const UPDATE_SUBORDER_DATA_LOADING = "UPDATE_SUBORDER_DATA_LOADING";
export const UPDATE_SUBORDER_DATA_SUCCESS = "UPDATE_SUBORDER_DATA_SUCCESS";
export const UPDATE_SUBORDER_DATA_ERROR = "UPDATE_SUBORDER_DATA_ERROR";

export const ORDER_EDITING_PRODUCT_AND_VARIANTID_ADDED_LOADING =
  "ORDER_EDITING_PRODUCT_AND_VARIANTID_ADDED_LOADING";
export const ORDER_EDITING_PRODUCT_AND_VARIANTID_ADDED_ERROR =
  "ORDER_EDITING_PRODUCT_AND_VARIANTID_ADDED_ERROR";
export const ORDER_EDITING_PRODUCT_AND_VARIANTID_ADDED_SUCCESS =
  "ORDER_EDITING_PRODUCT_AND_VARIANTID_ADDED_SUCCESS";

export const ORDER_EDITING_DATE_CITY_DATA_LOADING =
  "ORDER_EDITING_DATE_CITY_DATA_LOADING";
export const ORDER_EDITING_DATE_CITY_DATA_SUCCESS =
  "ORDER_EDITING_DATE_CITY_DATA_SUCCESS";
export const ORDER_EDITING_DATE_CITY_DATA_ERROR =
  "ORDER_EDITING_DATE_CITY_DATA_ERROR";

export const ORDER_EDITING_DELIVERY_SLOT_LOADING =
  "ORDER_EDITING_DELIVERY_SLOT_LOADING";
export const ORDER_EDITING_DELIVERY_SLOT_ERROR =
  "ORDER_EDITING_DELIVERY_SLOT_ERROR";
export const ORDER_EDITING_DELIVERY_SLOT_SUCCESS =
  "ORDER_EDITING_DELIVERY_SLOT_SUCCESS";

export const CALL_ORDER_PRINT_CHALLAN = "CALL_ORDER_PRINT_CHALLAN";
export const ORDER_EDITING_ORDER_NOTE_ATTR_SUCCESS =
  "ORDER_EDITING_ORDER_NOTE_ATTR_SUCCESS";

export const SET_SELECTED_TIME_SLOT = "SET_SELECTED_TIME_SLOT";

export const SET_ORDER_ALLOCATED_FUTURE_FOR_SHOP =
  "SET_ORDER_ALLOCATED_FUTURE_FOR_SHOP";
