import { Box, Heading } from '@chakra-ui/react'
import React from 'react'

const Error = () => {
  return (
    <Box color="gray.600">
        <Heading as='h2' size='2xl'
       mt="200px"
        fontFamily="NexaText-Trial-Heavy">
         Error 4O4
         </Heading>
         <Heading as='h2' size='xl'
        fontFamily="NexaText-Trial-Bold">
        page not found
         </Heading>
    </Box>
  )
}

export default Error