export const GET_PRODUCT_DATA_LOADING = "GET_PRODUCT_DATA_LOADING";
export const GET_PRODUCT_DATA_SUCCESS = "GET_PRODUCT_DATA_SUCCESS";
export const GET_PRODUCT_DATA_ERROR = "GET_PRODUCT_DATA_ERROR";

export const GET_SINGLE_PRODUCT_DATA_LOADING = "GET_SINGLE_PRODUCT_DATA_LOADING";
export const GET_SINGLE_PRODUCT_DATA_SUCCESS = "GET_SINGLE_PRODUCT_DATA_SUCCESS";
export const GET_SINGLE_PRODUCT_DATA_ERROR = "GET_SINGLE_PRODUCT_DATA_ERROR";

//***************************  SEARCH IS COMMON FOR VENDOR AND PRODUCT AND MANAGED FROM HERE *****************************
export const SEARCHED_VENDOR_DATA_LOADING = "SEARCHED_VENDOR_DATA_LOADING";
export const SEARCHED_VENDOR_DATA_ERROR = "SEARCHED_VENDOR_DATA_ERROR";
export const SEARCHED_VENDOR_DATA_SUCCESS = "SEARCHED_VENDOR_DATA_SUCCESS";


// export const UPDATE_PRODUCT_DATA_IN_VENDOR_LOADING="UPDATE_PRODUCT_DATA_IN_VENDOR_LOADING";
// export const UPDATE_PRODUCT_DATA_IN_VENDOR_ERROR="UPDATE_PRODUCT_DATA_IN_VENDOR_LOADING";
// export const UPDATE_PRODUCT_DATA_IN_VENDOR_SUCCESS="UPDATE_PRODUCT_DATA_IN_VENDOR_LOADING";

export const UPDATE_VENDOR_DETAIL_IN_PRODUCT_LOADING="UPDATE_VENDOR_DETAIL_IN_PRODUCT_LOADING";
export const UPDATE_VENDOR_DETAIL_IN_PRODUCT_ERROR="UPDATE_VENDOR_DETAIL_IN_PRODUCT_LOADING";
export const UPDATE_VENDOR_DETAIL_IN_PRODUCT_SUCCESS="UPDATE_VENDOR_DETAIL_IN_PRODUCT_LOADING";

export const UPDATE_VARIANTS_DETAIL_IN_PRODUCTDETAILPAGE_LOADING="UPDATE_VARIANTS_DETAIL_IN_PRODUCTDETAILPAGE_LOADING";
export const UPDATE_VARIANTS_DETAIL_IN_PRODUCTDETAIL_PAGE_ERROR="UPDATE_VARIANTS_DETAIL_IN_PRODUCTDETAIL_PAGE_ERROR";
export const UPDATE_VARIANTS_DETAIL_IN_PRODUCTDETAIL_PAGE_SUCCESS="UPDATE_VARIANTS_DETAIL_IN_PRODUCTDETAIL_PAGE_SUCCESS"