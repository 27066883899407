import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardBody,
  Flex,
  Grid,
  Spinner,
  Text,
  Toast,
  useToast,
} from "@chakra-ui/react";
import { BsDownload } from "react-icons/bs";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getVendorSingleData } from "../../redux/Admin/AppReducer/vendors/action";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setToast } from "../../Fucntions/Toastfunction";

const HomePartner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  // const partnerDetail = useSelector(
  //   (state) => state.VendorReducer.singlevendor[0]?.partner
  // );
  const [partnerDetail, setpartnerDetail] = useState({});
  console.log(partnerDetail, "detail");
  const loader = useSelector((state) => state.VendorReducer.isloading);

  const [Allocated, setAllocated] = useState({});
  const [AcceptedState, setAcceptedState] = useState({});
  const [RejectedState, setRejectedState] = useState({});

  function categorizeDates(dateArray, setStateObj) {
    const today = moment().startOf("day");
    const tomorrow = moment().add(1, "day").startOf("day");
    const yesterday = moment().subtract(1, "day").startOf("day");
    const todayDates = [];
    const tomorrowDates = [];
    const yesterdayDates = [];
    const futureDates = [];
    const pastDates = [];
    let obj = {};
    dateArray.forEach((date) => {
      const momentDate = moment(date, "YYYY-MM-DD");
      if (momentDate.isSame(today, "day")) {
        todayDates.push(date);
        obj.today = todayDates;
      } else if (momentDate.isSame(tomorrow, "day")) {
        tomorrowDates.push(date);
        obj.tomorrow = tomorrowDates;
      } else if (momentDate.isSame(yesterday, "day")) {
        yesterdayDates.push(date);
        obj.yesterday = yesterdayDates;
      } else if (momentDate.isBefore(today, "day")) {
        pastDates.push(date);
        obj.past = pastDates;
      } else {
        futureDates.push(date);
        obj.future = futureDates;
      }
    });
    setStateObj(obj);
  }
  // // console.log(AcceptedState,"akksakj")

  function pushArray(arr, set) {
    let newarr = [];
    for (let i = 0; i < arr?.length; i++) {
      newarr.push(arr[i].to_be_shipped_on);
    }
    categorizeDates(newarr, set);
  }

  useEffect(() => {
    pushArray(partnerDetail?.allocated_orders, setAllocated);
    pushArray(partnerDetail?.accepted_orders, setAcceptedState);
    pushArray(partnerDetail?.rejected_orders, setRejectedState);
  }, [partnerDetail]);
  console.log(Allocated,"dates")
  function getPartnerDetailData() {
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("partnertoken")
      )}`,
    };
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/partner/new/${JSON.parse(
        sessionStorage.getItem("Partner_id")
      )?JSON.parse(
        sessionStorage.getItem("Partner_id")
      ):JSON.parse(
        sessionStorage.getItem("id")
      )}`,
      headers,
    })
      .then((r) => {
        console.log(r.data, "partner Data");
        setpartnerDetail(r.data.partner);
      })
      .catch((err) => {
        console.log(err, "err daat");
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }
  useEffect(() => {
    // dispatch(
    //   getVendorSingleData(
    //     `${process.env.REACT_APP_API_URL}/partner/new/${JSON.parse(
    //       sessionStorage.getItem("id")
    //     )}`,
    //     "partnertoken"
    //   )
    // );
    getPartnerDetailData();
  }, []);

  // console.log(Allocated, "sadfk");
  return (
    <Box>
      {loader ? (
        <Spinner size="xl" marginTop="50px" />
      ) : (
        <>
          <Grid
            templateColumns={[
              "repeat(1,1fr)",
              "repeat(2,1fr)",
              "repeat(2,1fr)",
              "repeat(2,1fr)",
              "repeat(3,1fr)",
            ]}
            gap="20px"
            fontSize={{ base: "12px", md: "16px", lg: "16px" }}
          >
            <Box mb="20px">
              <Card
                bg="#A1BE28"
                color="white"
                _hover={{ cursor: "pointer" }}
                onClick={() => navigate("/partner/allocatedorders")}
              >
                <CardBody>
                  <Flex justifyContent="space-between">
                    <Text fontSize="lg" fontFamily="NexaText-Trial-Regular">
                      Allocated
                    </Text>
                    <BsDownload fontSize="25px" />
                  </Flex>
                  <br />
                  <Flex
                    justifyContent="space-around"
                    fontFamily="NexaText-Trial-Regular"
                    gap={"5px"}
                  >
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width={{ base: "80px", md: "80px", lg: "80px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/partner/allocatedorders?filter=today");
                      }}
                    >
                      Today ({Allocated?.today ? Allocated.today.length : 0})
                    </Flex>
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width={{ base: "100px", md: "120px", lg: "120px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/partner/allocatedorders?filter=tomorrow");
                      }}
                    >
                      Tomorrow (
                      {Allocated?.tomorrow ? Allocated.tomorrow.length : 0})
                    </Flex>
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width={{ base: "80px", md: "100px", lg: "100px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/partner/allocatedorders?filter=future");
                      }}
                    >
                      Future ({Allocated?.future ? Allocated.future.length : 0})
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </Box>

            <Box mb="20px">
              <Card
                bg="#A1BE28"
                color="white"
                _hover={{ cursor: "pointer" }}
                onClick={() => navigate("/partner/acceptedorder")}
              >
                <CardBody>
                  <Flex justifyContent="space-between">
                    <Text fontSize="lg" fontFamily="NexaText-Trial-Regular">
                      Accepted
                    </Text>
                    <BsDownload fontSize="25px" />
                  </Flex>
                  <br />
                  <Flex
                    justifyContent="space-around"
                    fontFamily="NexaText-Trial-Regular"
                    gap={"5px"}
                  >
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width={{ base: "80px", md: "100px", lg: "100px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/partner/acceptedorder?filter=today");
                      }}
                    >
                      Today (
                      {AcceptedState?.today ? AcceptedState.today.length : 0})
                    </Flex>
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width={{ base: "100px", md: "120px", lg: "120px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/partner/acceptedorder?filter=tomorrow");
                      }}
                    >
                      Tomorrow (
                      {AcceptedState?.tomorrow
                        ? AcceptedState.tomorrow.length
                        : 0}
                      )
                    </Flex>
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width={{ base: "80px", md: "100px", lg: "100px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/partner/acceptedorder?filter=future");
                      }}
                    >
                      Future (
                      {AcceptedState?.future ? AcceptedState.future.length : 0})
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </Box>

            <Box mb="20px">
              <Card
                bg="#A1BE28"
                color="white"
                _hover={{ cursor: "pointer" }}
                onClick={() => navigate("/partner/printedorders")}
              >
                <CardBody>
                  <Flex justifyContent="space-between">
                    <Text fontSize="lg" fontFamily="NexaText-Trial-Regular">
                      Printed
                    </Text>
                    <BsDownload fontSize="25px" />
                  </Flex>
                  <br />
                  <Flex
                    justifyContent="space-around"
                    fontFamily="NexaText-Trial-Regular"
                  >
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width={{ base: "80px", md: "100px", lg: "100px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/partner/printedorders?filter=today");
                      }}
                    >
                      Today (
                      {partnerDetail?.printed_orders_today?.length})
                    </Flex>
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width="100px"
                    >
                      All({partnerDetail?.printed_orders})
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </Box>
            <Box mb="20px">
              <Card
                bg="#A1BE28"
                color="white"
                _hover={{ cursor: "pointer" }}
                onClick={() => navigate("/partner/rejectedorders")}
              >
                <CardBody>
                  <Flex justifyContent="space-between">
                    <Text fontSize="lg" fontFamily="NexaText-Trial-Regular">
                      Rejected
                    </Text>
                    <BsDownload fontSize="25px" />
                  </Flex>
                  <br />
                  <Flex
                    justifyContent="space-around"
                    fontFamily="NexaText-Trial-Regular"
                  >
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width={{ base: "80px", md: "100px", lg: "100px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/partner/rejectedorders?filter=today");
                      }}
                    >
                      Today (
                      {RejectedState?.today ? RejectedState.today.length : 0})
                    </Flex>
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width="100px"
                    >
                      All({partnerDetail?.rejected_orders?.length})
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </Box>
            <Box mb="20px">
              <Card
                bg="#A1BE28"
                color="white"
                _hover={{ cursor: "pointer" }}
                onClick={() => navigate("/partner/driverallocated")}
              >
                <CardBody>
                  <Flex justifyContent="space-between">
                    <Text fontSize="lg" fontFamily="NexaText-Trial-Regular">
                      Driver Allocated
                    </Text>
                    <BsDownload fontSize="25px" />
                  </Flex>
                  <br />
                  <Flex
                    justifyContent="space-around"
                    fontFamily="NexaText-Trial-Regular"
                  >
                    {" "}
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width={{ base: "80px", md: "100px", lg: "100px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/partner/driverallocated?filter=today");
                      }}
                    >
                      Today (
                      {partnerDetail?.driver_allocated_orders_today
                        ? partnerDetail?.driver_allocated_orders_today.length
                        : 0}
                      )
                    </Flex>
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width="100px"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/partner/driverallocated?filter=all");
                      }}
                    >
                      All ({partnerDetail?.driver_allocated_orders})
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </Box>

            <Box mb="20px">
              <Card
                bg="#A1BE28"
                color="white"
                _hover={{ cursor: "pointer" }}
                onClick={() => navigate("/partner/outfordelivery")}
              >
                <CardBody>
                  <Flex justifyContent="space-between">
                    <Text fontSize="lg" fontFamily="NexaText-Trial-Regular">
                      Out For Delivery
                    </Text>
                    <BsDownload fontSize="25px" />
                  </Flex>
                  <br />
                  <Flex
                    justifyContent="space-around"
                    fontFamily="NexaText-Trial-Regular"
                  >
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width="100px"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/partner/outfordelivery?filter=today");
                      }}
                    >
                      Today (
                      {partnerDetail?.outForDelivery_orders_today?.length})
                    </Flex>
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width="100px"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/partner/outfordelivery?filter=all");
                      }}
                    >
                      All ({partnerDetail?.outForDelivery_orders})
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </Box>

            <Box mb="20px">
              <Card
                bg="#A1BE28"
                color="white"
                _hover={{ cursor: "pointer" }}
                onClick={() => navigate("/partner/deliveredorders")}
              >
                <CardBody>
                  <Flex justifyContent="space-between">
                    <Text fontSize="lg" fontFamily="NexaText-Trial-Regular">
                      Delivered
                    </Text>
                    <BsDownload fontSize="25px" />
                  </Flex>
                  <br />
                  <Flex
                    justifyContent="space-around"
                    fontFamily="NexaText-Trial-Regular"
                  >
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width="100px"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/partner/deliveredorders?filter=today");
                      }}
                    >
                      Today ({partnerDetail?.delivered_orders_today?.length})
                    </Flex>
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width="100px"
                    >
                      All ({partnerDetail?.delivered_orders})
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </Box>

            <Box mb="20px">
              <Card
                bg="#A1BE28"
                color="white"
                _hover={{ cursor: "pointer" }}
                onClick={() => navigate("/partner/attemptedorders")}
              >
                <CardBody>
                  <Flex justifyContent="space-between">
                    <Text fontSize="lg" fontFamily="NexaText-Trial-Regular">
                      Attempted
                    </Text>
                    <BsDownload fontSize="25px" />
                  </Flex>
                  <br />
                  <Flex
                    justifyContent="space-around"
                    fontFamily="NexaText-Trial-Regular"
                  >
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width="100px"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/partner/attemptedorders?filter=today");
                      }}
                    >
                      Today (
                      {partnerDetail?.attempted_orders_today
                        ? partnerDetail?.attempted_orders_today?.length
                        : 0}
                      )
                    </Flex>
                    <Flex
                      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                      borderRadius="7px"
                      justifyContent="center"
                      alignItems="center"
                      color="#A1BE28"
                      bg="white"
                      height="40px"
                      pt="5px"
                      width="100px"
                    >
                      All({partnerDetail?.attempted_orders})
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </Box>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default HomePartner;
