import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAllfuturedatesforShop } from "../../redux/Admin/AppReducer/orders/action";
export const BakerHome = () => {
  const navigate = useNavigate();
  const dispatch=useDispatch()
  let partner_id = JSON.parse(sessionStorage.getItem("partner_id"));
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("bakertoken"))}`,
  };
  // FOR TODAY
  const [todaydata, setTodaydata] = useState([]);
  const todaydate = new Date();
  const momentDatetoday = moment(todaydate).format("YYYY-MM-DD");

  // FOR TOMMORROW
  const [tommorrowdata, setTommorrowdata] = useState([]);
  const tomorrowdate = new Date(todaydate); // Create a new date object with today's date
  tomorrowdate.setDate(todaydate.getDate() + 1); // Add one day to the new date object
  const momentDatetomorrow = moment(tomorrowdate).format("YYYY-MM-DD");

  const [futuredata, setFuturedata] = useState([]);
  const [futurecount, setFutureCount] = useState([]);
  console.log(futurecount, "today");

  function getcountofsuborders(date, setdata, future) {
    axios({
      url: `${process.env.REACT_APP_API_URL}/baker/home`,
      method: "POST",
      headers,
      data: {
        partner_id,
        status1: "accepted",
        status2: "printed",
        status3: "allocated",
        future: future ? true : false,
        date,
      },
    })
      .then((r) => {
        setdata(r.data.suborders);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function categorizeDates(dateArray, setStateObj) {
    const today = moment().startOf("day");
    const tomorrow = moment().add(1, "day").startOf("day");
    const yesterday = moment().subtract(1, "day").startOf("day");
    const todayDates = [];
    const tomorrowDates = [];
    const yesterdayDates = [];
    const futureDates = [];
    const pastDates = [];
    let obj = {};
    dateArray.forEach((date) => {
      const momentDate = moment(date, "YYYY-MM-DD");
      if (momentDate.isSame(today, "day")) {
        todayDates.push(date);
        obj.today = todayDates;
      } else if (momentDate.isSame(tomorrow, "day")) {
        tomorrowDates.push(date);
        obj.tomorrow = tomorrowDates;
      } else if (momentDate.isSame(yesterday, "day")) {
        yesterdayDates.push(date);
        obj.yesterday = yesterdayDates;
      } else if (momentDate.isBefore(today, "day")) {
        pastDates.push(date);
        obj.past = pastDates;
      } else {
        futureDates.push(date);
        obj.future = futureDates;
      }
    });
    setStateObj(obj);
  }

  function pushArray(arr, set) {
    let newarr = [];
    for (let i = 0; i < arr?.length; i++) {
      newarr.push(arr[i].to_be_shipped_on);
    }
    categorizeDates(newarr, set);
  }
  useEffect(() => {
    getcountofsuborders(momentDatetoday, setTodaydata);
    getcountofsuborders(momentDatetomorrow, setTommorrowdata);
    getcountofsuborders(momentDatetoday, setFuturedata, true);
  }, [partner_id]);

  useEffect(() => {
    pushArray(futuredata, setFutureCount);
  }, [futuredata]);

  useEffect(()=>{
  dispatch(setAllfuturedatesforShop(futurecount))
  },[futurecount])
  return (
    <Box mt="200px">
      <Box
        boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
        width={{ base: "100%", md: "80%", lg: "400px" }}
        bg="white"
        margin="auto"
      >
        <Box border="1px solid #FFFFF">
          <Box bg="#7d7f34" textAlign="left" p="5px 0px 5px 10px">
            <Flex>
              <Text color="white" fontWeight="semibold">
                Allocated
              </Text>
              <Text></Text>
            </Flex>
          </Box>
          <Flex flexDirection="column" textAlign="left">
            <Flex
              p="10px 10px 10px 10px"
              justifyContent="space-between"
              onClick={() => navigate("/shop/allocated?filter=today")}
              _hover={{ cursor: "pointer" }}
            >
              <Text fontWeight="semibold" mt="10px">
                Today
              </Text>
              <Box bg="#7d7f34" borderRadius="50%" p="2" width="40px">
                <Text fontWeight="semibold" color="white" textAlign="center">
                  {todaydata.length}
                </Text>
              </Box>
            </Flex>
            <Divider />
            <Flex
              p="10px 10px 10px 10px"
              justifyContent="space-between"
              onClick={() => navigate("/shop/allocated?filter=tommorrow")}
              _hover={{ cursor: "pointer" }}
            >
              <Text fontWeight="semibold" mt="10px">
                Tommorrow
              </Text>
              <Box bg="#7d7f34" borderRadius="50%" p="2" width="40px">
                <Text fontWeight="semibold" color="white" textAlign="center">
                  {tommorrowdata.length}
                </Text>
              </Box>
            </Flex>
            <Divider />
            <Flex
              p="10px 10px 10px 10px"
              justifyContent="space-between"
              onClick={() => navigate("/shop/allocated?filter=future")}
              _hover={{ cursor: "pointer" }}
            >
              <Text fontWeight="semibold" mt="10px">
                Future
              </Text>
              <Box bg="#7d7f34" borderRadius="50%" p="2" width="40px">
                <Text fontWeight="semibold" color="white" textAlign="center">
                  {futurecount.future ? futurecount.future.length : 0}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
