import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Authentication = ({ children }) => {


  const isAuth = useSelector((state)=>state.AuthReducer.PartnerisAuth);
  if (isAuth) {
    return children;
  }
 
  return <Navigate to="/partner/login" />;
};

export default Authentication;
