import {
  Badge,
  Box,
  Flex,
  Stack,
  Text,
  Button,
  Heading,
  Divider,
  Image,
  Toast,
  useToast,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  RadioGroup,
  Radio,
  Input,
  Link,
} from "@chakra-ui/react";
import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import TimelineComp from "./Timeline";
import RightSideBar from "./RightSideBar";
import PaidComp from "./PaidComp";
import { useSelector } from "react-redux";
import { MdKeyboardBackspace } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ModaldorAllocatevendor from "./ModaldorAllocatevendor";
import { useDispatch } from "react-redux";
import {
  OrderEditingAddProductAndVarinatId,
  OrderEditingNoteattr,
} from "../../../redux/Admin/AppReducer/orders/action";
import Partiallypaid from "./Partiallypaid";
import ModalForCityAndDate from "../orders/EditMainOrder/ModalForCityAndDate";
import ModalforEditOrder from "../orders/EditSubOrder/ModalforEditOrder";
import EditPropertiesAddressModal from "./EditPropertiesAddressModal";
import { BiCheckCircle } from "react-icons/bi";
import axios from "axios";
import { setToast } from "../../../Fucntions/Toastfunction";
import SuborderPopupChallan from "../../../SuborderChallan/SuborderPopupChallan";
import ConfirmationModal from "../../../Fucntions/Modals/ConfirmationModal";
import "./style/timeline.module.css";
import * as types from "../../../redux/Admin/AppReducer/orders/action.types";
import { manageNoteAttribute } from "../../../Fucntions/NoteAttribute";
import { sub } from "date-fns";
import PersonalizedImage from "../../../images/personalized-image.png";
import timeline from "../../../images/timeline.jpg";

const OrderDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { id } = useParams();
  const [reason, setReason] = useState("");
  const [reasonArr, setReasonArr] = useState([]);
  const [isOpenModal3, setIsOpenModal3] = useState(false);
  const suborder = useSelector((state) => state.OrderReducer.order);
  console.log(suborder, "suborder");

  const [isLoading, setLoading] = useState(false);
  const [selectStatus, setSelectedStatus] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const openModal3 = () => {
    setIsOpenModal3(true);
  };
  const closeModal3 = () => {
    setIsOpenModal3(false);
  };

  // ****************************  EDIT PROPERTIES MODAL  ***************************************
  const [editpropertyAddressstatus, setEditPropertyAddressStatus] =
    useState(false);
  const openEditPropertyAddressModal = (item) => {
    setEditPropertyAddressStatus(true);
    dispatch(OrderEditingAddProductAndVarinatId([item, item.variant_id]));
  };
  const closeEditPropertyAddressModal = () => {
    setEditPropertyAddressStatus(false);
  };
  const saveReason = () => {
    setReasonArr([...reasonArr, reason]);
    setReason("");
    //(reasonArr)
    closeModal3();
  };
  // ***************************    MODAL FOR ALLOCATE VENDOR  *****************************************
  let [allocatetovendor, setAllocatetoVendor] = useState();
  let [relatedId, setRelatedId] = useState(null);
  const openAllocatetovendorModal = (relid) => {
    setRelatedId(relid);
    setAllocatetoVendor(true);
  };
  const closeAllocatedvendorModal = () => setAllocatetoVendor(false);

  //  ************************   ORDER EDITING START   ******************************************
  const handleOrderEditingStart = () => {
    //navigate(`/admin/order/suborder/editorder/${id}`);
  };

  // *************    MODAL FOR CITY AND DATE   *********************
  const [cityAndDateModalstatus, setCityAndDateModalStatus] = useState(false);

  const openCityAndDateModal = (item) => {
    dispatch(
      OrderEditingAddProductAndVarinatId([
        item.line_items[0],
        item.line_items[0].variant_id,
      ])
    );
    dispatch(OrderEditingNoteattr(item.note_attributes));
    //  onClose()

    setCityAndDateModalStatus(true);
  };
  const closeCityAndDateModal = () => setCityAndDateModalStatus(false);

  // ************************   ADD MORE DETAIL MODAL *******************************
  const [ordermodalopen, setOrderModalOpen] = useState(false);
  const OnOpenOrderModal = (item) => {
    setOrderModalOpen(true);
    dispatch(OrderEditingAddProductAndVarinatId([item, item.variant_id]));
  };
  const onCloseOrderModal = () => setOrderModalOpen(false);

  const [suborder_data, setSuborder_data] = useState([]);
  function getSuborderDataforSuborderDetail() {
    if (!suborder?.line_items) {
      return;
    }
    if (suborder.suborder_name) {
      return;
    }
    if (!suborder?.line_items[0]?.related_suborder) {
      return;
    }
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "GET",
      headers,
      url: `${process.env.REACT_APP_API_URL}/suborder/read/${suborder?.line_items[0]?.related_suborder}`,
    })
      .then((r) => {
        console.log(r.data, "dta");
        setSuborder_data(r.data);
      })
      .catch((err) => {
        console.log(err, "errerrerr");
      });
  }
  useEffect(() => {
    getSuborderDataforSuborderDetail();
  }, [suborder]);

  let shipping_method =
    suborder?.shipping_method ||
    suborder?.note_attributes?.find((k) => k.name === "shipping_method")?.value;

  let shipping_date =
    suborder?.to_be_shipped_on ||
    suborder?.note_attributes?.find((k) => k.name === "shipping_date")?.value;

  let shipping_time =
    suborder?.time_slot ||
    suborder?.note_attributes?.find((k) => k.name === "shipping_time")?.value;

  function showBoxOrNot(arr) {
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i].fulfillment_status === "fulfilled") {
        return true;
      }
    }
    return false;
  }

  function extractObjdata(obj) {
    let valuesArray = [];

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        valuesArray.push(obj[key]);
      }
    }
    return valuesArray;
  }

  function ForSelectingWhichIsSuborder(arr = []) {
    let clone = [...arr];
    let parent = [];

    if (pathname === `/admin/order/${id}`) {
      // console.log("order");
      let SuborderCheck = [];
      clone.forEach((item) => {
        if (!item.related_suborder) {
          parent.push([item]);
        } else {
          if (!SuborderCheck.includes(item.related_suborder)) {
            const children = clone.filter(
              (x) => x.related_suborder === item.related_suborder
            );
            parent.push(children);
            SuborderCheck.push(item.related_suborder);
          }
        }
      });

      const newParent = parent.map((child) => {
        if (child.length > 1) {
          let parentItem = [];
          let children = [];
          child.forEach((item) => {
            if (
              item.properties.map((prop) => prop.name).includes("_parent_id")
            ) {
              children.push(item);
            } else {
              parentItem.push(item);
            }
          });
          return [...parentItem, ...children];
        } else {
          return child;
        }
      });

      return newParent;
    } else {
      for (let k = 0; k < clone.length; k++) {
        if (!clone[k].properties.some((m) => m.name === "_parent_id")) {
          clone.unshift(parent[k]);
          clone.splice(k, 1);
        }

        return [clone];
      }
    }
  }
  // ***************************   BULK CHALLAN WORK  ************************************
  const [bulkchallan, setBulkchallan] = useState([]);

  let [bulkchallanstatus, setBulkchallanstatus] = useState(false);
  const openBulkChallanModal = () => {
    setBulkchallanstatus(true);
  };
  const closeBulkChallanmodal = () => {
    setBulkchallanstatus(false);
  };
  const toast = useToast();
  const [challanloader, setChallanLoader] = useState(false);

  function handlePrintBulkChallanProcess() {
    if (challanloader) {
      return;
    }
    // console.log("======================================")
    setChallanLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/order/${id}/suborders`,
      headers,
    })
      .then((r) => {
        // console.log(r.data,"|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||")
        setChallanLoader(false);
        setBulkchallan(r.data.suborders);
        openBulkChallanModal();
      })
      .catch((err) => {
        setChallanLoader(false);
        setToast(toast, "something went wrong", "", "error");
        // console.log(err)
      });
  }
  //  **************************** MODAL FOR ALL CHALLAN *****************************
  const [issuborderChallan, setIsSuborderChallan] = useState(false);
  const openSuborderChallanModal = () => {
    setIsSuborderChallan(true);
  };
  const closeSuborderChallanModal = () => {
    setIsSuborderChallan(false);
  };

  //  **************************** FOR DELETING ORDER *****************************
  const [orderdeleteconfirm, setorderDeleteConfirm] = useState(false);
  const onOpenOrderDelete = () => {
    setorderDeleteConfirm(true);
  };
  const onCloseOrderDelete = () => {
    setorderDeleteConfirm(false);
  };

  function deleteOrderBeginHitApi() {}

  const handleSuborderStatusUpdate = async (
    suborder_id,
    allocated_partner_id
  ) => {
    setLoading(true);
    if (!selectStatus) {
      console.error("Please select a status");
      return;
    }
    const REQ_URL = `${process.env.REACT_APP_API_URL}/suborder/admin_status_update`;
    const requestData = {
      suborder_ids: suborder_id,
      status: selectStatus,
      partner_id: allocated_partner_id,
    };

    console.log("requestData", requestData);
    try {
      const response = await axios.put(REQ_URL, requestData, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            sessionStorage.getItem("admintoken")
          )}`,
          "Content-Type": "application/json",
        },
      });
      console.log("Data Getting at Frontend", response.data);
      setLoading(false);
      closeModal();
      if (response.data) {
        toast({
          position: "top",
          title: "Status Updated",
          description: `Status changed Succesfully `,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } else {
        toast({
          position: "top",
          title: "Something went wrong",
          description: `Status not changed `,
          status: "failure",
          duration: 2000,
          isClosable: true,
        });
      }
      dispatch(
        getOrderData(
          `${process.env.REACT_APP_API_URL}/suborder/read/${id}`,
          navigate
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  const getOrderData = (url, navigate) => async (dispatch) => {
    dispatch({ type: types.GET_ORDER_DATA_LOADING });
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    return axios({
      method: "get",
      withCredentials: false,
      url: url,
      headers: headers,
    })
      .then((r) => {
        dispatch({ type: types.GET_ORDER_DATA_SUCCESS, payload: r.data });
      })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        dispatch({ type: types.GET_ORDER_DATA_ERROR });
        // setToast(toast, `${err.response.data.err}`, "", "error");
      });
  };

  const isStatusChangeButtonVisible = [
    "allocated",
    "accepted",
    "attempted",
    "out_for_delivery",
  ].includes(suborder?.status);

  const renderModalContent = () => {
    switch (suborder?.status) {
      case "allocated":
        return (
          <>
            <RadioGroup
              defaultValue={undefined}
              onChange={(value) => setSelectedStatus(value)}
            >
              <Stack spacing={5} direction="row">
                <Radio colorScheme="green" value="accepted">
                  Accept
                </Radio>
                <Radio colorScheme="red" value="rejected">
                  Reject
                </Radio>
              </Stack>
            </RadioGroup>
          </>
        );

      case "accepted":
        return (
          <>
            <RadioGroup
              defaultValue={undefined}
              onChange={(value) => setSelectedStatus(value)}
            >
              <Stack spacing={5} direction="row">
                <Radio colorScheme="green" value="out_for_delivery">
                  Out for Delivery
                </Radio>
                <Radio colorScheme="green" value="fulfilled">
                  Fulfilled
                </Radio>
                <Radio colorScheme="green" value="attempted">
                  Attempted
                </Radio>
              </Stack>
            </RadioGroup>
          </>
        );

      case "attempted":
        return (
          <>
            <RadioGroup
              defaultValue={undefined}
              onChange={(value) => setSelectedStatus(value)}
            >
              <Stack spacing={5} direction="row">
                <Radio colorScheme="green" value="fulfilled">
                  Fulfilled
                </Radio>
              </Stack>
            </RadioGroup>
          </>
        );

      case "out_for_delivery":
        return (
          <>
            <RadioGroup
              defaultValue={undefined}
              onChange={(value) => setSelectedStatus(value)}
            >
              <Stack spacing={5} direction="row">
                <Radio colorScheme="green" value="attempted">
                  Attempted
                </Radio>
                <Radio colorScheme="green" value="fulfilled">
                  Fulfilled
                </Radio>
              </Stack>
            </RadioGroup>
          </>
        );

      default:
        return null;
    }
  };

  // ****************  FOR EDITING SENDER DETAIL ********************
  const [edit_sender_detail, set_edit_sender_detail] = useState(false);
  const [senderdetail, setSenderDetail] = useState(null);
  const [senderLoader, setSenderLoader] = useState(false);
  console.log(senderLoader, "loader");
  let sender_name = suborder?.note_attributes?.find(
    (k) => k.name === "sender_name"
  ).value;
  function HandleSaveSenderDetail() {
    if (!senderdetail) {
      setToast(toast, "write something to edit", "", "error");
      return;
    }
    if (senderLoader) {
      return;
    }
    setSenderLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    let new_note = JSON.parse(JSON.stringify(suborder.note_attributes));
    if (new_note.some((k) => k.name === "sender_name")) {
      new_note.find((k) => k.name === "sender_name").value = senderdetail;
    } else {
      new_note.push({ name: "sender_name", value: senderdetail });
    }
    let note_attr = manageNoteAttribute(new_note);
    let data = {
      note_attr,
      id,
    };
    console.log(suborder.note_attributes, "atte");
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/order/updatesendername`,
      data,
      headers,
    })
      .then((r) => {
        setSenderLoader(false);
        set_edit_sender_detail(false);
        setToast(toast, `sender name updated`, "", "success");
        dispatch(
          getOrderData(
            `${process.env.REACT_APP_API_URL}/order/read/${id}`,
            navigate
          )
        );
      })
      .catch((err) => {
        console.log(err);
        set_edit_sender_detail(false);
        setToast(toast, `${err.response.data.err}`, "", "error");
        setSenderLoader(false);
      });
  }

  // ****************  FOR EDITING CAKE MESSAGE ********************
  const [cake_message, setCake_message] = useState([]);
  const [cake_message_modal, setCake_message_modal] = useState(false);
  const [line_item_id_of_cake, setline_item_id_of_cake] = useState(null);
  const openCakeMessageModal = (line_item_id) => {
    console.log(line_item_id, "inde");
    setline_item_id_of_cake(line_item_id);
    setCake_message_modal(true);
  };
  const closeCakeMessageModal = () => {
    setCake_message_modal(false);
  };
  return (
    <Box
      width={{ base: "100%", md: "90%", lg: "95%" }}
      color={"#f5f5f7"}
      margin="auto"
    >
      <ModalforEditOrder
        isOpen={cake_message_modal}
        line_item_id={line_item_id_of_cake}
        onClose={closeCakeMessageModal}
      />
      <ConfirmationModal
        heading="Delete Order"
        action="Are you Sure? You want to Delete this Order"
        ConfirmButton="Yes Delete"
        onClickFunction={deleteOrderBeginHitApi}
        isOpen={orderdeleteconfirm}
        onClose={onCloseOrderDelete}
      />

      {suborder?.suborder_id ? (
        <SuborderPopupChallan
          challan={[suborder]}
          isOpen={issuborderChallan}
          onClose={closeSuborderChallanModal}
        />
      ) : (
        <SuborderPopupChallan
          isOpen={bulkchallanstatus}
          challan={bulkchallan}
          onClose={closeBulkChallanmodal}
        />
      )}
      <ModaldorAllocatevendor
        isOpen={allocatetovendor}
        onClose={closeAllocatedvendorModal}
        relatedId={relatedId}
      />

      {/* *****************  MODAL FOR EDIT PROPERTIES   ************************** */}
      <EditPropertiesAddressModal
        isOpen={editpropertyAddressstatus}
        onClose={closeEditPropertyAddressModal}
      />

      {/* *****************  MODAL FOR CITY  ************************** */}
      <ModalForCityAndDate
        isOpen={cityAndDateModalstatus}
        onClose={closeCityAndDateModal}
      />

      {/* *************************     MODAL FOR EDITING ORDER   ************************************* */}
      <ModalforEditOrder isOpen={ordermodalopen} onClose={onCloseOrderModal} />

      <Flex
        justifyContent="space-between"
        p={{ base: "1", md: "1", lg: "20px" }}
        backgroundColor="#fff"
        border="1px #ccc solid"
        borderRadius="10px"
      >
        <Flex alignContent={"left"} gap="30px">
          <Button
            onClick={() => navigate(-1)}
            variant="outline"
            padding="10px"
            color="black"
            border="1px solid grey"
          >
            <MdKeyboardBackspace fontSize="30px" />
          </Button>

          <Stack direction="row" alignItems="center" p="2px">
            <Text
              fontSize={{ base: "12px", md: "16px", lg: "18px" }}
              color="black"
            >
              {suborder?.suborder_id
                ? suborder.suborder_name
                  ? suborder.suborder_name
                  : null
                : suborder.name}
            </Text>

            {!suborder?.suborder_id && (
              <Badge
                p="8px 10px 8px 10px"
                borderRadius={"20px"}
                colorScheme="purple"
              >
                {suborder.financial_status}
              </Badge>
            )}
            <Badge
              p="8px 10px 8px 10px"
              borderRadius={"20px"}
              colorScheme="purple"
            >
              {suborder?.status
                ? suborder.status
                : suborder.fulfillment_status
                ? suborder.fulfillment_status
                : "Unfulfilled"}
            </Badge>
          </Stack>
        </Flex>

        <Flex gap="10px">
          {isStatusChangeButtonVisible && (
            <Button colorScheme="teal" variant="outline" onClick={openModal}>
              Change status
            </Button>
          )}

          <Modal isOpen={isOpen} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent
              style={{
                width: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ModalHeader style={{ marginTop: "15px" }}>
                Change status here
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody style={{ marginBottom: "15px" }}>
                {renderModalContent()}
                {isLoading ? (
                  <Spinner style={{ marginTop: "15px" }} />
                ) : (
                  <Button
                    colorScheme="teal"
                    variant="outline"
                    mt={4}
                    onClick={() => {
                      handleSuborderStatusUpdate(
                        suborder.suborder_id,
                        suborder.currently_allocated_to
                      );
                    }}
                  >
                    Confirm
                  </Button>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>

          {suborder?.suborder_id && (
            <Button
              colorScheme="teal"
              variant="outline"
              border="1px solid teal"
              fontSize={{ base: "12px", md: "16px", lg: "16px" }}
              onClick={() => navigate(`/admin/order/${suborder?.parent_id}`)}
            >
              Go to Parent Order
            </Button>
          )}
          {/* {!suborder?.suborder_id && (
            <Button
              width="fit-content"
              onClick={() => {
                if (suborder?.status === null) {
                  return;
                }
                {
                  suborder.line_items[0].related_suborder &&
                    navigate(
                      `/admin/order/suborder/${suborder.line_items[0]?.suborder_status}/${suborder.line_items[0]?.related_suborder}`
                    );
                }
              }}
              colorScheme="teal"
              variant="outline"
              border="1px solid teal"
              fontSize={{ base: "12px", md: "16px", lg: "16px" }}
            >
              Go to Suborder
            </Button>
          )} */}

          {(suborder?.financial_status === "paid" ||
            suborder?.financial_status === "partially_paid") && (
            <a
              href={`https://admin.shopify.com/store/fnp-qatar/orders/${id}/refund`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {!suborder.suborder_name && (
                <Button
                  colorScheme="teal"
                  variant="outline"
                  border="1px solid teal"
                >
                  Refund
                </Button>
              )}
            </a>
          )}
          {suborder?.suborder_id ? (
            <Button
              colorScheme="teal"
              variant="outline"
              border="1px solid teal"
              onClick={openSuborderChallanModal}
            >
              Print Challan
            </Button>
          ) : (
            <Button
              colorScheme="teal"
              variant="outline"
              border="1px solid teal"
              onClick={handlePrintBulkChallanProcess}
            >
              {challanloader ? <Spinner /> : "Print All Challan"}
            </Button>
          )}

          {suborder?.id ? (
            <Button
              onClick={() => {
                navigate(`/admin/order/editorder/${id}`);
              }}
              colorScheme="teal"
              variant="outline"
              border="1px solid teal"
              fontSize={{ base: "12px", md: "16px", lg: "16px" }}
            >
              Edit
            </Button>
          ) : null}
        </Flex>
      </Flex>

      <Flex m="10px 0" justifyContent={"space-evenly"}>
        <Box
          backgroundColor="white"
          borderRadius="7px"
          p="4"
          width="100%"
          boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
          mt="20px"
        >
          <Flex>
            <Box
              backgroundColor="white"
              borderRadius="7px"
              color="black"
              mt="20px"
              mr="10px"
              width="33%"
              boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            >
              {suborder?.suborder_name ? (
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Time And Date
                </Heading>
              ) : (
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Edit Time And Date
                </Heading>
              )}
              <Flex direction="column" gap="10px" p="4">
                <Flex>Delivery Date : {shipping_date}</Flex>
                <Flex>Delivery Time : {shipping_time}</Flex>
                <Flex mb="15px">Delivery Mode : {shipping_method}</Flex>

                {!suborder?.suborder_name && (
                  <Text
                    width="max-content"
                    color="blue"
                    _hover={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => openCityAndDateModal(suborder)}
                  >
                    Edit Time
                  </Text>
                )}
              </Flex>
            </Box>

            <Box
              backgroundColor="white"
              borderRadius="7px"
              color="black"
              mt="20px"
              ml="10px"
              mr="10px"
              width="33%"
              boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            >
              {suborder?.suborder_name ? (
                <Heading
                  as="h4"
                  p="4"
                  size="sm"
                  textAlign="left"
                  bgColor="#f6f6f6"
                >
                  Address
                </Heading>
              ) : (
                <Heading
                  as="h4"
                  p="4"
                  size="sm"
                  textAlign="left"
                  bgColor="#f6f6f6"
                >
                  Edit Address
                </Heading>
              )}

              <Flex direction="column" gap="10px" p="4">
                <Flex>City : {suborder?.shipping_address?.city}</Flex>
                <Flex textAlign="left">
                  Address :{suborder?.shipping_address?.first_name},{" "}
                  {suborder?.shipping_address?.last_name},{" "}
                  {suborder?.shipping_address?.address1},{" "}
                  {suborder?.shipping_address?.address2},
                  {suborder?.shipping_address?.phone}
                </Flex>
                {!suborder?.suborder_name && (
                  <Text
                    width="max-content"
                    color="blue"
                    _hover={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => openEditPropertyAddressModal(suborder_data)}
                  >
                    Edit Address
                  </Text>
                )}
              </Flex>
            </Box>
            <Box
              backgroundColor="white"
              borderRadius="7px"
              color="black"
              mt="20px"
              ml="10px"
              width="33%"
              boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            >
              {suborder?.suborder_name ? (
                <>
                  <Heading
                    as="h4"
                    size="sm"
                    p="15px"
                    mb="10px"
                    textAlign="left"
                  >
                    Sender Detail
                  </Heading>
                  <Text textAlign="left">
                    Sender Name : {suborder.sender_name}
                  </Text>
                </>
              ) : (
                <Flex flexDir="column">
                  <Heading
                    as="h4"
                    size="sm"
                    p="15px"
                    mb="10px"
                    textAlign="left"
                    bgColor="#f6f6f6"
                  >
                    Edit Sender Detail
                  </Heading>
                  <Text textAlign="left" p="15px">
                    Sender Name :{sender_name}
                  </Text>
                  {edit_sender_detail && (
                    <Box mb="10px" width="200px" p="15px">
                      <Input
                        placeholder="Enter Sender Detail"
                        onChange={(e) => {
                          setSenderDetail(e.target.value);
                        }}
                      />
                    </Box>
                  )}
                  <Flex gap="20px" p="15px">
                    {!edit_sender_detail && (
                      <Text
                        width="max-content"
                        color="blue"
                        mt="55px"
                        _hover={{
                          textDecorationLine: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => set_edit_sender_detail(true)}
                      >
                        Edit Detail
                      </Text>
                    )}
                    {edit_sender_detail && (
                      <Text
                        width="max-content"
                        color="red"
                        _hover={{
                          textDecorationLine: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => set_edit_sender_detail(false)}
                      >
                        Cancel Edit
                      </Text>
                    )}
                    {edit_sender_detail && (
                      <Text
                        width="max-content"
                        color="green"
                        _hover={{
                          textDecorationLine: "underline",
                          cursor: "pointer",
                        }}
                        onClick={HandleSaveSenderDetail}
                      >
                        {senderLoader ? "...saving" : "Save Edit"}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              )}
            </Box>
          </Flex>

          <Flex>
            <Box
              backgroundColor="white"
              borderRadius="7px"
              color="black"
              mt="20px"
              mb="15px"
              width="100%"
              boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            >
              <Heading
                as="h4"
                size="sm"
                textAlign="left"
                p="4"
                bgColor="#f6f6f6"
              >
                Special Instructions
              </Heading>
              <Box p="20px" textAlign="left">
                <Text>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </Text>
              </Box>
              {/* {suborder?.note_attributes[0].value} */}
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Flex>
        <Box
          backgroundColor="white"
          borderRadius="7px"
          color="black"
          mt="20px"
          mb="15px"
          width="100%"
          boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
        >
          <Flex
            justifyContent="space-between"
            backgroundColor="#f6f6f6"
            p="15px"
          >
            <Flex>
              <Text>
                <b>Sub-Order No.</b> : #2599_1
              </Text>
            </Flex>
          </Flex>

          <Box borderRadius="7px" p="4">
            {suborder?.line_items &&
              ForSelectingWhichIsSuborder(
                suborder?.line_items?.filter(
                  (p) => p.fulfillable_quantity !== 0
                )
              )?.map((order, i) => (
                <Box key={i}>
                  {order?.map((item, index) => {
                    if (!item.variant_id) {
                      // **************************   CONDITION FOR CUSTOM ITEM  ******************************
                      return (
                        <Box key={index}>
                          {item.fulfillable_quantity === 0 &&
                          item.fulfillment_status === null ? null : (
                            <Table color="black">
                              <Tbody>
                                <Tr>
                                  <Td>
                                    <Flex flexDirection="column" gap="10px">
                                      <Text
                                        fontSize="14px"
                                        fontWeight="bold"
                                        width="200%"
                                      >
                                        {item.name}
                                      </Text>
                                      <Text fontWeight="bold" color="gray.700">
                                        Custom Item
                                      </Text>
                                    </Flex>
                                  </Td>

                                  <Td>
                                    <Flex flexDirection="column" gap="20px">
                                      <Flex gap="5px">
                                        <Text>Quantity</Text>
                                        <Text>:</Text>
                                        <Text>
                                          {item?.fulfillment_status ===
                                          "fulfilled"
                                            ? item.quantity
                                            : item?.fulfillable_quantity}
                                        </Text>
                                      </Flex>
                                    </Flex>
                                  </Td>

                                  <Td>
                                    <Flex gap="5px">
                                      <Text>
                                        {
                                          item?.price_set?.shop_money
                                            ?.currency_code
                                        }
                                      </Text>
                                      <Text>
                                        {item?.price_set?.shop_money?.amount}
                                      </Text>
                                      <Text>x </Text>
                                      <Text>
                                        {item?.fulfillment_status ===
                                        "fulfilled"
                                          ? item.quantity
                                          : item?.fulfillable_quantity}
                                      </Text>
                                    </Flex>
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          )}
                        </Box>
                      );
                    } else {
                      return (
                        <div key={index}>
                          {!item.properties.some(
                            (obj) => obj.name === "_parent_id"
                          ) ? (
                            <Box key={index}>
                              {/* ************************  LINING ************************ */}
                              <Box
                                mt="10px"
                                width="100%"
                                height="2px"
                                bg="black"
                                mb="10px"
                                display={i == 0 ? "none" : "block"}
                              ></Box>
                              {item.fulfillable_quantity === 0 &&
                              item.fulfillment_status === null ? null : (
                                <Flex color="black" width="100%">
                                  <Image
                                    src={
                                      item?.properties.find(
                                        (p) =>
                                          p.name.trim() === "_product_image"
                                      )?.value
                                    }
                                    height="200px"
                                    width="200px"
                                  />
                                  <Flex
                                    ml="20px"
                                    width="100%"
                                    flexDirection="column"
                                    gap="10px"
                                    textAlign="left"
                                  >
                                    <Flex justifyContent="space-between">
                                      <Flex>
                                        <Text
                                          fontSize="16px"
                                          fontWeight="bold"
                                          // width="200%"
                                        >
                                          {item.name}
                                        </Text>
                                      </Flex>
                                      <Flex>
                                        <Flex flexDirection="column" gap="20px">
                                          <Flex gap="5px">
                                            <Text>Quantity</Text>
                                            <Text>:</Text>
                                            <Text color="red">
                                              {item?.fulfillment_status ===
                                              "fulfilled"
                                                ? item.quantity
                                                : item?.fulfillable_quantity}
                                            </Text>
                                          </Flex>
                                        </Flex>
                                      </Flex>
                                    </Flex>

                                    <Flex gap="100px">
                                      <Text
                                        color="gray.600"
                                        fontWeight="semibold"
                                      >
                                        SKU : {item?.sku}
                                      </Text>
                                    </Flex>

                                    <Flex gap="5px">
                                      Price:
                                      <Text>
                                        {
                                          item?.price_set?.shop_money
                                            ?.currency_code
                                        }
                                      </Text>
                                      <Text>
                                        {item?.price_set?.shop_money?.amount}
                                      </Text>
                                    </Flex>

                                    {suborder.remark_notes && index == 0 && (
                                      <Text
                                        color="red"
                                        mt="-10px"
                                        fontWeight="bold"
                                      >
                                        Remark :{suborder.remark_notes}
                                      </Text>
                                    )}
                                  </Flex>
                                  {/* GO TO SUBORDER BUTTON  */}

                                  {/* MAIN FLEX */}
                                  <Flex
                                    gap="20px"
                                    mt="70px"
                                    textAlign="left"
                                    flexDirection="column"
                                  ></Flex>
                                </Flex>
                              )}
                            </Box>
                          ) : (
                            <Box key={index} pl="150px">
                              <Divider mt="10px" mb="10px" />
                              {item.fulfillable_quantity === 0 &&
                              item.fulfillment_status === null ? null : (
                                <Flex
                                  color="black"
                                  width="100%"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Image
                                    src={
                                      item?.properties.find(
                                        (p) =>
                                          p.name.trim() === "_product_image"
                                      )?.value
                                    }
                                    height="60px"
                                    width="60px"
                                  />
                                  <Flex
                                    flexDirection="column"
                                    gap="10px"
                                    textAlign="left"
                                  >
                                    <Text
                                      ml="10px"
                                      fontSize="20px"
                                      fontWeight="bold"
                                      // width="200%"
                                    >
                                      {item.name}
                                    </Text>
                                    <Text
                                      color="gray.600"
                                      ml="10px"
                                      fontWeight="semibold"
                                    >
                                      sku : {item?.sku}
                                    </Text>
                                  </Flex>

                                  <Flex flexDirection="column" gap="20px">
                                    <Flex gap="5px" mt="30px">
                                      <Text>Quantity</Text>
                                      <Text>:</Text>
                                      <Text>
                                        {item?.fulfillment_status ===
                                        "fulfilled"
                                          ? item.quantity
                                          : item?.fulfillable_quantity}
                                      </Text>
                                    </Flex>
                                  </Flex>

                                  <Flex gap="5px" ml="50px" mt="30px">
                                    <Text>
                                      {
                                        item?.price_set?.shop_money
                                          ?.currency_code
                                      }
                                    </Text>
                                    <Text>
                                      {item?.price_set?.shop_money?.amount}
                                    </Text>
                                    <Text>x </Text>
                                    <Text>
                                      {item?.fulfillment_status === "fulfilled"
                                        ? item.quantity
                                        : item?.fulfillable_quantity}
                                    </Text>
                                  </Flex>
                                </Flex>
                              )}
                              <Divider mt="10px" mb="10px" />
                            </Box>
                          )}
                        </div>
                      );
                    }
                  })}
                </Box>
              ))}

            <Flex m="10px 0" justifyContent={"space-evenly"}>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Message on Card
                </Heading>
                <Box p="20px">
                  <Text as="del" color="gray.600">
                    <b>Old Message: </b>
                    <span color="#222222">Happi Birthday!</span>
                  </Text>
                  <Text pt="15px">
                    <b>Message: </b>
                    <span>Happy Birthday Dear!</span>{" "}
                    <Link to="#" color="blue">
                      Edit Message
                    </Link>
                  </Text>
                </Box>
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Message on Cake
                </Heading>
                <Box p="20px">
                  <Text as="del" color="gray.600">
                    <b>Old Message: </b>
                    <span color="#222222">Happi Birthday!</span>
                  </Text>
                  <Text pt="15px">
                    <b>Message: </b>
                    <span>Happy Birthday Dear!</span>{" "}
                    <Link to="#" color="blue">
                      Edit Message
                    </Link>
                  </Text>
                </Box>
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Occasion
                </Heading>
                <Box>
                  <Text mt="35px">Not available</Text>
                </Box>
              </Box>
            </Flex>
            <Flex m="10px 0" justifyContent={"space-evenly"}>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Personalized Image
                </Heading>
                <img src={PersonalizedImage} alt="fnp" paddingRight="8px" />
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Personalized Text
                </Heading>
                <Box>
                  <Text mt="35px">Not available</Text>
                </Box>
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Image Captured by Delivery Boy
                </Heading>
                <Box>
                  <Text mt="35px">Not available</Text>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>

      <Flex>
        <Box
          backgroundColor="white"
          borderRadius="7px"
          color="black"
          mt="20px"
          mb="15px"
          width="100%"
          boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
        >
          <Flex
            justifyContent="space-between"
            backgroundColor="#f6f6f6"
            p="15px"
          >
            <Flex>
              <Text>
                <b>Sub-Order No.</b> : #2599_2
              </Text>
            </Flex>
          </Flex>

          <Box borderRadius="7px" p="4">
            {suborder?.line_items &&
              ForSelectingWhichIsSuborder(
                suborder?.line_items?.filter(
                  (p) => p.fulfillable_quantity !== 0
                )
              )?.map((order, i) => (
                <Box key={i}>
                  {order?.map((item, index) => {
                    if (!item.variant_id) {
                      // **************************   CONDITION FOR CUSTOM ITEM  ******************************
                      return (
                        <Box key={index}>
                          {item.fulfillable_quantity === 0 &&
                          item.fulfillment_status === null ? null : (
                            <Table color="black">
                              <Tbody>
                                <Tr>
                                  <Td>
                                    <Flex flexDirection="column" gap="10px">
                                      <Text
                                        fontSize="14px"
                                        fontWeight="bold"
                                        width="200%"
                                      >
                                        {item.name}
                                      </Text>
                                      <Text fontWeight="bold" color="gray.700">
                                        Custom Item
                                      </Text>
                                    </Flex>
                                  </Td>

                                  <Td>
                                    <Flex flexDirection="column" gap="20px">
                                      <Flex gap="5px">
                                        <Text>Quantity</Text>
                                        <Text>:</Text>
                                        <Text>
                                          {item?.fulfillment_status ===
                                          "fulfilled"
                                            ? item.quantity
                                            : item?.fulfillable_quantity}
                                        </Text>
                                      </Flex>
                                    </Flex>
                                  </Td>

                                  <Td>
                                    <Flex gap="5px">
                                      <Text>
                                        {
                                          item?.price_set?.shop_money
                                            ?.currency_code
                                        }
                                      </Text>
                                      <Text>
                                        {item?.price_set?.shop_money?.amount}
                                      </Text>
                                      <Text>x </Text>
                                      <Text>
                                        {item?.fulfillment_status ===
                                        "fulfilled"
                                          ? item.quantity
                                          : item?.fulfillable_quantity}
                                      </Text>
                                    </Flex>
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          )}
                        </Box>
                      );
                    } else {
                      return (
                        <div key={index}>
                          {!item.properties.some(
                            (obj) => obj.name === "_parent_id"
                          ) ? (
                            <Box key={index}>
                              {/* ************************  LINING ************************ */}
                              <Box
                                mt="10px"
                                width="100%"
                                height="2px"
                                bg="black"
                                mb="10px"
                                display={i == 0 ? "none" : "block"}
                              ></Box>
                              {item.fulfillable_quantity === 0 &&
                              item.fulfillment_status === null ? null : (
                                <Flex color="black" width="100%">
                                  <Image
                                    src={
                                      item?.properties.find(
                                        (p) =>
                                          p.name.trim() === "_product_image"
                                      )?.value
                                    }
                                    height="200px"
                                    width="200px"
                                  />
                                  <Flex
                                    ml="20px"
                                    width="100%"
                                    flexDirection="column"
                                    gap="10px"
                                    textAlign="left"
                                  >
                                    <Flex justifyContent="space-between">
                                      <Flex>
                                        <Text
                                          fontSize="16px"
                                          fontWeight="bold"
                                          // width="200%"
                                        >
                                          {item.name}
                                        </Text>
                                      </Flex>
                                      <Flex>
                                        <Flex flexDirection="column" gap="20px">
                                          <Flex gap="5px">
                                            <Text>Quantity</Text>
                                            <Text>:</Text>
                                            <Text color="red">
                                              {item?.fulfillment_status ===
                                              "fulfilled"
                                                ? item.quantity
                                                : item?.fulfillable_quantity}
                                            </Text>
                                          </Flex>
                                        </Flex>
                                      </Flex>
                                    </Flex>

                                    <Flex gap="100px">
                                      <Text
                                        color="gray.600"
                                        fontWeight="semibold"
                                      >
                                        SKU : {item?.sku}
                                      </Text>
                                    </Flex>

                                    <Flex gap="5px">
                                      Price:
                                      <Text>
                                        {
                                          item?.price_set?.shop_money
                                            ?.currency_code
                                        }
                                      </Text>
                                      <Text>
                                        {item?.price_set?.shop_money?.amount}
                                      </Text>
                                    </Flex>

                                    {suborder.remark_notes && index == 0 && (
                                      <Text
                                        color="red"
                                        mt="-10px"
                                        fontWeight="bold"
                                      >
                                        Remark :{suborder.remark_notes}
                                      </Text>
                                    )}
                                  </Flex>
                                  {/* GO TO SUBORDER BUTTON  */}

                                  {/* MAIN FLEX */}
                                  <Flex
                                    gap="20px"
                                    mt="70px"
                                    textAlign="left"
                                    flexDirection="column"
                                  ></Flex>
                                </Flex>
                              )}
                            </Box>
                          ) : (
                            <Box key={index} pl="150px">
                              <Divider mt="10px" mb="10px" />
                              {item.fulfillable_quantity === 0 &&
                              item.fulfillment_status === null ? null : (
                                <Flex
                                  color="black"
                                  width="100%"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Image
                                    src={
                                      item?.properties.find(
                                        (p) =>
                                          p.name.trim() === "_product_image"
                                      )?.value
                                    }
                                    height="60px"
                                    width="60px"
                                  />
                                  <Flex
                                    flexDirection="column"
                                    gap="10px"
                                    textAlign="left"
                                  >
                                    <Text
                                      ml="10px"
                                      fontSize="20px"
                                      fontWeight="bold"
                                      // width="200%"
                                    >
                                      {item.name}
                                    </Text>
                                    <Text
                                      color="gray.600"
                                      ml="10px"
                                      fontWeight="semibold"
                                    >
                                      sku : {item?.sku}
                                    </Text>
                                  </Flex>

                                  <Flex flexDirection="column" gap="20px">
                                    <Flex gap="5px" mt="30px">
                                      <Text>Quantity</Text>
                                      <Text>:</Text>
                                      <Text>
                                        {item?.fulfillment_status ===
                                        "fulfilled"
                                          ? item.quantity
                                          : item?.fulfillable_quantity}
                                      </Text>
                                    </Flex>
                                  </Flex>

                                  <Flex gap="5px" ml="50px" mt="30px">
                                    <Text>
                                      {
                                        item?.price_set?.shop_money
                                          ?.currency_code
                                      }
                                    </Text>
                                    <Text>
                                      {item?.price_set?.shop_money?.amount}
                                    </Text>
                                    <Text>x </Text>
                                    <Text>
                                      {item?.fulfillment_status === "fulfilled"
                                        ? item.quantity
                                        : item?.fulfillable_quantity}
                                    </Text>
                                  </Flex>
                                </Flex>
                              )}
                              <Divider mt="10px" mb="10px" />
                            </Box>
                          )}
                        </div>
                      );
                    }
                  })}
                </Box>
              ))}

            <Flex m="10px 0" justifyContent={"space-evenly"}>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Message on Card
                </Heading>
                <Box p="20px">
                  <Text as="del" color="gray.600">
                    <b>Old Message: </b>
                    <span color="#222222">Happi Birthday!</span>
                  </Text>
                  <Text pt="15px">
                    <b>Message: </b>
                    <span>Happy Birthday Dear!</span>{" "}
                    <Link to="#" color="blue">
                      Edit Message
                    </Link>
                  </Text>
                </Box>
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Message on Cake
                </Heading>
                <Box p="20px">
                  <Text as="del" color="gray.600">
                    <b>Old Message: </b>
                    <span color="#222222">Happi Birthday!</span>
                  </Text>
                  <Text pt="15px">
                    <b>Message: </b>
                    <span>Happy Birthday Dear!</span>{" "}
                    <Link to="#" color="blue">
                      Edit Message
                    </Link>
                  </Text>
                </Box>
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Occasion
                </Heading>
                <Box>
                  <Text mt="35px">Not available</Text>
                </Box>
              </Box>
            </Flex>
            <Flex m="10px 0" justifyContent={"space-evenly"}>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Personalized Image
                </Heading>
                <img src={PersonalizedImage} alt="fnp" paddingRight="8px" />
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Personalized Text
                </Heading>
                <Box>
                  <Text mt="35px">Not available</Text>
                </Box>
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Image Captured by Delivery Boy
                </Heading>
                <Box>
                  <Text mt="35px">Not available</Text>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>

      <Flex>
        <Box
          backgroundColor="white"
          borderRadius="7px"
          color="black"
          mt="20px"
          mb="15px"
          width="100%"
          boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
        >
          <Flex
            justifyContent="space-between"
            backgroundColor="#f6f6f6"
            p="15px"
          >
            <Flex>
              <Text>
                <b>Sub-Order No.</b> : #2599_3
              </Text>
            </Flex>
          </Flex>

          <Box borderRadius="7px" p="4">
            {suborder?.line_items &&
              ForSelectingWhichIsSuborder(
                suborder?.line_items?.filter(
                  (p) => p.fulfillable_quantity !== 0
                )
              )?.map((order, i) => (
                <Box key={i}>
                  {order?.map((item, index) => {
                    if (!item.variant_id) {
                      // **************************   CONDITION FOR CUSTOM ITEM  ******************************
                      return (
                        <Box key={index}>
                          {item.fulfillable_quantity === 0 &&
                          item.fulfillment_status === null ? null : (
                            <Table color="black">
                              <Tbody>
                                <Tr>
                                  <Td>
                                    <Flex flexDirection="column" gap="10px">
                                      <Text
                                        fontSize="14px"
                                        fontWeight="bold"
                                        width="200%"
                                      >
                                        {item.name}
                                      </Text>
                                      <Text fontWeight="bold" color="gray.700">
                                        Custom Item
                                      </Text>
                                    </Flex>
                                  </Td>

                                  <Td>
                                    <Flex flexDirection="column" gap="20px">
                                      <Flex gap="5px">
                                        <Text>Quantity</Text>
                                        <Text>:</Text>
                                        <Text>
                                          {item?.fulfillment_status ===
                                          "fulfilled"
                                            ? item.quantity
                                            : item?.fulfillable_quantity}
                                        </Text>
                                      </Flex>
                                    </Flex>
                                  </Td>

                                  <Td>
                                    <Flex gap="5px">
                                      <Text>
                                        {
                                          item?.price_set?.shop_money
                                            ?.currency_code
                                        }
                                      </Text>
                                      <Text>
                                        {item?.price_set?.shop_money?.amount}
                                      </Text>
                                      <Text>x </Text>
                                      <Text>
                                        {item?.fulfillment_status ===
                                        "fulfilled"
                                          ? item.quantity
                                          : item?.fulfillable_quantity}
                                      </Text>
                                    </Flex>
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          )}
                        </Box>
                      );
                    } else {
                      return (
                        <div key={index}>
                          {!item.properties.some(
                            (obj) => obj.name === "_parent_id"
                          ) ? (
                            <Box key={index}>
                              {/* ************************  LINING ************************ */}
                              <Box
                                mt="10px"
                                width="100%"
                                height="2px"
                                bg="black"
                                mb="10px"
                                display={i == 0 ? "none" : "block"}
                              ></Box>
                              {item.fulfillable_quantity === 0 &&
                              item.fulfillment_status === null ? null : (
                                <Flex color="black" width="100%">
                                  <Image
                                    src={
                                      item?.properties.find(
                                        (p) =>
                                          p.name.trim() === "_product_image"
                                      )?.value
                                    }
                                    height="200px"
                                    width="200px"
                                  />
                                  <Flex
                                    ml="20px"
                                    width="100%"
                                    flexDirection="column"
                                    gap="10px"
                                    textAlign="left"
                                  >
                                    <Flex justifyContent="space-between">
                                      <Flex>
                                        <Text
                                          fontSize="16px"
                                          fontWeight="bold"
                                          // width="200%"
                                        >
                                          {item.name}
                                        </Text>
                                      </Flex>
                                      <Flex>
                                        <Flex flexDirection="column" gap="20px">
                                          <Flex gap="5px">
                                            <Text>Quantity</Text>
                                            <Text>:</Text>
                                            <Text color="red">
                                              {item?.fulfillment_status ===
                                              "fulfilled"
                                                ? item.quantity
                                                : item?.fulfillable_quantity}
                                            </Text>
                                          </Flex>
                                        </Flex>
                                      </Flex>
                                    </Flex>

                                    <Flex gap="100px">
                                      <Text
                                        color="gray.600"
                                        fontWeight="semibold"
                                      >
                                        SKU : {item?.sku}
                                      </Text>
                                    </Flex>

                                    <Flex gap="5px">
                                      Price:
                                      <Text>
                                        {
                                          item?.price_set?.shop_money
                                            ?.currency_code
                                        }
                                      </Text>
                                      <Text>
                                        {item?.price_set?.shop_money?.amount}
                                      </Text>
                                    </Flex>

                                    {suborder.remark_notes && index == 0 && (
                                      <Text
                                        color="red"
                                        mt="-10px"
                                        fontWeight="bold"
                                      >
                                        Remark :{suborder.remark_notes}
                                      </Text>
                                    )}
                                  </Flex>
                                  {/* GO TO SUBORDER BUTTON  */}

                                  {/* MAIN FLEX */}
                                  <Flex
                                    gap="20px"
                                    mt="70px"
                                    textAlign="left"
                                    flexDirection="column"
                                  ></Flex>
                                </Flex>
                              )}
                            </Box>
                          ) : (
                            <Box key={index} pl="150px">
                              <Divider mt="10px" mb="10px" />
                              {item.fulfillable_quantity === 0 &&
                              item.fulfillment_status === null ? null : (
                                <Flex
                                  color="black"
                                  width="100%"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Image
                                    src={
                                      item?.properties.find(
                                        (p) =>
                                          p.name.trim() === "_product_image"
                                      )?.value
                                    }
                                    height="60px"
                                    width="60px"
                                  />
                                  <Flex
                                    flexDirection="column"
                                    gap="10px"
                                    textAlign="left"
                                  >
                                    <Text
                                      ml="10px"
                                      fontSize="20px"
                                      fontWeight="bold"
                                      // width="200%"
                                    >
                                      {item.name}
                                    </Text>
                                    <Text
                                      color="gray.600"
                                      ml="10px"
                                      fontWeight="semibold"
                                    >
                                      sku : {item?.sku}
                                    </Text>
                                  </Flex>

                                  <Flex flexDirection="column" gap="20px">
                                    <Flex gap="5px" mt="30px">
                                      <Text>Quantity</Text>
                                      <Text>:</Text>
                                      <Text>
                                        {item?.fulfillment_status ===
                                        "fulfilled"
                                          ? item.quantity
                                          : item?.fulfillable_quantity}
                                      </Text>
                                    </Flex>
                                  </Flex>

                                  <Flex gap="5px" ml="50px" mt="30px">
                                    <Text>
                                      {
                                        item?.price_set?.shop_money
                                          ?.currency_code
                                      }
                                    </Text>
                                    <Text>
                                      {item?.price_set?.shop_money?.amount}
                                    </Text>
                                    <Text>x </Text>
                                    <Text>
                                      {item?.fulfillment_status === "fulfilled"
                                        ? item.quantity
                                        : item?.fulfillable_quantity}
                                    </Text>
                                  </Flex>
                                </Flex>
                              )}
                              <Divider mt="10px" mb="10px" />
                            </Box>
                          )}
                        </div>
                      );
                    }
                  })}
                </Box>
              ))}

            <Flex m="10px 0" justifyContent={"space-evenly"}>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Message on Card
                </Heading>
                <Box p="20px">
                  <Text as="del" color="gray.600">
                    <b>Old Message: </b>
                    <span color="#222222">Happi Birthday!</span>
                  </Text>
                  <Text pt="15px">
                    <b>Message: </b>
                    <span>Happy Birthday Dear!</span>{" "}
                    <Link to="#" color="blue">
                      Edit Message
                    </Link>
                  </Text>
                </Box>
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Message on Cake
                </Heading>
                <Box p="20px">
                  <Text as="del" color="gray.600">
                    <b>Old Message: </b>
                    <span color="#222222">Happi Birthday!</span>
                  </Text>
                  <Text pt="15px">
                    <b>Message: </b>
                    <span>Happy Birthday Dear!</span>{" "}
                    <Link to="#" color="blue">
                      Edit Message
                    </Link>
                  </Text>
                </Box>
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Occasion
                </Heading>
                <Box>
                  <Text mt="35px">Not available</Text>
                </Box>
              </Box>
            </Flex>
            <Flex m="10px 0" justifyContent={"space-evenly"}>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Personalized Image
                </Heading>
                <img src={PersonalizedImage} alt="fnp" paddingRight="8px" />
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Personalized Text
                </Heading>
                <Box>
                  <Text mt="35px">Not available</Text>
                </Box>
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Image Captured by Delivery Boy
                </Heading>
                <Box>
                  <Text mt="35px">Not available</Text>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>

      <Flex>
        <Box
          backgroundColor="white"
          borderRadius="7px"
          color="black"
          mt="20px"
          mb="15px"
          width="100%"
          boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
        >
          <Flex
            justifyContent="space-between"
            backgroundColor="#f6f6f6"
            p="15px"
          >
            <Flex>
              <Text>
                <b>Sub-Order No.</b> : #2599_4
              </Text>
            </Flex>
          </Flex>

          <Box borderRadius="7px" p="4">
            {suborder?.line_items &&
              ForSelectingWhichIsSuborder(
                suborder?.line_items?.filter(
                  (p) => p.fulfillable_quantity !== 0
                )
              )?.map((order, i) => (
                <Box key={i}>
                  {order?.map((item, index) => {
                    if (!item.variant_id) {
                      // **************************   CONDITION FOR CUSTOM ITEM  ******************************
                      return (
                        <Box key={index}>
                          {item.fulfillable_quantity === 0 &&
                          item.fulfillment_status === null ? null : (
                            <Table color="black">
                              <Tbody>
                                <Tr>
                                  <Td>
                                    <Flex flexDirection="column" gap="10px">
                                      <Text
                                        fontSize="14px"
                                        fontWeight="bold"
                                        width="200%"
                                      >
                                        {item.name}
                                      </Text>
                                      <Text fontWeight="bold" color="gray.700">
                                        Custom Item
                                      </Text>
                                    </Flex>
                                  </Td>

                                  <Td>
                                    <Flex flexDirection="column" gap="20px">
                                      <Flex gap="5px">
                                        <Text>Quantity</Text>
                                        <Text>:</Text>
                                        <Text>
                                          {item?.fulfillment_status ===
                                          "fulfilled"
                                            ? item.quantity
                                            : item?.fulfillable_quantity}
                                        </Text>
                                      </Flex>
                                    </Flex>
                                  </Td>

                                  <Td>
                                    <Flex gap="5px">
                                      <Text>
                                        {
                                          item?.price_set?.shop_money
                                            ?.currency_code
                                        }
                                      </Text>
                                      <Text>
                                        {item?.price_set?.shop_money?.amount}
                                      </Text>
                                      <Text>x </Text>
                                      <Text>
                                        {item?.fulfillment_status ===
                                        "fulfilled"
                                          ? item.quantity
                                          : item?.fulfillable_quantity}
                                      </Text>
                                    </Flex>
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          )}
                        </Box>
                      );
                    } else {
                      return (
                        <div key={index}>
                          {!item.properties.some(
                            (obj) => obj.name === "_parent_id"
                          ) ? (
                            <Box key={index}>
                              {/* ************************  LINING ************************ */}
                              <Box
                                mt="10px"
                                width="100%"
                                height="2px"
                                bg="black"
                                mb="10px"
                                display={i == 0 ? "none" : "block"}
                              ></Box>
                              {item.fulfillable_quantity === 0 &&
                              item.fulfillment_status === null ? null : (
                                <Flex color="black" width="100%">
                                  <Image
                                    src={
                                      item?.properties.find(
                                        (p) =>
                                          p.name.trim() === "_product_image"
                                      )?.value
                                    }
                                    height="200px"
                                    width="200px"
                                  />
                                  <Flex
                                    ml="20px"
                                    width="100%"
                                    flexDirection="column"
                                    gap="10px"
                                    textAlign="left"
                                  >
                                    <Flex justifyContent="space-between">
                                      <Flex>
                                        <Text
                                          fontSize="16px"
                                          fontWeight="bold"
                                          // width="200%"
                                        >
                                          {item.name}
                                        </Text>
                                      </Flex>
                                      <Flex>
                                        <Flex flexDirection="column" gap="20px">
                                          <Flex gap="5px">
                                            <Text>Quantity</Text>
                                            <Text>:</Text>
                                            <Text color="red">
                                              {item?.fulfillment_status ===
                                              "fulfilled"
                                                ? item.quantity
                                                : item?.fulfillable_quantity}
                                            </Text>
                                          </Flex>
                                        </Flex>
                                      </Flex>
                                    </Flex>

                                    <Flex gap="100px">
                                      <Text
                                        color="gray.600"
                                        fontWeight="semibold"
                                      >
                                        SKU : {item?.sku}
                                      </Text>
                                    </Flex>

                                    <Flex gap="5px">
                                      Price:
                                      <Text>
                                        {
                                          item?.price_set?.shop_money
                                            ?.currency_code
                                        }
                                      </Text>
                                      <Text>
                                        {item?.price_set?.shop_money?.amount}
                                      </Text>
                                    </Flex>

                                    {suborder.remark_notes && index == 0 && (
                                      <Text
                                        color="red"
                                        mt="-10px"
                                        fontWeight="bold"
                                      >
                                        Remark :{suborder.remark_notes}
                                      </Text>
                                    )}
                                  </Flex>
                                  {/* GO TO SUBORDER BUTTON  */}

                                  {/* MAIN FLEX */}
                                  <Flex
                                    gap="20px"
                                    mt="70px"
                                    textAlign="left"
                                    flexDirection="column"
                                  ></Flex>
                                </Flex>
                              )}
                            </Box>
                          ) : (
                            <Box key={index} pl="150px">
                              <Divider mt="10px" mb="10px" />
                              {item.fulfillable_quantity === 0 &&
                              item.fulfillment_status === null ? null : (
                                <Flex
                                  color="black"
                                  width="100%"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Image
                                    src={
                                      item?.properties.find(
                                        (p) =>
                                          p.name.trim() === "_product_image"
                                      )?.value
                                    }
                                    height="60px"
                                    width="60px"
                                  />
                                  <Flex
                                    flexDirection="column"
                                    gap="10px"
                                    textAlign="left"
                                  >
                                    <Text
                                      ml="10px"
                                      fontSize="20px"
                                      fontWeight="bold"
                                      // width="200%"
                                    >
                                      {item.name}
                                    </Text>
                                    <Text
                                      color="gray.600"
                                      ml="10px"
                                      fontWeight="semibold"
                                    >
                                      sku : {item?.sku}
                                    </Text>
                                  </Flex>

                                  <Flex flexDirection="column" gap="20px">
                                    <Flex gap="5px" mt="30px">
                                      <Text>Quantity</Text>
                                      <Text>:</Text>
                                      <Text>
                                        {item?.fulfillment_status ===
                                        "fulfilled"
                                          ? item.quantity
                                          : item?.fulfillable_quantity}
                                      </Text>
                                    </Flex>
                                  </Flex>

                                  <Flex gap="5px" ml="50px" mt="30px">
                                    <Text>
                                      {
                                        item?.price_set?.shop_money
                                          ?.currency_code
                                      }
                                    </Text>
                                    <Text>
                                      {item?.price_set?.shop_money?.amount}
                                    </Text>
                                    <Text>x </Text>
                                    <Text>
                                      {item?.fulfillment_status === "fulfilled"
                                        ? item.quantity
                                        : item?.fulfillable_quantity}
                                    </Text>
                                  </Flex>
                                </Flex>
                              )}
                              <Divider mt="10px" mb="10px" />
                            </Box>
                          )}
                        </div>
                      );
                    }
                  })}
                </Box>
              ))}

            <Flex m="10px 0" justifyContent={"space-evenly"}>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Message on Card
                </Heading>
                <Box p="20px">
                  <Text as="del" color="gray.600">
                    <b>Old Message: </b>
                    <span color="#222222">Happi Birthday!</span>
                  </Text>
                  <Text pt="15px">
                    <b>Message: </b>
                    <span>Happy Birthday Dear!</span>{" "}
                    <Link to="#" color="blue">
                      Edit Message
                    </Link>
                  </Text>
                </Box>
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Message on Cake
                </Heading>
                <Box p="20px">
                  <Text as="del" color="gray.600">
                    <b>Old Message: </b>
                    <span color="#222222">Happi Birthday!</span>
                  </Text>
                  <Text pt="15px">
                    <b>Message: </b>
                    <span>Happy Birthday Dear!</span>{" "}
                    <Link to="#" color="blue">
                      Edit Message
                    </Link>
                  </Text>
                </Box>
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Occasion
                </Heading>
                <Box>
                  <Text mt="35px">Not available</Text>
                </Box>
              </Box>
            </Flex>
            <Flex m="10px 0" justifyContent={"space-evenly"}>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Personalized Image
                </Heading>
                <img src={PersonalizedImage} alt="fnp" paddingRight="8px" />
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                mr="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Personalized Text
                </Heading>
                <Box>
                  <Text mt="35px">Not available</Text>
                </Box>
              </Box>
              <Box
                backgroundColor="white"
                borderRadius="7px"
                color="black"
                mt="20px"
                ml="10px"
                width="33%"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Heading
                  as="h4"
                  size="sm"
                  textAlign="left"
                  p="4"
                  bgColor="#f6f6f6"
                >
                  Image Captured by Delivery Boy
                </Heading>
                <Box>
                  <Text mt="35px">Not available</Text>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>

      <Flex justifyContent="space-between">
        <Flex width="50%">
        <Box><img src={timeline} alt="Timeline" maxW="100%" maxH="450px" /></Box>
          
        </Flex>
        <Flex width="50%">
          <Box bgColor="#F5F5F5">
            {/* ********************************* FULLFILLED BOX ************************************************* */}
            {showBoxOrNot(suborder?.line_items) ? (
              <Box
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
                backgroundColor="white"
                color="black"
                mt="30px"
                borderRadius="7px"
                p="3"
                mb="20px"
                textAlign="left"
              >
                <br />
                <Flex gap="15px" mb="10px">
                  <Box
                    height="fit-content"
                    border="5px solid #A1EDD0"
                    borderRadius="50%"
                  >
                    <BiCheckCircle fontSize="20px" color="#A1EDE9" />
                  </Box>
                  <Heading
                    as="h5"
                    size="md"
                    fontFamily="NexaText-Trial-Bold"
                    mb="15px"
                    mt="5px"
                  >
                    fulfilled
                  </Heading>
                </Flex>
                {ForSelectingWhichIsSuborder(
                  suborder?.line_items.filter(
                    (k) => k.fulfillment_status === "fulfilled"
                  )
                )?.map((order, i) => (
                  <div key={i}>
                    <Box
                      mt="10px"
                      width="100%"
                      height="2px"
                      bg="black"
                      display={i == 0 ? "none" : "block"}
                    ></Box>
                    {order.map((item, i) => (
                      <Box key={i} mb="30px">
                        {!item.properties.some(
                          (obj) => obj?.name === "_parent_id"
                        ) ? (
                          <Flex justifyContent="space-between" mb="10px">
                            <Flex gap="20px">
                              <Image
                                src={
                                  item?.properties?.find(
                                    (p) => p?.name?.trim() === "_product_image"
                                  )?.value
                                }
                                height="80px"
                                width="80px"
                              />
                              <Flex
                                direction="column"
                                fontSize="14px"
                                mt="-5px"
                              >
                                <Text fontWeight="semibold">{item.title}</Text>
                                <Text color="gray.500">SKU: {item?.sku}</Text>
                                <Text>
                                  {item.quantity} X QAR {item?.price}
                                </Text>
                              </Flex>
                            </Flex>
                            <Text>QAR {+item?.quantity * +item?.price}</Text>
                          </Flex>
                        ) : (
                          <Box>
                            {/* <Divider /> */}
                            <Flex
                              justifyContent="space-between"
                              mb="10px"
                              ml="100px"
                              mt="20px"
                            >
                              <Flex gap="20px">
                                <Image
                                  src={
                                    item?.properties.find(
                                      (p) => p.name === "_product_image"
                                    )?.value
                                  }
                                  height="80px"
                                  width="80px"
                                />
                                <Flex
                                  direction="column"
                                  fontSize="14px"
                                  mt="-5px"
                                >
                                  <Text fontWeight="semibold">
                                    {item.title}
                                  </Text>
                                  <Text color="gray.500">SKU: {item?.sku}</Text>
                                  <Text>
                                    {item.quantity} X QAR {item?.price}
                                  </Text>
                                </Flex>
                              </Flex>
                              <Text>QAR {+item?.quantity * +item?.price}</Text>
                            </Flex>
                          </Box>
                        )}
                      </Box>
                    ))}
                  </div>
                ))}
                <Divider />
                <Text mt="10px" color="gray.500" fontWeight="semibold">
                  Fulfilled Orders Can't be Changed
                </Text>
              </Box>
            ) : null}
            {!suborder?.suborder_id && (
              <>
                {suborder?.financial_status === "paid" && <PaidComp />}
                {suborder?.financial_status !== "paid" && <Partiallypaid />}
              </>
            )}
            {suborder?.suborder_id && <TimelineComp suborder={suborder} />}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default OrderDetail;
